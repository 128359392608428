import React from 'react';
import '../styles/FullscreenCalculator.css';
import { SellerFinanceCalculator, SubtoCalculator } from './';

function FullscreenCalculator(props) {
    const strategyPanel = props.strategyPanel;
    const setStrategyPanel = props.setStrategyPanel;
    const property = props.property;
    const highLevelProfits = props.highLevelProfits;
    const setHighLevelProfits = props.setHighLevelProfits;
    const price = props.price;
    const setPrice = props.setPrice;
    const downPayment = props.downPayment;
    const setDownPayment = props.setDownPayment;
    const interestRate = props.interestRate;
    const setInterestRate = props.setInterestRate;
    const balloonPayment = props.balloonPayment;
    const setBalloonPayment = props.setBalloonPayment;
    const duration = props.duration;
    const setDuration = props.setDuration;
    const amortisation = props.amortisation;
    const setAmortisation = props.setAmortisation;
    const monthlyPayment = props.monthlyPayment;
    const setMonthlyPayment = props.setMonthlyPayment;
    const sellerCompensation = props.sellerCompensation;
    const setSellerCompensation = props.setSellerCompensation;
    const viewRentalComps = props.viewRentalComps;
    const mobileBar = props.mobileBar;
    const toggleMobileBottomBar = props.toggleMobileBottomBar;
    const cityId = props.cityId;
    const ownerEquity = props.ownerEquity;
    const defaultDownPayment = props.defaultDownPayment;
    const subToDisabled = props.subToDisabled;
    const setFullScreenFinancials = props.setFullScreenFinancials;
    const fullScreenFinancials = props.fullScreenFinancials;
    const setOwnerEquity = props.setOwnerEquity;
    const devMode = props.devMode;
    const openDealOptimiser = props.openDealOptimiser;
    const costPerSqFoot = props.costPerSqFoot;
    const setCostPerSqFoot = props.setCostPerSqFoot;
    const totalRehabCost = props.totalRehabCost;
    const setTotalRehabCost = props.setTotalRehabCost;
    const defaultCostPerSqFoot = props.defaultCostPerSqFoot;
    const blurred = props.blurred;
    const exitStrategy = props.exitStrategy;
    const setExitStrategy = props.setExitStrategy;
    const leaseOptionValue = props.leaseOptionValue;
    const setLeaseOptionValue = props.setLeaseOptionValue;
    const leaseOptionDownPayment = props.leaseOptionDownPayment;
    const setLeaseOptionDownPayment = props.setLeaseOptionDownPayment;
    const leaseOptionDollarDownPayment = props.leaseOptionDollarDownPayment;
    const setLeaseOptionDollarDownPayment = props.setLeaseOptionDollarDownPayment;
    const leaseOptionInterestRate = props.leaseOptionInterestRate;
    const setLeaseOptionInterestRate = props.setLeaseOptionInterestRate;
    const strRevenue = props.strRevenue;
    const setStrRevenue = props.setStrRevenue;
    const remainingMortgage = props.remainingMortgage;
    const setRemainingMortgage = props.setRemainingMortgage;
    const sellersMortgage = props.sellersMortgage;
    const setSellersMortgage = props.setSellersMortgage;

    return (
        <div className="full-screen-calculator-outer-container">
            <div className="full-screen-calculator-inner-container relative-container">
                <div className="full-screen-calculator-exit-container">

                </div>
                {
                    strategyPanel === 0 ?
                    <SellerFinanceCalculator
                        property={property}
                        highLevelProfits={highLevelProfits}
                        setHighLevelProfits={setHighLevelProfits}
                        price={price}
                        setPrice={setPrice}
                        downPayment={downPayment}
                        setDownPayment={setDownPayment}
                        interestRate={interestRate}
                        setInterestRate={setInterestRate}
                        balloonPayment={balloonPayment}
                        setBalloonPayment={setBalloonPayment}
                        duration={duration}
                        setDuration={setDuration}
                        amortisation={amortisation}
                        setAmortisation={setAmortisation}
                        monthlyPayment={monthlyPayment}
                        setMonthlyPayment={setMonthlyPayment}
                        sellerCompensation={sellerCompensation}
                        setSellerCompensation={setSellerCompensation}
                        viewRentalComps={viewRentalComps}
                        mobileBar={mobileBar}
                        toggleMobileBottomBar={toggleMobileBottomBar}
                        cityId={cityId}
                        strategyPanel={strategyPanel}
                        setStrategyPanel={setStrategyPanel}
                        subToDisabled={subToDisabled}
                        defaultDownPayment={defaultDownPayment}
                        setFullScreenFinancials={setFullScreenFinancials}
                        fullScreenFinancials={fullScreenFinancials}
                        devMode={devMode}
                        openDealOptimiser={openDealOptimiser}
                        costPerSqFoot={costPerSqFoot}
                        setCostPerSqFoot={setCostPerSqFoot}
                        totalRehabCost={totalRehabCost}
                        setTotalRehabCost={setTotalRehabCost}
                        defaultCostPerSqFoot={defaultCostPerSqFoot}
                        blurred={blurred}
                        exitStrategy={exitStrategy}
                        setExitStrategy={setExitStrategy}
                        leaseOptionValue={leaseOptionValue}
                        setLeaseOptionValue={setLeaseOptionValue}
                        leaseOptionDownPayment={leaseOptionDownPayment}
                        setLeaseOptionDownPayment={setLeaseOptionDownPayment}
                        leaseOptionDollarDownPayment={leaseOptionDollarDownPayment}
                        setLeaseOptionDollarDownPayment={setLeaseOptionDollarDownPayment}
                        leaseOptionInterestRate={leaseOptionInterestRate}
                        setLeaseOptionInterestRate={setLeaseOptionInterestRate}
                        strRevenue={strRevenue}
					    setStrRevenue={setStrRevenue}
                    />
                    :
                    <SubtoCalculator
                        property={property}
                        highLevelProfits={highLevelProfits}
                        setHighLevelProfits={setHighLevelProfits}
                        price={price}
                        setPrice={setPrice}
                        downPayment={downPayment}
                        setDownPayment={setDownPayment}
                        interestRate={interestRate}
                        setInterestRate={setInterestRate}
                        balloonPayment={balloonPayment}
                        setBalloonPayment={setBalloonPayment}
                        duration={duration}
                        setDuration={setDuration}
                        amortisation={amortisation}
                        setAmortisation={setAmortisation}
                        monthlyPayment={monthlyPayment}
                        setMonthlyPayment={setMonthlyPayment}
                        sellerCompensation={sellerCompensation}
                        setSellerCompensation={setSellerCompensation}
                        viewRentalComps={viewRentalComps}
                        mobileBar={mobileBar}
                        toggleMobileBottomBar={toggleMobileBottomBar}
                        cityId={cityId}
                        strategyPanel={strategyPanel}
                        setStrategyPanel={setStrategyPanel}
                        ownerEquity={ownerEquity}
                        defaultDownPayment={defaultDownPayment}
                        setFullScreenFinancials={setFullScreenFinancials}
                        fullScreenFinancials={fullScreenFinancials}
                        devMode={devMode}
                        openDealOptimiser={openDealOptimiser}
                        costPerSqFoot={costPerSqFoot}
                        setCostPerSqFoot={setCostPerSqFoot}
                        totalRehabCost={totalRehabCost}
                        setTotalRehabCost={setTotalRehabCost}
                        defaultCostPerSqFoot={defaultCostPerSqFoot}
                        setOwnerEquity={setOwnerEquity}
                        blurred={blurred}
                        exitStrategy={exitStrategy}
                        setExitStrategy={setExitStrategy}
                        leaseOptionValue={leaseOptionValue}
                        setLeaseOptionValue={setLeaseOptionValue}
                        leaseOptionDownPayment={leaseOptionDownPayment}
                        setLeaseOptionDownPayment={setLeaseOptionDownPayment}
                        leaseOptionDollarDownPayment={leaseOptionDollarDownPayment}
                        setLeaseOptionDollarDownPayment={setLeaseOptionDollarDownPayment}
                        leaseOptionInterestRate={leaseOptionInterestRate}
                        setLeaseOptionInterestRate={setLeaseOptionInterestRate}
                        strRevenue={strRevenue}
					    setStrRevenue={setStrRevenue}
                        remainingMortgage={remainingMortgage}
                        setRemainingMortgage={setRemainingMortgage}
                        sellersMortgage={sellersMortgage}
                        setSellersMortgage={setSellersMortgage}
                    />
                }
            </div>
        </div>
    )
};

export default FullscreenCalculator;