import React from 'react';
import '../styles/EditStrategy.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CustomRadio } from '../styles/GlobalStyles';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';

function EditListingType(props) {
    const fsbo = props.fsbo;
    const changeFSBOFilter = props.changeFSBOFilter;

    const listingTypeRadios = [
        {
            label: "Agent listings & For sale by owner",
            value: false
        },
        {
            label: "Only FSBO",
            value: true
        }
    ];

    return (
        <div className="edit-strategies-outer-container">
            <div className="edit-strategies-inner-container">
                <div className="edit-strategies-title-container margin-large">
                    <span className="body-semibold colour-primary">
                        Listing types
                    </span>
                </div>
                <FormControl className="">
                    <RadioGroup
                        aria-labelledby="fixer-upper-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={fsbo}
                        className="profile-buy-box-radio-column"
                        onChange={(val) => changeFSBOFilter(val.target.value)}
                    >
                    {
                        listingTypeRadios.map((item, index) =>
                            <FormControlLabel 
                                value={item.value} 
                                control={<CustomRadio />} 
                                className={index === 0 ? "margin-large" : ""}
                                label={
                                    <div className="edit-strategies-label-container">
                                        <span className="body-regular colour-primary">
                                            {item.label}
                                        </span>
                                    </div>
                                }
                                key={index}
                            />
                        )}
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    )
};

export default EditListingType;