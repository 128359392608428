import React, { Fragment, useState } from 'react';
import { FinancialsInputNoLabel } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import { ChangeDot } from '../assets';
import { DefaultTooltip, RentAccordion } from '../components';

function SFHRent(props) {
    const rent = props.rent;
    const blurred = props.blurred;
    const onChangeRent = props.onChangeRent;
    const defaultRent = props.defaultRent;
    const strategyPanel = props.strategyPanel;
    const resetRentEstimates = props.resetRentEstimates;
    const property = props.property;
    const [rentTooltip, setRentTooltip] = useState(false);
    const [rentAccordion, setRentAccordion] = useState(false);

    const handleRentAccordion = () => {
        setRentAccordion(!rentAccordion);
    };

    return (
        <div className="rent-tab-single-family-container">
            <div className="button-row margin-x-small">
                <span className="body-semibold colour-primary block-text subtext-margin-right">
                    Rent
                </span>
                <div 
                    className="relative-container cursor-pointer"
                    onMouseEnter={() => setRentTooltip(true)}
                    onMouseLeave={() => setRentTooltip(false)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" fill="#716E69"/>
                        <path d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" fill="#716E69"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" fill="#716E69"/>
                    </svg>
                    {
                        rentTooltip === true ?
                        <DefaultTooltip
                            title={"How do we estimate rent?"}
                            description={"Our Rent Estimate is an estimated monthly rental price, computed using a proprietary formula. We review local rental comparables and other industry estimates. It is a starting point in determining the monthly rental price for a specific property."}
                        />
                        :
                        null
                    }
                </div>
            </div>
            <div className="button-row margin-medium">
                <div className="button-row strategies-panel-financials-input">
                    <FinancialsInputNoLabel
                        value={blurred === true ? "999" : rent}
                        type="number"
                        label={
                            Number(rent) !== property.financials.rent ?
                            <Fragment>
                                <img
                                    src={ChangeDot}
                                    className="financials-panel-change-dot"
                                    alt="Change"
                                />
                            </Fragment>
                            :
                            null
                        }
                        id={`rent-input`}
                        placeholder={`${rent}`}
                        onWheel={(e) => e.target.blur()}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        disabled={blurred === true ? true : strategyPanel === 3 ? true : false}
                        onChange={(text) => onChangeRent(text.target.value)}
                    />
                </div>
                {
                    blurred === false ?
                    <div 
                        className={"new-financials-costs-recurring-costs-reset-container " + (Number(rent) !== Number(defaultRent) ? "" : "view-none")}
                        onClick={() => strategyPanel === 3 ? null : resetRentEstimates()}
                    >
                        <span className="label-semibold colour-link">
                            Reset
                        </span>
                    </div>
                    :
                    null
                }
            </div>
            {
                property.financials.rent > 0 ?
                <RentAccordion
                    rentAccordion={rentAccordion}
                    handleRentAccordion={handleRentAccordion}
                    property={property}
                />
                :
                null
            }
        </div>
    )
};

export default SFHRent;