import React, { useState } from 'react';
import '../styles/MultipleBadges.css';
import { FixerUpper } from '../assets';

function MultipleBadges(props) {
	const property = props.property;
	const inherited = property.ownerAttributes !== undefined && property.ownerAttributes !== null && property.ownerAttributes.inherited === true ? true : false;
	const fsbo = property.fsbo === true ? true : false;
	const backOnMarket = property.backOnMarket === true ? true : false;
	const fixerUpper = property.fixerUpper;
	
	const [hoverIndex, setHoverIndex] = useState(null);
	const [hoverOutIndex, setHoverOutIndex] = useState(null);

	const badges = [
		{
			label: "Inherited",
			visible: inherited,
			emoji: "🫴",
			icon: ""
		},
		{
			label: "FSBO",
			visible: fsbo,
			emoji: "👨",
			icon: ""
		},
		{
			label: "Back on Market",
			visible: backOnMarket,
			emoji: "🔄",
			icon: ""
		},
		{
			label: "Fixer Upper",
			visible: fixerUpper,
			emoji: "",
			icon: FixerUpper
		}
	];

	const hoverIn = (index) => {
		setHoverIndex(index)
		setHoverOutIndex(null);
	};

	const hoverOut = (index) => {
		setHoverIndex(null);
		setHoverOutIndex(index);


		setTimeout(() => {
			setHoverOutIndex(null);
		}, 250);
	};

	return (
		<div className="multiple-badges-outer-container">
			{
				badges.map((item, index) =>
					item.visible === true ?
					<div 
						className={"multiple-badges-element " + (index === hoverIndex ? "multiple-badges-element-hover" : "multiple-badges-element-hover-out") + (hoverIndex !== index && hoverOutIndex !== index ? " multiple-badges-text-none" : "")}
						key={index}
						onMouseOver={() => hoverIn(index)}
						onMouseOut={() => hoverOut(index)}
					>
						{
							item.emoji === "" ?
							<img
								src={item.icon}
								className="multiple-badges-icon"
								alt={item.label}
							/>
							:
							<span className="multiple-badges-emoji">
								{item.emoji}
							</span>
						}
						<span className="multiple-badges-text">
							{item.label}
						</span>
					</div>
					:
					null
				)
			}
		</div>
	)
}

export default MultipleBadges;