import React, { useState, useEffect } from 'react';
import '../styles/Calculators.css';
import { Header, Footer, SellerFinanceCalculatorStandalone } from '../components';

function Calculators() {

    const [downPayment, setDownPayment] = useState(10);
	const [interestRate, setInterestRate] = useState(3.5);
	const [highLevelProfits, setHighLevelProfits] = useState("");
	const [price, setPrice] = useState(0);
    const [balloonPayment, setBalloonPayment] = useState(0);
    const [duration, setDuration] = useState(60);
    const [amortisation, setAmortisation] = useState(360);
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [sellerCompensation, setSellerCompensation] = useState(price);
	const [fullScreenFinancials, setFullScreenFinancials] = useState(false);
	const [costPerSqFoot, setCostPerSqFoot] = useState(0);
	const [totalRehabCost, setTotalRehabCost] = useState(0);
	const [defaultCostPerSqFoot] = useState(0);
	const [exitStrategy, setExitStrategy] = useState(0);
	const [leaseOptionValue, setLeaseOptionValue] = useState(0);
	const [leaseOptionDownPayment, setLeaseOptionDownPayment] = useState(downPayment);
	const [leaseOptionInterestRate, setLeaseOptionInterestRate] = useState(8);
	const [strRevenue, setStrRevenue] = useState("");
    const [strategyPanel, setStrategyPanel] = useState(0);
	const defaultDownPayment = 10;

    useEffect(() => {
        document.title = "Calculators | Coffee Clozers";
    }, []);

    return (
        <div className="">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            <div className="calculators-inner-container">
                <SellerFinanceCalculatorStandalone
                    highLevelProfits={highLevelProfits}
                    setHighLevelProfits={setHighLevelProfits}
                    price={price}
                    setPrice={setPrice}
                    downPayment={downPayment}
                    setDownPayment={setDownPayment}
                    interestRate={interestRate}
                    setInterestRate={setInterestRate}
                    balloonPayment={balloonPayment}
                    setBalloonPayment={setBalloonPayment}
                    duration={duration}
                    setDuration={setDuration}
                    amortisation={amortisation}
                    setAmortisation={setAmortisation}
                    monthlyPayment={monthlyPayment}
                    setMonthlyPayment={setMonthlyPayment}
                    sellerCompensation={sellerCompensation}
                    setSellerCompensation={setSellerCompensation}
                    viewRentalComps={null}
                    mobileBar={false}
                    toggleMobileBottomBar={null}
                    cityId={null}
                    strategyPanel={strategyPanel}
                    setStrategyPanel={setStrategyPanel}
                    subToDisabled={true}
                    defaultDownPayment={defaultDownPayment}
                    fullScreenFinancials={fullScreenFinancials}
                    setFullScreenFinancials={setFullScreenFinancials}
                    devMode={false}
                    openDealOptimiser={null}
                    costPerSqFoot={costPerSqFoot}
                    setCostPerSqFoot={setCostPerSqFoot}
                    totalRehabCost={totalRehabCost}
                    setTotalRehabCost={setTotalRehabCost}
                    defaultCostPerSqFoot={defaultCostPerSqFoot}
                    setOwnerEquity={null}
                    blurred={false}
                    exitStrategy={exitStrategy}
                    setExitStrategy={setExitStrategy}
                    leaseOptionValue={leaseOptionValue}
                    setLeaseOptionValue={setLeaseOptionValue}
                    leaseOptionDownPayment={leaseOptionDownPayment}
                    setLeaseOptionDownPayment={setLeaseOptionDownPayment}
                    leaseOptionInterestRate={leaseOptionInterestRate}
                    setLeaseOptionInterestRate={setLeaseOptionInterestRate}
                    strRevenue={strRevenue}
                    setStrRevenue={setStrRevenue}
                />
            </div>
            <Footer />
        </div>
    )
};

export default Calculators;