import React, { useState } from 'react';
import { formatPrice, recordEvent } from '../functions';
import { DefaultTooltip } from '../components';
import { FinancialsInputNoLabel, formatterLong } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';

function STRRent(props) {
    const blurred = props.blurred;
    const strRevenue = props.strRevenue;
    const onChangeSTRRevenue = props.onChangeSTRRevenue;
    const [rentTooltip, setRentTooltip] = useState(false);

    const openRentaliser = () => {
        recordEvent("Open AirDNA Rentaliser", {});
        window.open("https://www.airdna.co/rentalizer", "_blank");
    };

    return (
        <div className="rent-tab-outer-container">
            <div className="rent-tab-inner-container">
                <div className="str-rent-title-container margin-medium">
                    <span className="body-regular colour-secondary">
                        Follow these steps to find short term rental (STR) income:
                    </span>
                </div>
                <div className="str-rent-steps-container margin-medium">
                    <div className="str-rent-step-element str-rent-step-row margin-medium">
                        <span className="body-regular colour-primary">
                            1. Go to the
                        </span>
                        <div 
                            className="text-button button-row"
                            onClick={() => openRentaliser()}
                        >
                            <span className="body-regular colour-link">
                                AirDNA free Rentalizer
                            </span>
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="16" 
                                height="16" 
                                viewBox="0 0 16 16"
                                fill="none"
                                className="str-rent-arrow"
                            >
                                <path 
                                    d="M15.5 1.85735V14.0735C15.5 14.8231 14.8923 15.4308 14.1427 15.4308C13.393 15.4308 12.7853 14.8231 12.7853 14.0735L12.7853 5.13427L2.81714 15.1024C2.28706 15.6325 1.42764 15.6325 0.897558 15.1024C0.36748 14.5724 0.367481 13.7129 0.897558 13.1829L10.8657 3.2147L1.92653 3.21469C1.17688 3.21469 0.56918 2.60699 0.56918 1.85735C0.569179 1.10771 1.17689 0.5 1.92653 0.5L14.1427 0.500002C14.8923 0.500002 15.5 1.10771 15.5 1.85735Z" 
                                    fill="#2F4858"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="str-rent-step-element margin-medium">
                        <span className="body-regular colour-primary">
                            2. Enter the property info & find the "Net operating income"
                        </span>
                    </div>
                    <div className="str-rent-step-element margin-medium">
                        <span className="body-regular colour-primary">
                            3. Enter that number below.
                        </span>
                    </div>
                </div>
                <div className="str-rent-body-container">
                    <div className="str-rent-body-title-container margin-x-small">
                        <span className="body-semibold colour-primary block-text subtext-margin-right">
                            Annual net operating income
                        </span>
                        <div 
                            className="relative-container cursor-pointer"
                            onMouseEnter={() => setRentTooltip(true)}
                            onMouseLeave={() => setRentTooltip(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" fill="#716E69"/>
                                <path d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" fill="#716E69"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" fill="#716E69"/>
                            </svg>
                            {
                                rentTooltip === true ?
                                <DefaultTooltip
                                    title={"Net operating income"}
                                    description={`On the AirDNA calculator, copy the number labeled as 'Net Operating Income' and paste it into this text input. \n\nYou can also customize this number by clicking on the 'Customize estimate' button.`}
                                />
                                :
                                null
                            }
                        </div>
                    </div>
                    <div className="str-rent-input-element">
                        <FinancialsInputNoLabel
                            value={blurred === true ? "999" : formatPrice(strRevenue)}
                            type="text"
                            id={`str-revenue-input`}
                            placeholder={formatPrice(50000)}
                            onWheel={(e) => e.target.blur()}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled={false}
                            onChange={(text) => onChangeSTRRevenue(text.target.value.replaceAll(",", ""))}
                        />
                        <div className="margin-top-x-small">
                            <span className="body-regular colour-secondary">
                                {formatterLong.format(strRevenue / 12).replace(".00", "")}/month
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default STRRent;