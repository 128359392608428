import React, { useEffect, useState, useRef } from 'react';
import '../styles/BuyBoxSetup.css';
import { Footer, Header, ChoosePropertyType, ChooseBudget, ChoosePropertySpecifics, Loading, ChooseStrategy, EditNeighbourhoodGrade, ChooseCounty } from '../components';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftThick, Pin } from '../assets';
import { ActionButton, colour } from '../styles/GlobalStyles';
import { addRecentMarket, checkOnAuthStateChanged, getDocument, makeId, recordEvent, setData } from '../functions';

function BuyBoxSetup() {

    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [chosenMarket, setChosenMarket] = useState(null);
    const [propertyTypes, setPropertyTypes] = useState([
        {
            code: "MultiFamily",
            included: false,
            property: "Multi-Family",
            abbreviation: "MFH"
        },
        {
            code: "SingleFamily",
            included: false,
            property: "Single-Family",
            abbreviation: "SFH"
        }
    ]);
    const [units, setUnits] = useState(1);
    const [budgetRange, setBudgetRange] = useState([0, 1000000000]);
    const [bedrooms, setBedrooms] = useState(1);
	const [bathrooms, setBathrooms] = useState(1);
	const [squareFootage, setSquareFootage] = useState([0, 999999]);
	const [constructionYears, setConstructionYears] = useState(["", ""]);
    const [strategy, setStrategy] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [firstRender, setFirstRender] = useState(false);
    const [error, setError] = useState("");
    const [neighbourhoodGradeMin, setNeighbourhoodGradeMin] = useState(1);
    const [neighbourhoodGradeMax, setNeighbourhoodGradeMax] = useState(8);
    const [geographyType, setGeographyType] = useState(0);
    const [customCities, setCustomCities] = useState([]);
    const [customZipCodes, setCustomZipCodes] = useState([]);
    const [allZipCodes, setAllZipCodes] = useState([]);
    const [zipOptions, setZipOptions] = useState([]);
    const buyBoxSetupRef = useRef();

    const steps = [
        {
            title: "Choose a market",
            description: "Select the county that you'd like to apply this buy-box to"
        },
        {
            title: "Property type",
            description: "Which property types are you interested in?"
        },
        {
            title: "Budget",
            description: "Indicate a budget range."
        },
        {
            title: "Property specifics",
            description: "Let’s get specific! Filter by these property details."
        },
        {
            title: "Acquisition strategy",
            description: "Choose an acquisition strategy to get search results tailored to you."
        },
        {
            title: "Location requirements",
            description: "Limit properties based on their neighborhood score."
        }
    ];

    const strategies = [
        {
            title: "Seller Finance",
            description: "This strategy means we're targeting properties that are owned free & clear. We rank these properties based on the likelihood of the owner being open to seller financing, and whether the property would make a good investment property (based on cash-flow, neighborhood, and condition).",
            text: "Seller Finance",
            value: "sellerFinance"
        },
        {
            title: "Subject To",
            description: "This strategy means we're targeting properties where the seller has a mortgage that would be desirable to take over (meaning a low monthly payment). We rank these properties based on the likelihood of the owner being open to selling 'Subject To', and whether the property would make a good investment property (based on cash-flow, neighborhood, and condition).",
            text: "Subject To",
            value: "subto"
        }
    ];

    useEffect(() => {
        document.title = "Buy Box Setup";
        const checkListings = async() => {
            const colRef = "Creative Buy Boxes";
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const userId = user.userId;
                const queryDocument = await getDocument(colRef, userId);
                if ( queryDocument.status === 200 ) {

                }
            }
            else {
                navigate("/my-cities");
            }
        };

       if ( firstRender === false ) {
            checkListings();
            setFirstRender(true);
        }

    }, [navigate, firstRender]);

    const goBack = () => {
        if ( step === 1 ) {
            navigate("/my-cities");
            recordEvent("Back to 'Where To Invest' Page", {});
        }
        else if ( step === 1 ) {
            navigate("/my-cities");
            recordEvent("Back to 'My Cities' Page", {});
        }
        else {
            recordEvent(`Back to Step ${step}`, {});
            setStep(step - 1);
        }
    };

    const changeNeighbourhoodGrade = (val, index) => {
        if ( index === 0 ) {
            setNeighbourhoodGradeMin(val);
        }
        else {
            setNeighbourhoodGradeMax(val);
        }
    };

    const next = async() => {
        if ( step === 5 ) {
            complete();
        }
        else {
            if ( step === 3 ) {
                setConstructionYears([1800, 2024]);
            }
            setStep(step + 1);
        }

        if ( buyBoxSetupRef.current !== null ) {
            buyBoxSetupRef.current.scrollIntoView();
        }
    };

    const checkDisabled = () => {
        if ( disabled === true ) {
            return true;
        }
        else if ( step === 1 )  {
            if ( chosenMarket === null ) {
                return true;
            }
            else {
                return false;
            }
        }
        else if ( step === 2 ) {
            if ( propertyTypes[0].included === false && propertyTypes[1].included === false ) {
                return true;
            }
            else {
                return false;
            }
        }
        else if ( step === 3 ) {
            return false;
        }
        else if ( step === 4 ) {
            return false;
        }
        else if ( step === 5 ) {
            if ( strategy === "" ) {
                return true;
            }
            else {
                return false;
            }
        }
        else if ( step === 6 ) {
            return false;
        }
    };

    const complete = async() => {
        setDisabled(true);
        setLoading(true);
        const colRef = "Creative Buy Boxes";
        // Create a new buy box

        setError("");
        const user = await checkOnAuthStateChanged();
        if ( user.status === 200 ) {
            const userId = user.userId;
            const queryDocument = await getDocument(colRef, userId);
            let pastBuyBoxes = [];
            if ( queryDocument.status === 200 ) {
                pastBuyBoxes = queryDocument.data.data;
            }

            const id = await makeId(10);
            const cityIndex = pastBuyBoxes.findIndex(e => e.msaCode === chosenMarket.msaCode);
            const strategyIndex = strategies.findIndex(e => e.value === strategy);
            const chosenStrategy = strategy === "" || strategyIndex === -1 ? "" : ` ${strategies[strategyIndex].text}`;
            const newBuyBox = {
                activeListings: 0,
                bathrooms: bathrooms,
                bedrooms: bedrooms,
                budgetRange: budgetRange,
                constructionYears: constructionYears,
                customCities: geographyType === 1 ? customCities : null,
                customZipCodes: geographyType === 2 ? customZipCodes : null,
                frequency: "daily",
                fsbo: false,
                hideFixerUppers: false,
                id: id,
                lastUpdate: new Date(),
                metroArea: true,
                msaCode: chosenMarket.msaCode,
                msaTitle: chosenMarket.msaTitle,
                neighbourhoodGradeMin: neighbourhoodGradeMin,
                neighbourhoodGradeMax: neighbourhoodGradeMax,
                newListings: 0,
                onlyADU: false,
                onlyExtraBedroom: false,
                onlyFixerUppers: false,
                propertyTypes: propertyTypes,
                sent: [],
                squareFootage: squareFootage,
                strategy: strategy,
                title: `My ${chosenMarket.msaTitle}${chosenStrategy} buy box`,
                totalListings: 0,
                units: units
            };

            newBuyBox.msaTitle = chosenMarket.msaTitle;
            newBuyBox.msaCode = chosenMarket.msaCode;

            if ( cityIndex === -1 ) {
                const newCityBuyBox = {
                    metroArea: true,
                    buyBoxes: [],
                    loaded: true,
                    metroCities: chosenMarket.metroCities,
                    msaCityIds: chosenMarket.msaCityIds
                };

                newCityBuyBox.msaTitle = chosenMarket.msaTitle;
                newCityBuyBox.msaCode = chosenMarket.msaCode;

                newCityBuyBox.buyBoxes.unshift(newBuyBox);
                pastBuyBoxes.unshift(newCityBuyBox);
            }
            else {
                pastBuyBoxes[cityIndex].buyBoxes.unshift(newBuyBox);
            }
            await setData(colRef, userId, pastBuyBoxes);

            recordEvent("New Buy Box Saved", {
                "City": chosenMarket.msaTitle,
                "City ID": chosenMarket.msaCode,
            });

            navigate("/my-cities");
            const newItem = {
                msaTitle: chosenMarket.msaTitle,
                msaCode: chosenMarket.msaCode
            };
            await addRecentMarket(newItem, userId, null);
        }
        else {
            navigate("/sign-up");
        }
        setLoading(false);
        setDisabled(false);
    };

    return (
        <div ref={buyBoxSetupRef} className="buy-box-setup-outer-container">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            <div className="buy-box-setup-inner-container">
                {
                    loading === true ?
                    <Loading />
                    :
                    null
                }
                <div className="buy-box-setup-title-container">
                    <h1 className="heading-large-semibold colour-primary margin-medium text-align-center">
                        Buy Box Setup
                    </h1>
                    <span className="body-regular colour-secondary block-text text-align-center margin-medium">
                        Let’s set up a buy box – this helps us show you the best 
                        properties and notify you when new ones within your buy box are listed.
                    </span>
                    {
                        chosenMarket === null ?
                        <div className="">

                        </div>
                        :
                        <div className="buy-box-setup-row margin-large">
                            <img
                                src={Pin}
                                className="buy-box-setup-pin"
                                alt="pin"
                            />
                            <span className="body-regular colour-secondary block-text text-align-center">
                                {chosenMarket.msaTitle}
                            </span>
                        </div>
                    }
                </div>
                <div className="buy-box-setup-mobile-top-fixed-container margin-x-small">
                    <div 
                        className="buy-box-setup-back-button-container text-button cursor-pointer"
                        onClick={() => goBack()}
                    >
                        <img
                            src={ChevronLeftThick}
                            className="buy-box-setup-back-button"
                            alt="Go back"
                        />
                        <span className="body-regular text-link">
                            Back
                        </span>
                    </div>
                    <div className="buy-box-setup-mobile-steps-container">
                        <span className="label-regular colour-secondary">
                            Step {step} of 6
                        </span>
                    </div>
                </div>
                <div className="buy-box-setup-mobile-progress-bar-container">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="4" viewBox="0 0 100% 4" fill="none">
                        <rect 
                            width="100%" 
                            height="4" 
                            rx="2" 
                            fill="#F2E1D0"
                        />
                        <rect
                            width={`${step * 25}%`} 
                            height="4" 
                            rx="2" 
                            fill={colour.blueBlue03}
                        />
                    </svg>
                </div>
                <div className="buy-box-setup-body-container margin-large">
                    <div className="buy-box-setup-body-inner-container">
                        <div className="buy-box-setup-body-mobile-top-container">
                            <div className="buy-box-setup-row margin-large">
                                <img
                                    src={Pin}
                                    className="buy-box-setup-pin"
                                    alt="pin"
                                />
                                <span className="body-regular colour-secondary block-text text-align-center">
                                    {
                                        chosenMarket === null ?
                                        ""
                                        :
                                        chosenMarket.msaTitle
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="buy-box-setup-body-bottom-container">
                            <div className="buy-box-setup-step-subtitle-container margin-large">
                                <h2 className="heading-small-semibold colour-primary block-text text-align-center margin-x-small">
                                    {steps[step - 1].title}
                                </h2>
                                <span className="body-regular colour-secondary block-text text-align-center">
                                    {steps[step - 1].description}
                                </span>
                            </div>
                            {
                                step === 1 ?
                                <ChooseCounty
                                    chosenMarket={chosenMarket}
                                    setChosenMarket={setChosenMarket}
                                    setDisabled={setDisabled}
                                    geographyType={geographyType}
                                    setGeographyType={setGeographyType}
                                    customCities={customCities}
                                    setCustomCities={setCustomCities}
                                    customZipCodes={customZipCodes}
                                    setCustomZipCodes={setCustomZipCodes}
                                    allZipCodes={allZipCodes}
                                    setAllZipCodes={setAllZipCodes}
                                    zipOptions={zipOptions}
                                    setZipOptions={setZipOptions}
                                />
                                :
                                step === 2 ?
                                <ChoosePropertyType
                                    propertyTypes={propertyTypes}
                                    setPropertyTypes={setPropertyTypes}
                                    units={units}
                                    setUnits={setUnits}
                                />
                                :
                                step === 3 ?
                                <ChooseBudget
                                    budgetRange={budgetRange}
                                    setBudgetRange={setBudgetRange}
                                />
                                :
                                step === 4 ?
                                <ChoosePropertySpecifics
                                    bedrooms={bedrooms}
                                    setBedrooms={setBedrooms}
                                    bathrooms={bathrooms}
                                    setBathrooms={setBathrooms}
                                    squareFootage={squareFootage}
                                    setSquareFootage={setSquareFootage}
                                    constructionYears={constructionYears}
                                    setConstructionYears={setConstructionYears}
                                />
                                :
                                step === 5 ?
                                <ChooseStrategy
                                    strategies={strategies}
                                    strategy={strategy}
                                    setStrategy={setStrategy}
                                />
                                :
                                step === 6 ?
                                <EditNeighbourhoodGrade
                                    neighbourhoodGradeMin={neighbourhoodGradeMin}
                                    neighbourhoodGradeMax={neighbourhoodGradeMax}
                                    changeNeighbourhoodGrade={changeNeighbourhoodGrade}
                                    filtersModal={false}
                                />
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
                <div className="buy-box-setup-buttons-row margin-x-x-large">
                    <div 
                        className="buy-box-setup-back-button-container text-button cursor-pointer"
                        onClick={() => goBack()}
                    >
                        <img
                            src={ChevronLeftThick}
                            className="buy-box-setup-back-button"
                            alt="Go back"
                        />
                        <span className="body-regular text-link">
                            Back
                        </span>
                    </div>
                    <div className="buy-box-setup-next-button">
                        {
                            error !== "" ?
                            <div className="margin-x-small">
                                <span className="body-regular colour-error">
                                    {error}
                                </span>
                            </div>
                            :
                            null
                        }
                        <ActionButton
                            disabled={checkDisabled()}
                            onClick={() => next()}
                        >
                            Next
                        </ActionButton>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default BuyBoxSetup;