import React from 'react';
import '../styles/ShareButtons.css';
import Heart from '../assets/heart.svg';
import HeartActive from '../assets/heart-active.svg';

function ShareButtons(props) {

	const property = props.property;
	const favourites = props.favourites;
	const toggleFavourite = props.toggleFavourite;
	const blurred = props.blurred;
	const favouriteDisable = blurred === true ? true : props.favouriteDisable;
	const setFavourites = props.setFavourites;
	const setFavouriteDisable = props.setFavouriteDisable;
	const navigate = props.navigate;
	const cityId = props.cityId;

	const zpidIndex = favourites.findIndex(e => e.zpid === property.zpid);
	const inFavourites = zpidIndex === -1 ? false : true;
	const cityObject = {
        cityId: cityId,
        city: property.address.city,
        state: property.address.state
    };
	const buttons = [
		{
			label: "Add to favorites",
			icon: inFavourites === false ? Heart : HeartActive,
			available: true
		}
	];

	const cta = () => {
		if ( favouriteDisable === false ) {
			toggleFavourite(favourites, property.zpid, setFavourites, setFavouriteDisable, navigate, cityObject, null);
		}
	}

	return (
		<div className="share-buttons-outer-container">
			<div className="share-buttons-inner-container">
				{
					buttons.map((item, index) =>
						item.available === true ?
						<div 
							className="share-buttons-element-container text-button"
							key={index}
							onClick={() => cta(index)}
						>
							<img
								src={item.icon}
								className="share-buttons-element-image"
								alt={item.label}
							/>
							<span className="body-regular colour-link">
								{item.label}
							</span>
						</div>
						:
						null
					)
				}
			</div>
		</div>
	)
}

export default ShareButtons;