import React, { useState, useEffect, useRef, createRef } from 'react';
import '../styles/Listings.css';
import { DefaultTooltip, EmergingAreaModal, Header, ListingsCard, Loading, MobilePropertyCardOverlay, Modal, NewFiltersModal, NewPropertyMap, Pagination, PropertyListButton, PropertyListTopBar } from '../components';
import { SortSelect, colour, defaultUserData, formatterCompact, numberFormatter, CustomRadio, FilterCityInputRound, neighbourhoodDataOptions, CustomSelect } from '../styles/GlobalStyles';
import { Search, Exit, Sort } from '../assets';
import { checkOnAuthStateChanged, cloudFunctionV2, getAPIGatewayListings, getDocument, recordEvent, resetFilterType, saveSearchParams, setData, sortValues, sortValuesSubto, checkUserId, getMiddle } from '../functions';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { counties } from '../counties';
import moment from 'moment';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';

function SampleMarket() {
    const cityId = "CTY45045";
    const countyIndex = counties.findIndex(e => e.code === cityId.replace("CTY", ""));
    const countyObject = countyIndex !== -1 ? counties[countyIndex] : null;
    const countyName = countyIndex !== -1 ? `${countyObject.county}${countyObject.state === "LA" || countyObject.state === "D.C." || (countyObject.state === "VA" && countyObject.county === "Richmond City") ? "" : " County"}, ${countyObject.state}` : null;
    const [searchParams, setSearchParams] = useSearchParams({});
    const [county, setCounty] = useState("");
    const [listings, setListings] = useState([]);
    const [tooltip, setTooltip] = useState(false);
    const [loading, setLoading] = useState(true);
    const [possibleListings, setPossibleListings] = useState(0);
    const [totalProperties, setTotalProperties] = useState(0);
    const [hiddenProperties, setHiddenProperties] = useState([]);
    const [firstQuery, setFirstQuery] = useState(false);
    const [userId, setUserId] = useState(null);
    const [countyOptions, setCountyOptions] = useState([]);
    const getPage = searchParams.get('page');
    const [page, setPage] = useState(getPage === null ? 1 : Number(getPage));
    const [allProperties, setAllProperties] = useState([]);
    const [propertiesOnPageChange, setPropertiesOnPageChange] = useState(false);
    const [hideModal, setHideModal] = useState(false);
    const [hideProperty, setHideProperty] = useState(null);
    const [reordering, setReordering] = useState(false);
    const getSorting = searchParams.get('sort');
	const [sorting, setSorting] = useState(getSorting === null ? 0 : Number(getSorting));
    const [filtersModalOpen, setFiltersModalOpen] = useState(false);
    const [filtersChanged, setFiltersChanged] = useState(false);
    const [openFilters, setOpenFilters] = useState(null);
    const [subscriptions, setSubscriptions] = useState(null);
    const [selectedTracts, setSelectedTracts] = useState([]);
    const [centreCoordinates, setCentreCoordinates] = useState({lat: 0, lng: 0});
    const [neighbourhoodData, setNeighbourhoodData] = useState([]);
    const [tracts, setTracts] = useState([]);
    const [neighbourhoodBounds, setNeighbourhoodBounds] = useState([]);
    const [emergingMarkets, setEmergingMarkets] = useState([]);
    const [emergingAreaModal, setEmergingAreaModal] = useState(false);
	const [emergingAreaTract, setEmergingAreaTract] = useState(null);
    const [hoverCard, setHoverCard] = useState("");
	const [propertyRefs, setPropertyRefs] = useState([]);
    const [userDetails, setUserDetails] = useState(null);
    const [lastClickedTract, setLastClickedTract] = useState("");
    const [clickedTract, setClickedTract] = useState("");
    const [cardIndex, setCardIndex] = useState(null);
    const [chosenTract, setChosenTract] = useState(null);
    const [mapSidePanelOpen, setMapSidePanelOpen] = useState(null);
    const [cardClick, setCardClick] = useState(false);
    const [showResetButton, setShowResetButton] = useState(false);
    const [mapProperties, setMapProperties] = useState([]);
    const [mobileAbstract, setMobileAbtract] = useState("");

    const getPropertyTypes = searchParams.get('propertyType');
	const parsedGetPropertyTypes = getPropertyTypes !== null ? JSON.parse(getPropertyTypes) : null;
	const [propertyTypes, setPropertyTypes] = useState([
		{
            code: "MultiFamily",
            included: getPropertyTypes !== null && parsedGetPropertyTypes.propertyTypes.includes("MFH") ? true : false,
            property: "Multi-Family",
            abbreviation: "MFH"
        },
        {
            code: "SingleFamily",
            included: getPropertyTypes !== null && parsedGetPropertyTypes.propertyTypes.includes("SFH") ? true : false,
            property: "Single-Family",
            abbreviation: "SFH"
        }
	]);
	const [units, setUnits] = useState(parsedGetPropertyTypes !== null && parsedGetPropertyTypes.units !== undefined ? parsedGetPropertyTypes.units : 1);
	const getBudgetRange = searchParams.get('budgetRange');
    const [budgetRange, setBudgetRange] = useState(getBudgetRange !== null ? JSON.parse(getBudgetRange) : [0, 1000000000]);
	const getPropertySpecifics = searchParams.get('propertySpecifics');
	const parsedPropertySpecifics = getPropertySpecifics !== null ? JSON.parse(getPropertySpecifics) : null;
    const [bedrooms, setBedrooms] = useState(parsedPropertySpecifics !== null ? parsedPropertySpecifics.bedrooms : 1);
	const [bathrooms, setBathrooms] = useState(parsedPropertySpecifics !== null ? parsedPropertySpecifics.bathrooms : 1);
	const [squareFootage, setSquareFootage] = useState(parsedPropertySpecifics !== null ? parsedPropertySpecifics.squareFootage : [0, 999999]);
	const [constructionYears, setConstructionYears] = useState(parsedPropertySpecifics !== null && parsedPropertySpecifics.constructionYears !== undefined ? parsedPropertySpecifics.constructionYears : ["", ""]);
    
    const getValueAddParam = searchParams.get('valueAdd');
    const parsedValueAddParams = getValueAddParam !== null ? JSON.parse(getValueAddParam) : null;
    const [onlyFixerUppers, setOnlyFixerUppers] = useState(getValueAddParam !== null ? parsedValueAddParams.onlyFixerUppers : false);
    const [hideFixerUppers, setHideFixerUppers] = useState(getValueAddParam !== null && parsedValueAddParams.hideFixerUppers !== undefined ? parsedValueAddParams.hideFixerUppers : false);
    
    const getMoreFilters = searchParams.get('more');
	const parsedMoreFilters = getMoreFilters !== null ? JSON.parse(getMoreFilters) : null;
    const [fsbo, setFsbo] = useState(parsedMoreFilters !== null && (parsedMoreFilters.fsbo === true || parsedMoreFilters.fsbo === "true") ? true : false);
    const [inherited, setInherited] = useState(parsedMoreFilters !== null && parsedMoreFilters.inherited !== undefined ? parsedMoreFilters.inherited : false);
    const [openStrategyPanel, setOpenStrategyPanel] = useState(false);
    const getStrategyParam = searchParams.get('strategy');
    const [strategy, setStrategy] = useState(getStrategyParam === null || (getStrategyParam !== "sellerFinance" && getStrategyParam !== "subto" && getStrategyParam !== "all") ? "sellerFinance" : getStrategyParam);
    const [leadType, setLeadType] = useState(strategy);
    const [neighbourhoodGradeMin, setNeighbourhoodGradeMin] = useState(parsedMoreFilters !== null && parsedMoreFilters.neighborhoodGradeMin !== undefined ? parsedMoreFilters.neighborhoodGradeMin : 1);
	const [neighbourhoodGradeMax, setNeighbourhoodGradeMax] = useState(parsedMoreFilters !== null && parsedMoreFilters.neighborhoodGradeMax !== undefined ? parsedMoreFilters.neighborhoodGradeMax : 8);
    const [hoverMapLegend, setHoverMapLegend] = useState(null);
    const [neighbourhoodOption, setNeighbourhoodOption] = useState(0);
    const [overlay, setOverlay] = useState(false);
    const [hideHeatmap, setHideHeatmap] = useState(false);

    const cardsRef = useRef(null);
    const location = useLocation();
    const state = location.state;
    const navigate = useNavigate();
    const [downPayment] = useState(0.1);
    const sortOptions = [
		{
			label: "Sort"
		},
		{
			label: "Rating"
		},
		{
			label: "Newest to oldest"
		},
		{
			label: "Oldest to newest"
		},
		{
			label: "Cash on cash return"
		},
		{
			label: "Price - lowest to highest"
		},
		{
			label: "Price - highest to lowest"
		}
	];

    const strategyRadios = [
        {
            label: "Seller Finance Leads",
            value: "sellerFinance"
        },
        {
            label: "SubTo Leads",
            value: "subto"
        },
        {
            label: "All properties",
            value: "all"
        }
    ];

    const tooltipMessaging = [
        {
            title: "Seller finance leads",
            description: `We've isolated these MLS properties based on three criteria: \n1. The seller owns the property free & clear \n2. The property is in good condition \n3. The property has good cash-flow on seller financing terms (or as good as we could find!)`
        },
        {
            title: "Subto leads",
            description: `We've isolated these MLS properties based on three criteria: \n1. The seller does NOT own the property free & clear \n2. The property is in good condition \n3. The property has good cash-flow on subto terms (or as good as we could find!)`
        },
        {
            title: "All properties",
            description: `All properties for sale in the county`
        }
    ];

    useEffect(() => {
        document.title = "Creative Listings - Sample Market";

        const fetchUserData = async() => {
            const colRef = "Subscriptions";
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                setUserId(user.userId);
                const queryData = await getDocument(colRef, user.userId);
                if ( queryData.status === 200 ) {
                    const today = new Date();
                    const todaySeconds = today.getTime() / 1000;
                    const oldSubs = queryData.data.data;
                    const creativeSubs = [];
                    for (let index = 0; index < oldSubs.length; index++) {
                        const element = oldSubs[index];
                        if ( element.endDate.seconds > todaySeconds && element.creative === true ) {
                            creativeSubs.push(element);
                        }
                    }

                    setSubscriptions(creativeSubs);
                }
                else {
                    setSubscriptions([]);
                }
                queryProperties();
            }
            else {
                setSubscriptions([]);
                queryProperties();
            }
        };

        const queryProperties = async() => {
            setLoading(true);
            const newFilters = {
				adu: false,
				bathrooms: bathrooms,
				bedrooms: bedrooms,
				budgetRange: budgetRange,
				constructionYears: constructionYears,
				squareFootage: squareFootage,
				units: units,
				propTypeMislabelled: false,
				priceReduction: false,
				extraBedroom: false,
				propertyTypes: propertyTypes,
				hideFixerUppers: hideFixerUppers,
				onlyFixerUppers: onlyFixerUppers,
				priceReductionIndex: 0,
				neighbourhoodGradeMin: neighbourhoodGradeMin,
				neighbourhoodGradeMax: neighbourhoodGradeMax,
				fsbo: fsbo,
                inherited: inherited,
                subto: strategy === "subto" ? true : false,
                sellerFinance: strategy === "sellerFinance" ? true : false
			};
			const getGatewayQueryString = await getAPIGatewayListings(defaultUserData.settings, false, cityId, newFilters);
			const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
			const gatewayData = {
				type: "gateway",
				resourceId: "listings",
				queryString: getGatewayQueryString
			};
			const getGateway = await cloudFunctionV2(gatewayURL, gatewayData);
			let queryData = [];
			if ( getGateway.status === 200 ) {
				const getGatewayBody = getGateway.body;
                setTotalProperties(getGatewayBody.totalPropertyCount);
                if ( getGatewayBody.filteredFreeClearCount !== undefined && strategy === "sellerFinance" ) {
                    setTotalProperties(getGatewayBody.filteredFreeClearCount);
                }
                else if ( getGatewayBody.filteredSubtoCount !== undefined && strategy === "subto" ) {
                    setTotalProperties(getGatewayBody.filteredSubtoCount);
                }
                queryData = getGatewayBody.props;
			}
			else if ( getGateway.status === 404 ) {
				const customS3Location = getGateway.body.s3_location;
				const params = {
					type: "s3",
					s3Data: {
						key: customS3Location,
						bucketName: "residentialpropertydata"
					}
				};
				const getS3Query = await cloudFunctionV2(gatewayURL, params);
				if ( getS3Query.status === 200 ) {
					const getS3Body = getS3Query.body;
                    queryData = getS3Body.props;
                    setTotalProperties(getS3Body.totalPropertyCount);
                    if ( getS3Body.filteredFreeClearCount !== undefined && strategy === "sellerFinance" ) {
                        setTotalProperties(getS3Body.filteredFreeClearCount);
                    }
                    else if ( getS3Body.filteredSubtoCount !== undefined && strategy === "subto" ) {
                        setTotalProperties(getS3Body.filteredSubtoCount);
                    }
				}
			}
			else {
                setLoading(false);
			}
            setLoading(false);
            setPossibleListings(queryData.length);
            setAllProperties(queryData);
            for (let index = 0; index < queryData.length; index++) {
                queryData[index].datePostedTS = new Date(queryData[index].datePosted);
                const a = moment(new Date());
				const b = moment(queryData[index].datePostedTS);
				const dateDifferenceDays = a.diff(b, 'days');
                const dateDifferenceMonths = a.diff(b, 'months');
                const dateDifferenceYears = a.diff(b, 'years');
				queryData[index].dateDifferenceDays = dateDifferenceDays;
                queryData[index].dateDifferenceMonths = dateDifferenceMonths;
                queryData[index].dateDifferenceYears = dateDifferenceYears;
            };

            const props = strategy === "sellerFinance" ? await sortValues(queryData, sorting) : await sortValuesSubto(queryData, sorting);
            const lowerBound = (page - 1) * 20;
            const upperBound = 20 * page;
            const propsSegmentedByPage = props.slice(lowerBound, upperBound);

            setPropertyRefs((elRefs) =>
                Array(propsSegmentedByPage.length)
                .fill()
                .map((_, i) => elRefs[i] || createRef()),
            );

            const mapLowerBound = 0;
			const mapUpperBound = mapLowerBound + 40;
			const propsSegmentedByMapPage = props.slice(mapLowerBound, mapUpperBound);
            setMapProperties(propsSegmentedByMapPage);
            parseFloat(findCentre(propsSegmentedByPage));
			parseFloat(findCentre(propsSegmentedByPage));
            setListings(propsSegmentedByPage);
            setLeadType(strategy);
        };

        const findCentre = (markers) => {
			const latMiddle = getMiddle('latitude', markers);
			const lngMiddle = getMiddle('longitude', markers);
			const centreObject = {
				lat: latMiddle,
				lng: lngMiddle
			};
			setCentreCoordinates(centreObject);
		}

		const getNeighbourhoodData = async() => {
			const neighbourhoodPath = `geo_output/map_neighborhood/county/${cityId.replace("CTY", "")}.json`;
			const params = {
				s3Data: {
					key: neighbourhoodPath,
					bucketName: "residentialpropertydata"
				},
				type: "s3"
			}
			const awsURL = process.env.REACT_APP_AWS_QUERY_URL;
			const queryData = await cloudFunctionV2(awsURL, params);
			if ( queryData.status === 200 ) {
				const body = queryData.body;
				setNeighbourhoodData(body);

				const newBounds = [];
				const getTracts = [];
				for (let index = 0; index < body.length; index++) {
					const element = body[index];
					getTracts.push(element.region.tractId);
					const tractPolygon = element.tractPolygon.flat(1);
					newBounds.push(tractPolygon);
				};
				setTracts(getTracts);
				setNeighbourhoodBounds(newBounds);

				const emergingMarketArray = [];
				for (let index = 0; index < body.length; index++) {
					const element = body[index];
					if ( element.region.emergingMarket === true ) {
						emergingMarketArray.push(element);
					}
				};
				setEmergingMarkets(emergingMarketArray);
			}
		}

        if ( firstQuery === false ) {
            fetchUserData();
            getNeighbourhoodData();
            setFirstQuery(true);
        }
        else if ( filtersChanged === true ) {
            queryProperties();
            setFiltersChanged(false);
        }
    }, [
        cityId, 
        firstQuery, 
        propertyTypes,
        bathrooms,
        bedrooms,
        budgetRange,
        constructionYears,
        units,
        squareFootage,
        fsbo,
        filtersChanged,
        page,
        sorting,
        strategy,
        onlyFixerUppers,
        hideFixerUppers,
        inherited,
        neighbourhoodGradeMin,
        neighbourhoodGradeMax
    ]);

    const options = [
        {
            label: "Properties",
            value: "properties",
            selected: true,
            route: `/properties-list/${cityId}`
        },
        {
            label: "Offers",
            value: "offers",
            selected: false,
            route: `/offers/${cityId}`
        }
    ];

    const changeOption = (item) => {
        navigate(item.route, {
            state: state
        });
    };

    const changeCounty = (val) => {
        setCounty(val);

        if ( val === "" ) {
            setCountyOptions([]);
            return;
        }

        const lowerCaseVal = val.toLowerCase();
        const countiesClone = [...counties];
        const newArray = [];
        for (let index = 0; index < countiesClone.length; index++) {
            const element = countiesClone[index];
            
            const fullName = `${element.county} County ${element.stateFull}`.toLowerCase();
            const fullNameComma = `${element.county} County, ${element.stateFull}`.toLowerCase();
            const abbreviatedName = `${element.county} County ${element.state}`.toLowerCase();
            const abbreviatedNameComma = `${element.county} County, ${element.state}`.toLowerCase();
            const justState = element.state.toLowerCase();
            const mainCity = element.mainCity.city === "" ? false : true;

            if ( justState.includes(lowerCaseVal) || fullName.includes(lowerCaseVal) || fullNameComma.includes(lowerCaseVal) || abbreviatedName.includes(lowerCaseVal) || abbreviatedNameComma.includes(lowerCaseVal) ) {
                newArray.push(counties[index]);
            }
            else if ( mainCity === true ) {
                const mainCityFullName = `${element.mainCity.city} ${element.stateFull}`.replace(".", "").toLowerCase();
                const mainCityFullNameComma = `${element.mainCity.city}, ${element.stateFull}`.replace(".", "").toLowerCase();
                const mainCityAbbreviatedName = `${element.mainCity.city} ${element.state}`.replace(".", "").toLowerCase();
                const mainCityAbbreviatedNameComma = `${element.mainCity.city}, ${element.state}`.replace(".", "").toLowerCase();
                const noFullStopVal = lowerCaseVal.replace(".", "");

                if ( mainCityFullName.includes(noFullStopVal) || mainCityFullNameComma.includes(noFullStopVal) || mainCityAbbreviatedName.includes(noFullStopVal) || mainCityAbbreviatedNameComma.includes(noFullStopVal) ) {
                    newArray.push(counties[index]);
                }
            }
        };
        setCountyOptions(newArray);
    };

    const hidePropertyFunc = async() => {
        setHideModal(false);
        setHideProperty(null);
        const propertyIndex = hiddenProperties.indexOf(hideProperty.zpid);
        const newHiddenProperties = [...hiddenProperties];
        if ( propertyIndex === -1 ) {
            newHiddenProperties.push(hideProperty.zpid);
            setHiddenProperties(newHiddenProperties);

        }
        else {
            newHiddenProperties.splice(propertyIndex, 1);
            setHiddenProperties(newHiddenProperties);
        }

        if ( userId !== null ) {
            const getProperties = await getDocument("Creative Listings", userId);
            const newData = getProperties.status === 200 ? getProperties.data.data : [{cityId: cityId, hiddenProperties: [], offers: []}];
            const cityIndex = newData.findIndex(e => e.cityId === cityId);
            if ( cityIndex !== -1 ) {
                newData[cityIndex].hiddenProperties = newHiddenProperties;
                await setData("Creative Listings", userId, newData);
            }
            else {
                const newCity = {
                    cityId: cityId,
                    hiddenProperties: newHiddenProperties,
                    offers: []
                };
                newData.push(newCity);
                await setData("Creative Listings", userId, newData);
            }
        }
    };

    const changeSorting = async(val) => {
        const labels = [
            "sort",
            "page"
        ];
        const values = [
            val,
            1
        ];
        await saveSearchParams(labels, values, searchParams, setSearchParams);
        const newProps = strategy === "sellerFinance" ? await sortValues(allProperties, val) : await sortValuesSubto(allProperties, val);
        const lowerBound = 0;
        const upperBound = 20;
        const propsSegmentedByPage = newProps.slice(lowerBound, upperBound);
        setListings(propsSegmentedByPage);
        setPage(1);
        setLoading(false);
        setSorting(val);
        setReordering(true);
        setTimeout(() => {
            setReordering(false);
        }, 500);
	}

    const selectCounty = (item) => {
        setCountyOptions([]);
        setCounty("");
        const countyCode = `CTY${item.code}`;
        window.open(`/properties-list/${countyCode}`, "_blank");
    };

    const resetCity = () => {
        setCounty("");
    };

    const closeHideModal = () => {
        setHideModal(false);
        setHideProperty(null);
    };

    const resetAllFilters = async() => {
		searchParams.delete("propertyType");
		searchParams.delete("budgetRange");
		searchParams.delete("propertySpecifics");
		searchParams.delete("valueAdd");
		searchParams.delete("more");
        searchParams.delete("page");
		await resetFilterType(
			"all",
			setPropertyTypes, 
            setUnits,
            setBudgetRange,
            setBedrooms,
            setBathrooms,
            setSquareFootage,
            setConstructionYears,
            setOnlyFixerUppers,
            setHideFixerUppers,
            setInherited,
            setNeighbourhoodGradeMin,
            setNeighbourhoodGradeMax,
            setFsbo,
            setPage
		);
		setSearchParams(searchParams);
        setFiltersChanged(true);
        recordEvent("Reset Filters", {});
	};

    const changeStrategy = async(val) => {
        setStrategy(val);
        setFiltersChanged(true);
        setOpenStrategyPanel(false);
        setPage(1);

        const labels = [
            "strategy",
            "page"
        ];
        const values = [
            val,
            1
        ];
        await saveSearchParams(labels, values, searchParams, setSearchParams);
    };

    const openStrategyDropdown = (e) => {
        const target = e.target;

        if ( (target.tagName === "path" || target.tagName === "svg") ) {
            if ( openStrategyPanel === true ) {
                setOpenStrategyPanel(false);
            }
            else {
                setOpenStrategyPanel(true);
            }
            return;
        }
        else if ( target.className.includes("filter-dropdown-chip") && openStrategyPanel === true) {
            setOpenStrategyPanel(false);
            return;
        }

        if ( openStrategyPanel !== true ) {
            setOpenStrategyPanel(true);
        }
    };

    const closeAllFilters = () => {
        setOpenFilters(null);
        setOpenStrategyPanel(false);
    };

    const sidePanelToggle = async() => {
		recordEvent("Side Panel Toggle", {
			open: !mapSidePanelOpen
		});

		setMapSidePanelOpen(!mapSidePanelOpen);
		if ( userDetails === null ) {
			return;
		}
		else if ( userDetails.sidePanel === true ) {
			return;
		}
		else {
			const user = await checkUserId();
			if ( user.status === 200 ) {
				const colRef = "Users";
				const docRef = user.userId;
				userDetails.sidePanel = true;

				await setData(colRef, docRef, userDetails);
			}
		}
	};

    const openOverlay = () => {
		setOverlay(!overlay);
		recordEvent("Overlay Toggle", {
			overlay: !overlay
		});

		if ( overlay === true ) {
			setTimeout(() => {
				if ( cardsRef.current !== null ) {
					cardsRef.current.scroll({
						top: 0,
						behavior: 'smooth'
					});
				}
			}, 250);
		}
	};

    const clickTract = (val) => {
		setCardIndex(null);

		if ( cardIndex === null ) {
			recordEvent("Click Tract", {
				tract: val
			});
			setClickedTract(val);
			setMapSidePanelOpen(true);

			for (let index = 0; index < neighbourhoodData.length; index++) {
				const element = neighbourhoodData[index];
				if ( element.region.tractId === val ) {
					setChosenTract(element);
				}
			}
		}
	};

    const onChangeNeighbourhoodOption = (val) => {
        if ( val === "hidden" ) {
            setHideHeatmap(true);
        }
        else {
            setHideHeatmap(false);
            setNeighbourhoodOption(val);
        }
    };

    const chooseMarket = () => {
        navigate("/pricing", {
            state: state
        });
    };

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik',
		},
        selectedMenuItem: {
			backgroundColor: colour.blueBlue01,
			fontFamily: 'Rubik',
		},
        hiddenMenuItem: {
            backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        hiddenMenuItemSelected: {
            backgroundColor: colour.blueBlue01,
			fontFamily: 'Rubik',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
		formControl: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			alignItems: 'center'
		}
	};

    return (
        <div className={"listings-outer-container " + (hideModal === true || filtersModalOpen === true || openFilters === true || emergingAreaModal === true ? "relative-container" : "")}> 
            <Header
                subscriptions={subscriptions}
                users={null}
                queries={[true, false]}
                mobileNav={false}
            />
            {
                hideModal === true ?
                <Modal
                    title="Hide Property?"
                    description={`Are you sure you want to hide ${hideProperty.address.streetAddress}, ${hideProperty.address.city}, ${hideProperty.address.state} ${hideProperty.address.zipcode}?`}
                    closeButtonText="Cancel"
                    saveButtonText="Hide"
                    closeModal={closeHideModal}
                    saveModal={hidePropertyFunc}
                />
                :
                null
            }
            {
                hideModal === true || filtersModalOpen === true ?
                <div className="dark-bg">
                </div>
                :
                null
            }
            {
                openFilters !== null || openStrategyPanel === true ?
                <div 
                    className="listings-filter-click-outside"
                    onClick={() => closeAllFilters()}
                >
                </div>
                :
                null
            }
            {
                emergingAreaModal === true ?
                <EmergingAreaModal
                    setEmergingAreaModal={setEmergingAreaModal}
                    setMapSidePanelOpen={setMapSidePanelOpen}
                    setChosenTract={setChosenTract}
                    setEmergingAreaTract={setEmergingAreaTract}
                    emergingAreaTract={emergingAreaTract}
                    setClickedTract={setClickedTract}
                />
                :
                null
            }
            <div className="listings-inner-container relative-container">
                {
                    loading === true ?
                    <Loading />
                    :
                    null
                }
                <div className="listings-top-section-container">
                    <div className="listings-top-options-container margin-large">
                        {
                            options.map((item, index) =>
                                <div 
                                    className="listings-top-option-element cursor-pointer"
                                    key={index}
                                    onClick={() => changeOption(item)}
                                >
                                    <span className={(item.selected === true ? "body-semibold colour-primary" : "body-regular colour-secondary")}>
                                        {item.label}
                                    </span>
                                </div>
                            )
                        }
                    </div>
                    <div className="listings-filter-sort-container margin-medium">
                        <div className="listings-filters-container">
                            <div className="listings-geography-input-container relative-container">
                                <FilterCityInputRound
                                    value={county}
                                    type="text"
                                    id="listings-geography-input"
                                    placeholder="Search for a county"
                                    onChange={(text) => changeCounty(text.target.value)}
                                    disabled={false}
                                />
                                <img
                                    src={county === "" ? Search : Exit}
                                    className={county === "" ? "listings-geography-search-icon" : "listings-geography-exit-icon"}
                                    alt="Search"
                                    onClick={() => county === "" ? null : resetCity()}
                                />
                                {
                                    countyOptions.length === 0 || county === "" ?
                                    null
                                    :
                                    <div className="listings-county-filtering-outer-container">
                                        {
                                            countyOptions.map((item, index) =>
                                                <div
                                                    className="listings-county-filtering-element"
                                                    key={index}
                                                    onClick={() => selectCounty(item)}
                                                >
                                                    <span className="body-regular colour-primary">
                                                        {item.county}{item.state === "LA" || item.state === "D.C." || (item.state === "VA" && item.county === "Richmond City") ? "" : " County"}, {item.state}
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                }
                            </div>
                            <PropertyListTopBar
                                cityId={cityId}
                                formatter={formatterCompact}
                                setFiltersModalOpen={setFiltersModalOpen}
                                cardsRef={cardsRef}
                                loading={loading}
                                setLoading={setLoading}
                                searchParams={searchParams}
                                setSearchParams={setSearchParams}
                                sorting={sorting}
                                setPage={setPage}
                                propertyTypes={propertyTypes}
                                setPropertyTypes={setPropertyTypes}
                                units={units}
                                setUnits={setUnits}
                                budgetRange={budgetRange}
                                setBudgetRange={setBudgetRange}
                                bedrooms={bedrooms}
                                setBedrooms={setBedrooms}
                                bathrooms={bathrooms}
                                setBathrooms={setBathrooms}
                                squareFootage={squareFootage}
                                setSquareFootage={setSquareFootage}
                                constructionYears={constructionYears}
                                setConstructionYears={setConstructionYears}
                                setPropertiesOnPageChange={setPropertiesOnPageChange}
                                fsbo={fsbo}
                                setFsbo={setFsbo}
                                setFiltersChanged={setFiltersChanged}
                                openFilters={openFilters}
                                setOpenFilters={setOpenFilters}
                                resetAllFilters={resetAllFilters}
                                onlyFixerUppers={onlyFixerUppers}
                                setOnlyFixerUppers={setOnlyFixerUppers}
                                hideFixerUppers={hideFixerUppers}
                                setHideFixerUppers={setHideFixerUppers}
                                inherited={inherited}
                                setInherited={setInherited}
                                neighbourhoodGradeMin={neighbourhoodGradeMin}
                                setNeighbourhoodGradeMin={setNeighbourhoodGradeMin}
                                neighbourhoodGradeMax={neighbourhoodGradeMax}
                                setNeighbourhoodGradeMax={setNeighbourhoodGradeMax}
                            />
                        </div>
                        <div className="listings-strategy-sorting-row">
                            <div 
                                className="filter-dropdown-outer-container filter-dropdown-chip cursor-pointer relative-container"
                                onClick={(e) => openStrategyDropdown(e)}
                            >
                                <div className={"filter-dropdown-inner-container filter-dropdown-chip " + (openStrategyPanel === true ? "filter-dropdown-reverse" : "")}>
                                    <span className="body-regular colour-secondary filter-dropdown-chip block-text subtext-margin-right">
                                        {
                                            strategy === "sellerFinance" ?
                                            "Seller Finance"
                                            :
                                            strategy === "subto" ?
                                            "SubTo"
                                            :
                                            "All"
                                        }
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                                        <path d="M4 6L7.4641 1.5H0.535898L4 6Z" fill={colour.grayScaleBlack}/>
                                    </svg>
                                </div>
                                {
                                    openStrategyPanel === true ?
                                    <div className="filter-dropdown-element-container bg-colour-light">
                                        <div className="filter-dropdown-inner-element-container">
                                            <h3 className="heading-small-semibold colour-quaternary margin-x-large">
                                                Choose your strategy
                                            </h3>
                                            <div className="filter-dropdown-element-body-container margin-medium">
                                                <FormControl className="new-filters-modal-radio-outer-container">
                                                    <RadioGroup
                                                        aria-labelledby="fixer-upper-buttons-group-label"
                                                        name="radio-buttons-group"
                                                        value={strategy}
                                                        className="choose-value-add-opportunity-property-condition-radios"
                                                        onChange={(val) => changeStrategy(val.target.value)}
                                                    >
                                                        {
                                                            strategyRadios.map((item, index) =>
                                                                <FormControlLabel 
                                                                    value={item.value} 
                                                                    control={<CustomRadio />}
                                                                    className=""
                                                                    label={<span className="body-regular colour-primary">{item.label}</span>}
                                                                    key={index}
                                                                />
                                                            )
                                                        }
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <div className="map-types-row mobile-none">
                                <Box>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="neighbourhood-option-select-label"
                                            id={`neighbourhood-option-select`}
                                            value={hideHeatmap === true ? "hidden" : neighbourhoodOption}
                                            label=""
                                            onChange={(event) => onChangeNeighbourhoodOption(event.target.value)}
                                            input={<CustomSelect />}
                                            className={hideHeatmap === true ? "neighbourhood-option-selector" : ""}
                                        >
                                            <MenuItem
                                                value={"hidden"}
                                                style={hideHeatmap === true ? styles.hiddenMenuItemSelected : styles.hiddenMenuItem}
                                                disabled={false}
                                            >
                                                Hide Heatmap
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg" 
                                                    width="16" 
                                                    height="16" 
                                                    viewBox="0 0 16 16" 
                                                    fill="none"
                                                >
                                                    <path 
                                                        d="M14.0878 2.98574C14.2701 2.81671 14.2701 2.54264 14.0878 2.3736C13.9055 2.20457 13.6099 2.20457 13.4276 2.3736L1.95111 13.0144C1.7688 13.1835 1.7688 13.4575 1.95111 13.6266C2.13343 13.7956 2.42901 13.7956 2.61132 13.6266L14.0878 2.98574Z" 
                                                        fill="#292621"
                                                    />
                                                    <path 
                                                        d="M8 3.36502C9.06405 3.36502 10.1176 3.6722 11.0951 4.12809L10.3462 4.82251C9.57269 4.50103 8.77831 4.30285 8 4.30285C6.47146 4.30285 4.88093 5.06722 3.50834 6.02876C2.51975 6.72131 1.70743 7.47124 1.19707 7.98206C1.70743 8.49288 2.51975 9.24281 3.50835 9.93536C3.76059 10.1121 4.0202 10.2821 4.28544 10.442L3.57174 11.1037C1.84521 10.0158 0.520206 8.65178 0.0723986 8.165C-0.0241321 8.06007 -0.0241332 7.90405 0.0723974 7.79912C0.860885 6.94201 4.369 3.36502 8 3.36502Z" 
                                                        fill="#292621"
                                                    />
                                                    <path 
                                                        d="M7.9611 5.67354C8.38493 5.67354 8.78364 5.77329 9.1314 5.94885L5.80714 9.03108C5.61779 8.70864 5.51021 8.33896 5.51021 7.94599C5.51021 6.69095 6.60751 5.67354 7.9611 5.67354Z" 
                                                        fill="#292621"
                                                    />
                                                    <path 
                                                        d="M4.95671 11.86L5.70766 11.1638C6.46415 11.4722 7.23974 11.6613 8 11.6613C9.52853 11.6613 11.1191 10.8969 12.4917 9.93536C13.4802 9.24281 14.2926 8.49288 14.8029 7.98206C14.2926 7.47124 13.4802 6.72131 12.4917 6.02876C12.2543 5.86251 12.0105 5.70216 11.7616 5.55063L12.4745 4.88966C14.1784 5.9726 15.4838 7.31669 15.9276 7.79912C16.0241 7.90405 16.0241 8.06007 15.9276 8.165C15.1391 9.02211 11.631 12.5991 8 12.5991C6.95474 12.5991 5.91966 12.3027 4.95671 11.86Z" 
                                                        fill="#292621"
                                                    />
                                                    <path 
                                                        d="M6.94646 10.0152L10.1928 7.00524C10.3336 7.2919 10.412 7.61045 10.412 7.94599C10.412 9.20103 9.31468 10.2184 7.9611 10.2184C7.59921 10.2184 7.25564 10.1457 6.94646 10.0152Z" 
                                                        fill="#292621"
                                                    />
                                                </svg>
                                            </MenuItem>	
                                            {
                                                neighbourhoodDataOptions.map((item, index) =>
                                                    <MenuItem
                                                        key={index}
                                                        value={index}
                                                        style={index === neighbourhoodOption && hideHeatmap === false ? styles.selectedMenuItem : styles.menuItem}
                                                        disabled={false}
                                                    >
                                                        {item.label}
                                                    </MenuItem>	
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                        </div>
                    </div>
                    <PropertyListButton
						clickFunc={() => openOverlay()}
						overlay={overlay}
					/>
                    {
                        filtersModalOpen === true ?
                            <NewFiltersModal
                                userDetails={defaultUserData}
                                setFiltersModalOpen={setFiltersModalOpen}
                                formatter={formatterCompact}
                                cityId={cityId}
                                searchParams={searchParams}
                                sorting={sorting}
                                setLoading={setLoading}
                                setSearchParams={setSearchParams}
                                propertyTypes={propertyTypes}
                                setPropertyTypes={setPropertyTypes}
                                units={units}
                                setUnits={setUnits}
                                budgetRange={budgetRange}
                                setBudgetRange={setBudgetRange}
                                bedrooms={bedrooms}
                                setBedrooms={setBedrooms}
                                bathrooms={bathrooms}
                                setBathrooms={setBathrooms}
                                squareFootage={squareFootage}
                                setSquareFootage={setSquareFootage}
                                constructionYears={constructionYears}
                                setConstructionYears={setConstructionYears}
                                setPage={setPage}
                                setPropertiesOnPageChange={setPropertiesOnPageChange}
                                fsbo={fsbo}
                                setFsbo={setFsbo}
                                setFiltersChanged={setFiltersChanged}
                                onlyFixerUppers={onlyFixerUppers}
                                setOnlyFixerUppers={setOnlyFixerUppers}
                                hideFixerUppers={hideFixerUppers}
                                setHideFixerUppers={setHideFixerUppers}
                                inherited={inherited}
                                setInherited={setInherited}
                                neighbourhoodGradeMin={neighbourhoodGradeMin}
                                setNeighbourhoodGradeMin={setNeighbourhoodGradeMin}
                                neighbourhoodGradeMax={neighbourhoodGradeMax}
                                setNeighbourhoodGradeMax={setNeighbourhoodGradeMax}
                            />
                        :
                        null
                    }
                    {
                        mobileAbstract !== "" ?
                        <div className="new-map-overlay-property-card-container new-map-overlay-property-card-container-mobile">
                            <MobilePropertyCardOverlay
                                item={mobileAbstract}
                                index={0}
                                key={0}
                                userDetails={userDetails}
                                abstract={true}
                                setMobileAbtract={setMobileAbtract}
                                cityId={cityId}
                            />
                        </div>
                        :
                        null
                    }
                </div>
                <div className="listings-inner-row-container">
                    <div className={"listings-left-container " + (overlay === false ? "new-property-list-hide-overlay" : "new-property-list-overlay-animate-in")}>
                        {
                            loading === null ?
                            null
                            :
                            reordering === true ?
                            <div className="reorder-loading-animation">
                                <Loading />
                            </div>
                            :
                            listings.length === 0 && loading === false ?
                            <div className="listings-no-properties-container">
                                <h2 className="heading-small-semibold colour-primary text-align-center margin-x-small">
                                    No properties found
                                </h2>
                                <span className="body-regular colour-secondary block-text text-align-center margin-large">
                                    We couldn't find any creative finance leads that
                                    match your filters 😔
                                </span>
                                <div className="listings-no-properties-cta-container">
                                    <div 
                                        className="text-button"
                                        onClick={() => resetAllFilters()}
                                    >
                                        <span className="body-regular text-link">
                                            Reset filters
                                        </span>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="listings-inner-left-container">
                                <div className="listings-left-top-row-container">
                                    <div className="listings-left-top-row-left-container">
                                        {
                                            countyName === null ?
                                            null
                                            :
                                            <div className="listings-county-title-container margin-x-small">
                                                <span className="body-semibold colour-primary">
                                                    {countyName}
                                                </span>
                                            </div>
                                        }
                                        <div className="listings-county-title-container margin-x-small">
                                            <span className="body-semibold colour-primary">
                                                <span className="colour-error">SAMPLE MARKET</span>
                                            </span>
                                        </div>
                                        <div className="choose-market-outer-container">
                                            <div 
                                                className="choose-market-container text-button margin-x-large"
                                                onClick={() => chooseMarket()}
                                            >
                                                <span className="body-regular text-link">
                                                    Upgrade to my market
                                                </span>
                                            </div>
                                        </div>
                                        {
                                            possibleListings === 0 ?
                                            null
                                            :
                                            <div className="listings-total-number-container margin-x-large">
                                                <span className="body-regular colour-secondary block-text subtext-margin-right">
                                                    Showing <span className="body-semibold">{numberFormatter.format(possibleListings)}</span> of {numberFormatter.format(totalProperties)} {leadType === "sellerFinance" ? "free & clear seller finance leads" : leadType === "subto" ? "subto leads" : "leads"}
                                                </span>
                                                {
                                                    leadType !== "all" ?
                                                    <div className="relative-container">
                                                        <svg 
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            width="16" 
                                                            height="16" 
                                                            viewBox="0 0 16 16" 
                                                            fill="none"
                                                            onMouseOver={() => setTooltip(true)}
                                                            onMouseLeave={() => setTooltip(false)}
                                                        >
                                                            <path 
                                                                d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" 
                                                                fill="#2F4858"
                                                            />
                                                            <path 
                                                                d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" 
                                                                fill="#2F4858"
                                                            />
                                                            <path 
                                                                fillRule="evenodd" 
                                                                clipRule="evenodd" 
                                                                d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" 
                                                                fill="#2F4858"
                                                            />
                                                        </svg>
                                                        {
                                                            tooltip === true ?
                                                            <DefaultTooltip
                                                                title={tooltipMessaging[leadType === "sellerFinance" ? 0 : 1].title}
                                                                description={tooltipMessaging[leadType === "sellerFinance" ? 0 : 1].description}
                                                                changeIcon={false}
                                                            />
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>   
                                        }
                                    </div>
                                    {
                                        possibleListings === 0 ?
                                        null
                                        :
                                        <div className="listings-sorting-container text-button">
                                            <Box>
                                                <FormControl sx={styles.formControl} fullWidth>
                                                    <img
                                                        src={Sort}
                                                        className="listings-sort-icon"
                                                        alt=""
                                                    />
                                                    <Select
                                                        labelId="sort-select-label"
                                                        id="sort-select"
                                                        value={sorting}
                                                        label=""
                                                        placeholder=""
                                                        onChange={(event) => changeSorting(event.target.value)}
                                                        input={<SortSelect />}
                                                        disabled={loading}
                                                    >
                                                        {
                                                            sortOptions.map((item, index) =>
                                                                <MenuItem 
                                                                    key={index}
                                                                    value={index}
                                                                    style={index === sorting ? styles.selectedMenuItem : styles.menuItem}
                                                                    disabled={index === 0 ? true : false}
                                                                >
                                                                    {item.label}
                                                                </MenuItem>	
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    }
                                </div>
                                <div 
                                    className="listings-grid-container"
                                    ref={cardsRef}
                                >
                                    {
                                        listings.map((item, index) =>
                                            hiddenProperties.indexOf(item.zpid) === -1 ?
                                            <ListingsCard
                                                property={item}
                                                key={index}
                                                downPayment={downPayment}
                                                hiddenProperties={hiddenProperties}
                                                setHiddenProperties={setHiddenProperties}
                                                userId={userId}
                                                cityId={cityId}
                                                type="listing"
                                                offers={null}
                                                setOffers={null}
                                                setHideModal={setHideModal}
                                                setHideProperty={setHideProperty}
                                                strategy={leadType}
                                                abstract={false}
                                                setHoverCard={setHoverCard}
                                                propertyRefs={propertyRefs}
                                                index={index}
                                            />
                                            :
                                            null
                                        )
                                    }
                                </div>
                            </div>
                        }
                        {
                            loading === true || reordering === true || listings.length === 0 ?
                            null
                            :
                            <Pagination
                                setMapProperties={null}
                                page={page}
                                queryLength={allProperties.length}
                                propertiesOnPage={20}
                                propertiesOnPageChange={propertiesOnPageChange}
                                setPropertiesOnPageChange={setPropertiesOnPageChange}
                                cityId={cityId}
                                setProperties={setListings}
                                setPage={setPage}
                                cardsRef={cardsRef}
                                searchParams={searchParams}
                                setSearchParams={setSearchParams}
                                allProperties={allProperties}
                                temporaryId={null}
                                setSignUpModal={null}
                                setLoading={setReordering}
                                offMarket={false}
                                setChangeMade={null}
                                everyProperty={allProperties}
                            />
                        }
                    </div>
                    {
                        listings.length === 0 && loading === true ?
                        null
                        :
                        <div className="new-property-list-map-container relative-container">
                            <NewPropertyMap
                                properties={(listings.length === 0 && loading === false) ? [] : mapProperties}
                                userDetails={userDetails}
                                setUserDetails={setUserDetails}
                                centreCoordinates={centreCoordinates}
                                allProperties={allProperties}
                                setMobileAbtract={setMobileAbtract}
                                cityId={cityId}
                                hoverCard={hoverCard}
                                propertyRefs={propertyRefs}
                                setCardProperties={setListings}
                                setMapProperties={setMapProperties}
                                noProperties={listings.length === 0 && loading === false ? true : false}
                                setPropertiesOnPageChange={setPropertiesOnPageChange}
                                setPage={setPage}
                                setLoading={setLoading}
                                setSearchParams={setSearchParams}
                                cardIndex={cardIndex}
                                setCardIndex={setCardIndex}
                                cardClick={cardClick}
                                setCardClick={setCardClick}
                                sorting={sorting}
                                searchParams={searchParams}
                                showResetButton={showResetButton}
                                setShowResetButton={setShowResetButton}
                                selectedTracts={selectedTracts}
                                setSelectedTracts={setSelectedTracts}
                                mapSidePanelOpen={mapSidePanelOpen}
                                lastClickedTract={lastClickedTract}
                                setLastClickedTract={setLastClickedTract}
                                setMapSidePanelOpen={setMapSidePanelOpen}
                                sidePanelToggle={sidePanelToggle}
                                loading={loading}
                                setProperties={setListings}
                                mapProperties={mapProperties}
                                setAllProperties={setAllProperties}
                                strategy={strategy}
                                neighbourhoodData={neighbourhoodData}
                                neighbourhoodBounds={neighbourhoodBounds}
                                tracts={tracts}
                                neighbourhoodOption={neighbourhoodOption}
                                clickTract={clickTract}
                                chosenTract={chosenTract}
                                clickedTract={clickedTract}
                                hoverMapLegend={hoverMapLegend}
                                setHoverMapLegend={setHoverMapLegend}
                                bubbles={true}
                                arrowClicked={null}
                                emergingMarkets={emergingMarkets}
                                setEmergingAreaModal={setEmergingAreaModal}
                                setEmergingAreaTract={setEmergingAreaTract}
                                neighbourhoodGradeMin={neighbourhoodGradeMin}
                                neighbourhoodGradeMax={neighbourhoodGradeMax}
                                userId={userId}
                                hiddenProperties={hiddenProperties}
                                setHiddenProperties={setHiddenProperties}
                                leadType={leadType}
                                setHideModal={setHideModal}
                                setHideProperty={setHideProperty}
                                downPayment={downPayment}
                                setHoverCard={setHoverCard}
                                hideHeatmap={hideHeatmap}
                                stateView={false}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default SampleMarket;