import React, { useState, useEffect } from 'react';
import '../styles/ForgotPassword.css';
import { Header, Footer } from '../components';
import { recordEvent, resetPassword } from '../functions/index';
import { useSelector, useDispatch } from 'react-redux';
import { ActionButton, FormInput } from '../styles/GlobalStyles';
import { emailAction } from '../actions';

function ForgotPassword() {
    const email = useSelector(state => state.email);
    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
	const dispatch = useDispatch();

    useEffect(() => {
        document.title = "Coffee Clozers | Forgot Password";
    }, []);

    const passwordRecovery = async() => {
        setDisabled(true);
		const query = await resetPassword(email);
		if ( query.status === 200 ) {
            setErrorMessage("");

            recordEvent("Password Forgotten", {
                email: email
            });

            setSuccess(true);
            setDisabled(false);
		}
		else {
            if ( query.code === "auth/user-not-found" ) {
                setErrorMessage("Email not found 🤔");
			}
            else if ( query.code === "auth/invalid-email" ) {
                setErrorMessage("Please enter a valid email 👍");
            }
			else {
				alert(query.message);
			}
            setDisabled(false);
		}
	};

    return (
        <div className="forgot-password-outer-container">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            <div className="forgot-password-inner-container">
                <div className="forgot-password-title-container margin-x-x-large">
                    <h1 className="heading-large-semibold colour-primary text-align-center">
					    Forgot your password?
				    </h1>
                </div>
                <div className="forgot-password-input-container margin-x-x-large">
					<FormInput
						value={email}
						type="email"
						label="Email"
						placeholder="youremail@email.com"
						id="email-input"
						onChange={(text) => dispatch(emailAction(text.target.value))}
					/>
                    {
                        errorMessage !== "" ?
                        <div className="forgot-password-success-message-container">
                            <span className="body-regular colour-error">
                                {errorMessage}
                            </span>
                        </div>
                        :
                        success === true ?
                        <div className="forgot-password-success-message-container">
                            <span className="body-regular colour-primary">
                                We just sent you an email 😊
                            </span>
                        </div>
                        :
                        null
                    }
				</div>
                <div className="forgot-password-button-container margin-x-x-large">
                    <ActionButton
						onClick={() => passwordRecovery()}
						disabled={email === "" || disabled === true ? true : false}
					>
						Submit
					</ActionButton>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ForgotPassword;