import React from 'react';
import '../styles/SidePanelTrigger.css';
import { Info } from '../assets';

function SidePanelTrigger(props) {
    const sidePanelToggle = props.sidePanelToggle;
    const lastClickedTract = props.lastClickedTract;
    const userDetails = props.userDetails;
    const sidePanelAnimation = userDetails === null ? "" : userDetails.sidePanel === undefined || userDetails.sidePanel === false ?  "side-panel-trigger-border-container-infinite" : "side-panel-trigger-border-container";

    return (
        <div 
            className={"side-panel-trigger-outer-container " + (lastClickedTract !== "" ? sidePanelAnimation : "")}
            onClick={() => sidePanelToggle()}
        >
            <div className="side-panel-trigger-inner-container">
                <img
                    src={Info}
                    className="side-panel-trigger-icon"
                    alt="Trigger Side Panel"
                />
            </div>
        </div>
    )
}

export default SidePanelTrigger;