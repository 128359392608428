import React, { useState, useEffect } from 'react';
import { ActionButton, NewMultilineInput } from '../styles/GlobalStyles';
import { useSelector, useDispatch } from 'react-redux';
import { pennyThoughtsAction } from '../actions';
import '../styles/PennyThoughts.css';
import { Header, Footer } from '../components';
import { setData, makeId, checkUserId, getIp, sendEmail, recordEvent } from '../functions/index';
import { useNavigate } from 'react-router-dom';
import { isBrowser, isMobile } from 'react-device-detect';

function PennyThoughts() {
	const pennyThoughts = useSelector(state => state.pennyThoughts);
	const [disabled, setDisabled] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const contactEmail = "liam.maher@coffeeclozers.com";
	const dynamicTemplate = "d-38f45cfd953d4dc0af3ca5204bd72b64";

	useEffect(() => {
		document.title = "Coffee Clozers | Penny 4 Thoughts";
	});

	const complete = async() => {
		setDisabled(true);
		const colRef = "Creative Penny For Thoughts";
		const docRef = await makeId(15);
		const user = await checkUserId();
		const ipAddress = await getIp();
		const data = {
			thought: pennyThoughts,
			user: user.userId,
			ip: ipAddress.error === true ? "" : ipAddress,
			date: new Date()
		};

		const writeData = await setData(colRef, docRef, data);
		recordEvent("User Feedback Submitted", {
			thought: pennyThoughts,
			user: user.userId,
			ip: ipAddress.error === true ? "" : ipAddress,
			date: new Date()
		
		});
		if ( writeData.status === 200 ) {
			const msg = {
				to: [contactEmail, "ariel.herrera@coffeeclozers.com", "maddy@coffeeclozers.com"],
				from: contactEmail,
				templateId: dynamicTemplate,
				dynamic_template_data: {
					thought: pennyThoughts,
					userId: user.userId,
					email: user.email,
					isMobile: isMobile === true ? "true" : "false",
					isBrowser: isBrowser === true ? "true" : "false",
					creative: true
				}
			};
			const emailAPI = await sendEmail(msg);
			console.log("email = ", emailAPI);
			alert("Thank you for sharing your thoughts!");
			dispatch(pennyThoughtsAction(""));
			navigate('/');
		}
		else {
			console.log(`There was an error: ${writeData.message}`);
			console.log(`data: ${data}`);
		}
		setDisabled(false);
	};

	return (
		<div className="penny-thoughts-outer-container">
			<Header
				subscriptions={null}
                users={null}
                queries={[true, true]}
				mobileNav={true}
			/>
			<div className="penny-thoughts-inner-container">
				<div className="penny-thoughts-title-container">
					<h1 className="heading-large-semibold colour-primary text-align-left margin-medium">
						Penny for your thoughts
					</h1>
					<h2 className="body-regular colour-primary text-align-left">
						Does our product suck? let us know here.
					</h2>
				</div>
				<div className="penny-thoughts-body-container">
					<NewMultilineInput
						value={pennyThoughts}
						id="penny-thoughts-input" 
						label=""
						placeholder="so whaddaya think..."
						rows="8"
						inputProps={{
							autoCapitalize: 'none'
						}}
						variant="outlined" 
						onChange={(text) => dispatch(pennyThoughtsAction(text.target.value))}
						multiline
					/>
					<div className="penny-thoughts-button-container">
						<ActionButton
							disabled={disabled === true ? true : pennyThoughts === "" ? true : false}
							onClick={() => complete()}
						>
							Send off
						</ActionButton>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default PennyThoughts;