import React from 'react';
import '../styles/MobileSubscriptionGrid.css';
import { PinBlue } from '../assets';
import { ActionButton, formatterLong } from '../styles/GlobalStyles';
import moment from 'moment';

function MobileSubscriptionGrid(props) {
    const subscriptions = props.subscriptions;
    const checkMonths = props.checkMonths;
    const cancelSubscription = props.cancelSubscription;
    const getHelp = props.getHelp;
    const freeTrialObject = props.freeTrialObject;

    const upgradeSubscription = () => {
        window.open("https://app.coffeeclozers.com/pricing", "_self");
    };

    return (
        <div className="mobile-subscription-grid">
            {
                subscriptions.length === 0 ?
                freeTrialObject.map((item, index) =>
                    <div 
                        className="mobile-subscription-card-outer-container"
                        key={index}
                    >
                        <div className="mobile-subscription-card-inner-container">
                            <div className="mobile-subscription-card-title-container margin-x-small">
                                <img
                                    src={PinBlue}
                                    className="subscription-table-pin-icon"
                                    alt="Pin"
                                />
                                <span className="body-regular text-link">
                                    All active markets
                                </span>
                            </div>
                            <div className="mobile-subscription-card-body-container">
                                <div className="mobile-subscription-card-left-body-container">
                                    <div className="mobile-subscription-card-top-row-container margin-x-x-small">
                                        <span className="body-semibold colour-primary block-text subtext-margin-right">
                                            7 day Free trial
                                        </span>
                                        <span className="body-regular colour-primary">
                                            (Free)
                                        </span>
                                    </div>
                                    <div className="">
                                        <span className="body-regular colour-primary block-text margin-x-x-small">
                                            <span className="colour-secondary">
                                                Expires{' '}
                                            </span>
                                            {moment.unix(item.endDate.seconds).format("MMM D, YYYY")}
                                        </span>
                                    </div>
                                    <div className="mobile-subscription-card-bottom-row-container">
                                        <span className="body-regular colour-primary text-italic">
                                            You will <span className="body-semibold text-italic">NOT</span>{' '}
                                            be billed when your trial is up.
                                        </span>
                                    </div>
                                </div>
                                <div className="mobile-subscription-card-right-body-container">
                                    <div className="mobile-subscription-card-upgrade-button">
                                        <ActionButton
                                            onClick={() => upgradeSubscription()}
                                        >
                                            Upgrade
                                        </ActionButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                :
                subscriptions.map((item, index) =>
                    <div 
                        className="mobile-subscription-card-outer-container"
                        key={index}
                    >
                        <div className="mobile-subscription-card-inner-container">
                            <div className="mobile-subscription-card-title-container margin-x-small">
                                <img
                                    src={PinBlue}
                                    className="subscription-table-pin-icon"
                                    alt="Pin"
                                />
                                <span className="body-regular text-link">
                                    {
                                        item.metroArea === true ?
                                        `${item.msaTitle}`
                                        :
                                        item.city !== undefined ?
                                        `${item.city}, ${item.state}`
                                        :
                                        "Core 50 cities"
                                    }
                                </span>
                            </div>
                            <div className="mobile-subscription-card-body-container">
                                <div className="mobile-subscription-card-left-body-container">
                                    <div className="mobile-subscription-card-top-row-container margin-x-x-small">
                                        {
                                            item.autoBilling === true ?
                                            <span className="body-semibold colour-primary block-text subtext-margin-right">
                                                Auto-renew
                                            </span>
                                            :
                                            <span className="body-semibold colour-primary block-text subtext-margin-right">
                                                {checkMonths(item)} {checkMonths(item) === 1 ? "month" : "months"} access
                                            </span>
                                        }
                                        <span className="body-regular colour-primary">
                                            {
                                                item.price === "" ?
                                                "" 
                                                :
                                                item.autoBilling === true ?
                                                `(${formatterLong.format(item.price).replace(".00", "")}/month)`
                                                :
                                                `(${formatterLong.format(item.price).replace(".00", "")})`
                                            }
                                        </span>
                                    </div>
                                    <div className="mobile-subscription-card-bottom-row-container">
                                        {
                                            item.autoBilling === true ?
                                            <span className="body-regular colour-secondary">
                                                Renews{' '}
                                                <span className="colour-primary">
                                                    {moment.unix(item.endDate.seconds).format("MMM D, YYYY")}
                                                </span>
                                            </span>
                                            :
                                            <div className="">
                                                <span className="body-regular colour-primary block-text margin-x-x-small">
                                                    <span className="colour-secondary">
                                                        Expires{' '}
                                                    </span>
                                                    {moment.unix(item.endDate.seconds).format("MMM D, YYYY")}
                                                </span>
                                                <span className="body-regular colour-primary text-italic">
                                                    Does <span className="body-semibold text-italic">NOT</span>{' '}
                                                    renew automatically
                                                </span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="mobile-subscription-card-right-body-container">
                                    {
                                        item.autoBilling === true ?
                                        <div 
                                            className="text-button"
                                            onClick={() => cancelSubscription(item)}
                                        >
                                            <span className="body-regular colour-error block-text text-align-center">
                                                Cancel
                                            </span>
                                        </div>
                                        :
                                        <div 
                                            className="text-button"
                                            onClick={() => getHelp()}
                                        >
                                            <span className="body-regular colour-link block-text text-align-center">
                                                Get help
                                            </span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
};

export default MobileSubscriptionGrid;