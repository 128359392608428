
import React, { useEffect, useState } from 'react';
import '../styles/BuyBoxCard.css';
import { colour, formatterCompact, neighbourhoodColours, numberFormatter } from '../styles/GlobalStyles';
import { BedroomsBlue, BathroomsBlue, ConstructionBlue, SqFootageBlue } from '../assets';
import { BuyBoxFrequency, BuyBoxMoreContainer, DefaultTooltip, InfoSquare, Loading, Selector } from './';
import moment from 'moment';
import { addRecentMarket, getBuyBoxFilterParams } from "../functions";

function BuyBoxCard(props) {
    const item = props.item;
    const index = props.index;
    const buyBoxFrequency = props.buyBoxFrequency;
    const setBuyBoxFrequency = props.setBuyBoxFrequency;
    const buyBoxMore = props.buyBoxMore;
    const setBuyBoxMore = props.setBuyBoxMore;
    const onChangeBuyBoxFrequency = props.onChangeBuyBoxFrequency;
    const editBuyBox = props.editBuyBox;
    const deleteBuyBox = props.deleteBuyBox;
    const setDeleteParam = props.setDeleteParam;
    const subscriptions = props.subscriptions;
    const today = new Date();
    const todaySeconds = today.getTime() / 1000;
    const subscriptionIndex = subscriptions.findIndex(e => e.endDate.seconds > todaySeconds);
    const userId = props.userId;
    const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
    const isAdmin = adminIds.indexOf(userId) !== -1 ? true : false;
    const freeTrial = props.freeTrial;
    const paidCity = isAdmin === true || subscriptionIndex !== -1 || freeTrial === true ? true : false;
    const openUpgradeModal = props.openUpgradeModal;
    const openStrategy = props.openStrategy;
    const setOpenStrategy = props.setOpenStrategy;
    const onChangeBuyBoxStrategy = props.onChangeBuyBoxStrategy;
    const city = props.city;
    const loadingBuyBoxes = props.loadingBuyBoxes;
    const setRecentlyViewed = props.setRecentlyViewed;
    const neighbourhoodGradeMin = item.neighbourhoodGradeMin !== undefined ? item.neighbourhoodGradeMin : 1;
    const neighbourhoodGradeMax = item.neighbourhoodGradeMax !== undefined ? item.neighbourhoodGradeMax : 8;
    const [loading, setLoading] = useState(false);
    const [tooltip, setTooltip] = useState(false);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
			setInnerWidth(window.innerWidth);
		};
	  
		window.addEventListener('resize', handleWindowResize);
    }, []);
    
    const lastUpdatedDate = item.lastUpdate.seconds;
    const a = moment(new Date());
    const b = moment.unix(lastUpdatedDate);
    const dateDifference = a.diff(b, 'days');
    const dateDifferenceString = dateDifference === 0 ? "Today" : dateDifference === 1 ? "Yesterday" : `${dateDifference} days ago`;

    const details = [
        {
            icon: BedroomsBlue,
            text: `${item.bedrooms} +`
        },
        {
            icon: BathroomsBlue,
            text: `${item.bathrooms} +`
        },
        {
            icon: SqFootageBlue,
            text: item.squareFootage[1] === 999999 ? "Any" : `${numberFormatter.format(item.squareFootage[0])} - ${numberFormatter.format(item.squareFootage[1])}`
        },
        {
            icon: ConstructionBlue,
            text: `${item.constructionYears[0]} - ${item.constructionYears[1]}`
        }
    ];

    const strategies = [
        {
            title: "Seller Finance",
            text: "Seller Finance",
            value: "sellerFinance",
            selected: item.strategy === "sellerFinance" ? true : false
        },
        {
            title: "Subject To",
            text: "Subject To",
            value: "subto",
            selected: item.strategy === "subto" ? true : false
        }
    ];


    const styles = {
        first: {
            backgroundColor: neighbourhoodColours[0]
        },
        second: {
            backgroundColor: neighbourhoodColours[1]
        },
        third: {
            backgroundColor: neighbourhoodColours[2]
        },
        fourth: {
            backgroundColor: neighbourhoodColours[3]
        },
        fifth: {
            backgroundColor: neighbourhoodColours[4]
        },
        sixth: {
            backgroundColor: neighbourhoodColours[5]
        },
        seventh: {
            backgroundColor: neighbourhoodColours[6]
        },
        eighth: {
            backgroundColor: neighbourhoodColours[7]
        }
    };

    const neighbourhoodOptions = [
        {
            label: "D Grade",
            labelMin: "D Grade",
            style: styles.eighth
        },
        {
            label: "C- Grade",
            labelMin: "C- Grade",
            style: styles.seventh
        },
        {
            label: "C Grade",
            labelMin: "C Grade",
            style: styles.sixth
        },
        {
            label: "C+ Grade",
            labelMin: "C+ Grade",
            style: styles.fifth
        },
        {
            label: "B- Grade",
            labelMin: "B- Grade",
            style: styles.fourth
        },
        {
            label: "B Grade",
            labelMin: "B Grade",
            style: styles.third
        },
        {
            label: "B+ Grade",
            labelMin: "B+ Grade",
            style: styles.second
        },
        {
            label: "A Grade",
            labelMin: "A Grade",
            style: styles.first
        }
    ];
    const neighbourhoodGrades = [
        {
            label: neighbourhoodOptions[neighbourhoodGradeMax - 1].label,
            labelMin: neighbourhoodOptions[neighbourhoodGradeMax - 1].labelMin,
            style: neighbourhoodOptions[neighbourhoodGradeMax - 1].style,
            value: neighbourhoodGradeMax
        },
        {
            label: neighbourhoodOptions[neighbourhoodGradeMin - 1].label,
            labelMin: neighbourhoodOptions[neighbourhoodGradeMin - 1].labelMin,
            style: neighbourhoodOptions[neighbourhoodGradeMin - 1].style,
            value: neighbourhoodGradeMin
        }
    ];

    const viewBuyBox = async(e) => {
        const target = e.target;
        const targetClass = target.classList.value;
        const parentTargetClass = target.parentNode.classList.value;
        if ( targetClass.includes("buy-box-frequency") || parentTargetClass.includes("buy-box-frequency") ) {
            return;
        }
        else if ( targetClass.includes("selector") || parentTargetClass.includes("selector") ) {
            return;
        }
        else if ( targetClass.includes("buy-box-card-free-mode") || parentTargetClass.includes("buy-box-card-free-mode") ) {
            return;
        }
        else if ( targetClass.includes("buy-box-card-details-strategy-container") || parentTargetClass.includes("buy-box-card-details-strategy-container") ) {
            return;
        }
        else if ( targetClass.includes("filter-dropdown-chip") || parentTargetClass.includes("filter-dropdown-chip") ) {
            return;
        }
        else if ( targetClass.includes("buy-box-card-strategy-icon") || parentTargetClass.includes("buy-box-card-strategy-icon") ) {
            return;
        }
        
        const filters = await getBuyBoxFilterParams(item);

        if ( paidCity === true ) {
            window.open(`/properties-list/${item.msaCode}?${filters}`, "_blank");

            if ( userId !== null ) {
                await addRecentMarket(item, userId, setRecentlyViewed);
            }
        }
        else {
            openUpgradeModal();
        }
    };

    const checkTitle = () => {
        const titleLength = item.title.length;
        const characterLimit = 34;
        if ( titleLength > characterLimit ) {
            return item.title.substring(0, characterLimit) + "...";
        }
        else {
            return item.title;
        }
    };

    const toggleTooltip = (val) => {
        setTooltip(val);
    };

    const checkSelectedMetroCity = () => {
        return item.msaTitle;
        // if ( item.buyBoxMetroCity === undefined ) {
        //     return "All cities";
        // }
        // else if ( item.buyBoxMetroCity === "all" ) {
        //     return "All cities";
        // }
        // else {
        //     const selectedCityId = item.buyBoxMetroCity;
        //     const metroCities = city.metroCities;
        //     const metroCityIndex = metroCities.findIndex(e => e.cityId === selectedCityId);
        //     if ( metroCityIndex !== -1 ) {
        //         const metroCityObject = metroCities[metroCityIndex];
        //         return `${metroCityObject.city}, ${metroCityObject.state}`;
        //     }
        //     else {
        //         return "All cities";
        //     }
        // }
    };

    const selectStrategy = (index) => {
        const option = strategies[index].value;
        setOpenStrategy(null);
        onChangeBuyBoxStrategy(option, item);
    };

    const checkStrategy = (val) => {
        if ( val === undefined ) {
            return "No strategy";
        }
        else {
            const strategyIndex = strategies.findIndex(e => e.value === val);
            return strategies[strategyIndex].title;
        }
    };

    return (
        <div className={"buy-box-card-outer-container relative-container bg-colour-white " + (buyBoxMore === true ? "buy-box-card-outer-container-extra-border" : "")}>
            <BuyBoxMoreContainer
                buyBoxMore={buyBoxMore}
                setBuyBoxMore={setBuyBoxMore}
                index={index}
                id={item.id}
                editBuyBox={editBuyBox}
                deleteBuyBox={deleteBuyBox}
                item={item}
                setLoading={setLoading}
                setDeleteParam={setDeleteParam}
                subscriptions={subscriptions}
                city={city}
            />
            {
                loading === true ?
                <Loading />
                :
                null
            }
            <div 
                className="buy-box-card-inner-container"
                onClick={(e) => viewBuyBox(e)}
            >
                <h2 className="heading-small-semibold margin-x-small">
                    {checkTitle()}
                </h2>
                <div className="buy-box-card-details-container margin-x-small">
                    {
                        item.metroArea === true ?
                        <div className="buy-box-detail-element">
                            <div className="buy-box-detail-element-text-container">
                                <span className="body-regular colour-primary">
                                    {checkSelectedMetroCity()}
                                </span>
                            </div>
                            <span className="body-regular colour-primary margin-sides">
                                •
                            </span>
                        </div>
                        :
                        null
                    }
                    {
                        item.propertyTypes.map((item, index) =>
                            item.included === true ?
                            <div 
                                className="buy-box-detail-element"
                                key={index}
                            >
                                <div className="buy-box-detail-element-text-container">
                                    <span className="body-regular colour-primary">
                                        {item.abbreviation}
                                    </span>
                                </div>
                                <span className="body-regular colour-primary margin-sides">
                                    •
                                </span>
                            </div>
                            :
                            null
                        )
                    }
                    <div className="buy-box-detail-element">
                        <div className="buy-box-detail-element-text-container">
                            <span className="body-regular colour-primary">
                                {formatterCompact.format(item.budgetRange[0])}{
                                    item.budgetRange[1] === 1000000000 ?
                                    `+`
                                    :
                                    ` - ${formatterCompact.format(item.budgetRange[1])}`
                                }
                            </span>
                        </div>
                    </div>
                </div>
                <div className="buy-box-card-details-container margin-medium">
                    {
                        details.map((item, index) =>
                            <div 
                                className={"buy-box-detail-element " + (index === details.length - 1 ? "" : "subtext-margin-right-x-x-large")}
                                key={index}
                            >
                                <img
                                    src={item.icon}
                                    className="buy-box-detail-element-icon block-text subtext-margin-right"
                                    alt="Bedrooms"
                                />
                                <span className="body-regular colour-quaternary">
                                    {item.text}
                                </span>
                            </div>
                        )
                    }
                </div>
                <div className="buy-box-card-details-strategy-container margin-medium">
                    <div 
                        className="filter-dropdown-outer-container filter-dropdown-chip cursor-pointer relative-container"
                        onClick={() => openStrategy === false ? setOpenStrategy(item.id) : null}
                    >
                        <div className={"filter-dropdown-inner-container filter-dropdown-chip " + (openStrategy === true ? "filter-dropdown-reverse" : "")}>
                            <span className="body-regular colour-primary filter-dropdown-chip block-text subtext-margin-right">
                                {checkStrategy(item.strategy)}
                            </span>
                            <svg className="buy-box-card-strategy-icon" xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                                <path d="M4 6L7.4641 1.5H0.535898L4 6Z" fill={colour.grayScaleBlack}/>
                            </svg>
                        </div>
                        {
                            openStrategy === true ?
                            <Selector
                                options={strategies}
                                selectOption={selectStrategy}
                            />
                            :
                            null
                        }
                    </div>
                </div>
                <div className="buy-box-card-details-neighbourhood-container margin-medium">
                    <div 
                        className="neighbourhood-badge-container"
                        style={neighbourhoodGrades[0].style}
                    >
                        <span className={"body-regular " + (neighbourhoodGrades[0].value === 8 ? "colour-primary" : "colour-white")}>
                            {
                                innerWidth < 500 ?
                                neighbourhoodGrades[0].labelMin
                                :
                                neighbourhoodGrades[0].label
                            }
                        </span>
                    </div>
                    <span className="body-regular colour-quaternary margin-sides">
                        -
                    </span>
                    <div 
                        className="neighbourhood-badge-container"
                        style={neighbourhoodGrades[1].style}
                    >
                        <span className={"body-regular " + (neighbourhoodGrades[1].value === 8 ? "colour-primary" : "colour-white")}>
                            {
                                innerWidth < 500 ?
                                neighbourhoodGrades[1].labelMin
                                :
                                neighbourhoodGrades[1].label
                            }
                        </span>
                    </div>
                </div>
                <div className="buy-box-card-details-boxes-row margin-x-large">
                    <InfoSquare
                        label="BUY BOX ACTIVE LISTINGS"
                        value={numberFormatter.format(item.activeListings)}
                        subLabel={`Of ${numberFormatter.format(item.totalListings)} total`}
                        item={item}
                        loadingBuyBoxes={loadingBuyBoxes}
                    />
                    {/* <InfoSquare
                        label="NEW LISTINGS"
                        value={numberFormatter.format(item.newListings)}
                        subLabel="Since last update"
                        item={item}
                        loadingBuyBoxes={loadingBuyBoxes}
                    /> */}
                </div>
            </div>
            <div className="buy-box-card-details-divider-line">
            </div>
            <div 
                className="buy-box-card-bottom-inner-container"
                onClick={(e) => viewBuyBox(e)}
            >
                <div className="buy-box-card-bottom-row">
                    <BuyBoxFrequency
                        frequency={item.frequency}
                        buyBoxFrequency={buyBoxFrequency}
                        setBuyBoxFrequency={setBuyBoxFrequency}
                        index={index}
                        id={item.id}
                        onChangeBuyBoxFrequency={onChangeBuyBoxFrequency}
                        city={item.city}
                        state={item.state}
                        item={item}
                    />
                    <div 
                        className={"buy-box-card-last-updated-container relative-container " + (paidCity === false ? "buy-box-card-free-mode" : "")}
                        onMouseEnter={() => paidCity === true ? null : toggleTooltip(true)}
                        onMouseLeave={() => paidCity === true ? null : toggleTooltip(false)}
                        onClick={() => paidCity === true ? null : openUpgradeModal()}
                    >
                        <span className="body-regular colour-secondary block-text subtext-margin-right">
                            Updated
                        </span>
                        <span className="body-semibold colour-link">
                            {
                                item.lastUpdateWords !== undefined ?
                                item.lastUpdateWords
                                :
                                dateDifferenceString
                            }
                        </span>
                        {
                            paidCity === false && tooltip === true ?
                            <DefaultTooltip
                                title="Upgrade to get daily updates"
                                description="Data is updated daily on paid plans"
                            />
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default BuyBoxCard;