import React, { useState } from 'react';
import { DefaultTooltip } from '../components';
import { FinancialsInputNoLabel, formatterLong, percentage } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import { formatPrice } from '../functions';

function LeaseOptionRent(props) {
    const blurred = props.blurred;
    const property = props.property;
    const leaseOptionValue = props.leaseOptionValue;
    const onChangeLeaseValue = props.onChangeLeaseValue;
    const downPaymentPercentage = props.downPaymentPercentage;
    const toggleDPPercentage = props.toggleDPPercentage;
    const onChangeLeaseDownPaymentDollar = props.onChangeLeaseDownPaymentDollar;
    const onChangeLeaseDownPayment = props.onChangeLeaseDownPayment;
    const onChangeLeaseInterest = props.onChangeLeaseInterest;
    const leaseOptionDownPayment = props.leaseOptionDownPayment;
    const leaseOptionDollarDownPayment = props.leaseOptionDollarDownPayment;
    const leaseOptionInterestRate = props.leaseOptionInterestRate;
    const leaseOptionRent = props.leaseOptionRent;
    const [rentTooltip, setRentTooltip] = useState(false);
    const [downPaymentTooltip, setDownPaymentTooltip] = useState(false);
    const [interestTooltip, setInterestTooltip] = useState(false);

    const openLeaseOptionVideo = () => {
        window.open("https://drive.google.com/file/d/1BFw3kERa2QXSdAbPotndyFg1xp_nI5E5/view", "_blank");
    };

    return (
        <div className="rent-tab-outer-container">
            <div className="rent-tab-inner-container">
                <div className="lease-option-final-description-container margin-large">
                    <span className="body-regular colour-secondary block-text subtext-margin-right">
                        Confused about lease options?
                    </span>
                    <span 
                        className="body-regular colour-link block-text underline"
                        onClick={() => openLeaseOptionVideo()}
                    >
                        Watch this video
                    </span>
                </div>
                <div className="button-row margin-x-small">
                    <span className="body-semibold colour-primary block-text subtext-margin-right">
                        Sale price (for tenant buyer)
                    </span>
                    <div 
                        className="relative-container cursor-pointer"
                        onMouseEnter={() => setRentTooltip(true)}
                        onMouseLeave={() => setRentTooltip(false)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" fill="#716E69"/>
                            <path d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" fill="#716E69"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" fill="#716E69"/>
                        </svg>
                        {
                            rentTooltip === true ?
                            <DefaultTooltip
                                title={"Lease Option Property Value"}
                                description={"This is the price you are charging your tenant buyer to buy your property in the future. This is typically 100% to 110% of today's property value."}
                            />
                            :
                            null
                        }
                    </div>
                </div>
                <div className="strategies-panel-financials-input lease-option-property-value-container margin-large">
                    <FinancialsInputNoLabel
                        value={blurred === true ? "999" : formatPrice(leaseOptionValue)}
                        type="text"
                        id={`lease-value-input`}
                        placeholder={formatPrice(property.price)}
                        onWheel={(e) => e.target.blur()}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        disabled={false}
                        onChange={(text) => onChangeLeaseValue(text.target.value.replaceAll(",", ""))}
                    />
                    {
                        property.price === Number(leaseOptionValue) ?
                        null
                        :
                        <div className="margin-top-x-small">
                            <span className="label-regular colour-secondary block-text text-italic">
                                {percentage.format(Math.abs((leaseOptionValue / property.price) - 1))}
                                {" "}
                                {leaseOptionValue > property.price ? "above " : "below "}
                                purchase price
                            </span>
                        </div>
                    }
                </div>
                <div className="lease-option-purchase-row margin-large">
                    <div className="lease-option-purchase-element">
                        <div className="lease-option-purchase-title-container margin-x-small">
                            <span className="body-semibold colour-primary block-text subtext-margin-right">
                                Option fee
                            </span>
                            <div 
                                className="relative-container cursor-pointer"
                                onMouseEnter={() => setDownPaymentTooltip(true)}
                                onMouseLeave={() => setDownPaymentTooltip(false)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" fill="#716E69"/>
                                    <path d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" fill="#716E69"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" fill="#716E69"/>
                                </svg>
                                {
                                    downPaymentTooltip === true ?
                                    <DefaultTooltip
                                        title={"Option fee"}
                                        description={"This is effectively the down payment your tenant buyer will pay you to enter into a lease option agreement. This is usually between 5% and 20% of the sale price."}
                                    />
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className={"strategies-panel-financials-input " + (downPaymentPercentage === true ? "monthly-costs-input-container-right" : "")}>
                            <FinancialsInputNoLabel
                                value={downPaymentPercentage === false ? formatPrice(leaseOptionDollarDownPayment) : `${leaseOptionDownPayment}`.length > 4 ? Number(leaseOptionDownPayment).toFixed(2) : leaseOptionDownPayment}
                                type="text"
                                id={`lease-option-dp-input`}
                                onWheel={(e) => e.target.blur()}
                                InputProps={{
                                    startAdornment: <InputAdornment className={downPaymentPercentage === false ? "financials-panel-active-option" : "financials-panel-dormant-option"} onClick={() => toggleDPPercentage(false)} position="start">$</InputAdornment>,
                                    endAdornment: <InputAdornment className={downPaymentPercentage === true ? "financials-panel-active-option" : "financials-panel-dormant-option"} onClick={() => toggleDPPercentage(true)} position="end">%</InputAdornment>
                                }}
                                onChange={(text) => downPaymentPercentage === false ? onChangeLeaseDownPaymentDollar(text.target.value.replaceAll(",", "")) : onChangeLeaseDownPayment(text.target.value)}
                            />
                        </div>
                    </div>
                    <div className="lease-option-purchase-element">
                        <div className="lease-option-purchase-title-container margin-x-small">
                            <span className="body-semibold colour-primary block-text subtext-margin-right">
                                Interest rate
                            </span>
                            <div 
                                className="relative-container cursor-pointer"
                                onMouseEnter={() => setInterestTooltip(true)}
                                onMouseLeave={() => setInterestTooltip(false)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" fill="#716E69"/>
                                    <path d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" fill="#716E69"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" fill="#716E69"/>
                                </svg>
                                {
                                    interestTooltip === true ?
                                    <DefaultTooltip
                                        title={"Interest rate"}
                                        description={"This is the interest rate you will charge your tenant buyer as part of the lease option agreement. This is typically between 7% and 10%, with payments amortized over 30 years."}
                                    />
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="strategies-panel-financials-input">
                            <FinancialsInputNoLabel
                                value={leaseOptionInterestRate}
                                type="text"
                                id={`lease-option-interest-input`}
                                onWheel={(e) => e.target.blur()}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                }}
                                onChange={(text) => onChangeLeaseInterest(text.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="lease-option-rent-value-container margin-large">
                    <span className="body-semibold colour-primary block-text margin-x-x-small">
                        Monthly rent =
                    </span>
                    <div className="lease-option-rent-value-row">
                        <span className="heading-small-regular colour-primary">
                            {formatterLong.format(leaseOptionRent).replace(".00", "")}
                        </span>
                        <span className="body-regular colour-secondary">
                            /month
                        </span>
                    </div>
                </div>
                <div className="lease-option-disclaimer-container">
                    <span className="label-regular colour-error block-text text-align-left">
                        DISCLAIMER: Lease options can vary. We assume zero principle pay-down by default.
                    </span>
                </div>
            </div>
        </div>
    )
};

export default LeaseOptionRent;