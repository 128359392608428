import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper/modules';
import '../styles/CustomSwiper.css';

function CustomSwiper(props) {
	const images = props.images;
	const title = props.title;
	const viewProperty = props.viewProperty;
	const imageLimit = props.imageLimit;
	const arrowClicked = props.arrowClicked;
	const swiperRef = props.swiperRef;
	const [elementHover, setElementHover] = useState(false);

	const showArrows = () => {
		if ( images.length > 1 ) {
			setElementHover(true)
		}
	};

	const hideArrows = () => {
		setElementHover(false)
	};

	return (
		images !== undefined ?
			<div 
				className={"custom-swiper-module-container " + (elementHover === true ? "custom-swiper-module-container-hover" : "")}
				onMouseEnter={() => showArrows()}
				onMouseLeave={() => hideArrows()}
			>
				<Swiper 
					pagination={{
						dynamicBullets: true,
					}}
					modules={[Pagination, Navigation]} 
					className="custom-swiper-outer-container"
					loop={true}
					// slidesPerView={5}
					navigation={{
						prevEl: '.swiper-button-prev',
						nextEl: '.swiper-button-next',
					}}
					ref={swiperRef}
				>
					<div className="swiper-button-prev" onClick={(e) => arrowClicked === null ? null : arrowClicked(e)}>
						<svg 
							xmlns="http://www.w3.org/2000/svg" 
							width="9" 
							height="8" 
							viewBox="0 0 9 8" 
							fill="none"
							className="custom-swiper-icon"
						>
							<path 
								d="M2.7592 0.243947C3.0723 -0.0813155 3.58721 -0.0813155 3.90032 0.243947L7.51595 3.99999L3.90032 7.75603C3.58721 8.08129 3.0723 8.08129 2.7592 7.75603C2.45527 7.44029 2.45527 6.93514 2.7592 6.6194L5.2807 3.99999L2.7592 1.38057C2.45527 1.06483 2.45527 0.559682 2.7592 0.243947Z"
								fill="#292621"
							/>
						</svg>
					</div>
      				<div className="swiper-button-next" onClick={(e) => arrowClicked === null ? null : arrowClicked(e)}>
					  	<svg 
							xmlns="http://www.w3.org/2000/svg" 
							width="9" 
							height="8" 
							viewBox="0 0 9 8" 
							fill="none"
							className="custom-swiper-icon"
						>
							<path 
								d="M2.7592 0.243947C3.0723 -0.0813155 3.58721 -0.0813155 3.90032 0.243947L7.51595 3.99999L3.90032 7.75603C3.58721 8.08129 3.0723 8.08129 2.7592 7.75603C2.45527 7.44029 2.45527 6.93514 2.7592 6.6194L5.2807 3.99999L2.7592 1.38057C2.45527 1.06483 2.45527 0.559682 2.7592 0.243947Z"
								fill="#292621"
							/>
						</svg>
					</div>
					{
						images.map((imageItem, index) =>
							imageItem.includes("maps.googleapis.com") === true ?
							null
							:
							(index + 1) <= imageLimit &&
							<SwiperSlide
								key={index}
							>
								<div 
									className="custom-swiper-image-container"
									onClick={(e) => viewProperty === null ? null : viewProperty(e)}
								>
									<img
										src={imageItem}
										className="custom-swiper-image"
										alt={imageItem.unlocked === true ? title : "random image"}
									/>
								</div>
							</SwiperSlide>
						)
					}
				</Swiper>
			</div>
		:
		null
	)
}

export default CustomSwiper;