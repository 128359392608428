import React, { useState } from 'react';
import '../styles/OptimiseDealModal.css';
import { ActionButton, FinancialsInput, formatterLong, numberFormatter, percentage, WhiteActionButton } from '../styles/GlobalStyles';
import { Chip, Loading } from './';
import { cloudFunctionV2, optimiseOfferString, formatPrice } from '../functions';
import InputAdornment from '@mui/material/InputAdornment';

function OptimiseDealModal(props) {
    const optimiseDealLoading = props.optimiseDealLoading;
    const setOptimiseDealLoading = props.setOptimiseDealLoading;
    const oldPrice = props.price;
    const property = props.property;
    const oldDuration = props.duration;
    const monthlyCosts = props.monthlyCosts;
    const setShowOptimiseDeal = props.setShowOptimiseDeal;
    const [price, setPrice] = useState(oldPrice);
    const [duration, setDuration] = useState(oldDuration / 12);
    const [rent, setRent] = useState(property.financials.rent);
    const [totalCosts, setTotalCosts] = useState(monthlyCosts);
    const [minimumDown, setMinimumDown] = useState(5);
    const [maximumDown, setMaximumDown] = useState(30);
    const [minimumInterest, setMinimumInterest] = useState(1);
    const [maximumInterest, setMaximumInterest] = useState(7);
    const [minimumSellerEarnings, setMinimumSellerEarnings] = useState(5);
    const [targetCoCR, setTargetCoCR] = useState(7);
    const [error, setError] = useState("");
    const [selectedOption, setSelectedOption] = useState(0);
    const [optimisedTerms, setOptimisedTerms] = useState(null);

    const options = [
        {
            label: "Terms",
            disabled: false
        },
        {
            label: "Results",
            disabled: optimisedTerms === null ? true : false
        }
    ];

    const changePanel = (index) => {
        setSelectedOption(index);
    };

    const onChangePrice = (value) => {
        setPrice(value);
    };

    const onChangeRent = (value) => {
        setRent(value);
    };

    const onChangeTotalCosts = (value) => {
        setTotalCosts(value);
    };

    const onChangeDuration = (value) => {
        setDuration(value);
    };

    const onChangeMinDown = (value) => {
        setMinimumDown(value);
    };

    const onChangeMaxDown = (value) => {
        setMaximumDown(value);
    };

    const onChangeMinInterest = (value) => {
        setMinimumInterest(value);
    };

    const onChangeMaxInterest = (value) => {
        setMaximumInterest(value);
    };

    const onChangeMinimumSellerEarnings = (value) => {
        setMinimumSellerEarnings(value);
    };

    const onChangeTargetCoCR = (value) => {
        setTargetCoCR(value);
    };

    const closeModal = () => {
        setShowOptimiseDeal(false);
    };

    const optimiseDeal = async() => {
		setOptimiseDealLoading(true);
		const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
		const offerParams = {
			type: "sellerFinance",
			price: Number(price),
			rent: Number(rent),
			monthlyCosts: Number(totalCosts),
			balloonYears: Number(duration),
			ballonAdjustableFlag: 1,
			minDown: Number(minimumDown),
			maxDown: Number(maximumDown),
			minInterest: Number(minimumInterest),
			maxInterest: Number(maximumInterest),
			requiredSellerEarnings: Number(minimumSellerEarnings),
			targetCoCR: Number(targetCoCR)
		};
		const getGatewayQueryString = await optimiseOfferString(offerParams);
		const resourceId = "termOptimisation";
        const getGatewayParams = {
            type: "gateway",
            resourceId: resourceId,
            queryString: getGatewayQueryString
        };
        const getGateway = await cloudFunctionV2(gatewayURL, getGatewayParams);
        if ( getGateway.status === 200 ) {
            setError("");
            const body = getGateway.body;
            setOptimisedTerms(body);
            setSelectedOption(1);
		}
        else {
            setError(`There was an error, contact us for more details`);
        }
		setOptimiseDealLoading(false);
	};

    return (
        <div className="optimise-deal-outer-container bg-colour-light">
            <div className="optimise-deal-inner-container relative-container">
                <div className="optimise-deal-title-container margin-medium">
                    <h2 className="heading-large-semibold colour-primary margin-x-small">
                        Optimize the deal
                    </h2>
                    <span className="body-regular colour-secondary">
                        Let's make the numbers work in your favor
                    </span>
                </div>
                {
                    optimiseDealLoading === true ?
                    <Loading />
                    :
                    null
                }
                <div className="optimise-deal-chips-row margin-medium">
                    {
                        options.map((item, index) =>
                            <Chip
                                label={item.label}
                                func={changePanel}
                                index={index}
                                selected={selectedOption}
                                changeIcon={null}
                                recommended={false}
                                hover={null}
                                leave={null}
                                disabled={item.disabled}
                                key={index}
                            />
                        )
                    }
                </div>
                <div className="optimise-deal-body-container margin-x-large">
                    {
                        selectedOption === 0 ?
                        <div className="optimise-deal-grid-container">
                            <div className="optimise-deal-input-container">
                                <FinancialsInput
                                    value={formatPrice(price)}
                                    type="text"
                                    label="Price"
                                    id={`optimise-modal-price-input`}
                                    onWheel={(e) => e.target.blur()}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }}
                                    onChange={(text) => onChangePrice(text.target.value.replace(",", ""))}
                                />
                            </div>
                            <div className="optimise-deal-input-container">
                                <FinancialsInput
                                    value={formatPrice(rent)}
                                    type="text"
                                    label="Rent"
                                    id={`optimise-modal-rent-input`}
                                    onWheel={(e) => e.target.blur()}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }}
                                    onChange={(text) => onChangeRent(text.target.value.replace(",", ""))}
                                />
                            </div>
                            <div className="optimise-deal-input-container">
                                <FinancialsInput
                                    value={formatPrice(totalCosts)}
                                    type="text"
                                    label="Monthly costs (minus mortgage)"
                                    id={`optimise-modal-monthly-costs-input`}
                                    onWheel={(e) => e.target.blur()}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }}
                                    onChange={(text) => onChangeTotalCosts(text.target.value.replace(",", ""))}
                                />
                            </div>
                            <div className="optimise-deal-input-container">
                                <FinancialsInput
                                    value={duration}
                                    type="text"
                                    label="Balloon due in"
                                    id={`optimise-modal-balloon-due-input`}
                                    onWheel={(e) => e.target.blur()}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">years</InputAdornment>
                                    }}
                                    onChange={(text) => onChangeDuration(text.target.value.replace(",", ""))}
                                />
                            </div>
                            <div className="optimise-deal-input-container">
                                <FinancialsInput
                                    value={minimumDown}
                                    type="text"
                                    label="Minimum down payment"
                                    id={`optimise-modal-min-dp-input`}
                                    onWheel={(e) => e.target.blur()}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                                    }}
                                    onChange={(text) => onChangeMinDown(text.target.value.replace(",", ""))}
                                />
                            </div>
                            <div className="optimise-deal-input-container">
                                <FinancialsInput
                                    value={maximumDown}
                                    type="text"
                                    label="Maximum down payment"
                                    id={`optimise-modal-max-dp-input`}
                                    onWheel={(e) => e.target.blur()}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                                    }}
                                    onChange={(text) => onChangeMaxDown(text.target.value.replace(",", ""))}
                                />
                            </div>
                            <div className="optimise-deal-input-container">
                                <FinancialsInput
                                    value={minimumInterest}
                                    type="text"
                                    label="Minimum interest"
                                    id={`optimise-modal-min-interest-input`}
                                    onWheel={(e) => e.target.blur()}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                                    }}
                                    onChange={(text) => onChangeMinInterest(text.target.value.replace(",", ""))}
                                />
                            </div>
                            <div className="optimise-deal-input-container">
                                <FinancialsInput
                                    value={maximumInterest}
                                    type="text"
                                    label="Maximum interest"
                                    id={`optimise-modal-max-interest-input`}
                                    onWheel={(e) => e.target.blur()}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                                    }}
                                    onChange={(text) => onChangeMaxInterest(text.target.value.replace(",", ""))}
                                />
                            </div>
                            <div className="optimise-deal-input-container">
                                <FinancialsInput
                                    value={minimumSellerEarnings}
                                    type="text"
                                    label="Minimum seller earnings"
                                    id={`optimise-modal-min-seller-earnings-input`}
                                    onWheel={(e) => e.target.blur()}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                                    }}
                                    onChange={(text) => onChangeMinimumSellerEarnings(text.target.value.replace(",", ""))}
                                />
                            </div>
                            <div className="optimise-deal-input-container">
                                <FinancialsInput
                                    value={targetCoCR}
                                    type="text"
                                    label="Target CoCR"
                                    id={`optimise-modal-target-cocr-input`}
                                    onWheel={(e) => e.target.blur()}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                                    }}
                                    onChange={(text) => onChangeTargetCoCR(text.target.value.replace(",", ""))}
                                />
                            </div>
                        </div>
                        :
                        <div className="optimise-deal-results-container">
                            <div className="optimise-deal-result-element margin-medium">
                                <span className="body-regular colour-primary">
                                    Down payment - <span className="body-semibold">{percentage.format(optimisedTerms.downPaymentPrct / 100)}</span>
                                </span>
                            </div>
                            <div className="optimise-deal-result-element margin-medium">
                                <span className="body-regular colour-primary">
                                    Interest rate - <span className="body-semibold">{percentage.format(optimisedTerms.interestRate / 100)}</span>
                                </span>
                            </div>
                            <div className="optimise-deal-result-element margin-medium">
                                <span className="body-regular colour-primary">
                                    Offer price - <span className="body-semibold">{formatterLong.format(optimisedTerms.offerPrice).replace(".00", "")}</span>
                                </span>
                            </div>
                            <div className="optimise-deal-result-element margin-x-large">
                                <span className="body-regular colour-primary">
                                    Balloon due in - <span className="body-semibold">{numberFormatter.format(optimisedTerms.balloonYears)} years</span>
                                </span>
                            </div>
                            <div className="optimise-deal-results-row">
                                <div className="optimise-deal-result-element subtext-margin-right-x-large">
                                    <span className="label-semibold-caps colour-primary block-text margin-x-x-small">
                                        CASH-FLOW
                                    </span>
                                    <span className={"heading-large-semibold block-text " + (optimisedTerms.cashOnCashReturn > 0.05 ? "colour-green" : optimisedTerms.cashOnCashReturn < 0 ? "colour-error" : "colour-medium")}>
                                        {formatterLong.format(optimisedTerms.monthlyCashFlow).replace(".00", "")}
                                    </span>
                                </div>
                                <div className="optimise-deal-result-element">
                                    <span className="label-semibold-caps colour-primary block-text margin-x-x-small">
                                        CASH ON CASH
                                    </span>
                                    <span className={"heading-large-semibold block-text " + (optimisedTerms.cashOnCashReturn > 0.05 ? "colour-green" : optimisedTerms.cashOnCashReturn < 0 ? "colour-error" : "colour-medium")}>
                                        {percentage.format(optimisedTerms.cashOnCashReturn)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="optimise-deal-button-container">
                    <div className="optimise-deal-close-button-container subtext-margin-right-x-large">
                        <WhiteActionButton
                            onClick={() => closeModal()}
                        >
                            Close modal
                        </WhiteActionButton>
                    </div>
                    {
                        selectedOption === 0 ?
                        <div className="optimise-deal-action-button-container">
                            <ActionButton
                                disabled={optimiseDealLoading}
                                onClick={() => optimiseDeal()}
                            >
                                Optimize Deal
                            </ActionButton>
                        </div>
                        :
                        null
                    }
                </div>
                {
                    error !== "" ?
                    <div className="optimise-deal-error-container">
                        <span className="label-regular colour-error block-text text-align-center">
                            {error}
                        </span>
                    </div>
                    :
                    null
                }
            </div>
        </div>
    )
};

export default OptimiseDealModal;