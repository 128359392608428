import React from 'react';
import '../styles/BuyBoxMoreContainer.css';
import { DownloadSelector } from './';
import { checkUserId, cloudFunctionV2, downloadExcel, getAPIGatewayListings, getDocument, recordEvent } from '../functions';
import { defaultUserData } from '../styles/GlobalStyles';

function BuyBoxMoreContainer(props) {
    const buyBoxMore = props.buyBoxMore;
    const setBuyBoxMore = props.setBuyBoxMore;
    const id = props.id;
    const editBuyBox = props.editBuyBox;
    const deleteBuyBox = props.deleteBuyBox;
    const item = props.item;
    const setLoading = props.setLoading;
    const setDeleteParam = props.setDeleteParam;

    const clickDownloadCSV = async() => {
        setLoading(true);
        setBuyBoxMore(null);

        const colRef = "Creative Financial Preferences";
        const user = await checkUserId();
        if ( user.status === 200 ) {
            const userId = user.userId;
            const queryData = await getDocument(colRef, userId);
            let userSettings = defaultUserData.settings;
            if ( queryData.status === 200 ) {
                userSettings = queryData.data.data;
            }

            const filters = {
                adu: item.onlyADU,
                bathrooms: item.bathrooms,
                bedrooms: item.bedrooms,
                budgetRange: item.budgetRange,
                constructionYears: item.constructionYears,
                squareFootage: item.squareFootage,
                units: item.units,
                propTypeMislabelled: false,
                sellerFinancing: false,
                priceReduction: false,
                propertyTypes: item.propertyTypes,
                extraBedroom: item.onlyExtraBedroom,
                hideFixerUppers: item.noFixerUpper,
                onlyFixerUppers: false,
                priceReductionIndex: 0,
                neighborhoodGradeMin: item.neighbourhoodGradeMin !== undefined ? item.neighbourhoodGradeMin : 1,
				neighborhoodGradeMax: item.neighbourhoodGradeMax !== undefined ? item.neighbourhoodGradeMax : 8,
                fsbo: 0
            };

            const getGatewayQueryString = await getAPIGatewayListings(userSettings, false, item.cityId, filters);
			const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
			const gatewayData = {
				type: "gateway",
				resourceId: "listings",
				queryString: getGatewayQueryString
			};
			const getListings = await cloudFunctionV2(gatewayURL, gatewayData);
            if ( getListings.status === 200 ) {
                const properties = getListings.body.props;
                const cityTitle = `${item.city} - ${item.state}`;
                const downPayment = userSettings.initialCosts[0].value !== "" ? userSettings.initialCosts[0].value : 20;
                const strategy = item.strategy === undefined || item.strategy === "" ? 0 : item.strategy === "turnkey" ? 0 : item.strategy === "househacking" ? 1 : item.strategy === "brrrr" ? 2 : item.strategy === "fix-flip" ? 3 : 0;
                downloadExcel(properties, cityTitle, item.cityId, downPayment, false, strategy);
                recordEvent("Buy Box - Download CSV", {
                    cityId: item.cityId
                });
            }
            else if ( getListings.status === 404 ) {
				const customS3Location = getListings.body.s3_location;
				const params = {
					type: "s3",
					s3Data: {
						key: customS3Location,
						bucketName: "residentialpropertydata"
					}
				};
				const getS3Query = await cloudFunctionV2(gatewayURL, params);
				if ( getS3Query.status === 200 ) {
					const getS3Body = getS3Query.body;
                    const properties = getS3Body.props;
                    const cityTitle = `${item.city} - ${item.state}`;
                    const downPayment = userSettings.initialCosts[0].value !== "" ? userSettings.initialCosts[0].value : 20;
                    const strategy = item.strategy === undefined || item.strategy === "" ? 0 : item.strategy === "turnkey" ? 0 : item.strategy === "househacking" ? 1 : item.strategy === "brrrr" ? 2 : item.strategy === "fix-flip" ? 3 : 0;
                    downloadExcel(properties, cityTitle, item.cityId, downPayment, false, strategy);
                    recordEvent("Buy Box - Download CSV", {
                        cityId: item.cityId
                    });
				}
			}
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    };

    const openSelector = () => {
        if ( buyBoxMore === false ) {
            setBuyBoxMore(id);
        }
    };

    return (
        <div 
            className="buy-box-more-container text-button"
            onClick={() => openSelector()}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="18" viewBox="0 0 30 18" fill="none">
                <path d="M6.3335 9C6.3335 10.6569 4.99035 12 3.3335 12C1.67664 12 0.333496 10.6569 0.333496 9C0.333496 7.34315 1.67664 6 3.3335 6C4.99035 6 6.3335 7.34315 6.3335 9Z" fill="#2F4858"/>
                <path d="M18.0002 9C18.0002 10.6569 16.657 12 15.0002 12C13.3433 12 12.0002 10.6569 12.0002 9C12.0002 7.34315 13.3433 6 15.0002 6C16.657 6 18.0002 7.34315 18.0002 9Z" fill="#2F4858"/>
                <path d="M26.6668 12C28.3237 12 29.6668 10.6569 29.6668 9C29.6668 7.34315 28.3237 6 26.6668 6C25.01 6 23.6668 7.34315 23.6668 9C23.6668 10.6569 25.01 12 26.6668 12Z" fill="#2F4858"/>
            </svg>
            
                {
                    buyBoxMore === true ?
                    <DownloadSelector
                        downloadCSV={clickDownloadCSV}
                        clickDownloadPDF={null}
                        buyBox={true}
                        editBuyBox={editBuyBox}
                        deleteBuyBox={deleteBuyBox}
                        item={item}
                        setDeleteParam={setDeleteParam}
                    />
                    :
                    null
                }
        </div>
    )
};

export default BuyBoxMoreContainer;