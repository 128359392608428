import React from 'react';
import '../styles/FavouritesGrid.css';
import { ListingsCard } from '../components';
import { defaultUserData } from '../styles/GlobalStyles';

function FavouritesGrid(props) {
    const properties = props.properties;
    const propertySelected = props.propertySelected;
    const downPayment = defaultUserData.settings.downPayment;

    return (
        <div className="favourites-grid-outer-container">
            {
                properties.map((item, index) =>
                    <div 
                        className={"favourites-grid-element " + (propertySelected(item.zpid) === true ? "property-grid-element-selected" : "")}
                        key={index}
                    >
                        {/* <div 
                            className="favourites-grid-element-compare-container"
                            onClick={() => toggleCheckbox(item.zpid)}
                        >
                            <div className="favourites-table-checkbox-container">
                                {
                                    propertySelected(item.zpid) === true ?
                                    <div className="favourites-table-checkbox">
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <span className="body-regular colour-secondary">
                                Compare
                            </span>
                        </div> */}
                        <ListingsCard
                            property={item}
                            key={index}
                            downPayment={downPayment}
                            hiddenProperties={[]}
                            setHiddenProperties={null}
                            userId={""}
                            cityId={item.cityId}
                            type="listing"
                            offers={null}
                            setOffers={null}
                            setHideModal={null}
                            setHideProperty={null}
                            strategy={"sellerFinance"}
                            abstract={true}
                            setHoverCard={null}
                            propertyRefs={[]}
                            index={index}
                        />
                    </div>
                )
            }
        </div>
    )
}

export default FavouritesGrid;