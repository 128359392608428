import { useEffect } from 'react';
import { getAPIGatewayDetail, makeId, cloudFunctionV2, checkOnAuthStateChanged, getUserDocuments, getAPIGatewayDetailAddress, getDocument } from '../functions';
import { defaultUserData } from '../styles/GlobalStyles';
import moment from 'moment';

function PropertyPageEffect(props) {
    const cityId = props.cityId;
	const setCityId = props.setCityId;
    const setProperty = props.setProperty;
	const setUserDetails = props.setUserDetails;
    const setMobileBar = props.setMobileBar;
    const id = props.id;
	const queryMade = props.queryMade;
    const setQueryMade = props.setQueryMade;
    const setLoading = props.setLoading;
	const setNoProperty = props.setNoProperty;
	const setSpreadsheetProperty = props.setSpreadsheetProperty;
	const setNeighbourhoodData = props.setNeighbourhoodData;
	const setNeighbourhoodBounds = props.setNeighbourhoodBounds;
	const setTracts = props.setTracts;
	const setArvComps = props.setArvComps;
	const setInnerWidth = props.setInnerWidth;
	const setUserId = props.setUserId;
	const setGeneratedOffers = props.setGeneratedOffers;
	const setPrice = props.setPrice;
	const strategyPanel = props.strategyPanel;
	const setOwnerEquity = props.setOwnerEquity;
	const setDownPayment = props.setDownPayment;
	const setInterestRate = props.setInterestRate;
	const setDuration = props.setDuration;
	const propertySearch = props.propertySearch;
	const setLoadingStep = props.setLoadingStep;
	const setFavourites = props.setFavourites;
	const setBlurred = props.setBlurred;
	const setLeaseOptionValue = props.setLeaseOptionValue;
	const setRemainingMortgage = props.setRemainingMortgage;
	const setSellersMortgage = props.setSellersMortgage;
	const setTotalGeneratedOffers = props.setTotalGeneratedOffers;
	const setPaidUser = props.setPaidUser;
	const setSubscriptions = props.setSubscriptions;

    useEffect(() => {

		const handleWindowResize = () => {
			if ( window.innerWidth > 1000 ) {
				document.body.removeAttribute("style");
				setMobileBar(false);
			}
			setInnerWidth(window.innerWidth);
		};
	  
		window.addEventListener('resize', handleWindowResize);
			
		const queryUser = async() => {
			const user = await checkOnAuthStateChanged();
			if ( user.status === 200 ) {
				setUserId(user.userId);

				const colRef = ["Users", "Creative Listings", "Creative Financial Preferences", "Creative Favourites", "Subscriptions"];
				const docRef = user.userId;
				const queryData = await getUserDocuments(colRef, docRef);
				if ( queryData[0].status === 200 ) {
					setUserDetails(queryData[0].data);
					checkBlurred(queryData[0].data);
				}

				if ( queryData[1].status === 200 ) {
					const creativeData = queryData[1].data;
					const findIndex = creativeData.findIndex(e => e.cityId === cityId);
                    if ( findIndex !== -1 ) {
                        const relevantObject = creativeData[findIndex];
                        const savedOffers = relevantObject.offers;
                        setGeneratedOffers(savedOffers)
					}

					const totalOffersArray = [];
					for (let index = 0; index < creativeData.length; index++) {
						const element = creativeData[index];
						const offersObject = element.offers;
						for (let ii = 0; ii < offersObject.length; ii++) {
							totalOffersArray.push(true);
						}
					}
					setTotalGeneratedOffers(totalOffersArray.length);
				}

				if ( queryData[2].status === 200 ) {
					const financialData = queryData[2].data.sellerFinance;
					const downPayment = Number(financialData.initialCosts[0].value);
					const interestRateObject = financialData.initialCosts[1];
					if ( interestRateObject.default === false ) {
						const interestRate = Number(interestRateObject.value);
						setInterestRate(interestRate);
					}
					const durationObject = financialData.initialCosts[2];
					if ( durationObject.default === false ) {
						const duration = Number(durationObject.value);
						setDuration(duration * 12);
					}
					setDownPayment(downPayment);
				}

				if ( queryData[3].status === 200 ) {
					const favourites = queryData[3].data;
					setFavourites(favourites);
				}
			}
			changeLoadingStep(2);
			queryGatewayProperties();
		};

		const queryGatewayProperties = async() => {
			document.title = "Coffee Clozers | Property";

			const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
			const userSettings = defaultUserData.settings;

			const address = propertySearch === true ? decodeURIComponent(id) : "";
			const getGatewayQueryString = propertySearch === true ? await getAPIGatewayDetailAddress(userSettings, address) : await getAPIGatewayDetail(cityId, id, userSettings);
			const resourceId = "detail";
			const getGatewayParams = {
				type: "gateway",
				resourceId: resourceId,
				queryString: getGatewayQueryString
			};
			const getGateway = await cloudFunctionV2(gatewayURL, getGatewayParams);
			changeLoadingStep(3);
			if ( getGateway.status === 200 ) {
				const getGatewayBody = getGateway.body;
				const propertyObject = getGatewayBody.prop;

				if ( propertyObject === null ) {
					setQueryMade(true);
					setLoading(false);
					setNoProperty(true);
				}
				else {
					setNoProperty(false);
					if ( setCityId !== null && propertyObject.cityId !== undefined && propertyObject.cityId !== null ) {
						setCityId(propertyObject.cityId);
					}
	
					propertyObject.datePostedTS = new Date(propertyObject.datePosted);
					propertyObject.offMarket = propertyObject.motivatedSeller === undefined ? false : true;
	
					const a = moment(new Date());
					const b = moment(propertyObject.datePostedTS);
					const dateDifference = a.diff(b, 'days');
					propertyObject.dateDifference = dateDifference;
	
					if ( propertyObject.uniqueUnitPropertyDetails !== undefined && propertyObject.uniqueUnitPropertyDetails !== null && propertyObject.uniqueUnitPropertyDetails.length > 1 ) {
						for (let index = 0; index < propertyObject.uniqueUnitPropertyDetails.length; index++) {
							propertyObject.uniqueUnitPropertyDetails[index].value = propertyObject.uniqueUnitPropertyDetails[index].rentcast_rent;
						}
					}
	
					if ( propertyObject.rentComps !== null && propertyObject.rentComps !== undefined ) {
						propertyObject.rentComps.sort((a,b) => (a.prediction_label < b.prediction_label) ? 1 : ((b.prediction_label < a.prediction_label) ? -1 : 0));
					}
	
					if ( propertyObject.units !== undefined && propertyObject.units !== null && propertyObject.units > 4 ) {
						propertyObject.units = 4;
					}
	
					if ( propertyObject.unitPropertyComps !== null && propertyObject.unitPropertyComps !== undefined ) {
						if ( propertyObject.unitPropertyComps.length === 1 ) {
							propertyObject.unitPropertyComps[0].sort((a,b) => (a.correlation < b.correlation) ? 1 : ((b.correlation < a.correlation) ? -1 : 0));
						}
						else if ( propertyObject.unitPropertyComps.length > 1 ) {
							propertyObject.unitPropertyComps[0].sort((a,b) => (a.correlation < b.correlation) ? 1 : ((b.correlation < a.correlation) ? -1 : 0));
							propertyObject.unitPropertyComps[1].sort((a,b) => (a.correlation < b.correlation) ? 1 : ((b.correlation < a.correlation) ? -1 : 0));
						}
					}
	
					if ( propertyObject.imgSrc === null || propertyObject.imgSrc === undefined || propertyObject.imgSrc.includes("maps") ) {
						propertyObject.offMarketImage = true;
					}
					else {
						propertyObject.offMarketImage = false;
					}
	
					if ( propertyObject.arvComps === true && propertyObject.arvCompsSubjProp !== null ) {
						propertyObject.arvCompsSubjProp = propertyObject.arvCompsSubjProp[0];
					}

					const newArvComps = [];
					if ( propertyObject.arvCompsSimilarProp !== undefined && propertyObject.arvCompsSimilarProp !== null ) {
						for (let index = 0; index < propertyObject.arvCompsSimilarProp.length; index++) {
							const element = propertyObject.arvCompsSimilarProp[index];
							if ( element.zpid === undefined || element.zpid === null ) {
								const madeUpZPID = await makeId(10)
								element.zpid = `MDP-${madeUpZPID}`;
							};
							newArvComps.push(element);
						}
					}
					setArvComps(newArvComps);

					if ( propertyObject.propertyTypeDimension === "Multi Family" && propertyObject.units !== undefined && propertyObject.units === null ) {
						propertyObject.units = 2;
						propertyObject.unitsGuess = true;
					}
	
					document.title = `Coffee Clozers | ${propertyObject.address.streetAddress}, ${propertyObject.address.city}, ${propertyObject.address.state} ${propertyObject.address.zipcode}`;
					const getCountyId = propertyObject.countyId !== undefined && propertyObject.countyId !== null ? propertyObject.countyId : null;
					getNeighbourhoodData(getCountyId, propertyObject);
					setProperty(propertyObject);

					if ( propertyObject.ownerAttributes !== undefined && propertyObject.ownerAttributes !== null ) {
						const remainingMortgage = propertyObject.ownerAttributes.estMortgageBalance;
						const equity = (propertyObject.price - remainingMortgage) / propertyObject.price;
						setOwnerEquity(equity);
					}

					setPrice(propertyObject.price);
					setLeaseOptionValue(propertyObject.price * 1.05);
					setRemainingMortgage(Number(propertyObject.ownerAttributes.estMortgageBalance));
					setSellersMortgage(Number(propertyObject.ownerAttributes.estMortgagePayment));

					setSpreadsheetProperty(propertyObject);
					setQueryMade(true);
					setLoading(false);
				}
			}
			else if ( getGateway.status === 405 ) {
				setLoading(false);
				setNoProperty(true);
			}
		}

		const checkBlurred = async(userData) => {
			const today = new Date();
			const todaySeconds = today.getTime() / 1000;

			if ( cityId === "CTY45045" ) {
				setPaidUser(true);
			}

			const freeTrial = userData.freeTrial;
			const freeTrialEnd = freeTrial === undefined || freeTrial.length === 0 ? null : freeTrial[0].endDate.seconds;
			const activeFreeTrial = freeTrialEnd !== null && todaySeconds < freeTrialEnd ? true : false;

			const colRef = "Subscriptions";
			const user = await checkOnAuthStateChanged();
			if ( user.status === 200 ) {
				const userId = user.userId;

				const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
				const isAdmin = adminIds.includes(userId);
				if ( isAdmin === true ) {
					setPaidUser(true);
				}

				const queryData = await getDocument(colRef, userId)
				if ( queryData.status === 200 ) {
					const data = queryData.data.data;
					setSubscriptions(data);
					const subscriptionIndex = data.findIndex(e => e.endDate.seconds > todaySeconds && e.creative === true);
					if ( subscriptionIndex === -1 && activeFreeTrial === false && isAdmin === false ) {
						setBlurred(true);
					}
					else if ( subscriptionIndex !== -1 ) {
						setPaidUser(true);
					}
				}
				else if ( activeFreeTrial === true || isAdmin === true ) {
					setSubscriptions([]);
				}
				else {
					setBlurred(true);
				}
			}
			else {
				setBlurred(true);
				setSubscriptions([]);
			}
			return;
		};

		const getNeighbourhoodData = async(getCountyId, propertyObject) => {
			const checkedCityId = getCountyId === null ? cityId : getCountyId;
			if ( checkedCityId === null ) {
				return;
			}

			const neighbourhoodPath = checkedCityId.includes("CTY") || getCountyId !== null ? `geo_output/map_neighborhood/county/${checkedCityId.replace("CTY", "")}.json` : `output/map_neighborhood/${checkedCityId}.json`
			const params = {
				s3Data: {
					key: neighbourhoodPath,
					bucketName: "residentialpropertydata"
				},
				type: "s3"
			};
			const awsURL = process.env.REACT_APP_AWS_QUERY_URL;
			const queryData = await cloudFunctionV2(awsURL, params);
			if ( queryData.status === 200 ) {
				const body = queryData.body;
				setNeighbourhoodData(body);

				const newBounds = [];
				const getTracts = [];
				for (let index = 0; index < body.length; index++) {
					const element = body[index];
					getTracts.push(element.region.tractId);
					const tractPolygon = element.tractPolygon.flat(1);
					newBounds.push(tractPolygon);
				};
				setTracts(getTracts);
				setNeighbourhoodBounds(newBounds);
			}
			else if ( propertyObject.awsMapLink !== undefined && propertyObject.awsMapLink !== null ) {
				const newParams = {
					s3Data: {
						key: propertyObject.awsMapLink,
						bucketName: "residentialpropertydata"
					},
					type: "s3"
				};
				const awsURL = process.env.REACT_APP_AWS_QUERY_URL;
				const queryAWSMap = await cloudFunctionV2(awsURL, newParams);
				if ( queryAWSMap.status === 200 ) {
					const body = queryAWSMap.body;
					setNeighbourhoodData(body);
	
					const newBounds = [];
					const getTracts = [];
					for (let index = 0; index < body.length; index++) {
						const element = body[index];
						getTracts.push(element.region.tractId);
						const tractPolygon = element.tractPolygon.flat(1);
						newBounds.push(tractPolygon);
					};
					setTracts(getTracts);
					setNeighbourhoodBounds(newBounds);
				}
			}
		}

		const changeLoadingStep = (val) => {
			if ( setLoadingStep !== null ) {
				setLoadingStep(val);
			}
		};
		
		if ( queryMade === false ) {
			changeLoadingStep(1);
			queryUser();
		}
		
	}, [
		cityId,
		id,
		queryMade,
		setCityId,
		setLoading,
		setNoProperty,
		setArvComps,
		setSpreadsheetProperty,
		setMobileBar,
		setNeighbourhoodData,
		setProperty,
		setQueryMade,
		setNeighbourhoodBounds,
		setInnerWidth,
		setTracts,
		setUserId,
		setGeneratedOffers,
		setUserDetails,
		setPrice,
		strategyPanel,
		setOwnerEquity,
		setDownPayment,
		setInterestRate,
		setDuration,
		propertySearch,
		setLoadingStep,
		setFavourites,
		setBlurred,
		setLeaseOptionValue,
		setRemainingMortgage,
		setSellersMortgage,
		setTotalGeneratedOffers,
		setPaidUser,
		setSubscriptions
	]);
}

export default PropertyPageEffect;