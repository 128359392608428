import React from 'react';
import { ActionButton } from '../styles/GlobalStyles';
import { CancelSubscriptionModal, CreditCard, DeleteCardModal, SubscriptionTable, MobileSubscriptionGrid, Loading } from '../components';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { recordEvent } from '../functions';

function PaymentSettings(props) {
    const userData = props.userData;
    const cardLoading = props.cardLoading;
    const setupIntent = props.setupIntent;
    const disabled = props.disabled;
    const deleteCard = props.deleteCard;
    const subscriptions = props.subscriptions;
    const expiredSubscriptions = props.expiredSubscriptions;
    const deleteModal = props.deleteModal;
    const setDeleteModal = props.setDeleteModal;
    const autoBilling = props.autoBilling;
    const cancelSubscription = props.cancelSubscription;
    const cancelSubscriptionModal = props.cancelSubscriptionModal;
    const setCancelSubscriptionModal = props.setCancelSubscriptionModal;
    const loading = props.loading;
    const setSubscriptionId = props.setSubscriptionId;
    const freeTrialObject = props.freeTrialObject;
    const navigate = useNavigate();

    const showDeleteModal = () => {
        setDeleteModal(true);
    }

    const activateCancelSubscriptionModal = (item) => {
        setCancelSubscriptionModal(true);
        setSubscriptionId(item.id)
    }

    const checkMonths = (item) => {
        const startDate = moment.unix(item.startDate.seconds);
        const endDate = moment.unix(item.endDate.seconds);
        const months = endDate.diff(startDate, 'months');
        return months;
    };

    const upgradeSubscription = (item) => {
        navigate("/pricing", {
            state: {
                chosenCity: {
                    city: item.city,
                    state: item.state
                }
            }
        });
        recordEvent("Upgrade Subscription", {
            oldPath: "/profile",
            newPath: "/pricing",
            item: item
        });
    };

    const getHelp = () => {
        window.location.href = "mailto:info@coffeeclozers.com";
    };

    return (
        <div className="profile-payments-subscriptions-container">
            <div className="profile-account-information-container margin-large">
                <div className="profile-inner-width-container">
                    <div className="profile-subheading-container margin-large">
                        <h2 className="body-semibold colour-quaternary">
                            Payment methods
                        </h2>
                    </div>
                    {
                        deleteModal === true ?
                        <DeleteCardModal
                            creditCard={userData.creditCard}
                            deleteFunction={deleteCard}
                            setDeleteModal={setDeleteModal}
                        />
                        :
                        cancelSubscriptionModal === true ?
                        <CancelSubscriptionModal
                            setCancelSubscriptionModal={setCancelSubscriptionModal}
                            cancelSubscription={cancelSubscription}
                            loading={loading}
                        />
                        :
                        null
                    }
                    {
                        userData.creditCard !== undefined && cardLoading === false ?
                        <div className="profile-payment-methods-container">
                            <CreditCard
                                creditCard={userData.creditCard}
                                deleteCard={showDeleteModal}
                                checkout={false}
                            />
                        </div>
                        :
                        cardLoading === true ?
                        <Loading />
                        :
                        <div className="profile-payment-methods-container">
                            <div className="profile-payment-method-text-container">
                                <span className="body-regular colour-secondary">
                                    No saved payment methods. 
                                    Add a payment method for faster checkout.
                                </span>
                                {
                                    autoBilling === true ?
                                    <span className="body-regular colour-error block-text margin-top-small">
                                        Please add a credit card back in to 
                                        continue your subscription's auto-billing
                                    </span>
                                    :
                                    null
                                }
                            </div>
                            <div className="profile-add-payment-method-button-container">
                                <ActionButton
                                    disabled={disabled}
                                    onClick={() => setupIntent()}
                                >
                                    Add a payment method
                                </ActionButton>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="profile-payment-settings-outer-container">
                <div className="">
                    <div className="profile-subheading-container margin-large">
                        <h2 className="body-semibold colour-quaternary">
                            Subscriptions
                        </h2>
                    </div>
                    <SubscriptionTable
                        subscriptions={subscriptions}
                        expiredSubscriptions={expiredSubscriptions}
                        checkMonths={checkMonths}
                        cancelSubscription={activateCancelSubscriptionModal}
                        getHelp={getHelp}
                        upgradeSubscription={upgradeSubscription}
                        freeTrialObject={freeTrialObject}
                    />
                    <MobileSubscriptionGrid
                        subscriptions={subscriptions}
                        expiredSubscriptions={expiredSubscriptions}
                        checkMonths={checkMonths}
                        cancelSubscription={activateCancelSubscriptionModal}
                        getHelp={getHelp}
                        upgradeSubscription={upgradeSubscription}
                        freeTrialObject={freeTrialObject}
                    />
                </div>
            </div>
        </div>
    )
}

export default PaymentSettings;