import React from 'react';
import './Buttons.css';

function AccentButton(props) {
    const text = props.text;
    const cta = props.cta;
    const disabled = props.disabled;
    const size = props.size;
    const sizeClass = size === "medium" ? "size-medium" : "size-small";
    const textSize = size === "medium" ? "body-semibold" : "label-semibold";
    const leftIcon = props.leftIcon;
    const leftIconClass = props.leftIconClass;
    const leftIconAlt = props.leftIconAlt;

    return (
        <div 
            className={"accent-button button-radius-normal cursor-pointer " + (disabled === true ? "button-disabled " : "") + (sizeClass)}
            onClick={disabled === true ? null : cta}
        >
            <div className={`${sizeClass}-padding`}>
                {
                    leftIcon !== null ?
                    <img
                        src={leftIcon}
                        className={leftIconClass}
                        alt={leftIconAlt}
                    />
                    :
                    null
                }
                <span className={"colour-white block-text text-align-center " + (textSize)}>
                    {text}
                </span>
            </div>
        </div>
    )
};

export default AccentButton;