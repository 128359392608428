import React, { useEffect } from 'react';
import '../styles/PricingRedirect.css';
import { Header, Footer } from '../components';

function PricingRedirect() {

    useEffect(() => {
        document.title = "Pricing";
        window.open("https://app.coffeeclozers.com/pricing", "_self");
    }, []);

    return (
        <div className="">
            <Header />
            <div className="pricing-redirect-inner-container">
                <h1 className="heading-large-semibold colour-primary text-align-center">
                    Redirecting...
                </h1>
            </div>
            <Footer />
        </div>
    )
};

export default PricingRedirect;