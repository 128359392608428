import React, { useEffect, useState } from 'react';
import '../styles/Welcome.css';
import { Header, Footer, RecommendedTab } from '../components';
import { checkOnAuthStateChanged, getDocument, recordEvent } from '../functions';
import { ExploreListings, SparkleLight } from '../assets';
import { AccentButton, SecondaryButton } from '../buttons';
import { useNavigate } from 'react-router-dom';

function Welcome() {
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {

        const fetchUserData = async() => {
            document.title = "Welcome | Coffee Clozers";
            const colRef = "Users";
            const user = await checkOnAuthStateChanged()
            if ( user.status === 200 ) {
                const userId = user.userId;
                const queryData = await getDocument(colRef, userId);
                if ( queryData.status === 200 ) {
                    setUserData(queryData.data.data);
                }
            }
        };
        fetchUserData();

    }, []);

    const options = [
        {
            image: ExploreListings,
            title: "Explore listings",
            description: "I prefer to start exploring listings on my own.",
            button: "",
            buttonText: "Explore on my own"
        },
        {
            image: SparkleLight,
            title: "Get a free demo",
            description: "I’d like to get an quick tutorial with a human",
            button: "accent",
            buttonText: "Book demo"
        }
    ];

    const openCalendly = () => {
        window.open("https://calendly.com/liam-maher/coffee-clozers-creative-finance?back=1", "_blank");
        recordEvent("Book Demo Opened", {
            page: "welcome"
        });
        navigate("/demo-confirmation");
    };

    const openSampleMarket = () => {
        navigate("/sample-market");
    };

    return (
        <div className="welcome-outer-container bg-colour-white">
            <Header
                subscriptions={null}
                users={userData}
                queries={[false, true]}
                mobileNav={false}
            />
            <div className="welcome-inner-container">
                <div className="welcome-title-container margin-large">
                    <h1 className="heading-large-semibold colour-primary text-align-center margin-x-small">
                        Welcome to Creative Clozers!
                    </h1>
                    <span className="body-regular colour-secondary text-align-center block-text">
                        Choose how you want to get started! We recommend you book a demo, 
                        then start exploring in the meantime.
                    </span>
                </div>
                <div className="welcome-body-container margin-x-large">
                    {
                        options.map((item, index) =>
                            <div 
                                className="welcome-element-container relative-container cursor-pointer"
                                key={index}
                            >
                                {
                                    index === 1 ?
                                    <RecommendedTab />
                                    :
                                    null
                                }
                                <div className="welcome-element-inner-container">
                                    <img
                                        src={item.image}
                                        className="welcome-element-image margin-x-large"
                                        alt={item.title}
                                    />
                                    <div className="welcome-element-text-container margin-x-large">
                                        <h2 className="heading-small-semibold colour-primary margin-x-small text-align-left">
                                            {item.title}
                                        </h2>
                                        <span className="body-regular colour-secondary block-text">
                                            {item.description}
                                        </span>
                                    </div>
                                    {
                                        item.button === "accent" ?
                                        <AccentButton
                                            text={item.buttonText}
                                            cta={openCalendly}
                                            disabled={false}
                                            size="medium"
                                            leftIcon={null}
                                            rightIcon={null}
                                        >
                                        </AccentButton>
                                        :
                                        <SecondaryButton
                                            text={item.buttonText}
                                            cta={openSampleMarket}
                                            size="medium"
                                            disabled={false}
                                        >
                                        </SecondaryButton>
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default Welcome;