import React, { useEffect, useRef, useState } from 'react';
import '../styles/CompsAnalysis.css';
import mapStyles from '../styles/mapStyles.json';
import { GoogleMap, useLoadScript, Polygon, OverlayView, OverlayViewF } from '@react-google-maps/api';
import { AddComp, ApplyEstimate, CompFilters, CompsFilter, CustomSwiper, MapLegend, Modal, NewSwitch } from './';
import { formatterLong, numberFormatter, WhiteActionButton, neighbourhoodColours, mapColours, colour, percentage, RedActionButton, GreenActionButton, neighbourhoodDataOptions, nonDisclosureStates } from '../styles/GlobalStyles';
import { SQFootage, Bedrooms, Bathrooms, DollarIcon, CoffeeIcon, HourGlass, PriceSqFoot, Construction, RedirectArrow, OffMarketHouse } from '../assets';
import moment from 'moment';
import { recordEvent, getCompsImages, cloudFunctionV2, saveCompData } from '../functions';
import { useNavigate } from 'react-router-dom';

function RentCompsAnalysis(props) {
    const property = props.property;
    const neighbourhoodBounds = props.neighbourhoodBounds;
    const neighbourhoodData = props.neighbourhoodData;
    const hasUndefinedNeighborhoodGrade = neighbourhoodData.some(e => e.neighborhoodGrade === undefined);
    const dataLocation = neighbourhoodDataOptions[1].location;
    const dataSubLocation = neighbourhoodDataOptions[1].subLocation;
    const closeCompsAnalysis = props.closeCompsAnalysis;
    const saveCompsAnalysis = props.saveCompsAnalysis;
    const signUpModal = props.signUpModal;
    const setSignUpModal = props.setSignUpModal;
    const userId = props.userId;
    const savedData = props.savedData;
    const setSavedData = props.setSavedData;
    const setEditedProperty = props.setEditedProperty;
    const strategyPanel = props.strategyPanel;
    const switchCompsTool = props.switchCompsTool;
    const propertyState = property.address.state;
    const isNonDisclosure = nonDisclosureStates.includes(propertyState);
    const [comps, setComps] = useState(props.comps);
    const [filteredComps, setFilteredComps] = useState(comps);
    const [centre] = useState({ lat: property.latitude, lng: property.longitude });
    const [renderOverlay, setRenderOverlay] = useState(false);
    const [selectedComp, setSelectedComp] = useState(null);
    const [maxDistance, setMaxDistance] = useState(savedData === null ? 2 : savedData.maxDistance);
    const [maxSoldDate, setMaxSoldDate] = useState(savedData === null ? 6 : savedData.maxSoldDate);
    const [addCompModal, setAddCompModal] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [bedrooms, setBedrooms] = useState(savedData === null ? 1 : savedData.bedrooms);
    const [bathrooms, setBathrooms] = useState(savedData === null ? 1 : savedData.bathrooms);
    const [squareFootage, setSquareFootage] = useState(savedData === null ? [0, 999999] : savedData.squareFootage);
    const [priceRange, setPriceRange] = useState(savedData === null ? ["", ""] : savedData.priceRange);
    const [listingStatus, setListingStatus] = useState(savedData === null ? 2 : savedData.listingStatus);
    const [numberOfFilters, setNumberOfFilters] = useState(savedData === null ? 0 : savedData.numberOfFilters);
    const [excludedComps, setExcludedComps] = useState(savedData === null ? [] : savedData.excludedComps);
    const [manualComps, setManualComps] = useState(savedData === null ? [] : savedData.manualComps);
    const [marketStats, setMarketStats] = useState([]);
    const [viewedProperties, setViewedProperties] = useState([]);
    const [hoverMapLegend, setHoverMapLegend] = useState(null);
    const [firstPricingOption, setFirstPricingOption] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const manualCompIndex = selectedComp === null ? -1 : manualComps.findIndex(e => e.id === selectedComp);
    const chosenCompIndex = selectedComp === null ? null : manualCompIndex !== -1 ? manualCompIndex : comps.findIndex(e => e.id === selectedComp);
    const chosenComp = chosenCompIndex === null || chosenCompIndex === -1 ? null : manualCompIndex !== -1 ? manualComps[manualCompIndex] : comps[chosenCompIndex];
    const selectedCompRef = useRef(null);
	const api_key = JSON.parse(process.env.REACT_APP_GOOGLE).key
    const { isLoaded } = useLoadScript({
		googleMapsApiKey: api_key
	});
    const compOptions = ["Rental comps", "Sales comps"];
    const switchDisabled = false;
    const valueField = property.financials.rent;
    const subjectPropertyFullAddress = `${property.address.streetAddress}, ${property.address.city}, ${property.address.state} ${property.address.zipcode}`;
    const chosenCompFullAddress = chosenComp === null ? "" : `${chosenComp.address.streetAddress}, ${chosenComp.address.city}, ${chosenComp.address.state} ${chosenComp.address.zipcode}`;
    const longAddresses = subjectPropertyFullAddress.length > 37 || chosenCompFullAddress.length > 37 ? true : false;
    const navigate = useNavigate();
    const [amendedValue, setAmendedValue] = useState(valueField);
    const [applyEstimateModal, setApplyEstimateModal] = useState(false);
    const [firstRender, setFirstRender] = useState(false);
    const showReset = numberOfFilters === 0 && maxDistance === 2 && maxSoldDate === 6 ? false : true;

    const propertyLabels = [
        {
            icon: Bedrooms,
            label: "Beds"
        },
        {
            icon: Bathrooms,
            label: "Baths"
        },
        {
            icon: Construction,
            label: "Built"
        },
        {
            icon: SQFootage,
            label: "Sqft"
        },
        {
            icon: PriceSqFoot,
            label: "Price/sqft"
        },
        {
            icon: DollarIcon,
            label: "Listing price"
        }
    ];

    const propertyValues = [
        {
            value: property.bedrooms !== null ? property.bedrooms : "N/A"
        },
        {
            value: property.bathrooms !== null ? property.bathrooms : "N/A"
        },
        {
            value: property.yearBuilt !== null ? property.yearBuilt : "N/A"
        },
        {
            value: property.livingArea !== null ? numberFormatter.format(property.livingArea) : "N/A"
        },
        {
            value: property.resoFacts.pricePerSqFoot !== null ? formatterLong.format(property.resoFacts.pricePerSqFoot).replace(".00", "") : "N/A"
        },
        {
            value: property.price !== null ? formatterLong.format(valueField).replace(".00", "") : "N/A"
        },
        {
            value: property.resoFacts.lotSize !== null ? `${numberFormatter.format(property.resoFacts.lotSize)} sqft` : "N/A"
        }
    ];

    const selectedCompLabels = chosenComp === null ? [] : [
        {
            icon: Bedrooms,
            label: "Beds"
        },
        {
            icon: Bathrooms,
            label: "Baths"
        },
        {
            icon: Construction,
            label: "Built"
        },
        {
            icon: SQFootage,
            label: "Sqft"
        },
        {
            icon: PriceSqFoot,
            label: "Price/sqft"
        },
        {
            icon: HourGlass,
            label: chosenComp.listingStatus === "FOR_SALE" ? "Listing date" : "Sold date"
        }
    ];

    const selectedCompValues = chosenComp === null ? [] : [
        {
            value: chosenComp.bedrooms !== null ? chosenComp.bedrooms : "N/A",
            difference: chosenComp.bedrooms !== null && property.bedrooms !== null ? chosenComp.bedrooms - property.bedrooms : null
        },
        {
            value: chosenComp.bathrooms !== null ? chosenComp.bathrooms : "N/A",
            difference: chosenComp.bathrooms !== null && property.bathrooms !== null ? chosenComp.bathrooms - property.bathrooms : null
        },
        {
            value: chosenComp.yearBuilt !== null ? chosenComp.yearBuilt : "N/A",
            difference: null
        },
        {
            value: chosenComp.squareFootage !== null ? numberFormatter.format(chosenComp.squareFootage) : "N/A",
            difference: chosenComp.squareFootage !== null && property.livingArea !== null ? chosenComp.squareFootage - property.livingArea : null
        },
        {
            value: chosenComp.pricePerSquareFoot !== null ? formatterLong.format(chosenComp.pricePerSquareFoot).replace(".00", "") : "N/A",
            difference: null
        },
        {
            value: chosenComp.activityDate !== null ? moment(chosenComp.activityDate).format("DD MMM YYYY") : "N/A",
            difference: null
        },
        {
            value: chosenComp.lotAreaValue !== null ? `${numberFormatter.format(chosenComp.lotAreaValue)} sqft` : "N/A",
            difference: chosenComp.lotAreaValue !== null && property.resoFacts.lotSize !== null ? chosenComp.lotAreaValue - property.resoFacts.lotSize : null
        }
    ];

    useEffect(() => {
		// There's a bug with the overlay where 
		// it only renders on a re-render

        const getImages = async() => {
            const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
			const getGatewayQueryString = await getCompsImages(comps, "rent");
			const resourceId = "compPhotos";
			const getGatewayParams = {
				type: "gateway",
				resourceId: resourceId,
				queryString: getGatewayQueryString
			};
			const getGateway = await cloudFunctionV2(gatewayURL, getGatewayParams);
            if ( getGateway.status === 200 ) {
                const body = getGateway.body;
                if ( body.message === "Endpoint request timed out" ) {
                    return;    
                }
                const rentCompImages = body.rent;
                const newArray = [];
                for (let index = 0; index < comps.length; index++) {
                    const element = comps[index];
                    const amendedId = `${element.address.streetAddress.replaceAll(" ", "-")},-${element.address.city},-${element.address.state},-${element.address.zipcode}`
                    const imageIndex = rentCompImages.findIndex(e => e.id === amendedId);
                    if ( imageIndex !== -1 ) {
                        element.images = rentCompImages[imageIndex].photos;
                        element.imgSrc = rentCompImages[imageIndex].photoSourceUrl;
                        newArray.push(element);
                    }
                    else {
                        element.images = [];
                        element.imgSrc = "";
                        newArray.push(element);
                    }
                };
                setComps(newArray);
                runCompsEffect(newArray);
            }
            else {
                runCompsEffect(comps);
            }
        };

        const calculateAverageEffect = (nums, round) => {
            if (nums.length === 0) return 0;
        
            const sum = nums.reduce((acc, num) => acc + num, 0);
            const average = sum / nums.length;

            if ( round === true ) {
                const res = Math.round(average);
                return res;
            }
            else {
                return average;
            }
        };

        const runCompsEffect = (amendedComps) => {
            const newComps = [];
            for (let index = 0; index < amendedComps.length; index++) {
                const element = amendedComps[index];
                const today = new Date();
                const todaySeconds = moment.unix(today.getTime() / 1000);
                const propertyDate = element.lastSeenDate !== undefined ? element.lastSeenDate : element.listingDate;

                const activityDateTS = moment(propertyDate).toDate();
                const activityDate = moment(activityDateTS)
                const updateDateMonthDifference = todaySeconds.diff(activityDate, 'months');
    
                const checkedMinPrice = priceRange[0] === "" ? 0 : Number(priceRange[0]);
                const checkedMaxPrice = priceRange[1] === "" || priceRange[1] === 0 ? 999999999 : Number(priceRange[1]);
    
                if ( 
                        element.distance <= maxDistance && 
                        updateDateMonthDifference <= maxSoldDate &&
                        element.bedrooms >= bedrooms &&
                        element.bathrooms >= bathrooms &&
                        element.squareFootage >= squareFootage[0] && element.squareFootage <= squareFootage[1] &&
                        (element.price >= checkedMinPrice && element.price <= checkedMaxPrice)
                    ) {
                    newComps.push(element);
                }
            }
            setFilteredComps(newComps);
            saveMarketStats(newComps);
        };

        const saveMarketStats = async(newFilteredComps) => {
            const newTotal = newFilteredComps.length + manualComps.length - excludedComps.length;
            const pricePerSqFootArray = [];
            const bedsArray = [];
            const bathsArray = [];
            for (let index = 0; index < newFilteredComps.length; index++) {
                const element = newFilteredComps[index];
                const excludedIndex = excludedComps.indexOf(element.id);
                if ( excludedIndex === -1 ) {
                    pricePerSqFootArray.push(element.pricePerSquareFoot);
                    bedsArray.push(element.bedrooms);
                    bathsArray.push(element.bathrooms);
                }
            };

            for (let index = 0; index < manualComps.length; index++) {
                const element = manualComps[index];
                const excludedIndex = excludedComps.indexOf(element.id);
                if ( excludedIndex === -1 ) {
                    pricePerSqFootArray.push(element.pricePerSquareFoot);
                    bedsArray.push(element.bedrooms);
                    bathsArray.push(element.bathrooms);
                }
            };

            const averageBeds = await calculateAverageEffect(bedsArray, true);
            const averageBaths = await calculateAverageEffect(bathsArray, true);

            const newArray = [
                {
                    label: "TOTAL COMPS",
                    value: newTotal
                },
                {
                    label: "AVG BEDS",
                    value: averageBeds
                },
                {
                    label: "AVG BATHS",
                    value: averageBaths
                }
            ];
            setMarketStats(newArray);

            if ( numberOfFilters === 0 && maxDistance === 2 && maxSoldDate === 6 && excludedComps.length === 0 ) {
                return;
            }
            else if ( savedData !== null ) {
                const weightedMedian = await recalculateAmendedValueEffect();
                setAmendedValue(weightedMedian);
            }
        };

        const recalculateAmendedValueEffect = () => {
            const finalComps = filteredComps.concat(manualComps);

            const values = [];
            const weights = [];
            for (let index = 0; index < finalComps.length; index++) {
                const element = finalComps[index];
                const excludedcompsIndex = excludedComps.indexOf(element.id);
                if ( excludedcompsIndex !== -1 ) {
                    continue;
                }

                const manualCompIndex = manualComps.findIndex(e => e.id === element.id);
                const price = element.price;
                const similarityScore = element.prediction_label !== undefined ? element.prediction_label : element.correlation;
                const weight = manualCompIndex === -1 ? similarityScore : 1;
                values.push(price);
                weights.push(weight);
            }
            if ( values.length === 0 ) {
                return 0;
            }
        
            const weightedSum = values.reduce((acc, value, index) => acc + value * weights[index], 0);
            const totalWeight = weights.reduce((acc, weight) => acc + weight, 0);
            const weightedAverage = weightedSum / totalWeight;
        
            return weightedAverage;
        };

		if ( renderOverlay === false && firstRender === false ) {
            setFirstRender(true);
            if ( comps !== undefined && comps !== null ) {
                getImages();
            }
			setTimeout(() => {
				setRenderOverlay(true);
			}, 1000);
		}

	}, [
        renderOverlay, 
        comps, 
        manualComps, 
        excludedComps,
        bedrooms,
        bathrooms,
        squareFootage,
        priceRange,
        listingStatus,
        maxDistance,
        maxSoldDate,
        filteredComps,
        firstRender,
        savedData,
        numberOfFilters
    ]);

    const checkTimeGap = (item) => {
        const today = new Date();
        const todaySeconds = moment.unix(today.getTime() / 1000);
        const propertyDate = item.lastSeenDate !== undefined ? item.lastSeenDate : item.listingDate;

        const activityDateTS = moment(propertyDate).toDate();
        const activityDate = moment(activityDateTS)
        const updateDateYearDifference = todaySeconds.diff(activityDate, 'years');
        const updateDateMonthDifference = todaySeconds.diff(activityDate, 'months');
        const updateDateDayDifference = todaySeconds.diff(activityDate, 'days');

        const checkedDifference = updateDateYearDifference > 0 ? `${updateDateYearDifference} yr${updateDateYearDifference === 1 ? "" : "s"} ago` : updateDateMonthDifference > 0 ? `${updateDateMonthDifference} mo ago` : `${updateDateDayDifference} day${updateDateDayDifference === 1 ? "" : "s"} ago`;
        return checkedDifference;
    };

    const checkTimeGapLong = (item) => {
        const today = new Date();
        const todaySeconds = moment.unix(today.getTime() / 1000);
        const propertyDate = item.lastSeenDate !== undefined ? item.lastSeenDate : item.listingDate;

        const activityDateTS = moment(propertyDate).toDate();
        const activityDate = moment(activityDateTS)
        const updateDateYearDifference = todaySeconds.diff(activityDate, 'years');
        const updateDateMonthDifference = todaySeconds.diff(activityDate, 'months');
        const updateDateDayDifference = todaySeconds.diff(activityDate, 'days');

        const checkedDifference = updateDateYearDifference > 0 ? `${updateDateYearDifference} year${updateDateYearDifference === 1 ? "" : "s"} ago` : updateDateMonthDifference > 0 ? `${updateDateMonthDifference} month${updateDateMonthDifference === 1 ? "" : "s"} ago` : `${updateDateDayDifference} day${updateDateDayDifference === 1 ? "" : "s"} ago`;
        return checkedDifference;
    };

	const options = {
		disableDefaultUI: false,
		scrollwheel: true,
		mapTypeControl: false,
		streetViewControl: false,
		fullscreenControl: true,
		disableDoubleClickZoom: true,
		styles: mapStyles
	};

    const rgbaToHex = (r, g, b, a) => {
        // Convert the RGB to a hexadecimal value
        const rHex = r.toString(16).padStart(2, '0');
        const gHex = g.toString(16).padStart(2, '0');
        const bHex = b.toString(16).padStart(2, '0');
    
        // Convert the alpha value to a hexadecimal value
        const aHex = Math.round(a * 255).toString(16).padStart(2, '0');
    
        // Combine the RGB and alpha values to get the hexadecimal color code
        const hexCode = `#${rHex}${gHex}${bHex}${aHex}`;
    
        return hexCode.toUpperCase(); // Convert to uppercase for consistency
    };

	const generateColor = (darkYellow, lightYellow, percentage) => {
        percentage = Math.max(0, Math.min(1, percentage));
      
        const deltaRed = lightYellow.red - darkYellow.red;
        const deltaGreen = lightYellow.green - darkYellow.green;
        const deltaBlue = lightYellow.blue - darkYellow.blue;
      
        const red = Math.round(darkYellow.red + (deltaRed * percentage));
        const green = Math.round(darkYellow.green + (deltaGreen * percentage));
        const blue = Math.round(darkYellow.blue + (deltaBlue * percentage));
        const getHex = rgbaToHex(red, green, blue, 0.7);
      
        return getHex;
    };

    const checkNeighbourhoodStyles = (index) => {
        if ( hasUndefinedNeighborhoodGrade === true ) {
            const allValues = [];
            for (let i = 0; i < neighbourhoodData.length; i++) {
				if ( neighbourhoodData[i].stats !== undefined ) {
					const element = neighbourhoodData[i].stats[dataLocation];
					if ( element !== null ) {
						const subElement = element[dataSubLocation];
						if ( subElement !== null ) {
							allValues.push(subElement);
						}
					}
				}
            }

			if ( neighbourhoodData[index].stats !== undefined ) {
				const tractElement = neighbourhoodData[index].stats[dataLocation];
				if ( tractElement === null ) {
					return styles.inactive
				}
				else if ( tractElement[dataSubLocation] === null ) {
					return styles.inactive
				}
				else {
					const tractValue = tractElement[dataSubLocation];
					const minVal = Math.min(...allValues);
					const maxVal = Math.max(...allValues);
					const range = maxVal - minVal;
					const distanceFromMin = tractValue - minVal;
					if ( range === 0 ) {
						const newStyle = {
							fillColor: styles.inactive,
							fillOpacity: 1,
							strokeColor: '#FFFFFF',
							strokeWeight: 1,
							zIndex: 1
						}
						
						return newStyle;
					};
					const percentage = (distanceFromMin / range);
					const darkYellow = colour.heatmapHot;
					const lightYellow = colour.heatmapCold;
					const getHex = generateColor(lightYellow, darkYellow, percentage);
				
					const newStyle = {
						fillColor: getHex,
						fillOpacity: 1,
						strokeColor: '#FFFFFF',
						strokeWeight: 1,
						zIndex: 1
					}
					
					return newStyle;
				}
			}
        }
        else if ( hoverMapLegend !== null ) {
            const tractValue = neighbourhoodData[index].neighborhoodGrade;
            const gradeTranslation = [
                8,
                7,
                6,
                5,
                4,
                3,
                2,
                1
            ];

            if ( tractValue === gradeTranslation[hoverMapLegend] ) {
                const newStyle = {
                    fillColor: neighbourhoodColours[hoverMapLegend],
                    fillOpacity: 1,
                    strokeColor: colour.blueBlue02,
                    strokeWeight: 3,
                    zIndex: 2
                }
                
                return newStyle;
            }
            else {
                const newStyle = {
                    fillColor: styles.inactive.fillColor,
                    fillOpacity: 1,
                    strokeColor: '#FFFFFF',
                    strokeWeight: 1,
                    zIndex: 1
                }
                
                return newStyle;
            }
        }
        else {
            const tractValue = neighbourhoodData[index].neighborhoodGrade;
            const getHex = tractValue === 8 ? styles.first.backgroundColor 
            :
            tractValue === 7 ? styles.second.backgroundColor
            :
            tractValue === 6 ? styles.third.backgroundColor
            :
            tractValue === 5 ? styles.fourth.backgroundColor
            :
            tractValue === 4 ? styles.fifth.backgroundColor
            :
            tractValue === 3 ? styles.sixth.backgroundColor
            :
            tractValue === 2 ? styles.seventh.backgroundColor
            :
            styles.eighth.backgroundColor;
            
            const newStyle = {
                fillColor: getHex,
                fillOpacity: 1,
                strokeColor: '#FFFFFF',
                strokeWeight: 1,
                zIndex: 1
            }
            
            return newStyle;
        }
	};
    
    // const addComp = () => {
    //     recordEvent("Add Comp Clicked", {});
    //     setAddCompModal(true);
    // };

    const clickComp = (index) => {
        recordEvent("Comp Clicked", {});
        setSelectedComp(filteredComps[index].id);

        const newViewedProperties = [...viewedProperties];
        const propertyIndex = viewedProperties.indexOf(filteredComps[index].id);
        if ( propertyIndex === -1 ) {
            newViewedProperties.push(filteredComps[index].id);
            setViewedProperties(newViewedProperties);
        }
        resetSwiper();
    };

    const clickManualComp = (index) => {
        recordEvent("Comp Clicked", {});
        setSelectedComp(manualComps[index].id);

        const newViewedProperties = [...viewedProperties];
        const propertyIndex = viewedProperties.indexOf(manualComps[index].id);
        if ( propertyIndex === -1 ) {
            newViewedProperties.push(manualComps[index].id);
            setViewedProperties(newViewedProperties);
        }
        resetSwiper();
    };

    const closeComp = () => {
        recordEvent("Comp Panel Closed", {});
        setSelectedComp(null);
        resetSwiper();
    };

    const resetSwiper = () => {
        if (selectedCompRef.current && selectedCompRef.current.swiper) {
            selectedCompRef.current.swiper.slideTo(0, 0);
        }
    };

    const styles = {
		inactive: {
			fillColor: mapColours.mapColorsMapZoneInactive,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		inactiveHover: {
			fillColor: mapColours.mapColorsMapZoneInactive,
			fillOpacity: 1,
			strokeColor: colour.blueBlue02,
			strokeWeight: 3,
			zIndex: 2
		},
		first: {
            backgroundColor: neighbourhoodColours[0]
        },
        second: {
            backgroundColor: neighbourhoodColours[1]
        },
        third: {
            backgroundColor: neighbourhoodColours[2]
        },
        fourth: {
            backgroundColor: neighbourhoodColours[3]
        },
        fifth: {
            backgroundColor: neighbourhoodColours[4]
        },
        sixth: {
            backgroundColor: neighbourhoodColours[5]
        },
        seventh: {
            backgroundColor: neighbourhoodColours[6]
        },
        eighth: {
            backgroundColor: neighbourhoodColours[7]
        }
    };

    const openFiltersModal = () => {
        recordEvent("Open Comp Filters", {});
        setOpenFilters(true);
    };

    const changeDistance = (event, value) => {
        setMaxDistance(value);
        runComps(value, maxSoldDate, bedrooms, bathrooms, squareFootage, priceRange, listingStatus, numberOfFilters, false);
    };

    const changeSoldDate = (event, value) => {
        setMaxSoldDate(value);
        runComps(maxDistance, value, bedrooms, bathrooms, squareFootage, priceRange, listingStatus, numberOfFilters, false);
    };

    const runComps = (newMaxDistance, newMaxSoldDate, newBeds, newBaths, newSqFootage, newPriceRange, newListingStatus, newTotalFilters, reset) => {
        const newComps = [];
        for (let index = 0; index < comps.length; index++) {
            const element = comps[index];
            const today = new Date();
            const todaySeconds = moment.unix(today.getTime() / 1000);
            const propertyDate = element.lastSeenDate !== undefined ? element.lastSeenDate : element.listingDate;
            const activityDateTS = moment(propertyDate).toDate();
            const activityDate = moment(activityDateTS)
            const updateDateMonthDifference = todaySeconds.diff(activityDate, 'months');

            const checkedMinPrice = newPriceRange[0] === "" ? 0 : Number(newPriceRange[0]);
            const checkedMaxPrice = newPriceRange[1] === "" || newPriceRange[1] === 0 ? 999999999 : Number(newPriceRange[1]);

            if ( 
                    element.distance <= newMaxDistance && 
                    updateDateMonthDifference <= newMaxSoldDate &&
                    element.bedrooms >= newBeds &&
                    element.bathrooms >= newBaths &&
                    element.squareFootage >= newSqFootage[0] && element.squareFootage <= newSqFootage[1] &&
                    (element.price >= checkedMinPrice && element.price <= checkedMaxPrice)
                ) {
                newComps.push(element);
            }
        }
        setFilteredComps(newComps);
        recalculateMarketStats(newComps, reset);
    };

    const calculateAverage = (nums, round) => {
        if (nums.length === 0) return 0;
        
        const sum = nums.reduce((acc, num) => acc + num, 0);
        const average = sum / nums.length;

        if ( round === true ) {
            const res = Math.round(average);
            return res;
        }
        else {
            return average;
        }
    };

    const recalculateMarketStats = async(newComps, reset) => {
        const relevantExcludedComps = [];
        for (let index = 0; index < newComps.length; index++) {
            const element = newComps[index];
            const excludedIndex = excludedComps.indexOf(element.id);
            if ( excludedIndex !== -1 ) {
                relevantExcludedComps.push(element.id);
            }
        }

        const newTotal = newComps.length + manualComps.length - relevantExcludedComps.length;
        const pricePerSqFootArray = [];
        const bedsArray = [];
        const bathsArray = [];
        for (let index = 0; index < newComps.length; index++) {
            const element = newComps[index];
            const excludedIndex = excludedComps.indexOf(element.id);
            if ( excludedIndex === -1 ) {
                pricePerSqFootArray.push(element.pricePerSquareFoot);
                bedsArray.push(element.bedrooms);
                bathsArray.push(element.bathrooms);
            }
        };

        for (let index = 0; index < manualComps.length; index++) {
            const element = manualComps[index];
            const excludedIndex = excludedComps.indexOf(element.id);
            if ( excludedIndex === -1 ) {
                pricePerSqFootArray.push(element.pricePerSquareFoot);
                bedsArray.push(element.bedrooms);
                bathsArray.push(element.bathrooms);
            }
        };

        const averageBeds = await calculateAverage(bedsArray, true);
        const averageBaths = await calculateAverage(bathsArray, true);

        const newArray = [
            {
                label: "TOTAL COMPS",
                value: newTotal
            },
            {
                label: "AVG BEDS",
                value: averageBeds
            },
            {
                label: "AVG BATHS",
                value: averageBaths
            }
        ];
        setMarketStats(newArray);

        if ( reset === false ) {
            const weightedMedian = await recalculateAmendedValue(newComps, excludedComps, manualComps);
            setAmendedValue(weightedMedian);
        }
    };

    const recalculateAmendedValue = (newComps, newExcluded, newManualComps) => {
        const finalComps = newComps.concat(newManualComps);

        const values = [];
        const weights = [];
        for (let index = 0; index < finalComps.length; index++) {
            const element = finalComps[index];
            const excludedcompsIndex = newExcluded.indexOf(element.id);
            if ( excludedcompsIndex !== -1 ) {
                continue;
            }

            const manualCompIndex = newManualComps.findIndex(e => e.id === element.id);
            const price = element.price;
            const similarityScore = element.prediction_label !== undefined ? element.prediction_label : element.correlation;
            const weight = manualCompIndex === -1 ? similarityScore : 1;
            values.push(price);
            weights.push(weight);
        }
        if ( values.length === 0 ) {
            return 0;
        }
    
        const weightedSum = values.reduce((acc, value, index) => acc + value * weights[index], 0);
        const totalWeight = weights.reduce((acc, weight) => acc + weight, 0);
        const weightedAverage = weightedSum / totalWeight;
    
        return weightedAverage;
    };

    const changePricingOption = () => {
        if ( switchDisabled === false ) {
            setFirstPricingOption(!firstPricingOption);

            if ( firstPricingOption === true ) {
                switchCompsTool(1);
            }
            else {
                switchCompsTool(0);
            }
        }
    };

    const excludeComp = async() => {
        const newArray = [...excludedComps];
        newArray.push(chosenComp.id);
        setExcludedComps(newArray);
        setSelectedComp(null);

        const marketStatsClone = [...marketStats];
        marketStatsClone[0].value = marketStatsClone[0].value - 1;
        setMarketStats(marketStatsClone);
        resetSwiper();
        const weightedMedian = await recalculateAmendedValue(filteredComps, newArray, manualComps);
        setAmendedValue(weightedMedian);
    };

    const includeComp = async() => {
        const newArray = [...excludedComps];
        const index = newArray.indexOf(chosenComp.id);
        if ( index !== -1 ) {
            newArray.splice(index, 1);
        }
        setExcludedComps(newArray);
        setSelectedComp(null);

        const marketStatsClone = [...marketStats];
        marketStatsClone[0].value = marketStatsClone[0].value + 1;
        setMarketStats(marketStatsClone);
        resetSwiper();
        const weightedMedian = await recalculateAmendedValue(filteredComps, newArray, manualComps);
        setAmendedValue(weightedMedian);
    };
    
    const checkExcluded = (id) => {
        const exclusionIndex = excludedComps.indexOf(id);
        if ( exclusionIndex !== -1 ) {
            return true;
        }
        else {
            return false;
        }
    };

    const checkViewed = (id) => {
        const viewIndex = viewedProperties.indexOf(id);
        if ( viewIndex !== -1 ) {
            return true;
        }
        else {
            return false;
        }
    };

    const finishAddComp = async(newArray) => {
        const weightedMedian = await recalculateAmendedValue(filteredComps, excludedComps, newArray);
        setAmendedValue(weightedMedian);
    };

    const saveFilters = (modalBeds, modalBaths, modalSqFootage, modalPriceRange, modalListingStatus, newTotalFilters) => {
        runComps(maxDistance, maxSoldDate, modalBeds, modalBaths, modalSqFootage, modalPriceRange, modalListingStatus, newTotalFilters, false);
    };

    const resetAllFilters = (newMaxDistance, newMaxSoldDate, modalBeds, modalBaths, modalSqFootage, modalPriceRange, modalListingStatus) => {
        runComps(newMaxDistance, newMaxSoldDate, modalBeds, modalBaths, modalSqFootage, modalPriceRange, modalListingStatus, 0, true);
        setAmendedValue(valueField);
    };

    const checkGoogleMapLink = (item) => {
        const images = item.images;
        if ( images === undefined || images === null || images.length === 0 ) {
            return false;
        }
        else if ( images[0].includes("maps.googleapis.com") ) {
            return true;
        }
        else {
            return false;
        }
    };

    // const saveWhichValue = () => {
    //     setApplyEstimateModal(true);
    // };

    const saveCompChanges = async() => {
        // All filters
        // Manually added comps
        // Excluded comps
        setDisabled(true);

        const compData = {
            maxDistance: maxDistance,
            maxSoldDate: maxSoldDate,
            bedrooms: bedrooms,
            bathrooms: bathrooms,
            squareFootage: squareFootage,
            priceRange: priceRange,
            listingStatus: listingStatus,
            numberOfFilters: numberOfFilters,
            manualComps: manualComps,
            excludedComps: excludedComps

        };
        const id = property.zpid;

        if ( userId !== null && userId !== "" ) {
            const finalCompData = {
                rentCompData: compData,
            };
            await saveCompData(finalCompData, userId, id);
            setSavedData(compData);
        }
        saveCompsAnalysis();
        setDisabled(false);
    };

    const openGoogle = (item) => {
        recordEvent("Open Google Link", {});
        const googleLink = `https://www.google.com/maps/place/${item.address.streetAddress.replace(" ", "+")},+${item.address.city},+${item.address.state}+${item.address.zipcode}`;
        window.open(googleLink, "_blank");
    };

    const closeApplyEstimateModal = () => {
        setApplyEstimateModal(false);
    };

    const closeSignupModal = () => {
        recordEvent("Close Sign Up Modal", {});
        setSignUpModal(false);
        closeCompsAnalysis();
    };

    const goToSignup = () => {
        recordEvent("Sign Up Clicked", {});
        closeCompsAnalysis();
        navigate("/sign-up");
    };

    const checkSimilarityScore = (item) => {
        const similarityScore = item.prediction_label !== undefined ? item.prediction_label : item.correlation;
        return similarityScore;
    };

    const resetFilters = () => {
        setBedrooms(1);
        setBathrooms(1);
        setSquareFootage([0, 999999]);
        setPriceRange(["", ""]);
        setListingStatus(2);
        setNumberOfFilters(0);
        setMaxDistance(2);
        setMaxSoldDate(6);
        resetAllFilters(2, 6, 1, 1, [0, 999999], ["", ""], 2);
    };

    const saveNewValue = async(val, type) => {
        setDisabled(true);
        const removedCommas = val.replaceAll(",", "");
        const newVal = Number(removedCommas);

        setEditedProperty(prevState => ({
            ...prevState,
            rent: newVal,
            strategy: strategyPanel
        }));
        await saveCompChanges();
    };

    return (
        <div className="comps-analysis-outer-container rent-comps-analysis-outer-container">
            <div className="comps-analysis-inner-container relative-container">
                {
                    signUpModal === true ?
                    <Modal
                        title="Create a FREE account to save changes 😊"
                        description="You'll need to create a free account to save your changes here"
                        closeButtonText="Close"
                        saveButtonText="Sign up"
                        closeModal={() => closeSignupModal()}
                        saveModal={() => goToSignup()}
                    />
                    :
                    null
                }
                {
                    addCompModal === true ?
                    <AddComp
                        setAddCompModal={setAddCompModal}
                        property={property}
                        setSelectedComp={setSelectedComp}
                        manualComps={manualComps}
                        setManualComps={setManualComps}
                        marketStats={marketStats}
                        setMarketStats={setMarketStats}
                        resetSwiper={resetSwiper}
                        finishAddComp={finishAddComp}
                    />
                    :
                    null
                }
                {
                    openFilters === true ?
                    <CompFilters
                        bedrooms={bedrooms}
                        setBedrooms={setBedrooms}
                        bathrooms={bathrooms}
                        setBathrooms={setBathrooms}
                        squareFootage={squareFootage}
                        setSquareFootage={setSquareFootage}
                        priceRange={priceRange}
                        setPriceRange={setPriceRange}
                        listingStatus={listingStatus}
                        setListingStatus={setListingStatus}
                        setOpenFilters={setOpenFilters}
                        saveFilters={saveFilters}
                        setNumberOfFilters={setNumberOfFilters}
                        setMaxDistance={setMaxDistance}
                        setMaxSoldDate={setMaxSoldDate}
                        resetAllFilters={resetAllFilters}
                        isNonDisclosure={isNonDisclosure}
                        rentMode={true}
                    />
                    :
                    null
                }
                {
                    applyEstimateModal === true ?
                    <ApplyEstimate
                        amendedValue={amendedValue}
                        setAmendedValue={setAmendedValue}
                        valueField={valueField}
                        closeModal={closeApplyEstimateModal}
                        saveCompChanges={saveCompChanges}
                        saveNewValue={saveNewValue}
                        rentMode={true}
                    />
                    :
                    null
                }
                {
                    addCompModal === true || openFilters === true || signUpModal === true || applyEstimateModal === true ?
                    <div className="dark-bg">
                    </div>
                    :
                    null
                }
                <div className="comps-analysis-left-container">
                    <div className="comps-analysis-left-inner-container">
                        <span className="body-regular colour-primary block-text margin-x-small">
                            Subject property
                        </span>
                        <div className="comps-analysis-subject-property-images-container margin-medium">
                            {
                                property.images !== undefined && property.images !== null && property.images.length > 0 && checkGoogleMapLink(property) === false ?
                                <CustomSwiper
                                    images={property.images}
                                    title={property.address.streetAddress}
                                    defaultImage={property.imgSrc}
                                    Heart={null}
                                    HeartActive={null}
                                    favourites={[]}
                                    toggleFavourite={null}
                                    favouriteDisable={null}
                                    item={property}
                                    setFavourites={null}
                                    setFavouriteDisable={null}
                                    abstract={false}
                                    viewProperty={null}
                                    cityId={null}
                                    setCardClick={null}
                                    temporaryId={null}
                                    setSignUpModal={null}
                                    propertySearch={false}
                                    partner={false}
                                    arrowClicked={null}
                                    imageLimit={100}
                                    swiperRef={null}
                                />
                                :
                                <div className="off-market-card-image-container">
                                    <img
                                        src={OffMarketHouse}
                                        className="off-market-card-icon"
                                        alt="Off Market"
                                    />
                                    <div 
                                        className="off-market-card-text-row cursor-pointer"
                                        onClick={() => openGoogle(property)}
                                    >
                                        <span className="label-regular-caps colour-secondary">
                                            Go to street view
                                        </span>
                                        <img
                                            src={RedirectArrow}
                                            className="off-market-card-redirect-arrow"
                                            alt="Redirect"
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={"comps-analysis-subject-property-address-container margin-x-small " + (longAddresses === true ? "tall-address-container" : "")}>
                            <span className="body-regular colour-primary">
                                {subjectPropertyFullAddress}
                            </span>
                        </div>
                        <div className="comps-analysis-arv-container margin-medium">
                            <div className="comps-analysis-arv-inner-container">
                                <span className="label-regular-caps colour-primary block-text">
                                    RENT ESTIMATE
                                </span>
                                <span className="heading-large-semibold colour-primary block-text">
                                    {
                                        amendedValue === valueField || Math.round(amendedValue) === 0 ?
                                        formatterLong.format(valueField).replace(".00", "")
                                        :
                                        formatterLong.format(Math.round(amendedValue)).replace(".00", "")
                                    }
                                </span>
                                {
                                    amendedValue !== valueField && Math.round(amendedValue) !== 0 ?
                                    <span className="body-regular colour-quaternary text-italic">
                                        was {formatterLong.format(valueField).replace(".00", "")}
                                    </span>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="comps-analysis-details-grid">
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <img
                                    src={propertyLabels[0].icon}
                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                    alt={propertyLabels[0].label}
                                />
                                <span className="body-regular colour-primary">
                                    {propertyLabels[0].label}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <span className="body-semibold colour-primary">
                                    {propertyValues[0].value}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <img
                                    src={propertyLabels[1].icon}
                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                    alt={propertyLabels[1].label}
                                />
                                <span className="body-regular colour-primary">
                                    {propertyLabels[1].label}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <span className="body-semibold colour-primary">
                                    {propertyValues[1].value}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <img
                                    src={propertyLabels[2].icon}
                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                    alt={propertyLabels[2].label}
                                />
                                <span className="body-regular colour-primary">
                                    {propertyLabels[2].label}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <span className="body-semibold colour-primary">
                                    {propertyValues[2].value}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <img
                                    src={propertyLabels[3].icon}
                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                    alt={propertyLabels[3].label}
                                />
                                <span className="body-regular colour-primary">
                                    {propertyLabels[3].label}
                                </span>
                            </div>
                            <div 
                                className="comps-analysis-details-element"
                            >
                                <span className="body-semibold colour-primary">
                                    {propertyValues[3].value}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="comps-analysis-map-container">
                    {
                        isLoaded === true ?
                        <GoogleMap
                            zoom={15}
                            center={centre}
                            mapContainerClassName="comps-analysis-map-element"
                            options={options}
                        >
                            {
                                renderOverlay === true && chosenComp !== null ?
                                <div className="comps-analysis-selected-comp-outer-container">
                                    <div className="comps-analysis-selected-comp-inner-container relative-container">
                                        <div 
                                            className="comps-analysis-selected-comp-exit-container"
                                            onClick={() => closeComp()}
                                        >
                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg" 
                                                width="16" 
                                                height="16" 
                                                viewBox="0 0 16 16" 
                                                fill="none"
                                            >
                                                <path 
                                                    fillRule="evenodd" 
                                                    clipRule="evenodd" 
                                                    d="M15.0814 1.44782C15.402 1.09182 15.3732 0.543356 15.0172 0.222805C14.6612 -0.0977454 14.1127 -0.0690019 13.7922 0.287006L8.0017 6.718L2.21124 0.287037C1.89069 -0.0689705 1.34223 -0.097714 0.986225 0.222837C0.630217 0.543388 0.601473 1.09185 0.922024 1.44785L6.83449 8.01432L0.947808 14.5521C0.627257 14.9081 0.656001 15.4566 1.01201 15.7772C1.36802 16.0977 1.91648 16.069 2.23703 15.713L8.0017 9.31064L13.7664 15.713C14.087 16.069 14.6354 16.0977 14.9914 15.7772C15.3474 15.4566 15.3762 14.9082 15.0556 14.5522L9.16892 8.01432L15.0814 1.44782Z" 
                                                    fill="#292621"
                                                />
                                            </svg>
                                        </div>
                                        <div className="comps-analysis-selected-comp-title-container margin-x-small">
                                            <div className="comps-analysis-selected-comp-top-row-container subtext-margin-right-large">
                                                <span className="body-regular colour-primary block-text subtext-margin-right">
                                                    Similarity
                                                </span>
                                                <span className="body-semibold colour-primary">
                                                    {
                                                        checkSimilarityScore(chosenComp) === 1 ?
                                                        "N/A"
                                                        :
                                                        percentage.format(checkSimilarityScore(chosenComp))
                                                    }
                                                </span>
                                            </div>
                                            <div className="comps-analysis-selected-comp-top-row-container">
                                                <span className="body-regular colour-primary block-text subtext-margin-right">
                                                    Distance
                                                </span>
                                                <span className="body-semibold colour-primary">
                                                    {chosenComp.distance.toFixed(2)}mi
                                                </span>
                                            </div>
                                        </div>
                                        <div className="comps-analysis-selected-comp-image-container margin-medium">
                                            {
                                                chosenComp.images !== undefined && chosenComp.images !== null && chosenComp.images.length > 0 && checkGoogleMapLink(chosenComp) === false ?
                                                <CustomSwiper
                                                    images={chosenComp.images}
                                                    title={chosenComp.address.streetAddress}
                                                    defaultImage={chosenComp.imgSrc}
                                                    Heart={null}
                                                    HeartActive={null}
                                                    favourites={[]}
                                                    toggleFavourite={null}
                                                    favouriteDisable={null}
                                                    item={chosenComp}
                                                    setFavourites={null}
                                                    setFavouriteDisable={null}
                                                    abstract={false}
                                                    viewProperty={null}
                                                    cityId={null}
                                                    setCardClick={null}
                                                    temporaryId={null}
                                                    setSignUpModal={null}
                                                    propertySearch={false}
                                                    partner={false}
                                                    arrowClicked={null}
                                                    imageLimit={100}
                                                    swiperRef={selectedCompRef}
                                                />
                                                :
                                                <div className="off-market-card-image-container">
                                                    <img
                                                        src={OffMarketHouse}
                                                        className="off-market-card-icon"
                                                        alt="Off Market"
                                                    />
                                                    <div 
                                                        className="off-market-card-text-row cursor-pointer"
                                                        onClick={() => openGoogle(chosenComp)}
                                                    >
                                                        <span className="label-regular-caps colour-secondary">
                                                            Go to street view
                                                        </span>
                                                        <img
                                                            src={RedirectArrow}
                                                            className="off-market-card-redirect-arrow"
                                                            alt="Redirect"
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className={"comps-analysis-selected-comp-address-container margin-x-small " + (longAddresses === true ? "tall-address-container" : "")}>
                                            <span className="body-regular colour-primary">
                                                {chosenCompFullAddress}
                                            </span>
                                        </div>
                                        <div className="comp-analysis-price-outer-container bg-colour-white margin-medium">
                                            <div className="comp-analysis-price-inner-container">
                                                <span className="label-regular-caps colour-primary block-text">
                                                    RENT
                                                </span>
                                                <span className="heading-large-semibold colour-primary block-text">
                                                    {formatterLong.format(chosenComp.price).replace(".00", "")}
                                                </span>
                                                <span className="body-regular text-link">
                                                    {checkTimeGapLong(chosenComp)}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="comps-analysis-details-grid margin-large">
                                            <div 
                                                className="comps-analysis-details-element"
                                            >
                                                <img
                                                    src={selectedCompLabels[0].icon}
                                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                                    alt={selectedCompLabels[0].label}
                                                />
                                                <span className="body-regular colour-primary">
                                                    {selectedCompLabels[0].label}
                                                </span>
                                            </div>
                                            <div 
                                                className="comps-analysis-details-element relative-container"
                                            >
                                                <span className="body-semibold colour-primary">
                                                    {selectedCompValues[0].value}
                                                </span>
                                                {
                                                    selectedCompValues[0].difference !== null && selectedCompValues[0].difference !== 0 ?
                                                    <span className={"label-semibold comps-analysis-difference-text " + (selectedCompValues[0].difference > 0 ? "colour-green" : "colour-error")}>
                                                        {selectedCompValues[0].difference > 0 ? "+" : ""}{numberFormatter.format(selectedCompValues[0].difference)}
                                                    </span>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <div 
                                                className="comps-analysis-details-element"
                                            >
                                                <img
                                                    src={selectedCompLabels[1].icon}
                                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                                    alt={selectedCompLabels[1].label}
                                                />
                                                <span className="body-regular colour-primary">
                                                    {selectedCompLabels[1].label}
                                                </span>
                                            </div>
                                            <div 
                                                className="comps-analysis-details-element relative-container"
                                            >
                                                <span className="body-semibold colour-primary">
                                                    {selectedCompValues[1].value}
                                                </span>
                                                {
                                                    selectedCompValues[1].difference !== null && selectedCompValues[1].difference !== 0 ?
                                                    <span className={"label-semibold comps-analysis-difference-text " + (selectedCompValues[1].difference > 0 ? "colour-green" : "colour-error")}>
                                                        {selectedCompValues[1].difference > 0 ? "+" : ""}{numberFormatter.format(selectedCompValues[1].difference)}
                                                    </span>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <div 
                                                className="comps-analysis-details-element"
                                            >
                                                <img
                                                    src={selectedCompLabels[2].icon}
                                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                                    alt={selectedCompLabels[2].label}
                                                />
                                                <span className="body-regular colour-primary">
                                                    {selectedCompLabels[2].label}
                                                </span>
                                            </div>
                                            <div 
                                                className="comps-analysis-details-element relative-container"
                                            >
                                                <span className="body-semibold colour-primary">
                                                    {selectedCompValues[2].value}
                                                </span>
                                                {
                                                    selectedCompValues[2].difference !== null && selectedCompValues[2].difference !== 0 ?
                                                    <span className={"label-semibold comps-analysis-difference-text " + (selectedCompValues[2].difference > 0 ? "colour-green" : "colour-error")}>
                                                        {selectedCompValues[2].difference > 0 ? "+" : ""}{numberFormatter.format(selectedCompValues[2].difference)}
                                                    </span>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <div 
                                                className="comps-analysis-details-element"
                                            >
                                                <img
                                                    src={selectedCompLabels[3].icon}
                                                    className="comps-analysis-details-icon block-text subtext-margin-right-large"
                                                    alt={selectedCompLabels[3].label}
                                                />
                                                <span className="body-regular colour-primary">
                                                    {selectedCompLabels[3].label}
                                                </span>
                                            </div>
                                            <div 
                                                className="comps-analysis-details-element"
                                            >
                                                <span className="body-semibold colour-primary">
                                                    {selectedCompValues[3].value}
                                                </span>
                                                {
                                                    selectedCompValues[3].difference !== null && selectedCompValues[3].difference !== 0 ?
                                                    <span className={"label-semibold comps-analysis-difference-text " + (selectedCompValues[3].difference > 0 ? "colour-green" : "colour-error")}>
                                                        {selectedCompValues[3].difference > 0 ? "+" : ""}{numberFormatter.format(selectedCompValues[3].difference)}
                                                    </span>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div className="comps-analysis-selected-comp-button-container">
                                            {
                                                checkExcluded(chosenComp.id) === true ?
                                                <GreenActionButton
                                                    onClick={() => includeComp()}
                                                >
                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg" 
                                                        width="17" 
                                                        height="16" 
                                                        viewBox="0 0 17 16" 
                                                        fill="none"
                                                        className="comps-analysis-selected-comp-button-icon"
                                                    >
                                                        <path 
                                                            fillRule="evenodd" 
                                                            clipRule="evenodd" 
                                                            d="M15.9995 1.43765C16.6415 2.0449 16.6696 3.05758 16.0623 3.69952L6.72901 13.5662C6.43211 13.8801 6.02089 14.0605 5.58888 14.0665C5.15687 14.0725 4.7408 13.9035 4.43529 13.598L0.968629 10.1314C0.34379 9.50653 0.34379 8.49347 0.968629 7.86863C1.59347 7.24379 2.60653 7.24379 3.23137 7.86863L5.5348 10.1721L13.7376 1.50048C14.3449 0.858536 15.3576 0.830406 15.9995 1.43765Z" 
                                                            fill="#FFFEFC"
                                                        />
                                                    </svg>
                                                    Include
                                                </GreenActionButton>
                                                :
                                                <RedActionButton
                                                    onClick={() => excludeComp()}
                                                >
                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg" 
                                                        width="17" 
                                                        height="16" 
                                                        viewBox="0 0 17 16" 
                                                        fill="none"
                                                        className="comps-analysis-selected-comp-button-icon"
                                                    >
                                                        <path 
                                                            fillRule="evenodd" 
                                                            clipRule="evenodd" 
                                                            d="M4.50479 0.648211C3.78082 -0.155843 2.54211 -0.220761 1.73805 0.503212C0.934001 1.22718 0.869083 2.46589 1.59306 3.26995L5.86282 8.012L1.61468 12.73C0.890706 13.5341 0.955625 14.7728 1.75968 15.4968C2.56373 16.2208 3.80244 16.1558 4.52641 15.3518L8.499 10.9398L12.4716 15.3518C13.1956 16.1558 14.4343 16.2207 15.2383 15.4968C16.0424 14.7728 16.1073 13.5341 15.3833 12.73L11.1352 8.012L15.4049 3.26996C16.1289 2.4659 16.064 1.22719 15.2599 0.503221C14.4559 -0.220752 13.2172 -0.155834 12.4932 0.648219L8.499 5.08423L4.50479 0.648211Z" 
                                                            fill="#FFFEFC"
                                                        />
                                                    </svg>
                                                    Exclude
                                                </RedActionButton>
                                            }
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                            {
							   renderOverlay === true ?
                               neighbourhoodBounds.map((item, index) =>
                                   <Polygon
                                       editable={false}
                                       paths={item}
                                       options={checkNeighbourhoodStyles(index)}
                                       key={index}
                                   />
                               )
                               :
                               null
                            }
                            {
                                renderOverlay === true ?
                                <OverlayViewF
                                    position={{ lat: property.latitude, lng: property.longitude }}
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                >
                                    <div className="comps-analysis-subject-property-map-element relative-container">
                                        <img
                                            src={CoffeeIcon}
                                            className="comps-analysis-coffee-icon"
                                            alt="Coffee"
                                        /> 
                                        <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68" fill="none">
                                            <g filter="url(#filter0_d_4838_55413)">
                                                <path d="M10 13C10 11.3431 11.3431 10 13 10H55C56.6569 10 58 11.3431 58 13V48.4615C58 50.1184 56.6569 51.4615 55 51.4615H42.6422C41.7566 51.4615 40.9162 51.8529 40.3462 52.5307L37.2017 56.2698C36.0029 57.6953 33.8084 57.6953 32.6096 56.2698L29.4651 52.5307C28.8951 51.8529 28.0547 51.4615 27.1691 51.4615H13C11.3431 51.4615 10 50.1184 10 48.4615V13Z" fill="#FFFEFC"/>
                                                <path d="M11 13C11 11.8954 11.8954 11 13 11H55C56.1046 11 57 11.8954 57 13V48.4615C57 49.5661 56.1046 50.4615 55 50.4615H42.6422C41.4614 50.4615 40.3409 50.9833 39.5809 51.887L36.4363 55.6262C35.6371 56.5765 34.1742 56.5765 33.375 55.6262L30.2305 51.887C29.4705 50.9833 28.35 50.4615 27.1691 50.4615H13C11.8954 50.4615 11 49.5661 11 48.4615V13Z" stroke="#512E18" strokeWidth="2"/>
                                            </g>
                                            <defs>
                                                <filter id="filter0_d_4838_55413" x="0" y="0" width="68" height="67.3398" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                                <feOffset/>
                                                <feGaussianBlur stdDeviation="5"/>
                                                <feComposite in2="hardAlpha" operator="out"/>
                                                <feColorMatrix type="matrix" values="0 0 0 0 0.305882 0 0 0 0 0.164706 0 0 0 0 0 0 0 0 0.25 0"/>
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4838_55413"/>
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4838_55413" result="shape"/>
                                                </filter>
                                            </defs>
                                        </svg>
                                    </div>
                                </OverlayViewF>
                                :
                                null
                            }
                            {
                                renderOverlay === true ?
                                filteredComps === null ?
                                null
                                :
                                filteredComps.map((item, index) =>
                                    <OverlayViewF
                                        position={{ lat: item.latitude, lng: item.longitude }}
                                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                        key={index}
                                    >
                                        {
                                            checkExcluded(item.id) === true ?
                                            <div 
                                                className={"comps-analysis-comp-map-excluded-element " + (selectedComp === item.id ? "bg-colour-brown" : "bg-colour-white")}
                                                onClick={() => clickComp(index)}
                                            >
                                                <span className={"body-semibold " + (selectedComp === item.id ? "colour-white" : "colour-brown")}>
                                                    {formatterLong.format(item.price).replace(".00", "")} {checkTimeGap(item)}
                                                </span>
                                            </div>
                                            :
                                            <div 
                                                className={"comps-analysis-comp-map-element " + (selectedComp === item.id ? "bg-colour-brown" : checkViewed(item.id) === true ? "bg-colour-archive-status" : "bg-colour-white")}
                                                onClick={() => clickComp(index)}
                                            >
                                                <span className={"body-semibold " + (selectedComp === item.id ? "colour-white" : "colour-brown")}>
                                                    {formatterLong.format(item.price).replace(".00", "")} {checkTimeGap(item)}
                                                </span>
                                            </div>
                                        }
                                    </OverlayViewF>
                                )
                                :
                                null
                            }
                            {
                                renderOverlay === true ?
                                manualComps.map((item, index) =>
                                    <OverlayViewF
                                        position={{ lat: item.latitude, lng: item.longitude }}
                                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                        key={index}
                                    >
                                        {
                                            checkExcluded(item.id) === true ?
                                            <div 
                                                className={"comps-analysis-comp-map-excluded-element " + (selectedComp === item.id ? "bg-colour-brown" : "bg-colour-white")}
                                                onClick={() => clickManualComp(index)}
                                            >
                                                <span className={"body-semibold " + (selectedComp === item.id ? "colour-white" : "colour-brown")}>
                                                    {formatterLong.format(item.price).replace(".00", "")} {checkTimeGap(item)}
                                                </span>
                                            </div>
                                            :
                                            <div 
                                                className={"comps-analysis-comp-map-element " + (selectedComp === item.id ? "bg-colour-brown" : checkViewed(item.id) === true ? "bg-colour-archive-status" : "bg-colour-white")}
                                                onClick={() => clickManualComp(index)}
                                            >
                                                <span className={"body-semibold " + (selectedComp === item.id ? "colour-white" : "colour-brown")}>
                                                    {formatterLong.format(item.price).replace(".00", "")} {checkTimeGap(item)}
                                                </span>
                                            </div>
                                        }
                                    </OverlayViewF>
                                )
                                :
                                null
                            }
                            {
                                renderOverlay === true ?
                                <div className="comps-analysis-first-custom-filter">
                                    <CompsFilter
                                        title="Max active date"
                                        value={`${maxSoldDate} month${maxSoldDate === 1 ? "" : "s"}`}
                                        changeValue={changeSoldDate}
                                        sliderVal={maxSoldDate}
                                        step={1}
                                        min={1}
                                        max={18}
                                    />
                                </div>
                                :
                                null
                            }
                            {
                                renderOverlay === true ?
                                <div className="comps-analysis-second-custom-filter">
                                    <CompsFilter
                                        title="Max distance"
                                        value={`${maxDistance} mile${maxDistance === 1 ? "" : "s"}`}
                                        changeValue={changeDistance}
                                        sliderVal={maxDistance}
                                        step={0.25}
                                        min={0.25}
                                        max={5}
                                    />
                                </div>
                                :
                                null
                            }
                            {
                                renderOverlay === true && showReset === true ?
                                <div 
                                    className="reset-button-outer-container"
                                    onClick={() => resetFilters()}
                                >
                                    <span className="label-semibold-caps text-link">
                                        Reset
                                    </span>
                                </div>
                                :
                                null
                            }
                            {
                                renderOverlay === true ?
                                <div className={"comps-analysis-all-filters-button " + (numberOfFilters > 0 ? "comps-analysis-all-filters-button-long" : "comps-analysis-all-filters-button-short")}>
                                    <WhiteActionButton
                                        onClick={() => openFiltersModal()}
                                    >
                                        <svg 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            width="17" 
                                            height="16" 
                                            viewBox="0 0 17 16" 
                                            fill="none"
                                            className="comps-analysis-add-comp-icon"
                                        >
                                            <path 
                                                fillRule="evenodd" 
                                                clipRule="evenodd" 
                                                d="M4.05078 0.726562C5.06742 0.726562 5.92166 1.42189 6.16386 2.36293H15.5053C15.8066 2.36293 16.0508 2.60713 16.0508 2.90838C16.0508 3.20963 15.8066 3.45384 15.5053 3.45384H6.16386C5.92166 4.39487 5.06742 5.0902 4.05078 5.0902C3.03414 5.0902 2.17991 4.39487 1.9377 3.45384H0.596236C0.29499 3.45384 0.0507812 3.20963 0.0507812 2.90838C0.0507812 2.60713 0.29499 2.36293 0.596236 2.36293H1.9377C2.17991 1.42189 3.03414 0.726562 4.05078 0.726562ZM5.68715 2.90838C5.68715 3.81212 4.95452 4.54474 4.05078 4.54474C3.14704 4.54474 2.41442 3.81212 2.41442 2.90838C2.41442 2.00464 3.14704 1.27202 4.05078 1.27202C4.95452 1.27202 5.68715 2.00464 5.68715 2.90838Z" 
                                                fill="#292621"
                                            />
                                            <path 
                                                fillRule="evenodd" 
                                                clipRule="evenodd" 
                                                d="M6.2326 10.9084C7.24924 10.9084 8.10348 11.6037 8.34568 12.5447H15.5053C15.8066 12.5447 16.0508 12.789 16.0508 13.0902C16.0508 13.3914 15.8066 13.6357 15.5053 13.6357H8.34568C8.10348 14.5767 7.24924 15.272 6.2326 15.272C5.21596 15.272 4.36172 14.5767 4.11952 13.6357H0.596236C0.29499 13.6357 0.0507812 13.3914 0.0507812 13.0902C0.0507812 12.789 0.29499 12.5447 0.596236 12.5447H4.11952C4.36172 11.6037 5.21596 10.9084 6.2326 10.9084ZM7.86896 13.0902C7.86896 13.9939 7.13634 14.7266 6.2326 14.7266C5.32886 14.7266 4.59624 13.9939 4.59624 13.0902C4.59624 12.1865 5.32886 11.4538 6.2326 11.4538C7.13634 11.4538 7.86896 12.1865 7.86896 13.0902Z" 
                                                fill="#292621"
                                            />
                                            <path 
                                                fillRule="evenodd" 
                                                clipRule="evenodd" 
                                                d="M0.596236 7.45384C0.29499 7.45384 0.0507812 7.69804 0.0507812 7.99929C0.0507812 8.30054 0.29499 8.54474 0.596236 8.54474H9.9377C10.1799 9.48578 11.0341 10.1811 12.0508 10.1811C13.0674 10.1811 13.9217 9.48578 14.1639 8.54474H15.5053C15.8066 8.54474 16.0508 8.30054 16.0508 7.99929C16.0508 7.69804 15.8066 7.45384 15.5053 7.45384H14.1639C13.9217 6.5128 13.0674 5.81747 12.0508 5.81747C11.0341 5.81747 10.1799 6.5128 9.9377 7.45384H0.596236ZM13.6871 7.99929C13.6871 8.90303 12.9545 9.63565 12.0508 9.63565C11.147 9.63565 10.4144 8.90303 10.4144 7.99929C10.4144 7.09555 11.147 6.36293 12.0508 6.36293C12.9545 6.36293 13.6871 7.09555 13.6871 7.99929Z" 
                                                fill="#292621"
                                            />
                                        </svg>
                                        All filters {numberOfFilters > 0 ? `(${numberOfFilters})` : ""}
                                    </WhiteActionButton>
                                </div>
                                :
                                null
                            }
                            {/* {
                                renderOverlay === true ?
                                <div className="comps-analysis-add-comp-button">
                                    <WhiteActionButton
                                        onClick={() => addComp()}
                                    >
                                        <svg 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            width="17" 
                                            height="16" 
                                            viewBox="0 0 17 16" 
                                            fill="none"
                                            className="comps-analysis-add-comp-icon"
                                        >
                                            <path 
                                                d="M7.51547 15.881C7.3172 15.881 7.14868 15.8216 7.00989 15.7026C6.89093 15.5638 6.83145 15.3953 6.83145 15.197V9.01115H0.734796C0.536531 9.01115 0.368006 8.95167 0.22922 8.83271C0.110261 8.69393 0.0507812 8.5254 0.0507812 8.32714V7.37546C0.0507812 7.1772 0.110261 7.01859 0.22922 6.89963C0.368006 6.76084 0.536531 6.69145 0.734796 6.69145H6.83145V0.684015C6.83145 0.48575 6.89093 0.327138 7.00989 0.208179C7.14868 0.0693929 7.3172 0 7.51547 0H8.55636C8.75462 0 8.91324 0.0693929 9.03219 0.208179C9.17098 0.327138 9.24037 0.48575 9.24037 0.684015V6.69145H15.3668C15.565 6.69145 15.7236 6.76084 15.8426 6.89963C15.9814 7.01859 16.0508 7.1772 16.0508 7.37546V8.32714C16.0508 8.5254 15.9814 8.69393 15.8426 8.83271C15.7236 8.95167 15.565 9.01115 15.3668 9.01115H9.24037V15.197C9.24037 15.3953 9.17098 15.5638 9.03219 15.7026C8.91324 15.8216 8.75462 15.881 8.55636 15.881H7.51547Z" 
                                                fill="#2F4858"
                                            />
                                        </svg>
                                        Add comp
                                    </WhiteActionButton>
                                </div>
                                :
                                null
                            } */}
                            {
                                renderOverlay === true ?
                                <MapLegend
                                    hoverMapLegend={hoverMapLegend}
                                    setHoverMapLegend={setHoverMapLegend}
                                    neighbourhoodOption={hasUndefinedNeighborhoodGrade === true ? 1 : null}
                                />
                                :
                                null
                            }
                        </GoogleMap>
                        :
                        null
                    }
                </div>
                <div className="comps-analysis-bottom-bar">
                    <div className="comps-analysis-bottom-inner-bar">
                        <div className="comps-analysis-bottom-left-container">
                            <div 
                                className="text-button-inverse subtext-margin-right-x-large"
                                onClick={() => closeCompsAnalysis()}
                            >
                                <span className="body-regular colour-white">
                                    Cancel
                                </span>
                            </div>
                            <div className="comps-analysis-button-container">
                                <WhiteActionButton
                                    disabled={disabled}
                                    onClick={() => closeCompsAnalysis()}
                                    //onClick={() => amendedValue === valueField || Math.round(amendedValue) === 0 || userId === null || userId === "" ? saveCompChanges() : saveWhichValue()}
                                >
                                    Save & exit
                                </WhiteActionButton>
                            </div>
                        </div>
                        <div className="comps-analysis-bottom-right-container">
                            <div className="comps-analysis-switch-container relative-container">
                                <NewSwitch
                                    firstOption={firstPricingOption}
                                    pricingSwitch={changePricingOption}
                                    textOptions={compOptions}
                                    large={true}
                                />
                            </div>
                            {
                                marketStats.map((item, index) =>
                                    <div 
                                        className="comps-analysis-market-stats-element"
                                        key={index}
                                    >
                                        <span className="label-regular-caps colour-white block-text">
                                            {item.label}
                                        </span>
                                        <span className="heading-large-semibold colour-white block-white">
                                            {item.value}
                                        </span>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default RentCompsAnalysis;