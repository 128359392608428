import React, { useState, useEffect } from 'react';
import '../styles/MyCities.css';
import { Header, Footer, SideNavigation, Loading, Modal, UnassignedCounties, BuyBoxesGrid, DeleteModal, PremiumModal, ExitScreen, MyMarkets, NationwideFSBO } from '../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronRight, Cog } from '../assets';
import { checkOnAuthStateChanged, getUserDocuments, recordEvent, unassignedCountyWrite, deleteBuyBoxFrequency, changeBuyBoxFrequency, changeBuyBoxStrategy, checkBuyBoxUpdates, addRecentMarket, removeRecentMarket } from '../functions';
import { EspressoShots } from '../assets/images';
import { ActionButton } from "../styles/GlobalStyles";

function MyCities() {
    const location = useLocation();
    let state = location.state;
    const navigate = useNavigate();

    const [paidUser, setPaidUser] = useState(false);
    const [freeTrial, setFreeTrial] = useState(false);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);
    const [firstRender, setFirstRender] = useState(false);
    const [unassignedCountyModal, setUnassignedCountyModal] = useState(false);
    const [anyUnassignedCounty, setAnyUnassignedCounty] = useState(false);
    const [buyBoxes, setBuyBoxes] = useState([]);
    const [buyBoxMore, setBuyBoxMore] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteParam, setDeleteParam] = useState(null);
    const [premiumModalOpen, setPremiumModalOpen] = useState(false);
    const [openStrategy, setOpenStrategy] = useState(null);
    const [buyBoxFrequency, setBuyBoxFrequency] = useState(null);
    const [userId, setUserId] = useState(null);
    const [loadingBuyBoxes, setLoadingBuyBoxes] = useState([]);
    const [recentlyViewed, setRecentlyViewed] = useState([]);
    const [marketSettings, setMarketSettings] = useState(null);

    useEffect(() => {
        document.title = "My Markets";
        const fetchUserData = async() => {
            setFirstRender(true);
            const collections = [
                "Users",
                "Subscriptions",
                "Creative Buy Boxes",
                "Recently Viewed"
            ];
            const today = new Date();
            const todaySeconds = today.getTime() / 1000;

            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                setUserId(user.userId);
                const queryUserData = await getUserDocuments(collections, user.userId);
				if ( queryUserData[0].status === 200 ) {
                    const data = queryUserData[0].data;
                    setUserData(data);
                }

                if ( queryUserData[1].status === 200 ) {
                    const oldSubs = queryUserData[1].data;
                    const creativeSubs = [];
                    for (let index = 0; index < oldSubs.length; index++) {
                        const element = oldSubs[index];
                        if ( element.creative === true && element.endDate.seconds > todaySeconds ) {
                            creativeSubs.push(element);
                        }
                    }
                    setSubscriptions(creativeSubs);

                    if ( creativeSubs.length === 0 && queryUserData[0].status === 200 ) {
						const freeTrialData = queryUserData[0].data.freeTrial;
						if ( freeTrialData.length !== 0 ) {
							const freeTrialEndDate = freeTrialData[0].endDate.seconds;
							if ( freeTrialEndDate > todaySeconds ) {
								setFreeTrial(true);
							}
						}
					}
                    
                    for (let index = 0; index < creativeSubs.length; index++) {
                        const element = creativeSubs[index];
                        if ( element.metroArea === true && element.msaCode === "" ) {
                            if ( queryUserData[0].status === 200 ) {
                                const data = queryUserData[0].data;
                                if ( data.creativeUnassignedCounties !== undefined && data.creativeUnassignedCounties[0] === true ) {
                                    setUnassignedCountyModal(true);
                                }
                            }
                            setAnyUnassignedCounty(true);
                        }
                    }

                    const findActiveSubscription = creativeSubs.findIndex(e => e.endDate.seconds > todaySeconds);
                    const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
                    const isAdmin = adminIds.includes(user.userId);
                    if ( findActiveSubscription !== -1 ) {
                        setPaidUser(true);
                    }
                    else if ( isAdmin === true ) {
                        setPaidUser(true);
                    }

                    setLoading(false);
                }
                else {
                    const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
                    const isAdmin = adminIds.includes(user.userId);
                    if ( isAdmin === true ) {
                        setPaidUser(true);
                    }
                    else if ( queryUserData[0].status === 200 ) {
						const freeTrialData = queryUserData[0].data.freeTrial;
						if ( freeTrialData.length !== 0 ) {
							const freeTrialEndDate = freeTrialData[0].endDate.seconds;
							if ( freeTrialEndDate > todaySeconds ) {
								setFreeTrial(true);
							}
						}
					}
                }

                if ( queryUserData[2].status === 200 ) {
                    setBuyBoxes(queryUserData[2].data);

                    // Check how many new properties have been uploaded to the buy box
                    const checkSubscriptions = queryUserData[1].status === 200 ? queryUserData[1].data : [];
                    const buyBoxUpdates = await checkBuyBoxUpdates(queryUserData[2].data, user.userId, checkSubscriptions, setLoadingBuyBoxes);
                    if ( buyBoxUpdates.changes === true ) {
                        setBuyBoxes(buyBoxUpdates.data);
                    }
                }

                if ( queryUserData[3].status === 200 ) {
                    setRecentlyViewed(queryUserData[3].data);
                }
                else if ( queryUserData[1].status === 200 ) {
                    const subscriptionData = queryUserData[1].data;
                    const newRecentlyViewed = [];
                    for (let index = 0; index < subscriptionData.length; index++) {
                        const element = subscriptionData[index];
                        if ( element.msaCode === "" ) {
                            continue;
                        }
                        const newElement = {
                            msaCode: element.msaCode,
                            msaTitle: element.msaTitle,
                            icon: ""
                        };
                        newRecentlyViewed.push(newElement);
                    }
                    setRecentlyViewed(newRecentlyViewed);
                }

                setLoading(false);
            }
            else {
                setLoading(false);
            }
        };

        if ( firstRender === false ) {
            fetchUserData();
        }
    }, [firstRender, userId]);

    const selectMobilePanel = (index) => {
        recordEvent("Mobile Select City", {
            city: recentlyViewed[index].label
        });
        window.open(`/properties-list/${recentlyViewed[index].value}`, "_blank");
    };

    const manageSubscriptions = () => {
        navigate("/profile?tab=1", {
            state: state
        });
    };

    const goToSampleMarket = () => {
        window.open("/sample-market", "_blank");
    };

    const closeUnassignedCountyModal = async() => {
		setUnassignedCountyModal(false);

        const currentUnassignedCounties = userData.creativeUnassignedCounties;
		if ( currentUnassignedCounties !== undefined ) {
			currentUnassignedCounties[0] = false;
			if ( userId !== null ) {
				await unassignedCountyWrite(userId, currentUnassignedCounties);
			}
		}
	};

	const goToUnassignedCounty = async() => {
		let unassignedCounties = 0;
		for (let index = 0; index < subscriptions.length; index++) {
			const element = subscriptions[index];
			if ( element.metroArea === true && element.msaCode === "" ) {
				unassignedCounties++;
			}
		}

        const currentUnassignedCounties = userData.creativeUnassignedCounties;
		if ( currentUnassignedCounties !== undefined ) {
			currentUnassignedCounties[0] = false;
			if ( userId !== null ) {
				await unassignedCountyWrite(userId, currentUnassignedCounties);
			}
		}

		navigate("/choose-counties", {
			state: {
				counties: unassignedCounties
			}
		});
	};

    const goToChooseCounties = () => {
        let unassignedCounties = 0;
		for (let index = 0; index < subscriptions.length; index++) {
			const element = subscriptions[index];
			if ( element.metroArea === true && element.msaCode === "" ) {
				unassignedCounties++;
			}
		}

        navigate("/choose-counties", {
			state: {
				counties: unassignedCounties
			}
		});
    };

    const openUpgradeModal = () => {
        setPremiumModalOpen(true);
    };

    const onChangeBuyBoxFrequency = async(frequency, id, item) => {
        setOpenStrategy(null);
        setLoading(true);
        const buyBoxChange = await changeBuyBoxFrequency(item, frequency, id);
        if ( buyBoxChange.status === 200 ) {
            setBuyBoxes(buyBoxChange.data);
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    };

    const triggerExit = () => {
        setBuyBoxFrequency(null);
        setBuyBoxMore(null);
        setOpenStrategy(null);
        setMarketSettings(null);
    };

    const deleteBuyBox = async(item) => {
        setDeleteModal(false);
        setLoading(true);
        const deletion = await deleteBuyBoxFrequency(item);
        if ( deletion.status === 200 ) {
            setBuyBoxes(deletion.data);
        }
        setLoading(false);
        setBuyBoxMore(null);

        const eventData = {};
        eventData.msaTitle = item.msaTitle;
        eventData.msaCode = item.msaCode;
        recordEvent("Delete Buy Box", eventData);
        setDeleteParam(null);
    };

    const onChangeBuyBoxStrategy = async(val, buyBoxItem) => {
        setLoading(true);
        const buyBoxChange = await changeBuyBoxStrategy(buyBoxItem, val);
        if ( buyBoxChange.status === 200 ) {
            setBuyBoxes(buyBoxChange.data);
            setLoading(false);
        }
        else if ( buyBoxChange.status === 400 ) {
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    };

    const editBuyBox = (item) => {
        if ( state === null ) {
            state = {
                userData: userData,
                buyBox: item,
            }
        }
        else {
            state.userData = userData;
            state.buyBox = item;
        }

        if ( item.metroArea === true ) {
            const msaCode = item.msaCode;
            const cityIndex = buyBoxes.findIndex(e => e.msaCode === msaCode);
            if ( cityIndex !== -1 ) {
                state.buyBoxCity = buyBoxes[cityIndex];
            }
        }
        else {
            delete state.buyBoxCity;
        }

        recordEvent("Edit Buy Box", {
            item: item
        });
        navigate("/edit-buy-box", {
            state: state
        });
    };

    const viewMarket = async(item) => {
        window.open(`/properties-list/${item.msaCode}`, "_blank");

        if ( userId !== null ) {
            await addRecentMarket(item, userId, setRecentlyViewed);
        }
    };

    const removeViewedMarket = async(item) => {
        if ( userId !== null ) {
            await removeRecentMarket(item, userId, setRecentlyViewed);
        }
    };

    return (
        <div className="my-cities-outer-container relative-container">
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
                mobileNav={false}
            />
            {
                premiumModalOpen === true || deleteModal === true || unassignedCountyModal === true ? 
                <div className="my-cities-modal-background">
                </div>
                :
                null
            }
            {
                (deleteModal === false) && (buyBoxFrequency !== null || buyBoxMore !== null || openStrategy !== null || marketSettings !== null) ?
                <ExitScreen
                    clickScreen={() => triggerExit()}
                />
                :
                null
            }
            {
                deleteModal === true ?
                <DeleteModal
                    deleteFunction={deleteBuyBox}
                    setDeleteModal={setDeleteModal}
                    description="Are you sure you want to delete this buy box?"
                    deleteParam={deleteParam}
                />
                :
                null
            }
            {
                premiumModalOpen === true ?
                <PremiumModal
                    setPremiumModalOpen={setPremiumModalOpen}
                    state={null}
                />
                :
                null
            }
            <div className="my-cities-inner-container relative-container">
                <SideNavigation
                    navigate={navigate}
                    state={state}
                    userData={userData}
                />
                <div className="my-cities-body-container">
                    <div className="my-cities-inner-body-container">
                        {
                            unassignedCountyModal === true ?
                            <Modal
                                title="You have counties that are unassigned"
                                description="You have counties that you bought which need to be assigned to your account!"
                                closeButtonText="Close"
                                saveButtonText="Choose counties"
                                closeModal={closeUnassignedCountyModal}
                                saveModal={goToUnassignedCounty}
                            />
                            :
                            null
                        }
                        {
                            loading === true ?
                            <div className="my-cities-loading-container">
                                <Loading />
                            </div>
                            :
                            null
                        }
                        <div className="my-cities-sub-body-container margin-x-large">
                            {
                                loading === true ?
                                null
                                :
                                paidUser === false && loading === false && freeTrial === false ?
                                <div className="my-cities-zero-state-container">
                                    <img
                                        src={EspressoShots}
                                        className="my-cities-espresso-shots margin-large"
                                        alt="Espresso Shots"
                                    />
                                    <h2 className="heading-small-semibold colour-primary text-align-center margin-x-small">
                                        Check out our sample market
                                    </h2>
                                    <span className="body-regular colour-secondary block-text text-align-center margin-large">
                                        Browse our sample market to see how everything works 😊
                                    </span>
                                    <div className="my-cities-zero-state-button-container">
                                        <ActionButton
                                            onClick={() => goToSampleMarket()}
                                        >
                                            View sample market
                                        </ActionButton>
                                    </div>
                                </div>
                                :
                                <div className="my-cities-top-row">
                                    <MyMarkets
                                        anyUnassignedCounty={anyUnassignedCounty}
                                        goToChooseCounties={goToChooseCounties}
                                        recentlyViewed={recentlyViewed}
                                        viewMarket={viewMarket}
                                        marketSettings={marketSettings}
                                        setMarketSettings={setMarketSettings}
                                        removeViewedMarket={removeViewedMarket}
                                    />
                                    <NationwideFSBO />
                                </div>
                            }
                        </div>
                        <BuyBoxesGrid
                            buyBoxes={buyBoxes}
                            buyBoxMore={buyBoxMore}
                            setBuyBoxMore={setBuyBoxMore}
                            buyBoxFrequency={buyBoxFrequency}
                            setBuyBoxFrequency={setBuyBoxFrequency}
                            onChangeBuyBoxFrequency={onChangeBuyBoxFrequency}
                            openStrategy={openStrategy}
                            setOpenStrategy={setOpenStrategy}
                            deleteBuyBox={deleteBuyBox}
                            editBuyBox={editBuyBox}
                            setDeleteParam={setDeleteParam}
                            subscriptions={subscriptions}
                            userId={userId}
                            onChangeBuyBoxStrategy={onChangeBuyBoxStrategy}
                            loadingBuyBoxes={loadingBuyBoxes}
                            setRecentlyViewed={setRecentlyViewed}
                            freeTrial={freeTrial}
                            openUpgradeModal={openUpgradeModal}
                        />
                    </div>
                    <div className="my-cities-mobile-body-container relative-container">
                        {
                            loading === true ?
                            <div className="my-cities-loading-container">
                                <Loading />
                            </div>
                            :
                            null
                        }
                        {
                            unassignedCountyModal === true ?
                            <Modal
                                title="You have counties that are unassigned"
                                description="You have counties that you bought which need to be assigned to your account!"
                                closeButtonText="Close"
                                saveButtonText="Choose counties"
                                closeModal={closeUnassignedCountyModal}
                                saveModal={goToUnassignedCounty}
                            />
                            :
                            null
                        }
                        <div className="margin-large">
                            <h1 className="heading-large-semibold colour-primary margin-x-small">
                                My markets
                            </h1>
                            <h2 className="body-regular colour-secondary">
                                The home for your cities and buy boxes.
                            </h2>
                            {
                                anyUnassignedCounty === true ?
                                <UnassignedCounties
                                    cta={goToChooseCounties}
                                />
                                :
                                null
                            }
                        </div>
                        <div className="my-cities-light-divider-line">
                        </div>
                        <div className="my-cities-mobile-options-container margin-x-x-x-large">
                            <div className="my-cities-mobile-option-element">
                                <div 
                                    className="button-row my-cities-mobile-option-padding"
                                    onClick={() => manageSubscriptions()}
                                >
                                    <img
                                        src={Cog}
                                        className="my-cities-mobile-option-cog"
                                        alt="Manage subscriptions"
                                    />
                                    <span className="body-regular colour-link block-text subtext-margin-right">
                                        Manage subscriptions
                                    </span>
                                    <img
                                        src={ChevronRight}
                                        className="my-cities-mobile-option-arrow"
                                        alt="Manage subscriptions"
                                    />
                                </div>
                            </div>
                            {
                                paidUser === false && loading === false && freeTrial === false ?
                                <div className="my-cities-zero-state-container">
                                    <img
                                        src={EspressoShots}
                                        className="my-cities-espresso-shots margin-large"
                                        alt="Espresso Shots"
                                    />
                                    <h2 className="heading-small-semibold colour-primary text-align-center margin-x-small">
                                        Check out our sample market
                                    </h2>
                                    <span className="body-regular colour-secondary block-text text-align-center margin-large">
                                        Browse our sample market to see how everything works 😊
                                    </span>
                                    <div className="my-cities-zero-state-button-container">
                                        <ActionButton
                                            onClick={() => goToSampleMarket()}
                                        >
                                            Explore sample market
                                        </ActionButton>
                                    </div>
                                </div>
                                :
                                recentlyViewed.map((item, index) =>
                                    <div 
                                        className="my-cities-mobile-option-element"
                                        key={index}
                                        onClick={() => selectMobilePanel(index)}
                                    >
                                        <div className="button-row my-cities-mobile-option-padding">
                                            <span className="body-regular colour-link block-text subtext-margin-right">
                                                {item.msaTitle}
                                            </span>
                                            <img
                                                src={ChevronRight}
                                                className="my-cities-mobile-option-arrow"
                                                alt={item.msaTitle}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default MyCities;