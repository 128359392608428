import React from 'react';
import '../styles/MobileBottomBar.css';
import { formatterLong, percentage } from '../styles/GlobalStyles';

function MobileBottomBar(props) {

    const highLevelProfits = props.highLevelProfits;
    const toggleMobileBottomBar = props.toggleMobileBottomBar;
    const mobileBar = props.mobileBar;

    return (
        mobileBar === false ?
        <div
            className="mobile-bottom-bar-outer-container"
            onClick={() => toggleMobileBottomBar()}
        >
            <div className="mobile-bottom-bar-toggle-icon">
            </div>
            <div className="mobile-bottom-bar-inner-container">
                <div className="mobile-bottom-bar-element-container">
                    <span className="xs-label colour-secondary block-text margin-x-small">
                        Up-front costs
                    </span>
                    <h2 className="body-semibold colour-error">
                        {formatterLong.format(highLevelProfits.totalCosts).replace(".00", "")}
                    </h2>
                </div>
                <div className="mobile-bottom-bar-element-container">
                    <span className="xs-label colour-secondary block-text margin-x-small">
                        Cash-Flow
                    </span>
                    <h2 className={"body-semibold " + (highLevelProfits.cashOnCash > 0.05 ? "colour-green" : highLevelProfits.cashOnCash < 0 ? "colour-error" : "colour-medium")}>
                        {formatterLong.format(Math.round(highLevelProfits.monthlyProfit)).replace(".00", "")}
                    </h2>
                </div>
                <div className="mobile-bottom-bar-element-container">
                    <span className="xs-label colour-secondary block-text margin-x-small">
                        Cash on Cash
                    </span>
                    <h2 className={"body-semibold " + (highLevelProfits.cashOnCash > 0.05 ? "colour-green" : highLevelProfits.cashOnCash < 0 ? "colour-error" : "colour-medium")}>
                        {percentage.format(highLevelProfits.cashOnCash)}
                    </h2>
                </div>
            </div>
        </div>
        :
        <div className="mobile-bottom-bar-outer-container-new">
            <div className="mobile-bottom-bar-inner-container-column">
                <span className="body-regular colour-secondary block-text text-align-center margin-x-small">
                    View this property on desktop to edit values
                </span>
            </div>
        </div>
    )
}

export default MobileBottomBar;