import React, { useState } from 'react';
import '../styles/ApplyEstimate.css';
import { FinancialsInputNoLabel, formatterLong, numberFormatter, CustomRadio, ActionButton, WhiteActionButton } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

function ApplyEstimate(props) {
    const amendedValue = props.amendedValue;
    const valueField = props.valueField;
    const closeModal = props.closeModal;
    const saveCompChanges = props.saveCompChanges;
    const saveNewValue = props.saveNewValue;
    const rentMode = props.rentMode;
    const [estimate, setEstimate] = useState(`${Math.round(amendedValue)}`);
    const [valueType, setValueType] = useState(rentMode === true ? "rent" : "");

    const radios = [
        {
            label: "ARV",
            value: "arv"
        },
        {
            label: "Offer price",
            value: "offer-price"
        }
    ];

    const onChangeEstimate = (text) => {
        const removedCommas = text.replaceAll(",", "");
        setEstimate(removedCommas);
    };

    const formatEstimate = (val) => {
        if ( val === "" ) {
            return val;
        }
        else {
            const removedCommas = val.replaceAll(",", "");
            const numberVal = Number(removedCommas);
            const formattedVal = numberFormatter.format(numberVal);
            return formattedVal;
        }
    };

    const changeRadio = (val) => {
        setValueType(val);
    };

    const keepOriginal = () => {
        saveCompChanges();
    };

    return (
        <div className={"apply-estimate-modal-outer-container bg-colour-light " + (rentMode === true ? "apply-estimate-modal-rent-container" : "")}>
            <div className="apply-estimate-inner-container relative-container">
                <div className="apply-estimate-exit-container cursor-pointer">
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="23" 
                        height="24" 
                        viewBox="0 0 23 24" 
                        fill="none"
                        className="apply-estimate-exit-icon"
                        onClick={() => closeModal()}
                    >
                        <path 
                            fillRule="evenodd" 
                            clipRule="evenodd" 
                            d="M2.75829 0.419021C2.2903 -0.100741 1.48956 -0.142706 0.969802 0.32529C0.45004 0.793286 0.408076 1.59402 0.876071 2.11378L9.7968 12.0213L0.914356 21.8862C0.446361 22.406 0.488325 23.2067 1.00809 23.6747C1.52785 24.1427 2.32858 24.1007 2.79658 23.581L11.5009 13.9138L20.2052 23.5809C20.6732 24.1007 21.4739 24.1427 21.9937 23.6747C22.5134 23.2067 22.5554 22.4059 22.0874 21.8862L13.205 12.0213L22.1257 2.11382C22.5937 1.59406 22.5517 0.793322 22.032 0.325326C21.5122 -0.142669 20.7115 -0.100704 20.2435 0.419057L11.5009 10.1287L2.75829 0.419021Z" 
                            fill="#2F4858"
                        />
                    </svg>
                </div>
                <div className="apply-estimate-title-container margin-x-large">
                    <h1 className="heading-large-semibold colour-primary margin-medium">
                        Apply new estimate?
                    </h1>
                    <span className="body-regular colour-secondary">
                        Do you want to save the new estimate based on comps (this will re-calculate property ROI) 
                        or use the original Coffee Clozers estimate?
                    </span>
                </div>
                <div className="apply-estimate-body-container">
                    <span className="body-semibold colour-primary block-text margin-x-small">
                        New {rentMode === true ? "rent" : ""} estimate
                    </span>
                    <div className="apply-estimate-input-container margin-x-small">
                        <FinancialsInputNoLabel
                            value={formatEstimate(estimate)}
                            type="text"
                            id={`new-estimate-input`}
                            placeholder={formatterLong.format(valueField).replace(".00", "")}
                            onWheel={(e) => e.target.blur()}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled={false}
                            onChange={(text) => onChangeEstimate(text.target.value)}
                        />
                    </div>
                    <div className="apply-estimate-was-container">
                        <span className="body-regular colour-quaternary text-italic">
                            original was {formatterLong.format(valueField).replace(".00", "")}
                        </span>
                    </div>
                    {
                        rentMode === false ?
                        <div className="apply-estimate-save-as-container margin-x-x-large">
                            <span className="body-semibold colour-primary block-text margin-x-small">
                                Save as
                            </span>
                            <div className="apply-estimate-radios-container">
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="payment-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        value={valueType}
                                        className=""
                                        onChange={(val) => changeRadio(val.target.value)}
                                    >
                                        {
                                            radios.map((item, index) =>
                                                <FormControlLabel
                                                    value={item.value} 
                                                    control={<CustomRadio />} 
                                                    className=""
                                                    label={<span className="body-regular colour-primary">{item.label}</span>}
                                                    key={index}
                                                />
                                            )
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        :
                        null
                    }
                    <div className="apply-estimate-buttons-container">
                        <div className="apply-estimate-original-button-container">
                            <WhiteActionButton
                                onClick={() => keepOriginal()}
                            >
                                Keep original estimate
                            </WhiteActionButton>
                        </div>
                        <div className="apply-estimate-save-button-container">
                            <ActionButton
                                disabled={valueType === "" || estimate === "" ? true : false}
                                onClick={() => saveNewValue(estimate, valueType)}
                            >
                                Save & apply
                            </ActionButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ApplyEstimate;