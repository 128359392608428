import React, { useState } from 'react';
import '../styles/MiscellaneousFilters.css';
import { colour, CustomRadio, CustomSelect } from '../styles/GlobalStyles';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { EditNeighbourhoodGrade } from './';

function MiscellaneousFilters(props) {
    const fsbo = props.fsbo;
    const changeFSBOFilter = props.changeFSBOFilter;
    const inherited = props.inherited;
    const setInherited = props.setInherited;
    const neighbourhoodGradeMin = props.neighbourhoodGradeMin;
    const setNeighbourhoodGradeMin = props.setNeighbourhoodGradeMin;
    const neighbourhoodGradeMax = props.neighbourhoodGradeMax;
    const setNeighbourhoodGradeMax = props.setNeighbourhoodGradeMax;
    const [selectOpen, setSelectOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(inherited === true ? ["Inherited"] : []);

    const options = [
        {
            label: "Inherited",
            value: inherited
        }
    ];

    const optionsSelected = (val) => {
		const index = selectedOptions.indexOf(val);
		if ( index === -1 ) {
			return false;
		}
		else {
			return true;
		}
	};

    const listingTypeRadios = [
        {
            label: "Agent listings & For sale by owner",
            value: false
        },
        {
            label: "Only FSBO",
            value: true
        }
    ];

    const changeDistressSignals = (vals) => {
        const newArray = [...selectedOptions];
		const index = newArray.indexOf(vals.label);
		if ( index === -1 ) {
			newArray.push(vals.label);
		}
		else {
			newArray.splice(index, 1);
		}

        const inheritedIndex = newArray.indexOf("Inherited");
        if ( inheritedIndex !== -1 ) {
            setInherited(true);
        }
        else {
            setInherited(false);
        }
		setSelectedOptions(newArray);
    };

    const changeNeighbourhoodGrade = (val, type) => {
        if ( type === 0 ) {
            setNeighbourhoodGradeMin(val);    
        }
        else {
            setNeighbourhoodGradeMax(val);
        }
    };

    const styles = {
        menuItem: {
            backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
        }
    }

    return (
        <div className="miscellaneous-filters-outer-container">
            <div className="miscellaneous-filters-inner-container">
                <div className="miscellaneous-filters-neighbourhood-grade-container">
                    <EditNeighbourhoodGrade
                        neighbourhoodGradeMin={neighbourhoodGradeMin}
                        neighbourhoodGradeMax={neighbourhoodGradeMax}
                        changeNeighbourhoodGrade={changeNeighbourhoodGrade}
                        filtersModal={true}
                    />
                </div>
                <div className="choose-value-add-opportunity-column margin-x-large">
                    <span className="body-semibold colour-primary block-text margin-x-small">
                        Distress signals
                    </span>
                    <Box>
						<FormControl fullWidth>
							<Select
								labelId="distress-signals-select-label"
								id="distress-signals-select"
								value={selectedOptions.length === 0 ? ["Distress signals"] : selectedOptions}
								label=""
								renderValue={(selected) => selected.join(", ")}
								input={<CustomSelect />}
								multiple
								open={selectOpen}
								onOpen={() => setSelectOpen(true)}
								onClose={() => setSelectOpen(false)}
								style={{ color: selectedOptions.length === 0 ? colour.textTertiary : colour.textPrimary }}
							> 
								{
									options.map((item, index) =>
										<MenuItem 
											key={index}
											value={item.label}
											style={styles.menuItem}
											onClick={() => changeDistressSignals(item)}
										>
											<div className="sign-up-component-how-did-you-hear-row">
												<div className="checkbox-container">
													{
														optionsSelected(item.label) === true ?
														<div className="checkbox-icon">
														</div>
														:
														null
													}
												</div>
												<span className="body-regular colour-primary">
													{item.label}
												</span>
											</div>
										</MenuItem>	
									)
								}
							</Select>
						</FormControl>
					</Box>   
                </div>
                <div className="choose-value-add-opportunity-column">
                    <div className="">
                        <FormControl className="new-filters-modal-radio-outer-container">
                            <RadioGroup
                                aria-labelledby="fixer-upper-buttons-group-label"
                                name="radio-buttons-group"
                                value={fsbo}
                                className="choose-value-add-opportunity-property-condition-radios"
                                onChange={(val) => changeFSBOFilter(val.target.value)}
                            >
                                {
                                    listingTypeRadios.map((item, index) =>
                                        <FormControlLabel 
                                            value={item.value} 
                                            control={<CustomRadio />}
                                            className=""
                                            label={<span className="body-regular colour-primary">{item.label}</span>}
                                            key={index}
                                        />
                                    )
                                }
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MiscellaneousFilters;