import React from 'react';
import { Carl } from '../assets';

function FSBOBadge() {
    return (
        <div className="fixer-upper-badge-outer-container">
			<img
				src={Carl}
				className="fixer-upper-badge-icon"
				alt="FSBO"
			/>
			<span className="top-pick-badge-text">
				FSBO
			</span>
		</div>
    )
}

export default FSBOBadge;