import React, { useState, useRef } from 'react';
import { FinancialsInput, IOSSwitch, ActionButton } from '../styles/GlobalStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputAdornment from '@mui/material/InputAdornment';
import { Loading } from './';
import { checkUserId, setData, defaultInitialCosts } from '../functions';

function FinancialsSettings(props) {
    const disabled = props.disabled;
    const setDisabled = props.setDisabled;
    const userDataSettings = props.userSettings;
    const [initialCosts, setInitialCosts] = useState(userDataSettings !== undefined ? userDataSettings.initialCosts : defaultInitialCosts);
    const [loading, setLoading] = useState(false);
    const [initialCostsEmptyError, setInitialCostsEmptyError] = useState(null);
    const [cityChanges, setCityChanges] = useState(false);
    const initialCostError = useRef(null);
    const setSelectedPanel = props.setSelectedPanel;

    const onChangeInitialCostsSwitch = (val, index) => {
        const clone = initialCosts.slice(0);
        clone[index].default = val;
        clone[index].value = "";
        setInitialCosts(clone);
        setDisabled(false);
    }

    const onChangeInitialCosts = (value, index) => {
        const clone = initialCosts.slice(0);
        const amendedValue = value.replaceAll(/\s/g,'');
        const valueNumber = Number(amendedValue);
        if ( valueNumber > 100 ) {
            return;
        }
        else {
            clone[index].value = amendedValue;
            clone[index].default = amendedValue === "" ? true : false;
            setInitialCosts(clone);
            setDisabled(false);
        }
    };

    const checkButtonDisabled = () => {
        const emptyArray = [];
        if ( emptyArray.length === 0 && cityChanges === true ) {
            return false;
        }
        else if ( cityChanges === true ) {
            return false;
        }
        else {
            return disabled;
        }
    };

    const saveChanges = async() => {
        setLoading(true);
        setDisabled(true);

        const user = await checkUserId();
        if ( user.status === 200 ) {
            const userId = user.userId;
            const colRef = "Settings | Coffee Clozers";

            const initialNoDefaultEmptyValIndex = initialCosts.findIndex(e => e.default === false && e.value === "");
            if ( initialNoDefaultEmptyValIndex === -1 ) {
                if ( initialCosts[0].value === "" ) {
                    initialCosts[0].value = "20";
                }
                const newSettings = {
                    sellerFinance: {
                        initialCosts: initialCosts
                    }
                };
                setInitialCostsEmptyError(null);

                await setData(colRef, userId, newSettings);
                setLoading(false);
                setDisabled(true);
                setCityChanges(false);
            }
            else {
                if ( initialNoDefaultEmptyValIndex !== -1 ) {
                    setInitialCostsEmptyError(initialNoDefaultEmptyValIndex);

                    setTimeout(() => {
                        const elementPosition = initialCostError.current.getBoundingClientRect();
                        window.scrollTo({ top: elementPosition.top - 300 + window.scrollY, behavior: 'smooth' });
                    }, 500);
                }

                setSelectedPanel(0);
                setLoading(false);
                return;
            }
        }
        else {
            setLoading(false);
            setDisabled(false);
        }
    };

    return (
        <div className="financial-preferences-body-container margin-top-x-large">
            <div className="financial-preferences-sticky-save-container sticky-save-button-in-view">
                <ActionButton
                    disabled={
                        loading === true ? true 
                        : 
                        disabled === true ? true
                        :
                        checkButtonDisabled() === true ? true
                        :
                        false
                    }
                    onClick={() => saveChanges()}
                >
                    Save
                </ActionButton>
            </div>
            <div className="financial-preferences-all-cities-container margin-x-x-large">
                <div className="profile-account-information-container financial-preferences-subheading-negative-margin">
                    <div className="financial-preferences-loading-container">
                        {
                            loading === true ?
                            <Loading />
                            :
                            null
                        }
                    </div>
                    <div className="financial-settings-inner-width-container">
                        <div className="financial-preferences-subheading-container-row margin-medium">
                            <div className="profile-subheading-inner-title-container">
                                <h2 className="heading-small-semibold colour-quaternary">
                                    Initial costs
                                </h2>
                            </div>
                        </div>
                        <div className="financial-settings-input-settings-container">
                            {
                                initialCosts.map((item, index) =>
                                    <div 
                                        className={"profile-account-settings-financials-input relative-container " + (item.percentage === true ? "financials-panel-percentage-container" : "")}
                                        key={index}
                                    >
                                        <div className="relative-container">
                                            <div className="profile-account-settings-costs-input-row">
                                                <span className="body-semibold colour-primary margin-medium block-text">
                                                    {item.label}
                                                </span>
                                                {
                                                    index > 0 ?
                                                    <div className="profile-account-switch-container margin-medium">
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={<IOSSwitch disabled={loading} checked={item.default} onChange={(e) => onChangeInitialCostsSwitch(e.target.checked, index)} sx={{ m: 1, marginRight: '0px' }} />}
                                                            />
                                                        </FormGroup>
                                                        <span className="body-regular colour-primary">
                                                            Use default
                                                        </span>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <div className="profile-account-settings-costs-input-row">
                                                <div className="financials-input-long-width">
                                                    <FinancialsInput
                                                        value={item.value}
                                                        type="number"
                                                        // label={item.label}
                                                        id={`${item.label}-input`}
                                                        onWheel={(e) => e.target.blur()}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment className="financials-panel-active-option" position="end">{index === 2 ? "years" : "%"}</InputAdornment>,
                                                        }}
                                                        placeholder={item.placeholder}
                                                        onChange={(text) => onChangeInitialCosts(text.target.value, index)}
                                                        disabled={false}
                                                    />
                                                    {
                                                        initialCostsEmptyError === index ?
                                                        <span 
                                                            className="body-regular colour-error block-text margin-top-small"
                                                            ref={initialCostError}
                                                        >
                                                            This field can't be empty if you're not using the default value
                                                        </span>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                <div className="profile-account-settings-default-cost-explanation">
                                                    <span className="label-regular colour-secondary">
                                                        {item.info}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="profile-mobile-sticky-save-button">
                <div className="profile-mobile-sticky-inner-container">
                    <ActionButton
                        disabled={
                            loading === true ? true 
                            : 
                            disabled === true ? true
                            :
                            checkButtonDisabled() === true ? true
                            :
                            false
                        }
                        onClick={() => saveChanges()}
                    >
                        Save
                    </ActionButton>
                </div>
            </div>
        </div>
    )
}

export default FinancialsSettings;