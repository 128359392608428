import React from 'react';
import '../styles/BuyBoxesGrid.css';
import { BuyBoxCard } from './';
import { PrimaryButton } from '../buttons';
import { useNavigate } from 'react-router-dom';
import { PlusWhite } from '../assets';

function BuyBoxesGrid(props) {
    const buyBoxes = props.buyBoxes;
    const buyBoxMore = props.buyBoxMore;
    const setBuyBoxMore = props.setBuyBoxMore;
    const buyBoxFrequency = props.buyBoxFrequency;
    const setBuyBoxFrequency = props.setBuyBoxFrequency;
    const onChangeBuyBoxFrequency = props.onChangeBuyBoxFrequency;
    const onChangeBuyBoxStrategy = props.onChangeBuyBoxStrategy;
    const openUpgradeModal = props.openUpgradeModal;
    const openStrategy = props.openStrategy;
    const setOpenStrategy = props.setOpenStrategy;
    const deleteBuyBox = props.deleteBuyBox;
    const editBuyBox = props.editBuyBox;
    const setDeleteParam = props.setDeleteParam;
    const subscriptions = props.subscriptions;
    const userId = props.userId;
    const loadingBuyBoxes = props.loadingBuyBoxes;
    const setRecentlyViewed = props.setRecentlyViewed;
    const freeTrial = props.freeTrial;
    const navigate = useNavigate();

    const newBuyBox = () => {
        if ( userId === null ) {
            navigate("/sign-up");
        }
        else {
            navigate("/buy-box-setup");
        }
    };

    return (
        <div className="buy-boxes-grid-outer-container">
            <div className="buy-boxes-grid-top-container">
                <div className="buy-boxes-grid-title-container">
                    <div className="">
                        <h3 className="heading-small-semibold colour-primary">
                            Buy Boxes
                        </h3>
                        <span className="body-regular colour-secondary">
                            For getting notified when deals come on market
                        </span>
                    </div>
                    <div className="">
                        <PrimaryButton
                            text="New Buy Box"
                            cta={newBuyBox}
                            disabled={false}
                            size="small"
                            leftIcon={PlusWhite}
                            leftIconClass="buy-box-grid-plus-icon"
                            rightIcon={null}
                            alt="Plus icon"
                        />
                    </div>
                </div>
                <div className="buy-boxes-grid-divider-line margin-tops">
                </div>
            </div>
            <div className="my-cities-grid">
                {
                    buyBoxes.map((city, i) =>
                        city.buyBoxes.map((item, index) =>
                        <BuyBoxCard
                            item={item}
                            index={index}
                            key={index}
                            buyBoxMore={buyBoxMore === item.id ? true : false}
                            setBuyBoxMore={setBuyBoxMore}
                            buyBoxFrequency={buyBoxFrequency === item.id ? true : false}
                            setBuyBoxFrequency={setBuyBoxFrequency}
                            onChangeBuyBoxFrequency={onChangeBuyBoxFrequency}
                            editBuyBox={editBuyBox}
                            deleteBuyBox={deleteBuyBox}
                            setDeleteParam={setDeleteParam}
                            openUpgradeModal={openUpgradeModal}
                            openStrategy={openStrategy === item.id ? true : false}
                            setOpenStrategy={setOpenStrategy}
                            onChangeBuyBoxStrategy={onChangeBuyBoxStrategy}
                            city={city}
                            subscriptions={subscriptions}
                            userId={userId}
                            loadingBuyBoxes={loadingBuyBoxes}
                            setRecentlyViewed={setRecentlyViewed}
                            freeTrial={freeTrial}
                        />
                        )
                    )
                }
            </div>
        </div>
    );
};

export default BuyBoxesGrid;