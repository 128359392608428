import React from 'react';
import '../styles/RecommendedTab.css';

function RecommendedTab(props) {
    return (
        <div className="recommended-tab-container bg-colour-sold-status">
            <div className="recommended-tab-inner-container">
                <span className="label-semibold-caps colour-primary">
                    RECOMMENDED
                </span>
            </div>
        </div>
    )
};

export default RecommendedTab;