import React, { useEffect, useState } from 'react';
import { Header, Footer, StripeForm, AccountSettings, PaymentSettings, Loading, SideNavigation, Chip, Modal, UnassignedCounties } from '../components';
import '../styles/Profile.css';
import { getDocument, checkOnAuthStateChanged, getSetupIntent, logout, checkUserId, createStripeSetupIntent, getStripeCustomer, setData, deleteStripePaymentMethod, cancelStripeSubscription, recordEvent, getUserDocuments, unassignedCountyWrite } from '../functions';
import { useNavigate, useLocation } from 'react-router-dom';
import { WhiteActionButton } from '../styles/GlobalStyles';
import { useSearchParams } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from '../assets';

function Profile() {

	const navigate = useNavigate();
	const [userData, setUserData] = useState("");
	const [activeSubscription, setActiveSubscription] = useState(false);
	const [subscriptions, setSubscriptions] = useState([]);
	const [disabled, setDisabled] = useState(false);
	const [selectedOption, setSelectedOption] = useState(0);
	const [mobileSelectedOption, setMobileSelectedOption] = useState(null);
	const [clientSecret, setClientSecret] = useState("");
	const [searchParams, setSearchParams] = useSearchParams({});
	const [paymentMethodModal, setPaymentMethodModal]  = useState(false);
	const [cardLoading, setCardLoading] = useState(false);
	const [subscriptionId, setSubscriptionId] = useState("");
	const [loading, setLoading] = useState(true);
	const [uploadedImage, setUploadedImage] = useState("");
	const [deleteModal, setDeleteModal] = useState(false);
	const [autoBilling, setAutoBilling] = useState(false);
	const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);
	const [emailChangeable, setEmailChangeable] = useState(false);
	const [expiredSubscriptions, setExpiredSubscriptions] = useState([]);
	const [unassignedCountyModal, setUnassignedCountyModal] = useState(false);
	const [anyUnassignedCounty, setAnyUnassignedCounty] = useState(false);
	const [freeTrialObject, setFreeTrialObject] = useState([]);
	const emailTemplate = `mailto:liam.maher@coffeeclozers.com?subject=Refund Request&body=Hi Coffee Clozers - your product sucks and here is why: %0D%0A%0D%0A {the floor is yours....be as specific as you can so we can make it better}`;
	const location = useLocation();
	const state = location.state;

	const options = [
		{
			label: "Profile",
			description: "Manage your personal details and account settings."
		},
		{
			label: "Payments & Subscription",
			description: "Manage your payment methods and subscription."
		}
	];

	useEffect(() => {
		async function fetchData() {

			document.title = "Coffee Clozers | My Profile";
			const collections = [
				"Users", 
				"Subscriptions"
			];
			const getUserId = await checkOnAuthStateChanged();
			const doc = getUserId.userId;
			if ( doc !== "" ) {
				const query = await getUserDocuments(collections, doc);
				if ( query[0].status !== 200 ) {
					navigate('/sign-up');
				}
				else {
					const userDetails = query[0].data;
					const userFreeTrial = userDetails.freeTrial;
					setFreeTrialObject(userFreeTrial);

					const getSetupIntentParams = searchParams.get('setup_intent');
					let card = "";
					if ( getSetupIntentParams !== null ) {
						const mode = window.location.hostname === "localhost" || window.location.hostname.includes("192.") || window.location.hostname.includes("refi-787d3") ? "test" : "live";
						card = await getSetupIntent(getSetupIntentParams, mode);
					}
					if ( card !== "" ) {
						userDetails.creditCard = card;
						setSelectedOption(1);
						setMobileSelectedOption(1);
						const data = userDetails;

						await setData(collections[0], doc, data);
						setSearchParams({tab: 1});
					}
					else {
						const getTab = searchParams.get('tab');
						if ( Number(getTab) !== null && Number(getTab) !== undefined && Number(getTab) < 4 && Number(getTab) > -1 ) {
							setSelectedOption(Number(getTab));
							setMobileSelectedOption(Number(getTab));
						}
					}

					setEmailChangeable(getUserId.emailChangeable);
					setAccountInformation([
						{
							label: "Name",
							value: `${userDetails.firstName} ${userDetails.lastName}`,
							edit: false
						},
						{
							label: "Email",
							value: userDetails.email,
							edit: false
						}
					]);

					setUserData(userDetails);

					if ( userDetails.profileImage !== undefined ) {
						setUploadedImage(userDetails.profileImage.url);
					}

					const today = new Date();
					const todaySeconds = today.getTime() / 1000;
					const oldSubs = query[1].status === 200 ? query[1].data : [];
					const userSubscriptions = [];
					for (let index = 0; index < oldSubs.length; index++) {
						const element = oldSubs[index];
						if ( element.creative === true ) {
							userSubscriptions.push(element);
						}
					}
					if ( userSubscriptions.length !== 0 ) {
						setSubscriptions(userSubscriptions);
						setActiveSubscription(true);

						const expiredArray = [];
						for (let index = 0; index < userSubscriptions.length; index++) {
							const element = userSubscriptions[index];
							const endDate = element.endDate.seconds;

							if ( endDate < todaySeconds ) {
								expiredArray.push(element);
							}

							if ( element.metroArea === true && element.msaCode === "" ) {
								if ( userDetails.creativeUnassignedCounties !== undefined && userDetails.creativeUnassignedCounties[1] === true ) {
									setUnassignedCountyModal(true);
								}
								setAnyUnassignedCounty(true);
							}
						};

						setExpiredSubscriptions(expiredArray);
					}
					setLoading(false);
				}
			}
			else {
				navigate('/sign-up');
			}
		}

		fetchData();
	}, [navigate, searchParams, setSearchParams]);

	const logoutUser = async() => {
		setLoading(true);
		recordEvent("Logout", {});
		const user = await logout();

		if ( user.status === 200 ) {
			setLoading(false);
			navigate('/');
		}
	};

	const closeModal = () => {
		setPaymentMethodModal(false);
	};

	const getSubscription = async() => {
		// setDisabled(true);
		const user = await checkUserId();
		if ( user.status === 200 ) {
			navigate("/my-cities");
		}
		else {
			navigate('/sign-up');
		}
	};

	const setupIntent = async() => {
		setDisabled(true);
		setLoading(true);
		if ( clientSecret !== "" ) {
			setPaymentMethodModal(true);
			setCardLoading(false);
			setDisabled(false);
			setLoading(false);
		}
		else {
			let customerId = userData.customerId;
			const mode = window.location.hostname === "localhost" || window.location.hostname.includes("192.") || window.location.hostname.includes("refi-787d3") ? "test" : "live";
			if ( customerId === undefined ) {
				const customer = {
					email: userData.email,
					name: `${userData.firstName} ${userData.lastName}`,
					phone: userData.phoneNumber !== undefined ? userData.phoneNumber : "",
					mode: mode
				};
				const getCustomer = await getStripeCustomer(customer);
				customerId = getCustomer.customerId;
				const user = await checkUserId();

				if ( user.status === 200 ) {
					const colRef = "Users";
					const docRef = user.userId;
					const data = userData;
					data.customerId = customerId;
					setUserData(data);
					await setData(colRef, docRef, data);
				}
			}
			const params = {
				customerId: customerId,
				mode: mode
			};
			const getIntent = await createStripeSetupIntent(params);
			if ( getIntent.status === 200 ) {
				const id = getIntent.setupIntent.client_secret;
				setClientSecret(id);
				setPaymentMethodModal(true);
				setCardLoading(false);
				setDisabled(false);
				setLoading(false);
				recordEvent("Add Payment Method Clicked");
			}
		}
	};

	const deleteCard = async(item) => {
		setDeleteModal(false);
		setDisabled(true);
		setCardLoading(true);
		if ( disabled === false ) {
			let clone = JSON.parse(JSON.stringify(userData))
			delete clone.creditCard;
			setUserData(clone);
			setClientSecret("");
			const params = {
				mode: item.mode,
				paymentMethod: item.paymentMethod
			};
			const deletion = await deleteStripePaymentMethod(params);
			if ( deletion.status === 200 ) {
				const colRef = "Users";
				const user = await checkUserId();
				if ( user.status === 200 ) {
					const docRef = user.userId;
					const data = clone;
					await setData(colRef, docRef, data);
					setDisabled(false);
					setCardLoading(false);
				}
			}
		}
	};

	const cancelSubscription = async() => {
		if ( subscriptionId !== "" ) {
			setLoading(true);
			const user = await checkUserId();
			if ( user.status === 200 ) {
				const colRef = "Subscriptions";
				const docRef = user.userId;
				const queryData = await getDocument(colRef, docRef);
				if ( queryData.status === 200 ) {
					const mode = window.location.hostname === "localhost" || window.location.hostname.includes("192.") || window.location.hostname.includes("refi-787d3") || window.location.hostname.includes("192") ? "test" : "live";
					const subscriptionData = {
						subscription: subscriptionId,
						mode: mode
					};
					const subCancellation = await cancelStripeSubscription(subscriptionData);
					if ( subCancellation.status === 200 ) {
						const oldSubs = queryData.data.data;
						for (let index = 0; index < oldSubs.length; index++) {
							const element = oldSubs[index];
							if ( element.id === subscriptionId ) {
								oldSubs[index].autoBilling = false;
								oldSubs[index].cancelled = true;
							}
						}
						await setData(colRef, docRef, oldSubs);
						cancelNormalSubscription(docRef, subscriptionId);
						setSubscriptions(oldSubs);
						setSubscriptionId("");
						setLoading(false);
						setCancelSubscriptionModal(false);
						setAutoBilling(false);
					}
					else {
						setLoading(false);
					}
				}
				else {
					setLoading(false);
				}
			}
			else {
				setLoading(false);
			}
		}
	};

	const cancelNormalSubscription = async(userId, id) => {
		const colRef = "Subscriptions";
		const docRef = userId;
		const queryData = await getDocument(colRef, docRef);
		if ( queryData.status === 200 ) {
			const data = queryData.data.data;
			for (let index = 0; index < data.length; index++) {
				const element = data[index];
				if ( element.id === id ) {
					data[index].autoBilling = false;
					data[index].cancelled = true;
				}
			}
			await setData(colRef, docRef, data);
		}
	};

	const [accountInformation, setAccountInformation] = useState(userData === "" ? [] : [
		
	]);

	const changeSelectedOption = (index) => {
		setSelectedOption(index);
		setSearchParams({tab: index});
	};

	const saveImageFile = async(uploadedFile, userId) => {
        const colRef = "Users";
        const docRef = userId;
        const queryData = await getDocument(colRef, docRef);
        if ( queryData.status === 200 ) {
            const data = queryData.data.data;
            data.profileImage = uploadedFile;
            await setData(colRef, docRef, data);
            setUploadedImage(uploadedFile.url);
        }
    };

	const selectMobilePanel = (index) => {
		setMobileSelectedOption(index);
	};

	const mobileBack = () => {
		setMobileSelectedOption(null);
	};

	const closeUnassignedCountyModal = async() => {
		setUnassignedCountyModal(false);
		
		const currentUnassignedCounties = userData.creativeUnassignedCounties;
		if ( currentUnassignedCounties !== undefined ) {
			currentUnassignedCounties[1] = false;
			const user = await checkUserId();
			if ( user.status === 200 ) {
				const userId = user.userId;
				await unassignedCountyWrite(userId, currentUnassignedCounties);
			}
		}
	};

	const goToUnassignedCounty = async() => {
		let unassignedCounties = 0;
		for (let index = 0; index < subscriptions.length; index++) {
			const element = subscriptions[index];
			if ( element.metroArea === true && element.msaCode === "" ) {
				unassignedCounties++;
			}
		}

		const currentUnassignedCounties = userData.creativeUnassignedCounties;
		if ( currentUnassignedCounties !== undefined ) {
			currentUnassignedCounties[1] = false;
			const user = await checkUserId();
			if ( user.status === 200 ) {
				const userId = user.userId;
				await unassignedCountyWrite(userId, currentUnassignedCounties);
			}
		}

		navigate("/choose-counties", {
			state: {
				counties: unassignedCounties
			}
		});
	};

	const goToChooseCounties = () => {
        let unassignedCounties = 0;
		for (let index = 0; index < subscriptions.length; index++) {
			const element = subscriptions[index];
			if ( element.metroArea === true && element.msaCode === "" ) {
				unassignedCounties++;
			}
		}

        navigate("/choose-counties", {
			state: {
				counties: unassignedCounties
			}
		});
    };

	return (
		<div className="profile-outer-container">
			<Header
				subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
				mobileNav={true}
			/>
			{
				paymentMethodModal === true ?
				<div className="stripe-form-dark-bg">
				</div>
				:
				unassignedCountyModal === true ?
				<div className="dark-bg">
				</div>
				:
				null
			}
			{
				loading === true ?
				<Loading />
				:
				null
			}
			<div className="profile-outer-row">
				<SideNavigation
					navigate={navigate}
					state={state}
					userData={userData === "" ? null : userData}
				/>
				<div className="profile-inner-container">
					{
						unassignedCountyModal === true ?
						<Modal
							title="You have counties that are unassigned"
							description="You have counties that you bought which need to be assigned to your account!"
							closeButtonText="Close"
							saveButtonText="Choose counties"
							closeModal={closeUnassignedCountyModal}
							saveModal={goToUnassignedCounty}
						/>
						:
						null
					}
					<div className="profile-inner-max-width-container">
						{
							clientSecret !== "" && paymentMethodModal === true ?
							<StripeForm
								clientSecret={clientSecret}
								closeModal={closeModal}
								domain="profile"
								productIndex={null}
								product={null}
								userData={userData}
								setUserData={setUserData}
								setPaymentMethodModal={setPaymentMethodModal}
								setLoading={setLoading}
							/>
							:
							null
						}
						<div className={"profile-title-container margin-large " + (mobileSelectedOption !== null ? "mobile-none" : "")}>
							<h1 className="heading-large-semibold colour-primary">
								Account
							</h1>
							{
								anyUnassignedCounty === true ?
								<UnassignedCounties
									cta={goToChooseCounties}
								/>
								:
								null
							}
						</div>
						<div className="profile-options-row margin-large">
							{
								userData === "" ?
								null
								:
								options.map((item, index) =>
									<Chip
										label={item.label}
										func={changeSelectedOption}
										index={index}
										selected={selectedOption}
										key={index}
										recommended={false}
										hover={null}
                                    	leave={null}
										disabled={false}
									/>
								)
							}
						</div>
						{
							mobileSelectedOption === null ?
							<div className="my-cities-light-divider-line desktop-none">
							</div>
							:
							null
						}
						<div className="profile-mobile-options-container margin-large desktop-none margin-none">
							{
								userData === "" ?
								null
								:
								mobileSelectedOption !== null ?
								null
								:
								options.map((item, index) =>
									<div 
										className="my-cities-mobile-option-element"
										key={index}
										onClick={() => selectMobilePanel(index)}
									>
										<div className="button-row my-cities-mobile-option-padding">
											<span className="body-regular colour-link block-text subtext-margin-right">
												{item.label}
											</span>
											<img
												src={ChevronRight}
												className="my-cities-mobile-option-arrow"
												alt={item.label}
											/>
										</div>
									</div>
								)
							}
							{
								anyUnassignedCounty === true ?
								<div className="">
								</div>
								:
								null
							}
						</div>
						<div className="profile-desktop-body-container">
							<div className="profile-title-container margin-large">
								<h2 className="heading-small-semibold colour-primary">
									{options[selectedOption].label}
								</h2>
							</div>
							<div className="profile-separator-container margin-tops">
							</div>
							{
								userData === "" ?
								null
								:
								selectedOption === 0 ?
								<AccountSettings
									accountInformation={accountInformation}
									setAccountInformation={setAccountInformation}
									uploadedImage={uploadedImage}
									saveImageFile={saveImageFile}
									userData={userData}
									navigate={navigate}
									state={state}
									setLoading={setLoading}
									emailChangeable={emailChangeable}
								/>
								:
								<PaymentSettings
									userData={userData}
									cardLoading={cardLoading}
									activeSubscription={activeSubscription}
									setupIntent={setupIntent}
									getSubscription={getSubscription}
									disabled={disabled}
									deleteCard={deleteCard}
									emailTemplate={emailTemplate}
									deleteModal={deleteModal}
									setDeleteModal={setDeleteModal}
									autoBilling={autoBilling}
									cancelSubscription={cancelSubscription}
									cancelSubscriptionModal={cancelSubscriptionModal}
									setCancelSubscriptionModal={setCancelSubscriptionModal}
									loading={loading}
									subscriptions={subscriptions}
									expiredSubscriptions={expiredSubscriptions}
									setSubscriptionId={setSubscriptionId}
									freeTrialObject={freeTrialObject}
								/>
							}
						</div>
						{
							mobileSelectedOption !== null ?
							<div className="profile-mobile-body-container">
								<div 
									className="my-cities-mobile-back-container button-row text-button margin-large"
									onClick={() => mobileBack()}
								>
									<img
										src={ChevronLeft}
										className="my-cities-back-chevron-left"
										alt="Go back"
									/>
									<span className="body-regular colour-link">
										Back
									</span>
								</div>
								<div className="profile-title-container margin-large">
									<h2 className="heading-small-semibold colour-primary">
										{options[mobileSelectedOption].label}
									</h2>
								</div>
								<div className="profile-separator-container margin-tops">
								</div>
								{
									userData === "" ?
									null
									:
									mobileSelectedOption === 0 ?
									<AccountSettings
										accountInformation={accountInformation}
										setAccountInformation={setAccountInformation}
										uploadedImage={uploadedImage}
										saveImageFile={saveImageFile}
										userData={userData}
										navigate={navigate}
										state={state}
										setLoading={setLoading}
										emailChangeable={emailChangeable}
									/>
									:
									<PaymentSettings
										userData={userData}
										cardLoading={cardLoading}
										activeSubscription={activeSubscription}
										setupIntent={setupIntent}
										getSubscription={getSubscription}
										disabled={disabled}
										deleteCard={deleteCard}
										emailTemplate={emailTemplate}
										deleteModal={deleteModal}
										setDeleteModal={setDeleteModal}
										autoBilling={autoBilling}
										cancelSubscription={cancelSubscription}
										cancelSubscriptionModal={cancelSubscriptionModal}
										setCancelSubscriptionModal={setCancelSubscriptionModal}
										loading={loading}
										subscriptions={subscriptions}
										expiredSubscriptions={expiredSubscriptions}
										setSubscriptionId={setSubscriptionId}
										freeTrialObject={freeTrialObject}
									/>
								}
							</div>
							:
							null
						}
						{
							userData !== "" ?
							<div className={"profile-logout-outer-container " + (mobileSelectedOption === null ? "mobile-none" : "")}>
								<div className="profile-logout-container">
									<WhiteActionButton
										onClick={() => logoutUser()}
									>
										Logout
									</WhiteActionButton>
								</div>
							</div>
							:
							null
						}
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default Profile;