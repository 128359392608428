import React from 'react';
import './Buttons.css';

function SecondaryButton(props) {
    const text = props.text;
    const cta = props.cta;
    const disabled = props.disabled;
    const sizeClass = props.size === "medium" ? "size-medium" : props.size === "small" ? "size-small" : "size-large";
    const textSize = props.size === "medium" ? "body-semibold" : "label-semibold";

    return (
        <div 
            className={"secondary-button button-radius-normal cursor-pointer " + (disabled === true ? "button-disabled " : "") + (sizeClass)}
            onClick={disabled === true ? null : cta}
        >
            <div className={`${sizeClass}-padding`}>
                <span className={(textSize) + " colour-primary block-text text-align-center"}>
                    {text}
                </span>
            </div>
        </div>
    )
};

export default SecondaryButton;