import React, { useState } from 'react';
import '../styles/SideNavigation.css';
import { CogBlack, HeartBlack, QuickReport, Home } from '../assets';
import { DefaultUserImage, SelectedLine } from './';

function SideNavigation(props) {
    const pathname = window.location.pathname;
    const navigate = props.navigate;
    const state = props.state;
    const userData = props.userData;
    const [options] = useState([
        {
            title: "Home",
            icon: Home,
            link: "/my-cities",
            active: pathname === "/my-cities" || pathname === "/" ? true : false
        },
        {
            title: "Saved",
            icon: HeartBlack,
            link: "/favourites",
            active: pathname === "/favourites" ? true : false
        },
        {
            title: "Quick Report",
            icon: QuickReport,
            link: "/quick-report",
            active: pathname === "/quick-report" ? true : false
        },
        {
            title: "Settings",
            icon: CogBlack,
            link: "/preferences",
            active: pathname === "/preferences" ? true : false
        }
    ]);

    const selectOption = (item) => {
        navigate(item.link, {
            state: state
        });
    };

    return (
        <div className="side-navigation-outer-container bg-colour-white">
            <div className="side-navigation-inner-container relative-container">
                <ul className="side-navigation-options-container">
                    {
                        options.map((item, index) =>
                            index === 3 ?
                            null
                            :
                            <li
                                className="side-navigation-element-container"
                                key={index}
                                onClick={() => selectOption(item)}
                            >
                                <div className={"button-row side-navigation-element-inner-container cursor-pointer " + (item.active === true ? "side-navigation-option-active" : "")}>
                                    <img
                                        src={item.icon}
                                        className={"side-navigation-option-icon " + (item.active === false ? "side-navigation-option-grey-icon" : "")}
                                        alt={item.title}
                                    />
                                    <span className={item.active === true ? "body-semibold colour-primary" : "body-regular text-link"}>
                                        {item.title}
                                    </span>
                                </div>
                                <SelectedLine
                                    visible={item.active}
                                />
                            </li>
                        )
                    }
                    <div className="side-navigation-divider-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="1" viewBox="0 0 200 1" fill="none">
                            <rect width="200" height="0.5" rx="0.25" fill="#989793"/>
                        </svg>
                    </div>
                    {
                        options.map((item, index) =>
                            index !== 3 ?
                            null
                            :
                            <li
                                className="side-navigation-element-container"
                                key={index}
                                onClick={() => selectOption(item)}
                            >
                                <div className={"button-row side-navigation-element-inner-container cursor-pointer " + (item.active === true ? "side-navigation-option-active" : "")}>
                                    <img
                                        src={item.icon}
                                        className={"side-navigation-option-icon " + (item.active === false ? "side-navigation-option-grey-icon" : "")}
                                        alt={item.title}
                                    />
                                    <span className={item.active === true ? "body-semibold colour-primary" : "body-regular text-link"}>
                                        {item.title}
                                    </span>
                                </div>
                                <SelectedLine
                                    visible={item.active}
                                />
                            </li>
                        )
                    }
                </ul>
                <div className="side-navigation-account-container">
                    <div className="side-navigation-divider-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="1" viewBox="0 0 200 1" fill="none">
                            <rect width="200" height="0.5" rx="0.25" fill="#989793"/>
                        </svg>
                    </div>
                    <div className="side-navigation-element-container">
                        <div 
                            className={"button-row side-navigation-element-inner-container cursor-pointer " + (pathname === "/profile" ? "side-navigation-option-active" : "")}
                            onClick={() => selectOption({link: "/profile"})}
                        >
                            {
                                userData === null || userData.profileImage === undefined ?
                                <DefaultUserImage
                                    size="small"
                                />
                                :
                                <img
                                    src={userData.profileImage.url}
                                    className="side-navigation-account-pic"
                                    alt="My account"
                                />
                            }
                            <span className="body-regular text-link">
                                Account
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SideNavigation;