import React, { useEffect, useState } from 'react';
import { Header, Footer, Loading, ListingsCard, OfferModal, IndigoBadge } from '../components';
import { useNavigate } from 'react-router-dom';
import { checkOnAuthStateChanged, cloudFunctionV2, getAPIGatewayListings, getUserDocuments } from '../functions';
import { defaultUserData } from '../styles/GlobalStyles';
import moment from 'moment';

function SampleOffers() {
	const cityId = "CTY45045";
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [offers, setOffers] = useState([]);
    const [userId, setUserId] = useState(null);
    const [offerModal, setOfferModal] = useState(false);
    const [offerProperty, setOfferProperty] = useState(null);
    const [offerPropertyTerms, setOfferPropertyTerms] = useState(null);
    const [generatedOffers, setGeneratedOffers] = useState([]);
    const [userData, setUserData] = useState(null);
    const [offerCount, setOfferCount] = useState(null);

    const options = [
        {
            label: "Properties",
            value: "properties",
            selected: false,
            route: `/sample-market`
        },
        {
            label: "Offers",
            value: "offers",
            selected: true,
            route: `/sample-offers`
        }
    ];

    useEffect(() => {

        const propertyTypes = [
            {
                code: "MultiFamily",
                included: true,
                property: "Multi-Family",
                abbreviation: "MFH"
            },
            {
                code: "SingleFamily",
                included: true,
                property: "Single-Family",
                abbreviation: "SFH"
            }
        ];

        const fetchUserData = async() => {
            const colRef = ["Users", "Creative Listings"];
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const docRef = user.userId;
                setUserId(user.userId);
                const queryData = await getUserDocuments(colRef, docRef);
                if ( queryData[1].status === 200 ) {
                    const data = queryData[1].data;
                    const findIndex = data.findIndex(e => e.cityId === cityId);
                    if ( findIndex !== -1 ) {
                        const relevantObject = data[findIndex];
                        const savedOffers = relevantObject.offers;
                        setGeneratedOffers(savedOffers)
                        setOfferCount(savedOffers.length);
                        const checkedOffers = [];
                        for (let index = 0; index < savedOffers.length; index++) {
                            const element = savedOffers[index];
                            if ( element.sent === false ) {
                                checkedOffers.push(savedOffers[index]);
                            }
                        }
                        queryProperties(checkedOffers);
                    }
                    else {
                        queryProperties([]);
                    }
                }
                else {
                    queryProperties([]);
                }

                if ( queryData[0].status === 200 ) {
                    setUserData(queryData[0].data);
                }
            }
            else {
                queryProperties([]);
            }
        };

        const queryProperties = async(vals) => {
            if ( vals.length === 0 ) {
                setLoading(false);
                return;
            }
            const newFilters = {
				adu: false,
				bathrooms: 1,
				bedrooms: 1,
				budgetRange: [0, 10000000],
				constructionYears: [1800, 2024],
				squareFootage: [0, 10000000],
				units: 1,
				propTypeMislabelled: false,
				priceReduction: false,
				extraBedroom: false,
				propertyTypes: propertyTypes,
				hideFixerUppers: false,
				onlyFixerUppers: false,
				priceReductionIndex: 0,
				neighbourhoodGradeMin: 1,
				neighbourhoodGradeMax: 8,
				fsbo: false,
                subto: false,
                sellerFinance: true
			};
			const getGatewayQueryString = await getAPIGatewayListings(defaultUserData.settings, false, cityId, newFilters);
			const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
			const gatewayData = {
				type: "gateway",
				resourceId: "listings",
				queryString: getGatewayQueryString
			};
			const getGateway = await cloudFunctionV2(gatewayURL, gatewayData);
			let queryData = [];
			if ( getGateway.status === 200 ) {
				const getGatewayBody = getGateway.body;
                queryData = getGatewayBody.props;
			}
			else if ( getGateway.status === 404 ) {
				const customS3Location = getGateway.body.s3_location;
				const params = {
					type: "s3",
					s3Data: {
						key: customS3Location,
						bucketName: "residentialpropertydata"
					}
				};
				const getS3Query = await cloudFunctionV2(gatewayURL, params);
				if ( getS3Query.status === 200 ) {
					const getS3Body = getS3Query.body;
                    queryData = getS3Body.props;
				}
			}
			else {
                setLoading(false);
			}
            setLoading(false);

            const newListings = [];
            for (let index = 0; index < queryData.length; index++) {
                queryData[index].datePostedTS = new Date(queryData[index].datePosted);
                const a = moment(new Date());
				const b = moment(queryData[index].datePostedTS);
				const dateDifferenceDays = a.diff(b, 'days');
                const dateDifferenceMonths = a.diff(b, 'months');
				queryData[index].dateDifferenceDays = dateDifferenceDays;
                queryData[index].dateDifferenceMonths = dateDifferenceMonths;

                const zpidIndex = vals.findIndex(e => e.zpid === queryData[index].zpid);
                if ( zpidIndex !== -1 ) {
                    queryData[index].offerURL = vals[zpidIndex].offerLink;
                    newListings.push(queryData[index]);
                }
            }
            setOffers(newListings);
            setLoading(false);
        };

        fetchUserData();

    }, [cityId]);

    const changeOption = (item) => {
        navigate(item.route);
    };

    const viewOfferModal = (item) => {
        for (let index = 0; index < generatedOffers.length; index++) {
            const element = generatedOffers[index];
            if ( element.zpid === item.zpid ) {
                setOfferPropertyTerms(element);
                break;
            }
        };
        setOfferModal(true);
        setOfferProperty(item);
    };

    const closeModal = () => {
        setOfferModal(false);
        setOfferProperty(null);
        setOfferPropertyTerms(null);
    };

    return (
        <div className={"listings-outer-container " + (offerModal === true ? "position-relative" : "")}>
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            {
                offerModal === true ? 
                <div className="dark-bg">
                </div>
                :
                null
            }
            <div className="listings-inner-container relative-container">
                {
                    loading === true ?
                    <Loading />
                    :
                    null
                }
                {
                    offerModal === true ?
                    <OfferModal
                        offerProperty={offerProperty}
                        closeModal={closeModal}
                        offerPropertyTerms={offerPropertyTerms}
                        userData={userData}
                    />
                    :
                    null
                }
                <div className="listings-top-options-container margin-large">
                    {
                        options.map((item, index) =>
                            <div 
                                className="listings-top-option-element cursor-pointer"
                                key={index}
                                onClick={() => changeOption(item)}
                            >
                                <span className={(item.selected === true ? "body-semibold colour-primary" : "body-regular colour-secondary")}>
                                    {item.label}
                                </span>
                                {
                                    index === 1 && offerCount !== null ?
                                    <IndigoBadge
                                        text={offerCount}
                                        extraClass="subtext-margin-left"
                                    />
                                    :
                                    null
                                }
                            </div>
                        )
                    }
                </div>
                {
                    offers.length === 0 && loading === false ?
                    <div className="listings-no-results-container">
                        <h3 className="heading-small-semibold colour-primary text-align-center margin-x-small">
                            No offers found
                        </h3>
                        <span className="body-regular colour-secondary block-text text-align-center">
                            These are rookie numbers, we gotta pump these up
                        </span>
                    </div>
                    :
                    <div className="listings-grid-container">
                        {
                            offers.map((item, index) =>
                                <ListingsCard
                                    property={item}
                                    key={index}
                                    downPayment={0.2}
                                    hiddenProperties={[]}
                                    setHiddenProperties={null}
                                    userId={userId}
                                    cityId={cityId}
                                    type="offer"
                                    offers={offers}
                                    setOffers={setOffers}
                                    viewOfferModal={viewOfferModal}
                                    strategy="sellerFinance"
                                    abstract={false}
                                    setHoverCard={null}
                                    propertyRefs={null}
                                    index={index}
                                />
                            )
                        }
                    </div>
                }
            </div>
            <Footer />
        </div>
    )
};

export default SampleOffers;