import React, { useState, useRef } from 'react';
import '../styles/NewFiltersModal.css';
import Exit from '../assets/exit.svg';
import { checkUserId, recordEvent, resetFilterType, saveSearchParams } from '../functions';
import { useNavigate } from 'react-router-dom';
import { ActionButton } from '../styles/GlobalStyles';
import { ChoosePropertyType, ChooseBudget, ChoosePropertySpecifics, MiscellaneousFilters, ChooseValueAddOpportunity } from './';

function NewFiltersModal(props) {

	const setFiltersModalOpen = props.setFiltersModalOpen;
	// const setLoading = props.setLoading;
	const searchParams = props.searchParams;
	const setSearchParams = props.setSearchParams;
	const filtersRef = useRef(null);
	const propertyTypes = props.propertyTypes;
	const setPropertyTypes = props.setPropertyTypes;
	const units = props.units;
	const setUnits = props.setUnits;
	const budgetRange = props.budgetRange;
	const setBudgetRange = props.setBudgetRange;
	const bedrooms = props.bedrooms;
	const setBedrooms = props.setBedrooms;
	const bathrooms = props.bathrooms;
	const setBathrooms = props.setBathrooms;
	const squareFootage = props.squareFootage;
	const setSquareFootage = props.setSquareFootage;
	const constructionYears = props.constructionYears;
	const setConstructionYears = props.setConstructionYears;
	const setPage = props.setPage;
	const onlyFixerUppers = props.onlyFixerUppers;
	const setOnlyFixerUppers = props.setOnlyFixerUppers;
	const fsbo = props.fsbo;
	const setFsbo = props.setFsbo;
	const setFiltersChanged = props.setFiltersChanged;
	const hideFixerUppers = props.hideFixerUppers;
	const setHideFixerUppers = props.setHideFixerUppers;
	const inherited = props.inherited;
	const setInherited = props.setInherited;
	const neighbourhoodGradeMin = props.neighbourhoodGradeMin;
	const setNeighbourhoodGradeMin = props.setNeighbourhoodGradeMin;
	const neighbourhoodGradeMax = props.neighbourhoodGradeMax;
	const setNeighbourhoodGradeMax = props.setNeighbourhoodGradeMax;
	
	const [disabled, setDisabled] = useState(false);
	const navigate = useNavigate();

	const changeFSBOFilter = (val) => {
		setFsbo(val === "true" || val === true ? true : false);
	};

	const saveChanges = async() => {
		const propertyTypeArray = [];
		for (let index = 0; index < propertyTypes.length; index++) {
			const element = propertyTypes[index];
			if ( element.included === true ) {
				propertyTypeArray.push(true);
			}
		}

		const getUserId = await checkUserId();
		if ( getUserId.userId === "" ) {
			navigate('/sign-up');
		}
		else {
			setDisabled(true);
			const newConstructionYears = [Number(constructionYears[0]), Number(constructionYears[1])];
			const labels = [
				"propertyType",
				"budgetRange",
				"propertySpecifics",
				"valueAdd",
				"more",
				"page"
			];

			const propertyTypesArray = propertyTypes.map(e => e.included ? e.abbreviation : null);
            const filteredPropertyTypesArray = propertyTypesArray.filter(value => value !== null);
            const commaSeparatedPropertyTypesString = filteredPropertyTypesArray.join(',');
            
			const propertyTypesObject = {
                propertyTypes: commaSeparatedPropertyTypesString
            };
            if ( commaSeparatedPropertyTypesString.includes("MFH") ) {
                propertyTypesObject.units = units;
            }
            else if ( commaSeparatedPropertyTypesString === "" ) {
                propertyTypesObject.propertyTypes = "MFH,SFH";
                propertyTypesObject.units = 1;
            }

			const propertySpecificsObject = {
                bedrooms: bedrooms,
                bathrooms: bathrooms,
                squareFootage: squareFootage
            };

			const lowConstructionYearLength = constructionYears[0].length;
            const highConstructionYearLength = constructionYears[1].length;
			if ( 
                constructionYears[0] !== "" && constructionYears[1] !== "" &&
                newConstructionYears[0] <= newConstructionYears[1] &&
                lowConstructionYearLength === 4 && highConstructionYearLength === 4
            ) {
				propertySpecificsObject.constructionYears = newConstructionYears;
			}
			else if ( constructionYears[0].length === 4 && constructionYears[1].length < 4 ) {
                propertySpecificsObject.constructionYears = [newConstructionYears[0], 2024];
                setConstructionYears([newConstructionYears[0], 2024]);
            }

			const valueAddObject = {
				onlyFixerUppers: onlyFixerUppers,
				hideFixerUppers: hideFixerUppers
			};

			const moreObject = {
				fsbo: fsbo,
				inherited: inherited
			};

			const values = [
				JSON.stringify(propertyTypesObject),
				JSON.stringify(budgetRange),
				JSON.stringify(propertySpecificsObject),
				JSON.stringify(valueAddObject),
				JSON.stringify(moreObject),
				1
			];
			setPage(1);
			setFiltersChanged(true);
			await saveSearchParams(labels, values, searchParams, setSearchParams);
			recordEvent("Save Filters", {});
			setDisabled(false);
			closeFilters();
		}
	};

	const closeFilters = () => {
		setFiltersModalOpen(false);
		recordEvent("Close Filters", {});
	};

	const resetAllFilters = async() => {
		searchParams.delete("propertyType");
		searchParams.delete("budgetRange");
		searchParams.delete("propertySpecifics");
		searchParams.delete("more");
		await resetFilterType(
			"all",
			setPropertyTypes, 
            setUnits,
            setBudgetRange,
            setBedrooms,
            setBathrooms,
            setSquareFootage,
            setConstructionYears,
			setOnlyFixerUppers,
			setHideFixerUppers,
			setInherited,
			setNeighbourhoodGradeMin,
            setNeighbourhoodGradeMax,
            setFsbo,
			setPage
		);
		setSearchParams(searchParams);
		closeFilters();
		setFiltersChanged(true);
	};

	return (
		<div className="new-filters-modal-outer-container">
			<div className="new-filters-modal-sticky-title margin-medium">
				<div className="new-filters-modal-inner-sticky-title">
					<div className="new-filters-modal-title-container">
						<h2 className="body-regular colour-primary">
							Filters
						</h2>
					</div>
					<div 
						onClick={() => closeFilters()}
						className="new-filters-modal-exit-container"
					>
						<img
							src={Exit}
							className="filters-exit"
							alt="Close Modal"
						/>
					</div>
				</div>
			</div>
			<div ref={filtersRef} className="new-filters-modal-inner-container">
				<div className="new-filters-modal-section-container margin-x-large margin-top-x-x-large">
					<div className="new-filters-modal-section-title-container margin-x-large">
						<h3 className="heading-small-semibold colour-quaternary">
							Property Type
						</h3>
					</div>
					<ChoosePropertyType
						propertyTypes={propertyTypes}
						setPropertyTypes={setPropertyTypes}
						units={units}
						setUnits={setUnits}
					/>
				</div>
				<div className="new-filters-modal-separator-line margin-tops">
				</div>
				<div className="new-filters-modal-section-container margin-x-large margin-top-x-x-large">
					<div className="new-filters-modal-section-title-container margin-x-large">
						<h3 className="heading-small-semibold colour-quaternary">
							Budget
						</h3>
					</div>
					<ChooseBudget
						budgetRange={budgetRange}
						setBudgetRange={setBudgetRange}
					/>
				</div>
				<div className="new-filters-modal-separator-line margin-tops">
				</div>
				<div className="new-filters-modal-section-container margin-x-large margin-top-x-x-large">
					<div className="new-filters-modal-section-title-container margin-x-large">
						<h3 className="heading-small-semibold colour-quaternary">
							Property Specifics
						</h3>
					</div>
					<ChoosePropertySpecifics
						bedrooms={bedrooms}
						setBedrooms={setBedrooms}
						bathrooms={bathrooms}
						setBathrooms={setBathrooms}
						squareFootage={squareFootage}
						setSquareFootage={setSquareFootage}
						constructionYears={constructionYears}
						setConstructionYears={setConstructionYears}
					/>
				</div>
				<div className="new-filters-modal-separator-line margin-tops">
				</div>
				<div className="new-filters-modal-section-container margin-x-large margin-top-x-x-large">
					<div className="new-filters-modal-section-title-container margin-x-large">
						<h3 className="heading-small-semibold colour-quaternary">
							Value add
						</h3>
					</div>
					<ChooseValueAddOpportunity
						onlyFixerUppers={onlyFixerUppers}
						setOnlyFixerUppers={setOnlyFixerUppers}
						hideFixerUppers={hideFixerUppers}
						setHideFixerUppers={setHideFixerUppers}
						setDisabled={null}
					/>
				</div>
				<div className="new-filters-modal-section-container margin-x-large margin-top-x-x-large">
					<div className="new-filters-modal-section-title-container margin-x-large">
						<h3 className="heading-small-semibold colour-quaternary">
							Listing type
						</h3>
					</div>
					<MiscellaneousFilters
						fsbo={fsbo}
						changeFSBOFilter={changeFSBOFilter}
						inherited={inherited}
						setInherited={setInherited}
						neighbourhoodGradeMin={neighbourhoodGradeMin}
                        setNeighbourhoodGradeMin={setNeighbourhoodGradeMin}
						neighbourhoodGradeMax={neighbourhoodGradeMax}
						setNeighbourhoodGradeMax={setNeighbourhoodGradeMax}
					/>
				</div>
			</div>
			<div className="new-filters-modal-button-outer-container">
				<div className="new-filters-modal-reset-container">
					<span 
						className="new-filters-modal-reset-text"
						onClick={() => disabled === true ? null : resetAllFilters()}
					>
						Reset all
					</span>
				</div>
				<div className="new-filters-modal-button-inner-container">
					<div className="new-filters-modal-button-element-container">
						<ActionButton
							disabled={disabled}
							onClick={() => saveChanges()}
						>
							Save
						</ActionButton>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NewFiltersModal;