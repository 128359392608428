import React, { useRef, useState } from 'react';
import '../styles/Property.css';
import '../styles/Listing.css';
import { Header, Footer, StickyROI, ImageGrid, Location, ContactAgent, Loading, PropertyAbstract, PropertyDetails, OwnerDetails, OffMarketImageContainer, Comparables, ExitScreen, NeighbourhoodData, ARVCompsAnalysis, RentCompsAnalysis, SellerFinanceCalculator, OfferModal, SubtoCalculator, MobileBottomBar, FullscreenCalculator, OptimiseDealModal, ReportLoading, ShareButtons, UpgradeModalQuickReport } from '../components';
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { toggleFavourite, downloadExcel, recordEvent, queryOfferREI, getDocument, setData } from '../functions';
import { formatterLong } from '../styles/GlobalStyles';
import { PropertyPageEffect } from '../queries';
import { PropertyPDF } from '../pdfs';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

function PropertySearch() {

	const subToDisabled = false;
	const params = useParams();
	const id = decodeURIComponent(params.id);
	const [cityId, setCityId] = useState(null);
	const [searchParams] = useSearchParams({});
	const getStrategy = searchParams.get("strategy");
	const strategyParam = getStrategy === null ? 0 : parseInt(getStrategy) > 1 ? 0 : parseInt(getStrategy);
	const [strategyPanel, setStrategyPanel] = useState(subToDisabled === true ? 0 : strategyParam);
	const [property, setProperty] = useState([]);
	const [userId, setUserId] = useState(null);
	const [favourites, setFavourites] = useState([]);
	const [favouriteDisable, setFavouriteDisable] = useState(false);
	const [editedProperty, setEditedProperty] = useState("");
	const [userDetails, setUserDetails] = useState(null);
	const [downPayment, setDownPayment] = useState(10);
	const [interestRate, setInterestRate] = useState(3.5);
	const [loanYears] = useState(30);
	const [highLevelProfits, setHighLevelProfits] = useState("");
	const [queryMade, setQueryMade] = useState(false);
	const [neighbourhoodData, setNeighbourhoodData] = useState([]);
	const [tracts, setTracts] = useState([]);
	const [neighbourhoodBounds, setNeighbourhoodBounds] = useState([]);
	const [freeTrial] = useState(true);
	const [blurred, setBlurred] = useState(false);
	const [loading, setLoading] = useState(true);
	const [spreadsheetProperty, setSpreadsheetProperty] = useState(null);
	const [cityState] = useState("");
	const [selectedCompsPanel, setSelectedCompsPanel] = useState(0);
	const [noProperty, setNoProperty] = useState(false);
	const [showARVCompsAnalysis, setShowARVCompsAnalysis] = useState(false);
	const [showRentCompsAnalysis, setShowRentCompsAnalysis] = useState(false);
	const [arvComps, setArvComps] = useState([]);
	const [signUpModal, setSignUpModal] = useState(false);
	const [savedSalesCompData, setSavedSalesCompData] = useState(null);
	const [savedRentCompData, setSavedRentCompData] = useState(null);
	const [applyEstimateModal, setApplyEstimateModal] = useState(false);
	const [innerWidth, setInnerWidth] = useState(window.innerWidth);
	const [offerModal, setOfferModal] = useState(false);
	const [offerPropertyTerms, setOfferPropertyTerms] = useState(null);
	const [generatedOffers, setGeneratedOffers] = useState([]);
	const [offerLink, setOfferLink] = useState("");
	const [price, setPrice] = useState(0);
    const [balloonPayment, setBalloonPayment] = useState(0);
    const [duration, setDuration] = useState(60);
    const [amortisation, setAmortisation] = useState(360);
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [sellerCompensation, setSellerCompensation] = useState(price);
	const [offerLoading, setOfferLoading] = useState(false);
	const [mobileBar, setMobileBar] = useState(false);
	const [ownerEquity, setOwnerEquity] = useState(0);
	const [fullScreenFinancials, setFullScreenFinancials] = useState(false);
	const [optimiseDealLoading, setOptimiseDealLoading] = useState(false);
	const [showOptimiseDeal, setShowOptimiseDeal] = useState(false);
	const [costPerSqFoot, setCostPerSqFoot] = useState(0);
	const [totalRehabCost, setTotalRehabCost] = useState(0);
	const [defaultCostPerSqFoot] = useState(0);
	const [exitStrategy, setExitStrategy] = useState(0);
	const [leaseOptionValue, setLeaseOptionValue] = useState(0);
	const [leaseOptionDownPayment, setLeaseOptionDownPayment] = useState(downPayment);
	const [leaseOptionDollarDownPayment, setLeaseOptionDollarDownPayment] = useState(leaseOptionValue * (leaseOptionDownPayment / 100));
	const [leaseOptionInterestRate, setLeaseOptionInterestRate] = useState(8);
	const [strRevenue, setStrRevenue] = useState("");
	const [totalGeneratedOffers, setTotalGeneratedOffers] = useState(0);
	const [loadingStep, setLoadingStep] = useState(0);
	const [remainingMortgage, setRemainingMortgage] = useState(0);
	const [sellersMortgage, setSellersMortgage] = useState(0);
	const [paidUser, setPaidUser] = useState(false);
	const [subscriptions, setSubscriptions] = useState(null);
	const [upgradeModal, setUpgradeModal] = useState(false);

	const defaultDownPayment = 10;
	const totalMonthlyCosts = property.length === 0 ? 0 : Number((property.financials.propertyTaxes + property.financials.insurance + property.financials.hoaFees + property.financials.vacancyRateAllocation + property.financials.managementFee + property.financials.maintenanceFee + property.financials.otherCosts).toFixed(2));
	const strategyWords = strategyPanel === 0 ? "sellerFinance" : "subto";

	const compsRef = useRef(null);
	const navigate = useNavigate();
	const location = useLocation();
	const state = location.state;
	const origin = window.location.origin;
	const devMode = origin.includes("localhost") || window.location.hostname.includes("192.") || origin.includes("creative-65fe1") ? true : false;

	const toggleMobileBottomBar = () => {
		if ( mobileBar === true ) {
			document.body.removeAttribute("style");
		}
		else {
			document.body.style = "overflow: hidden";
		}
		setMobileBar(!mobileBar);
	};

	const downloadPDF = async() => {
		if ( property.length !== 0 ) {
			const doc = <PropertyPDF property={property} propertySearch={false} partner={false} highLevelProfits={highLevelProfits} />;
			const asPdf = pdf([]); // {} is important, throws without an argument
			asPdf.updateContainer(doc);
			const blob = await asPdf.toBlob();
			saveAs(blob, `${property.address.streetAddress}, ${property.address.city}, ${property.address.zipcode}.pdf`);
		}
	};

	const mapImages = () => {
		if ( property.length !== 0 && property.images !== undefined && property.images !== null && property.images.length > 0 ) {
			const firstImage = property.images[0];
			if ( firstImage.includes("maps.googleapis.com") ) {
				return true;
			}
			else {
				return false;
			}
		}
		else {
			return false;
		}
	};

	const runComps = () => {
		recordEvent("Run Comps", {
			zpid: id
		});

		if ( selectedCompsPanel === 0 ) {
			setShowRentCompsAnalysis(true);
		}
		else {
			setShowARVCompsAnalysis(true);
		}
	};

	const switchCompsTool = (val) => {
		const type = val === 1 ? "Sales" : "Rent";
		recordEvent(`Switch Comps - ${type}`, {
			zpid: id
		});

		if ( selectedCompsPanel === 0 ) {
			setShowRentCompsAnalysis(false);
			setShowARVCompsAnalysis(true);
		}
		else {
			setShowRentCompsAnalysis(true);
			setShowARVCompsAnalysis(false);
		}
		setSelectedCompsPanel(val);
	};

	const closeCompsAnalysis = () => {
		recordEvent("Comps Analysis Closed", {});
		setShowARVCompsAnalysis(false);
		setShowRentCompsAnalysis(false);
	};

	const saveCompsAnalysis = () => {
		if ( userId === null ) {
			setSignUpModal(true);
		}
		else {
			setShowARVCompsAnalysis(false);
			setShowRentCompsAnalysis(false);
			recordEvent("Comps Analysis Saved", {});
		}
	};

	const viewRentalComps = () => {
		if ( compsRef.current !== null ) {
			recordEvent("View Rental Comps", {
				"CityId": cityId,
				"zpid": id
			});
            compsRef.current.scrollIntoView({ 
                behavior: 'smooth',
            });
			setSelectedCompsPanel(0);
        }
	};

	const closeModal = () => {
		setOfferModal(false);
	};

	const openDealOptimiser = () => {
		setShowOptimiseDeal(true);
	};

	const goToOffers = () => {
        for (let index = 0; index < generatedOffers.length; index++) {
            const element = generatedOffers[index];
            if ( element.zpid === property.zpid ) {
                setOfferPropertyTerms(element);
                break;
            }
        };
        setOfferModal(true);
    };

	const downloadOffer = (url) => {
		// Create a link element
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('target', '_blank');
		link.setAttribute('download', 'filename.ext'); // You can set the file name dynamically here if needed
	
		// Append to the document and trigger click
		document.body.appendChild(link);
		link.click();
	
		// Clean up and remove the link
		link.parentNode.removeChild(link);
	};

	const getOffer = async() => {
		if ( paidUser === false && totalGeneratedOffers >=3 ) {
			setUpgradeModal(true);
			return;
		}

        setOfferLoading(true);
        const params = {
            overrides: {
                market_value: formatterLong.format(price).replace(".00", ""),
                cf_down_payment_amount: `${downPayment}%`,
                cf_interest_rate: `${interestRate}%`,
                cf_amortization_term_months: `${amortisation} months`,
                cf_balloon_term_months: `${duration} months`,
            },
            address: `${property.address.streetAddress}, ${property.address.city} ${property.address.state} ${property.address.zipcode}`,
            templateId: strategyPanel === 0 ? "Owner Finance Offer with Balloon" : "Subject-to with Seller Finance"
        };

		if ( strategyPanel === 1 ) {
			params.overrides.mortgage_amount = formatterLong.format(Number(remainingMortgage)).replace(".00", "");
		}

        const queryData = await queryOfferREI(params);
        if ( queryData.status === 200 ) {
            setOfferLink(queryData.data);
            downloadOffer(queryData.data);
            setOfferLoading(false);
            if ( userId !== undefined && userId !== null ) {
                const getProperties = await getDocument("Creative Listings", userId);
                const newData = getProperties.status === 200 ? getProperties.data.data : [{cityId: cityId, hiddenProperties: [], offers: []}];
                const cityIndex = newData.findIndex(e => e.cityId === cityId);
                if ( cityIndex !== -1 ) {
                    const oldOffers = newData[cityIndex].offers;
                    const offerIndex = oldOffers.findIndex(e => e.zpid === property.zpid);
                    if ( offerIndex === -1 ) {
                        const newObject = {
                            zpid: property.zpid,
                            downPayment: Number(downPayment),
                            interestRate: Number(interestRate),
                            amortisation: Number(amortisation),
                            duration: Number(duration),
                            price: Number(price),
                            offerLink: queryData.data,
                            sent: false,
                            sellerCompensation: sellerCompensation,
							strategy: strategyWords,
							mortgageAmount: Number(remainingMortgage)
                        }
                        oldOffers.push(newObject);
                        newData[cityIndex].offers = oldOffers;
                    }
                    else {
                        oldOffers[offerIndex].downPayment = Number(downPayment);
                        oldOffers[offerIndex].interestRate = Number(interestRate);
                        oldOffers[offerIndex].amortisation = Number(amortisation);
                        oldOffers[offerIndex].duration = Number(duration);
                        oldOffers[offerIndex].price = Number(price);
                        oldOffers[offerIndex].sellerCompensation = Number(sellerCompensation);
                        oldOffers[offerIndex].offerLink = queryData.data;
						oldOffers[offerIndex].strategy = strategyWords;
						oldOffers[offerIndex].mortgageAmount = Number(remainingMortgage);
                        newData[cityIndex].offers = oldOffers;
                    }
					setGeneratedOffers(oldOffers);
                    await setData("Creative Listings", userId, newData);
                }
				else {
					const newCity = {
						cityId: cityId,
						hiddenProperties: [],
						offers: [
							{
								zpid: property.zpid,
								downPayment: Number(downPayment),
								interestRate: Number(interestRate),
								amortisation: Number(amortisation),
								duration: Number(duration),
								price: Number(price),
								offerLink: queryData.data,
								sent: false,
								sellerCompensation: sellerCompensation,
								strategy: strategyWords,
								mortgageAmount: Number(remainingMortgage)
							}
						]
					};
					newData.push(newCity);
					setGeneratedOffers(newCity.offers);
					await setData("Creative Listings", userId, newData);
				}
            }
			else {
				const oldOffers = [...generatedOffers];
				const offerIndex = oldOffers.findIndex(e => e.zpid === property.zpid);
				if ( offerIndex === -1 ) {
					const newObject = {
						zpid: property.zpid,
						downPayment: Number(downPayment),
						interestRate: Number(interestRate),
						amortisation: Number(amortisation),
						duration: Number(duration),
						price: Number(price),
						offerLink: queryData.data,
						sent: false,
						sellerCompensation: sellerCompensation,
						strategy: strategyWords,
						mortgageAmount: Number(remainingMortgage)
					}
					oldOffers.push(newObject);
				}
				else {
					oldOffers[offerIndex].downPayment = Number(downPayment);
					oldOffers[offerIndex].interestRate = Number(interestRate);
					oldOffers[offerIndex].amortisation = Number(amortisation);
					oldOffers[offerIndex].duration = Number(duration);
					oldOffers[offerIndex].price = Number(price);
					oldOffers[offerIndex].sellerCompensation = Number(sellerCompensation);
					oldOffers[offerIndex].offerLink = queryData.data;
					oldOffers[offerIndex].strategy = strategyWords;
					oldOffers[offerIndex].mortgageAmount = Number(remainingMortgage);
				}
				setGeneratedOffers(oldOffers);
				setOfferLoading(false);
			}
        }
    };

	return (
		<div className={"property-outer-container " + (offerModal === true || offerLoading === true || fullScreenFinancials === true || showOptimiseDeal === true || upgradeModal === true ? "relative-container" : "") + (mobileBar === true ? "property-mobile-bar-active" : "") + (fullScreenFinancials === true || showOptimiseDeal === true ? " property-no-scroll" : "")}>
			<Header
				subscriptions={subscriptions}
                users={userDetails}
                queries={[false, false]}
				mobileNav={false}
			/>
			{
				offerModal === true || offerLoading === true || fullScreenFinancials === true || showOptimiseDeal === true || upgradeModal === true ?
				<div className="dark-bg">
				</div>
				:
				null
			}
			<PropertyPageEffect
				cityId={cityId}
				setCityId={setCityId}
				setProperty={setProperty}
				setUserDetails={setUserDetails}
				setMobileBar={setMobileBar}
				id={id}
				queryMade={queryMade}
				setQueryMade={setQueryMade}
				setLoading={setLoading}
				setNoProperty={setNoProperty}
				setSpreadsheetProperty={setSpreadsheetProperty}
				setNeighbourhoodData={setNeighbourhoodData}
				setNeighbourhoodBounds={setNeighbourhoodBounds}
				setTracts={setTracts}
				setArvComps={setArvComps}
				setInnerWidth={setInnerWidth}
				setUserId={setUserId}
				setGeneratedOffers={setGeneratedOffers}
				setPrice={setPrice}
				strategyPanel={strategyPanel}
				setDownPayment={setDownPayment}
				setInterestRate={setInterestRate}
				setOwnerEquity={setOwnerEquity}
				setDuration={setDuration}
				propertySearch={true}
				setLoadingStep={setLoadingStep}
				setFavourites={setFavourites}
				setBlurred={setBlurred}
				setLeaseOptionValue={setLeaseOptionValue}
				setRemainingMortgage={setRemainingMortgage}
				setSellersMortgage={setSellersMortgage}
				setTotalGeneratedOffers={setTotalGeneratedOffers}
				setPaidUser={setPaidUser}
				setSubscriptions={setSubscriptions}
			/>
			{
				showARVCompsAnalysis === true && property.arvCompsSimilarProp !== null ?
				<ARVCompsAnalysis
					property={property}
					neighbourhoodData={neighbourhoodData}
					neighbourhoodBounds={neighbourhoodBounds}
					comps={arvComps}
					closeCompsAnalysis={closeCompsAnalysis}
					saveCompsAnalysis={saveCompsAnalysis}
					userId={userId}
					signUpModal={signUpModal}
					setSignUpModal={setSignUpModal}
					savedData={savedSalesCompData}
					setSavedData={setSavedSalesCompData}
					editedProperty={editedProperty}
					setEditedProperty={setEditedProperty}
					loanYears={loanYears}
					strategyPanel={strategyPanel}
					switchCompsTool={switchCompsTool}
				/>
				:
				showRentCompsAnalysis === true ?
				<RentCompsAnalysis
					property={property}
					neighbourhoodData={neighbourhoodData}
					neighbourhoodBounds={neighbourhoodBounds}
					comps={property.rentComps}
					closeCompsAnalysis={closeCompsAnalysis}
					saveCompsAnalysis={saveCompsAnalysis}
					userId={userId}
					signUpModal={signUpModal}
					setSignUpModal={setSignUpModal}
					savedData={savedRentCompData}
					setSavedData={setSavedRentCompData}
					editedProperty={editedProperty}
					setEditedProperty={setEditedProperty}
					loanYears={loanYears}
					strategyPanel={strategyPanel}
					switchCompsTool={switchCompsTool}
				/>
				:
				null
			}
			{
				fullScreenFinancials === true ?
				<FullscreenCalculator
					strategyPanel={strategyPanel}
					setStrategyPanel={setStrategyPanel}
					property={property}
					highLevelProfits={highLevelProfits}
					setHighLevelProfits={setHighLevelProfits}
					price={price}
					setPrice={setPrice}
					downPayment={downPayment}
					setDownPayment={setDownPayment}
					interestRate={interestRate}
					setInterestRate={setInterestRate}
					balloonPayment={balloonPayment}
					setBalloonPayment={setBalloonPayment}
					duration={duration}
					setDuration={setDuration}
					amortisation={amortisation}
					setAmortisation={setAmortisation}
					monthlyPayment={monthlyPayment}
					setMonthlyPayment={setMonthlyPayment}
					sellerCompensation={sellerCompensation}
					setSellerCompensation={setSellerCompensation}
					viewRentalComps={viewRentalComps}
					mobileBar={mobileBar}
					toggleMobileBottomBar={toggleMobileBottomBar}
					cityId={cityId}
					ownerEquity={ownerEquity}
					defaultDownPayment={defaultDownPayment}
					setFullScreenFinancials={setFullScreenFinancials}
					subToDisabled={subToDisabled}
					fullScreenFinancials={fullScreenFinancials}
					setOwnerEquity={setOwnerEquity}
					devMode={devMode}
					openDealOptimiser={openDealOptimiser}
					costPerSqFoot={costPerSqFoot}
					setCostPerSqFoot={setCostPerSqFoot}
					totalRehabCost={totalRehabCost}
					setTotalRehabCost={setTotalRehabCost}
					defaultCostPerSqFoot={defaultCostPerSqFoot}
					blurred={blurred}
					exitStrategy={exitStrategy}
					setExitStrategy={setExitStrategy}
					leaseOptionValue={leaseOptionValue}
					setLeaseOptionValue={setLeaseOptionValue}
					leaseOptionDownPayment={leaseOptionDownPayment}
					setLeaseOptionDownPayment={setLeaseOptionDownPayment}
					leaseOptionDollarDownPayment={leaseOptionDollarDownPayment}
					setLeaseOptionDollarDownPayment={setLeaseOptionDollarDownPayment}
					leaseOptionInterestRate={leaseOptionInterestRate}
					setLeaseOptionInterestRate={setLeaseOptionInterestRate}
					strRevenue={strRevenue}
					setStrRevenue={setStrRevenue}
					remainingMortgage={remainingMortgage}
					setRemainingMortgage={setRemainingMortgage}
					sellersMortgage={sellersMortgage}
					setSellersMortgage={setSellersMortgage}
				/>
				:
				null
			}
			{
				showOptimiseDeal === true ?
				<OptimiseDealModal
					property={property}
					optimiseDealLoading={optimiseDealLoading}
					setOptimiseDealLoading={setOptimiseDealLoading}
					price={price}
					strategyPanel={strategyPanel}
					duration={duration}
					monthlyCosts={totalMonthlyCosts}
					setShowOptimiseDeal={setShowOptimiseDeal}
				/>
				:
				null
			}
			{
				upgradeModal === true ?
				<UpgradeModalQuickReport
					setViewUpgradeModal={setUpgradeModal}
					title="You're out of offer letters :("
					description="You've reached your limit of 3 offer letters. Upgrade to a paid plan to generate more."
				/>
				:
				null
			}
			<div className="property-relative-container">
				{
					property.length === 0 || highLevelProfits === "" ?
					null
					:
					<StickyROI
						formatter={formatterLong}
						highLevelProfits={highLevelProfits}
						toggleFavourite={toggleFavourite}
						favourites={favourites}
						setFavourites={setFavourites}
						setFavouriteDisable={setFavouriteDisable}
						navigate={navigate}
						favouriteDisable={favouriteDisable}
						freeTrial={freeTrial}
						downloadCSV={downloadExcel}
						property={property}
						cityId={cityId}
						downPayment={downPayment}
						blurred={blurred}
						spreadsheetProperty={spreadsheetProperty}
						interestRate={interestRate}
						propertySearch={false}
						partner={false}
						partnerId={null}
						partnerView={false}
						downloadPDF={downloadPDF}
						strategyPanel={strategyPanel}
						oldFormat={false}
						testMode={false}
						offerLink={offerLink}
						getOffer={getOffer}
						goToOffers={goToOffers}
						offerLoading={offerLoading}
						exitStrategy={exitStrategy}
						totalGeneratedOffers={totalGeneratedOffers}
						paidUser={paidUser}
					/>
				}
			</div>
			{
				loading === true ?
				<ReportLoading
					step={loadingStep}
				/>
				:
				(queryMade === true && property.length === 0) || (noProperty === true) ?
				<div className="property-body-container property-no-property-container">
					<h2 className="property-not-found-text">
						No property found {":("}
					</h2>
				</div>
				:
				property.length !== 0 ?
				<div className={"property-body-container " + (mobileBar === true ? "relative-container" : "") + (showARVCompsAnalysis === true || showRentCompsAnalysis ? " desktop-none" : "")}>
					{
						offerLoading === true ?
						<Loading />
						:
						null
					}
					{
						property.images !== undefined && property.images !== null && property.images.length > 0 && mapImages() === false ?
						<ImageGrid 
							property={property}
							images={property.images !== undefined ? property.images : [property.imgSrc]}
							title={property.address.streetAddress}
							preview={false}
							blurred={blurred}
						/>
						:
						null
					}
					{
						offerModal === true && offerPropertyTerms !== null ?
						<OfferModal
							offerProperty={property}
							closeModal={closeModal}
							offerPropertyTerms={offerPropertyTerms}
							userData={userDetails}
						/>
						:
						null
					}
					<div className="property-inner-body-container">
						<div className="property-inner-body-left-container">
							{
								property.images === undefined || property.images === null || property.images.length === 0 || (property.images.length === 1 && mapImages() === true) ?
								<OffMarketImageContainer
									property={property}
								/>
								:
								null
							}
							<PropertyAbstract
								property={property}
								formatter={formatterLong}
								propertySearch={false}
								queryARV={null}
								arvLoading={false}
								arvErrorMessage={null}
								partner={false}
								strategy={strategyPanel}
								runComps={runComps}
							/>
							<PropertyDetails
								property={property}
								formatter={formatterLong}
								propertySearch={false}
							/>
							{
								property.ownerAttributes !== undefined ?
								<OwnerDetails
									property={property}
									blurred={blurred}
									freeTrial={freeTrial}
								/>
								:
								null
							}
							{
								neighbourhoodData.length !== 0 ?
								<NeighbourhoodData
									neighbourhoodData={neighbourhoodData}
									property={property}
									setShowUpgradeModal={null}
								/>
								:
								null
							}
						</div>
						{
							mobileBar === true ?
							<ExitScreen
								clickScreen={() => toggleMobileBottomBar()}
							/>
							:
							null
						}
						{
							(showARVCompsAnalysis === true || showRentCompsAnalysis === true || applyEstimateModal === true) && innerWidth > 999 ?
							null
							:
							strategyPanel === 1 && subToDisabled === false ?
							<SubtoCalculator
								property={property}
								highLevelProfits={highLevelProfits}
								setHighLevelProfits={setHighLevelProfits}
								price={price}
								setPrice={setPrice}
								downPayment={downPayment}
								setDownPayment={setDownPayment}
								interestRate={interestRate}
								setInterestRate={setInterestRate}
								balloonPayment={balloonPayment}
								setBalloonPayment={setBalloonPayment}
								duration={duration}
								setDuration={setDuration}
								amortisation={amortisation}
								setAmortisation={setAmortisation}
								monthlyPayment={monthlyPayment}
								setMonthlyPayment={setMonthlyPayment}
								sellerCompensation={sellerCompensation}
								setSellerCompensation={setSellerCompensation}
								viewRentalComps={viewRentalComps}
								mobileBar={mobileBar}
								toggleMobileBottomBar={toggleMobileBottomBar}
								cityId={cityId}
								strategyPanel={strategyPanel}
								setStrategyPanel={setStrategyPanel}
								ownerEquity={ownerEquity}
								defaultDownPayment={defaultDownPayment}
								fullScreenFinancials={fullScreenFinancials}
								setFullScreenFinancials={setFullScreenFinancials}
								setOwnerEquity={setOwnerEquity}
								costPerSqFoot={costPerSqFoot}
								setCostPerSqFoot={setCostPerSqFoot}
								totalRehabCost={totalRehabCost}
								setTotalRehabCost={setTotalRehabCost}
								defaultCostPerSqFoot={defaultCostPerSqFoot}
								blurred={blurred}
								exitStrategy={exitStrategy}
								setExitStrategy={setExitStrategy}
								leaseOptionValue={leaseOptionValue}
								setLeaseOptionValue={setLeaseOptionValue}
								leaseOptionDownPayment={leaseOptionDownPayment}
								setLeaseOptionDownPayment={setLeaseOptionDownPayment}
								leaseOptionDollarDownPayment={leaseOptionDollarDownPayment}
								setLeaseOptionDollarDownPayment={setLeaseOptionDollarDownPayment}
								leaseOptionInterestRate={leaseOptionInterestRate}
								setLeaseOptionInterestRate={setLeaseOptionInterestRate}
								strRevenue={strRevenue}
								setStrRevenue={setStrRevenue}
								remainingMortgage={remainingMortgage}
								setRemainingMortgage={setRemainingMortgage}
								sellersMortgage={sellersMortgage}
								setSellersMortgage={setSellersMortgage}
							/>
							:
							<SellerFinanceCalculator
								property={property}
								highLevelProfits={highLevelProfits}
								setHighLevelProfits={setHighLevelProfits}
								price={price}
								setPrice={setPrice}
								downPayment={downPayment}
								setDownPayment={setDownPayment}
								interestRate={interestRate}
								setInterestRate={setInterestRate}
								balloonPayment={balloonPayment}
								setBalloonPayment={setBalloonPayment}
								duration={duration}
								setDuration={setDuration}
								amortisation={amortisation}
								setAmortisation={setAmortisation}
								monthlyPayment={monthlyPayment}
								setMonthlyPayment={setMonthlyPayment}
								sellerCompensation={sellerCompensation}
								setSellerCompensation={setSellerCompensation}
								viewRentalComps={viewRentalComps}
								mobileBar={mobileBar}
								toggleMobileBottomBar={toggleMobileBottomBar}
								cityId={cityId}
								strategyPanel={strategyPanel}
								setStrategyPanel={setStrategyPanel}
								subToDisabled={subToDisabled}
								defaultDownPayment={defaultDownPayment}
								fullScreenFinancials={fullScreenFinancials}
								setFullScreenFinancials={setFullScreenFinancials}
								devMode={devMode}
								openDealOptimiser={openDealOptimiser}
								costPerSqFoot={costPerSqFoot}
								setCostPerSqFoot={setCostPerSqFoot}
								totalRehabCost={totalRehabCost}
								setTotalRehabCost={setTotalRehabCost}
								defaultCostPerSqFoot={defaultCostPerSqFoot}
								setOwnerEquity={setOwnerEquity}
								blurred={blurred}
								exitStrategy={exitStrategy}
								setExitStrategy={setExitStrategy}
								leaseOptionValue={leaseOptionValue}
								setLeaseOptionValue={setLeaseOptionValue}
								leaseOptionDownPayment={leaseOptionDownPayment}
								setLeaseOptionDownPayment={setLeaseOptionDownPayment}
								leaseOptionDollarDownPayment={leaseOptionDollarDownPayment}
								setLeaseOptionDollarDownPayment={setLeaseOptionDollarDownPayment}
								leaseOptionInterestRate={leaseOptionInterestRate}
								setLeaseOptionInterestRate={setLeaseOptionInterestRate}
								strRevenue={strRevenue}
								setStrRevenue={setStrRevenue}
							/>
						}
					</div>
					{
						neighbourhoodData.length !== 0 && neighbourhoodBounds.length !== 0 ?
						<Location
							property={property}
							cityId={cityId}
							cityState={cityState}
							neighbourhoodData={neighbourhoodData}
							tracts={tracts}
							neighbourhoodBounds={neighbourhoodBounds}
							setShowUpgradeModal={null}
						/>
						:
						null
					}
					{
						property.rentComps !== null || property.unitPropertyComps !== undefined ?
						<Comparables
							property={property}
							blurred={blurred}
							propertySearch={false}
							compsRef={compsRef}
							selectedPanel={selectedCompsPanel}
							setSelectedPanel={setSelectedCompsPanel}
							runComps={runComps}
							savedData={savedSalesCompData}
							setSavedData={setSavedSalesCompData}
							savedRentData={savedRentCompData}
							setSavedRentData={setSavedRentCompData}
							editedProperty={editedProperty}
							setEditedProperty={setEditedProperty}
							strategyPanel={strategyPanel}
							loanYears={loanYears}
							applyEstimateModal={applyEstimateModal}
							setApplyEstimateModal={setApplyEstimateModal}
						/>
						:
						null
					}
					<div className="property-bottom-body-container">
						{
							property.listedBy === null || property.attributionInfo === null ?
							null
							:
							<ContactAgent
								property={property}
								partnerData={null}
								state={state}
							/>
						}
						<ShareButtons
							property={property}
							favourites={favourites}
							toggleFavourite={toggleFavourite}
							favouriteDisable={favouriteDisable}
							setFavourites={setFavourites}
							setFavouriteDisable={setFavouriteDisable}
							navigate={navigate}
							cityId={cityId}
							userDetails={userDetails}
							freeTrial={freeTrial}
							spreadsheetProperty={spreadsheetProperty}
							downPayment={downPayment}
							propertySearch={false}
							partner={false}
							downloadPDF={downloadPDF}
							strategyPanel={strategyPanel}
							blurred={blurred}
						/>
					</div>
					{
						property.length === 0 || highLevelProfits === "" ?
						null
						:
						<MobileBottomBar
							highLevelProfits={highLevelProfits}
							formatter={formatterLong}
							toggleMobileBottomBar={toggleMobileBottomBar}
							mobileBar={mobileBar}
							blurred={blurred}
							strategy={strategyPanel}
							property={property}
							favourites={favourites}
							setFavourites={setFavourites}
							favouriteDisable={favouriteDisable}
							setFavouriteDisable={setFavouriteDisable}
							navigate={navigate}
							cityId={cityId}
							newVersion={true}
						/>
					}
				</div>
				:
				null
			}
			{
				property.length !== 0 ?
				<Footer />
				:
				null
			}
		</div>
	)
}

export default PropertySearch;