import React, { useState, useRef } from 'react';
import moment from 'moment';
import { ApplyEstimate, ProgressBar } from './';
import { formatterLong, percentage, numberFormatter, colour, RedActionButton, GreenActionButton, defaultCompData } from '../styles/GlobalStyles';
import { checkUserId, saveCompData } from '../functions';

function RentalComps(props) {
    const property = props.property;
    const blurred = props.blurred;
    const partnerEdit = props.partnerEdit;
    const deleteComp = props.deleteComp;
    const rent = props.rent;
    const comps = props.comps;
    const multipleUniqueUnits = props.multipleUniqueUnits;
    const savedData = props.savedData === null ? defaultCompData : props.savedData;
    const setSavedData = props.setSavedData;
    const strategyPanel = props.strategyPanel;
    const editedProperty = props.editedProperty;
    const setEditedProperty = props.setEditedProperty;
    const applyEstimateModal = props.applyEstimateModal;
    const setApplyEstimateModal = props.setApplyEstimateModal;
    const excludedComps = savedData === null ? [] : savedData.excludedComps;
    const valueField = property.financials.rent;
    const [direction, setDirection] = useState("descending");
    const [hoverOption, setHoverOption] = useState(null);
    const [sortedComps, setSortedComps] = useState(comps);
    const similarityScoreField = property.propertyTypeDimension === "Multi Family" ? "correlation" : "prediction_label";
    const [sorting, setSorting] = useState(similarityScoreField);
    const [selectedComps, setSelectedComps] = useState([]);
    const [showExcludedComps, setShowExcludedComps] = useState(false);
    const [amendedValue, setAmendedValue] = useState(null);
    const scrollContainer = useRef(null);
    const labels = [
        {
            label: "Address",
            val: null
        },
        {
            label: "Distance",
            val: "distance"
        },
        {
            label: "Similarity",
            val: similarityScoreField
        },
        {
            label: "Rent",
            val: "price"
        },
        {
            label: "Sqft",
            val: "squareFootage"
        },
        {
            label: "Beds",
            val: "bedrooms"
        },
        {
            label: "Baths",
            val: "bathrooms"
        },
        {
            label: "Built in",
            val: "yearBuilt"
        },
        {
            label: "Date",
            val: "listingDate"
        },
        {
            label: "Property type",
            val: null
        }
    ];

    const layoutLetters = [
        "A",
        "B",
        "C",
        "D"
    ];

    const subjectProperty = [
        {
            label: `${property.address.streetAddress}`,
            class: "bg-colour-medium",
            innerClass: multipleUniqueUnits === true && property.propertyTypeDimension === "Multi Family" ? "body-semibold colour-primary block-text text-align-left" : "body-regular colour-primary block-text text-align-left",
            was: null
        },
        {
            label: "",
            class: "bg-colour-medium",
            innerClass: "",
            was: null
        },
        {
            label: "",
            class: "bg-colour-medium",
            innerClass: "",
            was: null
        },
        {
            label: editedProperty !== null && editedProperty !== "" && editedProperty.rent !== null ? formatterLong.format(editedProperty.rent).replace(".00", "") : rent === null && property.financials !== undefined ? formatterLong.format(property.financials.rent).replace(".00", "") : rent !== null ? rent : formatterLong.format(0).replace(".00", ""),
            class: "bg-colour-medium",
            innerClass: "body-regular colour-primary block-text text-align-left",
            was: editedProperty === null || editedProperty === "" ? null : editedProperty.rent === null ? null : editedProperty.rent === property.financials.rent ? null : formatterLong.format(property.financials.rent).replace(".00", ""),
        },
        {
            label: `${property.livingArea !== null ? `${numberFormatter.format(property.livingArea)}` : "N/A"}`,
            class: "bg-colour-medium",
            innerClass: "body-regular colour-primary block-text text-align-left",
            was: null
        },
        {
            label: `${property.bedrooms}`,
            class: "bg-colour-medium",
            innerClass: "body-regular colour-primary block-text text-align-left",
            was: null
        },
        {
            label: `${property.bathrooms}`,
            class: "bg-colour-medium",
            innerClass: "body-regular colour-primary block-text text-align-left",
            was: null
        },
        {
            label: `${property.yearBuilt}`,
            class: "bg-colour-medium",
            innerClass: "body-regular colour-primary block-text text-align-left",
            was: null
        },
        {
            label: "",
            class: "bg-colour-medium",
            innerClass: "",
            was: null
        },
        {
            label: `${(property.resoFacts !== undefined && property.resoFacts.propertyType === "SINGLE_FAMILY") || (property.propertyTypeDimension === "Single Family") ? "Single Family" : property.propertyTypeDimension === "Condo" ? "Condo" : property.propertyTypeDimension === "Townhouse" ? "Townhouse" : "Multi Family"}`,
            class: "bg-colour-medium",
            innerClass: "body-regular colour-secondary white-space-no-wrap block-text text-align-left",
            was: null
        }
    ];

    const findListingDate = (item) => {
        const formattedDate = moment(item).format("MMM D, YYYY");
        return formattedDate;
    };

    const findListingDateDifference = (item) => {
        const date = item;
        const monthsDifference = moment().diff(moment(date), 'months');
        const weeksDifference = moment().diff(moment(date), 'weeks');
        const daysDifference = moment().diff(moment(date), 'days');
        const relevantDifference = monthsDifference > 0 ? monthsDifference : weeksDifference > 0 ? weeksDifference : daysDifference;
        const relevantUnit = monthsDifference > 0 ? "month" : weeksDifference > 0 ? "week" : "day";
        const finalString = relevantDifference === 0 ? "today" : `${relevantDifference} ${relevantUnit}${relevantDifference === 1 ? "" : "s"} ago`;

        return finalString;
    };

    const sortVals = (newComps, val, type, number) => {
        if ( type === "descending" ) {
            if ( number === true ) {
                newComps.sort((a, b) => (a[val] == null && b[val] == null) ? 0 : (a[val] == null) ? 1 : (b[val] == null) ? -1 : (Number(a[val]) < Number(b[val])) ? 1 : (Number(b[val]) < Number(a[val])) ? -1 : 0);
            }
            else {
                newComps.sort((a, b) => (a[val] == null && b[val] == null) ? 0 : (a[val] == null) ? 1 : (b[val] == null) ? -1 : (a[val] < b[val]) ? 1 : (b[val] < a[val]) ? -1 : 0);
            }
        }
        else {
            if ( number === true ) {
                newComps.sort((a, b) => (a[val] == null && b[val] == null) ? 0 : (a[val] == null) ? 1 : (b[val] == null) ? -1 : (Number(a[val]) > Number(b[val])) ? 1 : (Number(b[val]) > Number(a[val])) ? -1 : 0);
            }
            else {
                newComps.sort((a, b) => (a[val] == null && b[val] == null) ? 0 : (a[val] == null) ? 1 : (b[val] == null) ? -1 : (a[val] > b[val]) ? 1 : (b[val] > a[val]) ? -1 : 0);
            }
        }
        return newComps;
    }

    const reorderComps = async(val) => {
        if ( property.propertyTypeDimension === "Multi Family" ) {
            const finalComps = [];
            for (let index = 0; index < comps.length; index++) {
                const element = comps[index];
                const arrayClone = [...element];
                if ( sorting === val ) {
                    if ( direction === "descending" ) {
                        const checkNumber = val === "listingDate" ? false : true;
                        const newComps = await sortVals(arrayClone, val, "ascending", checkNumber);
                        finalComps.push(newComps);
                    }
                    else {
                        const checkNumber = val === "listingDate" ? false : true;
                        const newComps = await sortVals(arrayClone, val, "descending", checkNumber);
                        finalComps.push(newComps);
                    }
                }
                else {
                    const checkNumber = val === "listingDate" ? false : true;
                    const newComps = await sortVals(arrayClone, val, "descending", checkNumber);
                    finalComps.push(newComps);
                }   
            }

            if ( direction === "descending" ) {
                setDirection("ascending");
            }
            else {
                setDirection("descending");
            }
            setSorting(val);
            setSortedComps(finalComps);
        }
        else {
            if ( sorting === val ) {
                if ( direction === "descending" ) {
                    setSorting(val);
                    const compsClone = [...comps];
                    const checkNumber = val === "listingDate" ? false : true;
                    const newComps = await sortVals(compsClone, val, "ascending", checkNumber);
                    setSortedComps(newComps);
                    setDirection("ascending");
                }
                else {
                    setSorting(val);
                    const compsClone = [...comps];
                    const checkNumber = val === "listingDate" ? false : true;
                    const newComps = await sortVals(compsClone, val, "descending", checkNumber);
                    setSortedComps(newComps);
                    setDirection("descending");
                }
            }
            else {
                setSorting(val);
                const compsClone = [...comps];
                const checkNumber = val === "listingDate" ? false : true;
                const newComps = await sortVals(compsClone, val, "descending", checkNumber);
                setSortedComps(newComps);
                setDirection("descending");
            }
        }
        
        if ( scrollContainer.current !== null ) {
            scrollContainer.current.scrollTo(0, 0);
        }
    };

    const toggleCheckbox = (item) => {
        const excludedSelectedComps = [];
        for (let index = 0; index < selectedComps.length; index++) {
            const element = selectedComps[index];
            const excludedIndex = excludedComps.indexOf(element);
            if ( excludedIndex !== -1 ) {
                excludedSelectedComps.push(element);
            }
        }

        const selectedCompsClone = excludedSelectedComps.length === 0 ? [...selectedComps] : [];
        const compIndex = selectedCompsClone.indexOf(item.id);
        if ( compIndex === -1 ) {
            selectedCompsClone.push(item.id);
        }
        else {
            selectedCompsClone.splice(compIndex, 1);
        }
        setSelectedComps(selectedCompsClone);
    };

    const toggleExcludedCheckbox = (item) => {
        const nonExcludedComps = [];
        for (let index = 0; index < selectedComps.length; index++) {
            const element = selectedComps[index];
            const excludedIndex = excludedComps.indexOf(element);
            if ( excludedIndex === -1 ) {
                nonExcludedComps.push(element);
            }
        }

        const selectedCompsClone = nonExcludedComps.length === 0 ? [...selectedComps] : [];
        const compIndex = selectedCompsClone.indexOf(item.id);
        if ( compIndex === -1 ) {
            selectedCompsClone.push(item.id);
        }
        else {
            selectedCompsClone.splice(compIndex, 1);
        }
        setSelectedComps(selectedCompsClone);
    };
    
    const toggleAllCheckboxes = () => {
        if ( property.propertyTypeDimension === "Multi Family" ) {
            const newArray = comps.flat(2);
            if ( selectedComps.length === (newArray.length - excludedComps.length) ) {
                setSelectedComps([]);
            }
            else {
                const idArray = newArray.flat(2).map(item => item.id);
                const finalComps = [];
                for (let index = 0; index < idArray.length; index++) {
                    const element = idArray[index];
                    const id = idArray[index];
                    const excludedIndex = excludedComps.indexOf(id);
                    if ( excludedIndex === -1 ) {
                        finalComps.push(element);
                    }
                }
                setSelectedComps(finalComps);
            }
        }
        else {
            if ( selectedComps.length === (comps.length - excludedComps.length) ) {
                setSelectedComps([]);
            }
            else {
                const idArray = comps.flat(2).map(item => item.id);
                const finalComps = [];
                for (let index = 0; index < idArray.length; index++) {
                    const element = idArray[index];
                    const id = idArray[index];
                    const excludedIndex = excludedComps.indexOf(id);
                    if ( excludedIndex === -1 ) {
                        finalComps.push(element);
                    }
                }
                setSelectedComps(finalComps);
            }
        }
    };

    const checkSelectedComp = (item) => {
        const compIndex = selectedComps.findIndex(e => e === item.id);
        if ( compIndex === -1 ) {
            return false;
        }
        else {
            return true;
        }
    };

    const checkCompActive = (item) => {
        const compIndex = comps.findIndex(e => e.id === item.id);
        if ( partnerEdit === false ) {
            return true;
        }
        else if ( compIndex !== -1 ) {
            return true;
        }
        else {
            return false;
        }
    };

    const excludeComps = async() => {
        const savedDataClone = structuredClone(savedData);
        const newArray = [];
        for (let index = 0; index < selectedComps.length; index++) {
            const element = selectedComps[index];
            const excludedIndex = excludedComps.indexOf(element);
            if ( excludedIndex === -1 ) {
                newArray.push(element);
            }
        };
        const newExcludedComps = [...excludedComps, ...newArray];
        savedDataClone.excludedComps = newExcludedComps;
        setSavedData(savedDataClone);
        setSelectedComps([]);

        const user = await checkUserId();
        if ( user.status === 200 ) {
            const userId = user.userId;
            const finalCompData = {
                rentCompData: savedDataClone,
            };
            await saveCompData(finalCompData, userId, property.zpid);
        }
        
        if ( property.propertyTypeDimension === "Single Family" ) {
            recalculateRent(newExcludedComps);
        }
    };

    const includeComps = async() => {
        const savedDataClone = structuredClone(savedData);
        const newArray = []
        for (let index = 0; index < excludedComps.length; index++) {
            const element = excludedComps[index];
            const excludedIndex = selectedComps.indexOf(element);
            if ( excludedIndex === -1 ) {
                newArray.push(element);
            }
        };
        savedDataClone.excludedComps = newArray;
        setSavedData(savedDataClone);
        setSelectedComps([]);
        const user = await checkUserId();
        if ( user.status === 200 ) {
            const userId = user.userId;
            const finalCompData = {
                rentCompData: savedDataClone,
            };
            await saveCompData(finalCompData, userId, property.zpid);
        }

        if ( property.propertyTypeDimension === "Single Family" ) {
            recalculateRent(newArray);
        }
    };

    const recalculateRent = async(newData) => {
        const newComps = [];
        const maxDistance = savedData.maxDistance;
        const maxSoldDate = savedData.maxSoldDate;
        const bedrooms = savedData.bedrooms;
        const bathrooms = savedData.bathrooms;
        const priceRange = savedData.priceRange;
        const squareFootage = savedData.squareFootage;
        const flattenedComps = sortedComps.flat(2);

        for (let index = 0; index < flattenedComps.length; index++) {
            const element = flattenedComps[index];
            const today = new Date();
            const todaySeconds = moment.unix(today.getTime() / 1000);
            const propertyDate = element.lastSeenDate !== undefined ? element.lastSeenDate : element.listingDate;
            const activityDateTS = moment(propertyDate).toDate();
            const activityDate = moment(activityDateTS)
            const updateDateMonthDifference = todaySeconds.diff(activityDate, 'months');

            const checkedMinPrice = priceRange[0] === "" ? 0 : Number(priceRange[0]);
            const checkedMaxPrice = priceRange[1] === "" || priceRange[1] === 0 ? 999999999 : Number(priceRange[1]);

            if ( 
                    element.distance <= maxDistance && 
                    updateDateMonthDifference <= maxSoldDate &&
                    element.bedrooms >= bedrooms &&
                    element.bathrooms >= bathrooms &&
                    element.squareFootage >= squareFootage[0] && element.squareFootage <= squareFootage[1] &&
                    (element.price >= checkedMinPrice && element.price <= checkedMaxPrice)
                ) {
                newComps.push(element);
            }
        }

        if ( newData.length === 0 && property.financials.rent === editedProperty.rent ) {

        }
        else if ( newData.length === 0 ) {
            setAmendedValue(property.financials.rent);
            setApplyEstimateModal(true);
        }
        else {
            const weightedMedian = await recalculateAmendedValue(newComps, newData);
            setAmendedValue(weightedMedian);
            setApplyEstimateModal(true);
        }
    };


    const recalculateAmendedValue = (finalComps, newExcluded) => {
        const values = [];
        const weights = [];
        for (let index = 0; index < finalComps.length; index++) {
            const element = finalComps[index];
            const excludedcompsIndex = newExcluded.indexOf(element.id);
            if ( excludedcompsIndex !== -1 ) {
                continue;
            }

            const price = Number(element.price);
            const weight = Number(element[similarityScoreField]);
            values.push(price);
            weights.push(weight);
        }
        if ( values.length === 0 ) {
            return 0;
        }
    
        const weightedSum = values.reduce((acc, value, index) => acc + value * weights[index], 0);
        const totalWeight = weights.reduce((acc, weight) => acc + weight, 0);
        const weightedAverage = weightedSum / totalWeight;
    
        return weightedAverage;
    };

    const checkCompExcluded = (item) => {
        const compIndex = excludedComps.indexOf(item.id);
        if ( compIndex === -1 ) {
            return false;
        }
        else {
            return true;
        }
    };

    const toggleExcludedComps = () => {
        setShowExcludedComps(!showExcludedComps);

        setTimeout(() => {
            if ( !showExcludedComps === true && scrollContainer.current !== null ) {
                scrollContainer.current.scrollTo(0, scrollContainer.current.scrollHeight);
            }
        }, 100);
    };

    const checkSelectedCompExclusion = () => {
        const newArray = [];
        for (let index = 0; index < selectedComps.length; index++) {
            const element = selectedComps[index];
            const excludedIndex = excludedComps.indexOf(element);
            if ( excludedIndex !== -1 ) {
                newArray.push(element);
            }
        }

        if ( newArray.length === 0 ) {
            return false;
        }
        else {
            return true;
        }
    };

    const closeApplyEstimateModal = () => {
        setApplyEstimateModal(false);
    };

    const saveNewValue = async(val, type) => {
        const removedCommas = val.replaceAll(",", "");
        const newVal = Number(removedCommas);

        setEditedProperty(prevState => ({
            ...prevState,
            rent: newVal,
            strategy: strategyPanel
        }));
        closeApplyEstimateModal();
    };

    return (
        <div className={"comparables-outer-container " + (applyEstimateModal === true ? "relative-container" : "")}>
            {
                applyEstimateModal === true ?
                <ApplyEstimate
                    amendedValue={amendedValue}
                    setAmendedValue={setAmendedValue}
                    valueField={valueField}
                    closeModal={closeApplyEstimateModal}
                    saveCompChanges={closeApplyEstimateModal}
                    saveNewValue={saveNewValue}
                    rentMode={true}
                />
                :
                null
            }
            {
                selectedComps.length === 0 ?
                null
                :
                <div className="comps-table-bulk-action-container margin-medium mobile-none">
                    <div className="comps-table-bulk-action-inner-container">
                        <div className="comps-table-bulk-action-left-container">
                            <span className="body-regular colour-primary">
                                {selectedComps.length} comp{selectedComps.length === 1 ? "" : "s"} selected
                            </span>
                        </div>
                        <div className="comps-table-bulk-action-right-container">
                            {
                                checkSelectedCompExclusion() === false ?
                                <RedActionButton
                                    onClick={() => excludeComps()}
                                >
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="17" 
                                        height="16" 
                                        viewBox="0 0 17 16" 
                                        fill="none"
                                        className="subtext-margin-right"
                                    >
                                        <path 
                                            fillRule="evenodd" 
                                            clipRule="evenodd" 
                                            d="M4.00479 0.648211C3.28082 -0.155843 2.04211 -0.220761 1.23805 0.503212C0.434001 1.22718 0.369083 2.46589 1.09306 3.26995L5.36282 8.012L1.11468 12.73C0.390706 13.5341 0.455625 14.7728 1.25968 15.4968C2.06373 16.2208 3.30244 16.1558 4.02641 15.3518L7.999 10.9398L11.9716 15.3518C12.6956 16.1558 13.9343 16.2207 14.7383 15.4968C15.5424 14.7728 15.6073 13.5341 14.8833 12.73L10.6352 8.012L14.9049 3.26996C15.6289 2.4659 15.564 1.22719 14.7599 0.503221C13.9559 -0.220752 12.7172 -0.155834 11.9932 0.648219L7.999 5.08423L4.00479 0.648211Z" 
                                            fill="#FFFEFC"
                                        />
                                    </svg>
                                    Exclude selection
                                </RedActionButton>
                                :
                                <GreenActionButton
                                    onClick={() => includeComps()}
                                >
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="17" 
                                        height="16" 
                                        viewBox="0 0 17 16" 
                                        fill="none"
                                        className="subtext-margin-right"
                                    >
                                        <path 
                                            fillRule="evenodd" 
                                            clipRule="evenodd" 
                                            d="M15.8237 1.43765C16.4657 2.0449 16.4938 3.05758 15.8866 3.69952L6.55323 13.5662C6.25633 13.8801 5.84511 14.0605 5.4131 14.0665C4.98109 14.0725 4.56502 13.9035 4.25951 13.598L0.792848 10.1314C0.168009 9.50653 0.168009 8.49347 0.792848 7.86863C1.41769 7.24379 2.43075 7.24379 3.05559 7.86863L5.35902 10.1721L13.5619 1.50048C14.1691 0.858536 15.1818 0.830406 15.8237 1.43765Z" 
                                            fill="#FFFEFC"
                                        />
                                    </svg>
                                    Include Selection
                                </GreenActionButton>
                            }
                        </div>
                    </div>
                </div>
            }
            <div className="rental-comps-outer-body-container">
            {
                multipleUniqueUnits === true && property.propertyTypeDimension === "Multi Family" ?
                sortedComps.map((item, index) =>
                    <div 
                        className="rental-comps-multiple-layouts-container"
                        key={index}
                    >
                        <div 
                            className="rental-comps-body-container"
                            ref={scrollContainer}
                        >
                            <table 
                                className="rental-comps-table-container"
                            >
                                <thead className="rental-comps-table-head">
                                    {
                                        index === 0 ?
                                        <tr className="rental-comps-label-row bg-colour-light">
                                            <th className="rental-comps-table-checkbox-container bg-colour-light mobile-none">
                                                <div 
                                                    className="checkbox-container"
                                                    onClick={() => toggleAllCheckboxes()}
                                                >
                                                {
                                                        selectedComps.length === (comps.flat(2).length - excludedComps.length) && checkSelectedCompExclusion() === false ?
                                                        <div className="checkbox-icon">
                                                        </div>
                                                        :
                                                        null
                                                }
                                                </div>
                                            </th>
                                            {
                                                labels.map((item, i) =>
                                                    <th
                                                        className={"rental-comps-table-label relative-container " + (item.val === null ? "" : "cursor-pointer")}
                                                        key={i}
                                                        onMouseEnter={() => setHoverOption(item.val)}
                                                        onMouseLeave={() => setHoverOption(null)}
                                                        onClick={() => item.val === null ? null : reorderComps(item.val)}
                                                        >
                                                        <span className={"body-semibold colour-primary float-left " + (i === 0 ? "block-text" : "")}>
                                                            {item.label}
                                                        </span>
                                                        {
                                                            (item.val === null) || (item.val !== sorting && item.val !== hoverOption) ?
                                                            null
                                                            :
                                                            <div className="rental-comps-icon-container">
                                                                <svg 
                                                                    xmlns="http://www.w3.org/2000/svg" 
                                                                    width="16" 
                                                                    height="16" 
                                                                    viewBox="0 0 16 16" 
                                                                    fill="none"
                                                                >
                                                                    <path 
                                                                        fillRule="evenodd" 
                                                                        clipRule="evenodd"
                                                                        d="M8.35401 5.87499L4.67098 2.19197C4.44497 1.96596 4.07854 1.96596 3.85253 2.19197L0.169507 5.87499C-0.0565021 6.101 -0.0565023 6.46743 0.169507 6.69344C0.395515 6.91945 0.761948 6.91945 0.987957 6.69344L3.68303 3.99837L3.68302 12.3094C3.68302 12.629 3.94213 12.8881 4.26176 12.8881C4.58138 12.8881 4.84049 12.629 4.84049 12.3094L4.84049 3.99837L7.53556 6.69344C7.76156 6.91945 8.128 6.91945 8.35401 6.69344C8.58002 6.46743 8.58002 6.101 8.35401 5.87499ZM15.8305 10.1243L12.1475 13.8074C11.9215 14.0334 11.555 14.0334 11.329 13.8074L7.64599 10.1243C7.41999 9.89834 7.41999 9.53191 7.64599 9.3059C7.872 9.07989 8.23844 9.07989 8.46444 9.3059L11.1595 12.001L11.1595 3.68993C11.1595 3.37031 11.4186 3.1112 11.7382 3.1112C12.0579 3.1112 12.317 3.37031 12.317 3.68993L12.317 12.001L15.012 9.3059C15.2381 9.07989 15.6045 9.07989 15.8305 9.3059C16.0565 9.53191 16.0565 9.89834 15.8305 10.1243Z" 
                                                                        fill={item.val === sorting ? colour.grayScaleBlack : colour.grayScaleGray03}
                                                                    />
                                                                </svg>
                                                            </div>
                                                        }
                                                    </th>
                                                )
                                            }
                                        </tr>
                                        :
                                        null
                                    }
                                    <tr className="rental-comps-subject-property-row">
                                        <th className="rental-comps-table-checkbox-container bg-colour-medium mobile-none">
                                            <span className="label-regular-caps colour-secondary block-text text-align-left margin-left-medium">
                                                SUB.<br/>PROP.
                                            </span>
                                        </th>
                                        {
                                            subjectProperty.map((item, ii) => 
                                                <th
                                                    className={item.class}
                                                    key={ii}
                                                >
                                                    <span className={item.innerClass}>
                                                        {
                                                            ii === 0 ?
                                                            `Layout ${layoutLetters[index]}`
                                                            :
                                                            ii === 3 ?
                                                            formatterLong.format(Number(property.uniqueUnitPropertyDetails[index].rentcast_rent)).replace(".00", "")
                                                            :
                                                            ii === 4 ?
                                                            numberFormatter.format(Number(property.uniqueUnitPropertyDetails[index].sqft))
                                                            :
                                                            ii === 5 ?
                                                            property.uniqueUnitPropertyDetails[index].bedrooms
                                                            :
                                                            ii === 6 ?
                                                            property.uniqueUnitPropertyDetails[index].bathrooms
                                                            :
                                                            item.label
                                                        }
                                                    </span>
                                                    {
                                                        item.was !== null ?
                                                        <span className="body-regular colour-secondary block-text text-italic text-align-left">
                                                            was {item.was}
                                                        </span>
                                                        :
                                                        null
                                                    }
                                                </th>
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        property.unitPropertyComps !== undefined && property.unitPropertyComps !== null && property.unitPropertyComps.length !== 0 ?
                                        sortedComps[index].map((subItem, i) =>
                                            checkCompActive(subItem) === false || checkCompExcluded(subItem) === true ?
                                            null
                                            :
                                            <tr
                                                key={i}
                                                className={"rental-comps-table-row cursor-pointer " + (checkSelectedComp(subItem) ? "rental-comps-table-row-selected" : "")}
                                                onClick={() => toggleCheckbox(subItem)}
                                            >
                                                <td className="rental-comps-table-checkbox-container mobile-none">
                                                    <div 
                                                        className="checkbox-container"
                                                        onClick={() => toggleCheckbox(subItem)}
                                                    >
                                                        {
                                                            checkSelectedComp(subItem) ?
                                                            <div className="checkbox-icon">
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-blur-subject rental-comps-multi-format-address-container">
                                                    <span className="body-regular colour-primary white-space-no-wrap">
                                                        {
                                                            blurred === true ?
                                                            "1234 Main St"
                                                            :
                                                            subItem.address.streetAddress
                                                        }
                                                    </span>
                                                    <span className="label-regular colour-secondary block-text">
                                                        {subItem.address.city}, {subItem.address.state} {subItem.address.zipcode}
                                                    </span>
                                                    {
                                                        partnerEdit === true ?
                                                        <span 
                                                            className="body-regular underline cursor-pointer colour-error"
                                                            onClick={() => deleteComp(subItem)}
                                                        >
                                                            Delete
                                                        </span>
                                                        :
                                                        null
                                                    }
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-multi-format-correlation-container">
                                                    <span className="body-regular colour-primary white-space-no-wrap">
                                                        {
                                                            typeof subItem.distance === "string" ?
                                                            Number(subItem.distance).toFixed(2)
                                                            :
                                                            typeof subItem.distance === "number" ?
                                                            subItem.distance.toFixed(2)
                                                            :
                                                            subItem.distance
                                                        } mi
                                                    </span>
                                                </td>
                                                {
                                                    subItem.userInputted === true ?
                                                    <td className="rental-comps-table-value rental-comps-multi-format-correlation-container">
                                                        <span className="body-regular colour-primary">
                                                            User inputted
                                                        </span>
                                                    </td> 
                                                    :
                                                    <td className="rental-comps-table-value rental-comps-multi-format-correlation-container">
                                                        <span className="body-semibold colour-primary">
                                                            {percentage.format(subItem.correlation).replace(".00", "")}
                                                        </span>
                                                        <div className="rental-comps-progress-bar-outer-container">
                                                            <ProgressBar
                                                                item={subItem}
                                                            />
                                                        </div>
                                                    </td>   
                                                }
                                                <td className="rental-comps-table-value rental-comps-blur-subject rental-comps-multi-format-correlation-container">
                                                    <span className="body-regular colour-primary">
                                                        {
                                                            blurred === true ?
                                                            "$999"
                                                            :
                                                            formatterLong.format(subItem.price).replace(".00", "")
                                                        }
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-multi-format-correlation-container">
                                                    <span className="body-regular colour-primary">
                                                        {
                                                            subItem.squareFootage !== undefined && subItem.squareFootage !== null ?
                                                            numberFormatter.format(subItem.squareFootage)
                                                            :
                                                            "N/A"
                                                        }
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-multi-format-small-container">
                                                    <span className="body-regular colour-primary">
                                                        {subItem.bedrooms}
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-multi-format-small-container">
                                                    <span className="body-regular colour-primary">
                                                        {subItem.bathrooms}
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-multi-format-small-container">
                                                    <span className="body-regular colour-primary">
                                                        {subItem.yearBuilt === null || subItem.yearBuilt === undefined ? "N/A" : subItem.yearBuilt}
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-multi-format-correlation-container">
                                                    <span className="body-regular colour-primary block-text text-align-left">
                                                        {findListingDateDifference(subItem.lastSeenDate)}
                                                    </span>
                                                    <span className="label-regular colour-secondary block-text text-align-left">
                                                        {findListingDate(subItem.lastSeenDate)}
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-multi-format-correlation-container">
                                                    <span className="body-regular colour-secondary">
                                                        {subItem.propertyType}
                                                    </span>
                                                </td>
                                            </tr>   
                                        )
                                        :
                                        null
                                    }
                                    {
                                        excludedComps.length > 0 ?
                                            <tr
                                                className=""
                                            >
                                                <td className="rental-comps-table-checkbox-container mobile-none">
                                                    
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-table-value-address rental-comps-blur-subject"> 
                                                    <div
                                                        className="show-hide-excluded-comps mobile-none text-button button-row"
                                                        onClick={() => toggleExcludedComps()}
                                                    >
                                                        <span className="body-regular text-link block-text subtext-margin-right">
                                                            {
                                                                showExcludedComps === false ?
                                                                "Show excluded comps"
                                                                :
                                                                "Hide excluded comps"
                                                            }
                                                        </span>
                                                        <svg 
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            width="16" 
                                                            height="16" 
                                                            viewBox="0 0 16 16" 
                                                            fill="none"
                                                            className={showExcludedComps === false ? "reverse" : ""}
                                                        >
                                                            <path 
                                                                fillRule="evenodd" 
                                                                clipRule="evenodd" 
                                                                d="M0.242395 12.5943C0.565587 12.9054 1.08959 12.9054 1.41278 12.5943L8 6.25327L14.5872 12.5943C14.9104 12.9054 15.4344 12.9054 15.7576 12.5943C16.0808 12.2831 16.0808 11.7787 15.7576 11.4676L8 4L0.242395 11.4676C-0.0807978 11.7787 -0.0807978 12.2831 0.242395 12.5943Z" 
                                                                fill="#2F4858"
                                                            />
                                                        </svg>
                                                    </div>
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-distance-container rental-comps-blur-subject"> 
                                                    
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-similarity-container rental-comps-blur-subject"> 
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                                </td>
                                            </tr>
                                        :
                                        null
                                    }
                                    {
                                        showExcludedComps === false ?
                                        null
                                        :
                                        property.unitPropertyComps !== undefined && property.unitPropertyComps !== null && property.unitPropertyComps.length !== 0 ?
                                        sortedComps[index].map((subItem, i) =>
                                            checkCompExcluded(subItem) === false ?
                                            null
                                            :
                                            <tr
                                                key={i}
                                                className={"rental-comps-table-row cursor-pointer excluded-comps-table-row " + (checkSelectedComp(subItem) ? "rental-comps-table-row-selected" : "")}
                                                onClick={() => toggleExcludedCheckbox(subItem)}
                                            >
                                                <td className="rental-comps-table-checkbox-container mobile-none">
                                                    <div 
                                                        className="checkbox-container"
                                                        onClick={() => toggleExcludedCheckbox(subItem)}
                                                    >
                                                        {
                                                            checkSelectedComp(subItem) ?
                                                            <div className="checkbox-icon">
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-blur-subject rental-comps-multi-format-address-container">
                                                    <span className="body-regular colour-secondary white-space-no-wrap">
                                                        {
                                                            blurred === true ?
                                                            "1234 Main St"
                                                            :
                                                            subItem.address.streetAddress
                                                        }
                                                    </span>
                                                    <span className="label-regular colour-secondary block-text">
                                                        {subItem.address.city}, {subItem.address.state} {subItem.address.zipcode}
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-multi-format-correlation-container">
                                                    <span className="body-regular colour-secondary white-space-no-wrap">
                                                        {
                                                            typeof subItem.distance === "string" ?
                                                            Number(subItem.distance).toFixed(2)
                                                            :
                                                            typeof subItem.distance === "number" ?
                                                            subItem.distance.toFixed(2)
                                                            :
                                                            subItem.distance
                                                        } mi
                                                    </span>
                                                </td>
                                                {
                                                    subItem.userInputted === true ?
                                                    <td className="rental-comps-table-value rental-comps-multi-format-correlation-container">
                                                        <span className="body-regular colour-secondary">
                                                            User inputted
                                                        </span>
                                                    </td> 
                                                    :
                                                    <td className="rental-comps-table-value rental-comps-multi-format-correlation-container">
                                                        <span className="body-semibold colour-secondary">
                                                            {percentage.format(subItem.correlation).replace(".00", "")}
                                                        </span>
                                                        <div className="rental-comps-progress-bar-outer-container">
                                                            <ProgressBar
                                                                item={subItem}
                                                            />
                                                        </div>
                                                    </td>   
                                                }
                                                <td className="rental-comps-table-value rental-comps-blur-subject rental-comps-multi-format-correlation-container">
                                                    <span className="body-regular colour-secondary">
                                                        {
                                                            blurred === true ?
                                                            "$999"
                                                            :
                                                            formatterLong.format(subItem.price).replace(".00", "")
                                                        }
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-multi-format-correlation-container">
                                                    <span className="body-regular colour-secondary">
                                                        {
                                                            subItem.squareFootage !== undefined && subItem.squareFootage !== null ?
                                                            numberFormatter.format(subItem.squareFootage)
                                                            :
                                                            "N/A"
                                                        }
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-multi-format-small-container">
                                                    <span className="body-regular colour-secondary">
                                                        {subItem.bedrooms}
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-multi-format-small-container">
                                                    <span className="body-regular colour-secondary">
                                                        {subItem.bathrooms}
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-multi-format-small-container">
                                                    <span className="body-regular colour-secondary">
                                                        {subItem.yearBuilt === null || subItem.yearBuilt === undefined ? "N/A" : subItem.yearBuilt}
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-multi-format-correlation-container">
                                                    <span className="body-regular colour-primary block-text text-align-left text-italic">
                                                        {findListingDateDifference(subItem.lastSeenDate)}
                                                    </span>
                                                    <span className="label-regular colour-secondary block-text text-align-left text-italic">
                                                        {findListingDate(subItem.lastSeenDate)}
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-multi-format-correlation-container">
                                                    <span className="body-regular colour-secondary">
                                                        {subItem.propertyType}
                                                    </span>
                                                </td>
                                            </tr>   
                                        )
                                        :
                                        null
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
                :
                property.propertyTypeDimension === "Multi Family" && property.uniqueUnitPropertyDetails !== undefined ?
                    <div 
                        className="rental-comps-body-container"
                        ref={scrollContainer}
                    >
                        <table className="rental-comps-table-container">
                            <thead className="rental-comps-table-head">
                                <tr className="rental-comps-label-row bg-colour-light">
                                    <th className="rental-comps-table-checkbox-container bg-colour-light mobile-none">
                                        <div 
                                            className="checkbox-container"
                                            onClick={() => toggleAllCheckboxes()}
                                        >
                                        {
                                                selectedComps.length === (comps.flat(2).length - excludedComps.length) && checkSelectedCompExclusion() === false ?
                                                <div className="checkbox-icon">
                                                </div>
                                                :
                                                null
                                        }
                                        </div>
                                    </th>
                                    {
                                        labels.map((item, index) => 
                                            <th
                                                className={"rental-comps-table-label relative-container " + (item.val === null ? "" : "cursor-pointer")}
                                                key={index}
                                                onMouseEnter={() => setHoverOption(item.val)}
                                                onMouseLeave={() => setHoverOption(null)}
                                                onClick={() => item.val === null ? null : reorderComps(item.val)}
                                                >
                                                <span className={"body-semibold colour-primary float-left " + (index === 0 ? "block-text" : "")}>
                                                    {item.label}
                                                </span>
                                                {
                                                    (item.val === null) || (item.val !== sorting && item.val !== hoverOption) ?
                                                    null
                                                    :
                                                    <div className="rental-comps-icon-container">
                                                        <svg 
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            width="16" 
                                                            height="16" 
                                                            viewBox="0 0 16 16" 
                                                            fill="none"
                                                        >
                                                            <path 
                                                                fillRule="evenodd" 
                                                                clipRule="evenodd"
                                                                d="M8.35401 5.87499L4.67098 2.19197C4.44497 1.96596 4.07854 1.96596 3.85253 2.19197L0.169507 5.87499C-0.0565021 6.101 -0.0565023 6.46743 0.169507 6.69344C0.395515 6.91945 0.761948 6.91945 0.987957 6.69344L3.68303 3.99837L3.68302 12.3094C3.68302 12.629 3.94213 12.8881 4.26176 12.8881C4.58138 12.8881 4.84049 12.629 4.84049 12.3094L4.84049 3.99837L7.53556 6.69344C7.76156 6.91945 8.128 6.91945 8.35401 6.69344C8.58002 6.46743 8.58002 6.101 8.35401 5.87499ZM15.8305 10.1243L12.1475 13.8074C11.9215 14.0334 11.555 14.0334 11.329 13.8074L7.64599 10.1243C7.41999 9.89834 7.41999 9.53191 7.64599 9.3059C7.872 9.07989 8.23844 9.07989 8.46444 9.3059L11.1595 12.001L11.1595 3.68993C11.1595 3.37031 11.4186 3.1112 11.7382 3.1112C12.0579 3.1112 12.317 3.37031 12.317 3.68993L12.317 12.001L15.012 9.3059C15.2381 9.07989 15.6045 9.07989 15.8305 9.3059C16.0565 9.53191 16.0565 9.89834 15.8305 10.1243Z" 
                                                                fill={item.val === sorting ? colour.grayScaleBlack : colour.grayScaleGray03}
                                                            />
                                                        </svg>
                                                    </div>
                                                }
                                            </th>
                                        )
                                    }
                                </tr>
                                <tr className="rental-comps-subject-property-row">
                                    <th className="rental-comps-table-checkbox-container bg-colour-medium mobile-none">
                                        <span className="label-regular-caps colour-secondary block-text text-align-left margin-left-medium">
                                            SUB.<br/>PROP.
                                        </span>
                                    </th>
                                    {
                                        subjectProperty.map((item, index) => 
                                            <th
                                                className={item.class}
                                                key={index}
                                            >
                                                <span className={item.innerClass}>
                                                    {
                                                        index === 3 && property.uniqueUnitPropertyDetails !== null ?
                                                        formatterLong.format(Number(property.uniqueUnitPropertyDetails[0].rentcast_rent)).replace(".00", "")
                                                        :
                                                        index === 4 && property.uniqueUnitPropertyDetails !== null ?
                                                        numberFormatter.format(Number(property.uniqueUnitPropertyDetails[0].sqft))
                                                        :
                                                        index === 5 && property.uniqueUnitPropertyDetails !== null ?
                                                        property.uniqueUnitPropertyDetails[0].bedrooms
                                                        :
                                                        index === 6 && property.uniqueUnitPropertyDetails !== null ?
                                                        property.uniqueUnitPropertyDetails[0].bathrooms
                                                        :
                                                        item.label
                                                    }
                                                </span>
                                                {
                                                    item.was !== null ?
                                                    <span className="body-regular colour-secondary block-text text-italic text-align-left">
                                                        was {item.was}
                                                    </span>
                                                    :
                                                    null
                                                }
                                            </th>
                                        )
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    property.unitPropertyComps !== undefined && property.unitPropertyComps !== null ?
                                    sortedComps.map((oItem, oIndex) =>
                                        oItem.map((item, index) =>
                                            checkCompActive(item) === false || checkCompExcluded(item) === true ?
                                            null
                                            :
                                            <tr
                                                key={index}
                                                className={"rental-comps-table-row cursor-pointer " + (checkSelectedComp(item) ? "rental-comps-table-row-selected" : "")}
                                                onClick={() => toggleCheckbox(item)}
                                            >
                                                <td className="rental-comps-table-checkbox-container mobile-none">
                                                    <div 
                                                        className="checkbox-container"
                                                        onClick={() => toggleCheckbox(item)}
                                                    >
                                                        {
                                                            checkSelectedComp(item) ?
                                                            <div className="checkbox-icon">
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-blur-subject">
                                                    <span className="body-regular colour-primary">
                                                        {
                                                            blurred === true ?
                                                            "1234 Main St"
                                                            :
                                                            item.address.streetAddress
                                                        }
                                                    </span>
                                                    <span className="label-regular colour-secondary block-text">
                                                        {item.address.city}, {item.address.state} {item.address.zipcode}
                                                    </span>
                                                    {
                                                        partnerEdit === true ?
                                                        <span 
                                                            className="body-regular underline cursor-pointer colour-error"
                                                            onClick={() => deleteComp(item)}
                                                        >
                                                            Delete
                                                        </span>
                                                        :
                                                        null
                                                    }
                                                </td>
                                                <td className="rental-comps-table-value">
                                                    <span className="body-regular colour-primary">
                                                        {Number(item.distance).toFixed(2)} mi
                                                    </span>
                                                </td>
                                                {
                                                    item.userInputted === true ?
                                                    <td className="rental-comps-table-value">
                                                        <span className="body-regular colour-primary">
                                                            User inputted
                                                        </span>
                                                    </td> 
                                                    :
                                                    <td className="rental-comps-table-value">
                                                        <span className="body-semibold colour-primary">
                                                            {percentage.format(item.correlation).replace(".00", "")}
                                                        </span>
                                                        <div className="rental-comps-progress-bar-outer-container">
                                                            <ProgressBar
                                                                item={item}
                                                            />
                                                        </div>
                                                    </td>  
                                                }
                                                <td className="rental-comps-table-value rental-comps-blur-subject">
                                                    <span className="body-regular colour-primary">
                                                        {
                                                            blurred === true ?
                                                            "$999"
                                                            :
                                                            formatterLong.format(item.price).replace(".00", "")
                                                        }
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value">
                                                    <span className="body-regular colour-primary">
                                                        {
                                                            item.squareFootage !== undefined && item.squareFootage !== null ?
                                                            numberFormatter.format(item.squareFootage)
                                                            :
                                                            "N/A"
                                                        }
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value">
                                                    <span className="body-regular colour-primary">
                                                        {item.bedrooms}
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value">
                                                    <span className="body-regular colour-primary">
                                                        {item.bathrooms}
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value">
                                                    <span className="body-regular colour-primary">
                                                        {item.yearBuilt === undefined || item.yearBuilt === null ? "N/A" : item.yearBuilt}
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value">
                                                    <span className="body-regular colour-primary block-text text-align-left text-italic">
                                                        {findListingDateDifference(item.lastSeenDate)}
                                                    </span>
                                                    <span className="label-regular colour-secondary block-text text-align-left text-italic">
                                                        {findListingDate(item.lastSeenDate)}
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value">
                                                    <span className="body-regular colour-secondary">
                                                        {item.propertyType}
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    )
                                    :
                                    null      
                                }
                                {
                                    excludedComps.length > 0 ?
                                        <tr
                                            className=""
                                        >
                                            <td className="rental-comps-table-checkbox-container mobile-none">
                                                
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-table-value-address rental-comps-blur-subject"> 
                                                <div
                                                    className="show-hide-excluded-comps mobile-none text-button button-row"
                                                    onClick={() => toggleExcludedComps()}
                                                >
                                                    <span className="body-regular text-link block-text subtext-margin-right">
                                                        {
                                                            showExcludedComps === false ?
                                                            "Show excluded comps"
                                                            :
                                                            "Hide excluded comps"
                                                        }
                                                    </span>
                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg" 
                                                        width="16" 
                                                        height="16" 
                                                        viewBox="0 0 16 16" 
                                                        fill="none"
                                                        className={showExcludedComps === false ? "reverse" : ""}
                                                    >
                                                        <path 
                                                            fillRule="evenodd" 
                                                            clipRule="evenodd" 
                                                            d="M0.242395 12.5943C0.565587 12.9054 1.08959 12.9054 1.41278 12.5943L8 6.25327L14.5872 12.5943C14.9104 12.9054 15.4344 12.9054 15.7576 12.5943C16.0808 12.2831 16.0808 11.7787 15.7576 11.4676L8 4L0.242395 11.4676C-0.0807978 11.7787 -0.0807978 12.2831 0.242395 12.5943Z" 
                                                            fill="#2F4858"
                                                        />
                                                    </svg>
                                                </div>
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-distance-container rental-comps-blur-subject">  
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-similarity-container rental-comps-blur-subject"> 
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                            </td>
                                        </tr>
                                    :
                                    null
                                }
                                {
                                    showExcludedComps === false ?
                                    null
                                    :
                                    property.unitPropertyComps !== undefined && property.unitPropertyComps !== null ?
                                    sortedComps.map((oItem, oIndex) =>
                                        oItem.map((item, index) =>
                                            checkCompExcluded(item) === false ?
                                            null
                                            :
                                            <tr
                                                key={index}
                                                className={"rental-comps-table-row cursor-pointer excluded-comps-table-row " + (checkSelectedComp(item) ? "rental-comps-table-row-selected" : "")}
                                                onClick={() => toggleExcludedCheckbox(item)}
                                            >
                                                <td className="rental-comps-table-checkbox-container mobile-none">
                                                    <div 
                                                        className="checkbox-container"
                                                        onClick={() => toggleExcludedCheckbox(item)}
                                                    >
                                                        {
                                                            checkSelectedComp(item) ?
                                                            <div className="checkbox-icon">
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </td>
                                                <td className="rental-comps-table-value rental-comps-blur-subject">
                                                    <span className="body-regular colour-secondary">
                                                        {
                                                            blurred === true ?
                                                            "1234 Main St"
                                                            :
                                                            item.address.streetAddress
                                                        }
                                                    </span>
                                                    <span className="label-regular colour-secondary block-text">
                                                        {item.address.city}, {item.address.state} {item.address.zipcode}
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value">
                                                    <span className="body-regular colour-secondary">
                                                        {Number(item.distance).toFixed(2)} mi
                                                    </span>
                                                </td>
                                                {
                                                    item.userInputted === true ?
                                                    <td className="rental-comps-table-value">
                                                        <span className="body-regular colour-secondary">
                                                            User inputted
                                                        </span>
                                                    </td> 
                                                    :
                                                    <td className="rental-comps-table-value">
                                                        <span className="body-semibold colour-secondary">
                                                            {percentage.format(item.correlation).replace(".00", "")}
                                                        </span>
                                                        <div className="rental-comps-progress-bar-outer-container">
                                                            <ProgressBar
                                                                item={item}
                                                            />
                                                        </div>
                                                    </td>  
                                                }
                                                <td className="rental-comps-table-value rental-comps-blur-subject">
                                                    <span className="body-regular colour-secondary">
                                                        {
                                                            blurred === true ?
                                                            "$999"
                                                            :
                                                            formatterLong.format(item.price).replace(".00", "")
                                                        }
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value">
                                                    <span className="body-regular colour-secondary">
                                                        {
                                                            item.squareFootage !== undefined && item.squareFootage !== null ?
                                                            numberFormatter.format(item.squareFootage)
                                                            :
                                                            "N/A"
                                                        }
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value">
                                                    <span className="body-regular colour-secondary">
                                                        {item.bedrooms}
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value">
                                                    <span className="body-regular colour-secondary">
                                                        {item.bathrooms}
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value">
                                                    <span className="body-regular colour-secondary">
                                                        {item.yearBuilt === undefined || item.yearBuilt === null ? "N/A" : item.yearBuilt}
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value">
                                                    <span className="body-regular colour-primary block-text text-align-left text-italic">
                                                        {findListingDateDifference(item.lastSeenDate)}
                                                    </span>
                                                    <span className="label-regular colour-secondary block-text text-align-left text-italic">
                                                        {findListingDate(item.lastSeenDate)}
                                                    </span>
                                                </td>
                                                <td className="rental-comps-table-value">
                                                    <span className="body-regular colour-secondary">
                                                        {item.propertyType}
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    )
                                    :
                                    null
                                }
                            </tbody>
                        </table>
                    </div>
                :
                property.rentComps !== undefined && property.rentComps !== null ?
                    <div 
                        className="rental-comps-body-container"
                        ref={scrollContainer}
                    >
                        <table className="rental-comps-table-container">
                            <thead className="rental-comps-table-head">
                                <tr className="rental-comps-label-row bg-colour-light">
                                    <th className="rental-comps-table-checkbox-container bg-colour-light mobile-none">
                                        <div 
                                            className="checkbox-container"
                                            onClick={() => toggleAllCheckboxes()}
                                        >
                                        {
                                                selectedComps.length === (comps.flat(2).length - excludedComps.length) && checkSelectedCompExclusion() === false ?
                                                <div className="checkbox-icon">
                                                </div>
                                                :
                                                null
                                        }
                                        </div>
                                    </th>
                                    {
                                        labels.map((item, index) => 
                                            <th
                                                className={"rental-comps-table-label relative-container " + (item.val === null ? "" : "cursor-pointer")}
                                                key={index}
                                                onMouseEnter={() => setHoverOption(item.val)}
                                                onMouseLeave={() => setHoverOption(null)}
                                                onClick={() => item.val === null ? null : reorderComps(item.val)}
                                                >
                                                <span className={"body-semibold colour-primary float-left " + (index === 0 ? "block-text" : "")}>
                                                    {item.label}
                                                </span>
                                                {
                                                    (item.val === null) || (item.val !== sorting && item.val !== hoverOption) ?
                                                    null
                                                    :
                                                    <div className="rental-comps-icon-container">
                                                        <svg 
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            width="16" 
                                                            height="16" 
                                                            viewBox="0 0 16 16" 
                                                            fill="none"
                                                        >
                                                            <path 
                                                                fillRule="evenodd" 
                                                                clipRule="evenodd"
                                                                d="M8.35401 5.87499L4.67098 2.19197C4.44497 1.96596 4.07854 1.96596 3.85253 2.19197L0.169507 5.87499C-0.0565021 6.101 -0.0565023 6.46743 0.169507 6.69344C0.395515 6.91945 0.761948 6.91945 0.987957 6.69344L3.68303 3.99837L3.68302 12.3094C3.68302 12.629 3.94213 12.8881 4.26176 12.8881C4.58138 12.8881 4.84049 12.629 4.84049 12.3094L4.84049 3.99837L7.53556 6.69344C7.76156 6.91945 8.128 6.91945 8.35401 6.69344C8.58002 6.46743 8.58002 6.101 8.35401 5.87499ZM15.8305 10.1243L12.1475 13.8074C11.9215 14.0334 11.555 14.0334 11.329 13.8074L7.64599 10.1243C7.41999 9.89834 7.41999 9.53191 7.64599 9.3059C7.872 9.07989 8.23844 9.07989 8.46444 9.3059L11.1595 12.001L11.1595 3.68993C11.1595 3.37031 11.4186 3.1112 11.7382 3.1112C12.0579 3.1112 12.317 3.37031 12.317 3.68993L12.317 12.001L15.012 9.3059C15.2381 9.07989 15.6045 9.07989 15.8305 9.3059C16.0565 9.53191 16.0565 9.89834 15.8305 10.1243Z" 
                                                                fill={item.val === sorting ? colour.grayScaleBlack : colour.grayScaleGray03}
                                                            />
                                                        </svg>
                                                    </div>
                                                }
                                            </th>
                                        )
                                    }
                                </tr>
                                <tr className="rental-comps-subject-property-row">
                                    <th className="rental-comps-table-checkbox-container bg-colour-medium mobile-none">
                                        <span className="label-regular-caps colour-secondary block-text text-align-left margin-left-medium">
                                            SUB.<br/>PROP.
                                        </span>
                                    </th>
                                    {
                                        subjectProperty.map((item, index) => 
                                            <th
                                                className={item.class}
                                                key={index}
                                            >
                                                <span className={item.innerClass}>
                                                    {item.label}
                                                </span>
                                                {
                                                    item.was !== null ?
                                                    <span className="body-regular colour-secondary block-text text-italic text-align-left">
                                                        was {item.was}
                                                    </span>
                                                    :
                                                    null
                                                }
                                            </th>
                                        )
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sortedComps.map((item, index) =>
                                        checkCompActive(item) === false || checkCompExcluded(item) === true ?
                                        null
                                        :
                                        <tr
                                            key={index}
                                            className={"rental-comps-table-row cursor-pointer " + (checkSelectedComp(item) ? "rental-comps-table-row-selected" : "")}
                                            onClick={() => toggleCheckbox(item)}
                                        >
                                            <td className="rental-comps-table-checkbox-container mobile-none">
                                                <div 
                                                    className="checkbox-container"
                                                    onClick={() => toggleCheckbox(item)}
                                                >
                                                    {
                                                        checkSelectedComp(item) ?
                                                        <div className="checkbox-icon">
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-blur-subject">
                                                <span className="body-regular colour-primary">
                                                    {
                                                        blurred === true ?
                                                        "1234 Main St"
                                                        :
                                                        item.address.streetAddress
                                                    }
                                                </span>
                                                <span className="label-regular colour-secondary block-text">
                                                    {item.address.city}, {item.address.state} {item.address.zipcode}
                                                </span>
                                                {
                                                    partnerEdit === true ?
                                                    <span 
                                                        className="body-regular underline cursor-pointer colour-error"
                                                        onClick={() => deleteComp(item)}
                                                    >
                                                        Delete
                                                    </span>
                                                    :
                                                    null
                                                }
                                            </td>
                                            <td className="rental-comps-table-value">
                                                <span className="body-regular colour-primary">
                                                    {item.distance.toFixed(2)} mi
                                                </span>
                                            </td>
                                            {
                                                item.userInputted === true ?
                                                <td className="rental-comps-table-value">
                                                    <span className="body-regular colour-primary">
                                                        User inputted
                                                    </span>
                                                </td> 
                                                :
                                                <td className="rental-comps-table-value">
                                                    <span className="body-semibold colour-primary">
                                                        {percentage.format(item.prediction_label).replace(".00", "")}
                                                    </span>
                                                    <div className="rental-comps-progress-bar-outer-container">
                                                        <ProgressBar
                                                            item={item}
                                                        />
                                                    </div>
                                                </td>  
                                            }
                                            <td className="rental-comps-table-value rental-comps-blur-subject">
                                                <span className="body-regular colour-primary">
                                                    {
                                                        blurred === true ?
                                                        "$999"
                                                        :
                                                        formatterLong.format(item.price).replace(".00", "")
                                                    }
                                                </span>
                                            </td>
                                            <td className="rental-comps-table-value">
                                                <span className="body-regular colour-primary">
                                                    {
                                                        item.squareFootage !== undefined && item.squareFootage !== null ?
                                                        numberFormatter.format(item.squareFootage)
                                                        :
                                                        "N/A"
                                                    }
                                                </span>
                                            </td>
                                            <td className="rental-comps-table-value">
                                                <span className="body-regular colour-primary">
                                                    {item.bedrooms}
                                                </span>
                                            </td>
                                            <td className="rental-comps-table-value">
                                                <span className="body-regular colour-primary">
                                                    {item.bathrooms}
                                                </span>
                                            </td>
                                            <td className="rental-comps-table-value">
                                                <span className="body-regular colour-primary">
                                                    {item.yearBuilt}
                                                </span>
                                            </td>
                                            <td className="rental-comps-table-value">
                                                <span className="body-regular colour-primary block-text text-align-left">
                                                    {findListingDateDifference(item.listingDate)}
                                                </span>
                                                <span className="label-regular colour-secondary block-text text-align-left">
                                                    {moment(item.listingDate).format("MMM D, YYYY")}
                                                </span>
                                            </td>
                                            <td className="rental-comps-table-value">
                                                <span className="body-regular colour-secondary">
                                                    {item.propertyType}
                                                </span>
                                            </td>
                                        </tr>
                                    )            
                                }
                                {
                                    excludedComps.length > 0 ?
                                        <tr
                                            className=""
                                        >
                                            <td className="rental-comps-table-checkbox-container mobile-none">
                                                
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-table-value-address rental-comps-blur-subject"> 
                                                <div
                                                    className="show-hide-excluded-comps mobile-none text-button button-row"
                                                    onClick={() => toggleExcludedComps()}
                                                >
                                                    <span className="body-regular text-link block-text subtext-margin-right">
                                                        {
                                                            showExcludedComps === false ?
                                                            "Show excluded comps"
                                                            :
                                                            "Hide excluded comps"
                                                        }
                                                    </span>
                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg" 
                                                        width="16" 
                                                        height="16" 
                                                        viewBox="0 0 16 16" 
                                                        fill="none"
                                                        className={showExcludedComps === false ? "reverse" : ""}
                                                    >
                                                        <path 
                                                            fillRule="evenodd" 
                                                            clipRule="evenodd" 
                                                            d="M0.242395 12.5943C0.565587 12.9054 1.08959 12.9054 1.41278 12.5943L8 6.25327L14.5872 12.5943C14.9104 12.9054 15.4344 12.9054 15.7576 12.5943C16.0808 12.2831 16.0808 11.7787 15.7576 11.4676L8 4L0.242395 11.4676C-0.0807978 11.7787 -0.0807978 12.2831 0.242395 12.5943Z" 
                                                            fill="#2F4858"
                                                        />
                                                    </svg>
                                                </div>
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-distance-container rental-comps-blur-subject"> 
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-similarity-container rental-comps-blur-subject"> 
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-blur-subject"> 
                                            </td>
                                        </tr>
                                    :
                                    null
                                }
                                {
                                    showExcludedComps === false ?
                                    null
                                    :
                                    sortedComps.map((item, index) =>
                                        checkCompExcluded(item) === false ?
                                        null
                                        :
                                        <tr
                                            key={index}
                                            className={"rental-comps-table-row excluded-comps-table-row cursor-pointer " + (checkSelectedComp(item) ? "rental-comps-table-row-selected" : "")}
                                            onClick={() => toggleExcludedCheckbox(item)}
                                        >
                                            <td className="rental-comps-table-checkbox-container mobile-none">
                                                <div 
                                                    className="checkbox-container"
                                                    onClick={() => toggleExcludedCheckbox(item)}
                                                >
                                                    {
                                                        checkSelectedComp(item) ?
                                                        <div className="checkbox-icon">
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </td>
                                            <td className="rental-comps-table-value rental-comps-blur-subject">
                                                <span className="body-regular colour-secondary block-text text-italic">
                                                    {
                                                        blurred === true ?
                                                        "1234 Main St"
                                                        :
                                                        item.address.streetAddress
                                                    }
                                                </span>
                                                <span className="label-regular colour-secondary block-text text-italic">
                                                    {item.address.city}, {item.address.state} {item.address.zipcode}
                                                </span>
                                            </td>
                                            <td className="rental-comps-table-value">
                                                <span className="body-regular colour-secondary block-text text-italic">
                                                    {item.distance.toFixed(2)} mi
                                                </span>
                                            </td>
                                            {
                                                item.userInputted === true ?
                                                <td className="rental-comps-table-value">
                                                    <span className="body-regular colour-secondary block-text text-italic">
                                                        User inputted
                                                    </span>
                                                </td> 
                                                :
                                                <td className="rental-comps-table-value">
                                                    <span className="body-semibold colour-secondary block-text text-italic">
                                                        {percentage.format(item.prediction_label).replace(".00", "")}
                                                    </span>
                                                    <div className="rental-comps-progress-bar-outer-container">
                                                        <ProgressBar
                                                            item={item}
                                                        />
                                                    </div>
                                                </td>  
                                            }
                                            <td className="rental-comps-table-value rental-comps-blur-subject">
                                                <span className="body-regular colour-secondary block-text text-italic">
                                                    {
                                                        blurred === true ?
                                                        "$999"
                                                        :
                                                        formatterLong.format(item.price).replace(".00", "")
                                                    }
                                                </span>
                                            </td>
                                            <td className="rental-comps-table-value">
                                                <span className="body-regular colour-secondary block-text text-italic">
                                                    {
                                                        item.squareFootage !== undefined && item.squareFootage !== null ?
                                                        numberFormatter.format(item.squareFootage)
                                                        :
                                                        "N/A"
                                                    }
                                                </span>
                                            </td>
                                            <td className="rental-comps-table-value">
                                                <span className="body-regular colour-secondary block-text text-italic">
                                                    {item.bedrooms}
                                                </span>
                                            </td>
                                            <td className="rental-comps-table-value">
                                                <span className="body-regular colour-secondary block-text text-italic">
                                                    {item.bathrooms}
                                                </span>
                                            </td>
                                            <td className="rental-comps-table-value">
                                                <span className="body-regular colour-secondary ">
                                                    {item.yearBuilt}
                                                </span>
                                            </td>
                                            <td className="rental-comps-table-value">
                                                <span className="body-regular colour-primary block-text text-align-left text-italic">
                                                    {findListingDateDifference(item.listingDate)}
                                                </span>
                                                <span className="label-regular colour-secondary block-text text-align-left text-italic">
                                                    {moment(item.listingDate).format("MMM D, YYYY")}
                                                </span>
                                            </td>
                                            <td className="rental-comps-table-value">
                                                <span className="body-regular colour-secondary block-text text-italic">
                                                    {item.propertyType}
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                :
                null
            }
            </div>
        </div>
    )
}

export default RentalComps;