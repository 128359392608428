import React from 'react';
import ReactDOM from 'react-dom/client';
import MyCities from './pages/MyCities';
import Listings from './properties/Listings';
import Listing from './properties/Listing';
import ScrollToTop from './components/ScrollToTop';
import ErrorPage from './pages/ErrorPage';
import Offers from './properties/Offers';
import Sent from './properties/Sent';
import Login from './pages/Login';
import PricingRedirect from './pages/PricingRedirect';
import Pay from './pages/Pay';
import Profile from './pages/Profile';
import CustomPlan from './pages/CustomPlan';
import SignUp from './pages/SignUp';
import PennyThoughts from './pages/PennyThoughts';
import BuyLogin from './pages/BuyLogin';
import BuySignUp from './pages/BuySignUp';
import ChooseCounties from './pages/ChooseCounties';
import PaymentSuccess from './pages/PaymentSuccess';
import FinancialPreferences from './pages/FinancialPreferences';
import DescribeYou from './pages/DescribeYou';
import HowDidYouHear from './pages/HowDidYouHear';
import SampleMarket from './properties/SampleMarket';
import SampleOffers from './properties/SampleOffers';
import ForgotPassword from './pages/ForgotPassword';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PropertySearchLandingPage from './pages/PropertySearchLandingPage';
import PropertySearch from './pages/PropertySearch';
import GrantSubscription from './pages/GrantSubscription';
import Favorites from './properties/Favourites';
import ChooseProduct from './pages/ChooseProduct';
import Welcome from './pages/Welcome';
import DemoConfirmation from './pages/DemoConfirmation';
import BuyBoxSetup from './onboarding/BuyBoxSetup';
import EditBuyBox from './pages/EditBuyBox';
import Calculators from './pages/Calculators';
import VerifyEmail from './pages/VerifyEmail';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit'
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import allReducers from './reducers';
import './styles/App.css';
import mixpanel from 'mixpanel-browser';
import './styles/Variables.css';
import { init as initFullStory } from '@fullstory/browser';
import { PostHogProvider} from 'posthog-js/react'
import TagManager from 'react-gtm-module';
const store = configureStore({ reducer: allReducers });

// Initialize Firebase
const app = initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE).config);
getAnalytics(app);

mixpanel.init(JSON.parse(process.env.REACT_APP_MIXPANEL).key, {debug: false});
initFullStory({ orgId: JSON.parse(process.env.REACT_APP_FULLSTORY).orgId });

const options = {
	api_host: JSON.parse(process.env.REACT_APP_POSTHOG).host
};

const tagManagerArgs = {
    gtmId: JSON.parse(process.env.REACT_APP_GOOGLE).gtmId
};
TagManager.initialize(tagManagerArgs);

const routing = (
	<Provider
	  store={store}
	>
	  	<BrowserRouter>
			<PostHogProvider 
				apiKey={JSON.parse(process.env.REACT_APP_POSTHOG).key}
				options={options}
			>
				<ScrollToTop>
					<Routes>
						<Route path="/" element={<MyCities />} />
						<Route path="/my-cities" element={<MyCities />} />
						<Route path="/sample-market" element={<SampleMarket />} />
						<Route path="/sample-offers" element={<SampleOffers />} />
						<Route path="/state-properties-list/:id" element={<Listings />} />
						<Route path="/properties-list/:id" element={<Listings />} />
						<Route path="/properties/:cityId/:id" element={<Listing />} />
						<Route path="/offers/:id" element={<Offers />} />
						<Route path="/sent/:id" element={<Sent />} />
						<Route path="/pricing" element={<PricingRedirect />} />
						<Route path="/pay" element={<Pay />} />
						<Route path="/profile" element={<Profile />} />
						<Route path="/custom-plan" element={<CustomPlan />} />
						<Route path="/login" element={<Login />} />
						<Route path="/sign-up" element={<SignUp />} />
						<Route path="/password-forgotten" element={<ForgotPassword />} />
						<Route path="/penny-for-thoughts" element={<PennyThoughts />} />
						<Route path="/buy-login" element={<BuyLogin />} />
						<Route path="/buy-sign-up" element={<BuySignUp />} />
						<Route path="/choose-counties" element={<ChooseCounties />} />
						<Route path="/preferences" element={<FinancialPreferences />} />
						<Route path="/quick-report" element={<PropertySearchLandingPage />} />
						<Route path="/quick-report/:id" element={<PropertySearch />} />
						<Route path="/success" element={<PaymentSuccess />} />
						<Route path="/describe-you" element={<DescribeYou />} />
						<Route path="/how-did-you-hear" element={<HowDidYouHear />} />
						<Route path="/terms-conditions" element={<TermsAndConditions />} />
						<Route path="/privacy-policy" element={<PrivacyPolicy />} />
						<Route path="/grant-subscription" element={<GrantSubscription />} />
						<Route path="/favourites" element={<Favorites />} />
						<Route path="/choose-product" element={<ChooseProduct />} />
						<Route path="/welcome" element={<Welcome />} />
						<Route path="/demo-confirmation" element={<DemoConfirmation />} />
						<Route path="/buy-box-setup" element={<BuyBoxSetup />} />
						<Route path="/edit-buy-box" element={<EditBuyBox />} />
						<Route path="/calculators" element={<Calculators />} />
						<Route path="/verify-email" element={<VerifyEmail />} />
						<Route path="*" element={<ErrorPage />} />
					</Routes>
				</ScrollToTop>
			</PostHogProvider>
		</BrowserRouter>
	</Provider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	// <React.StrictMode> {
		routing
	// } </React.StrictMode>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();