import React from 'react';
import '../styles/MobileBottomNav.css';
import { colour } from '../styles/GlobalStyles';
import { useNavigate, useLocation } from 'react-router-dom';

function MobileBottomNav() {
    const pathname = window.location.pathname;
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    const links = [
        {
            label: "Home",
            link: "/",
            iconInactive: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M7.21774 0.318486C7.43012 0.0963974 7.71801 -0.00991672 8.00071 0.000726521C8.28341 -0.00944369 8.57083 0.0968705 8.78226 0.318605L15.7138 7.57422C16.1074 7.98623 16.0933 8.63996 15.6822 9.03447C15.2711 9.42898 14.6189 9.41479 14.2253 9.00278L8.00024 2.48628L1.77472 9.00266C1.38112 9.41467 0.728878 9.42886 0.317809 9.03447C-0.0932613 8.63996 -0.10742 7.98611 0.286188 7.57422L7.21774 0.318486Z" fill={colour.grayScaleGray02}/><path d="M15.012 9.56309C14.8464 9.56746 14.6802 9.5321 14.5287 9.45725V15.0312C14.5287 15.2987 14.3126 15.5156 14.0455 15.5156H13.5622H2.44678H1.9635C1.8356 15.5156 1.7195 15.4659 1.63314 15.3847C1.57178 15.3269 1.52506 15.2532 1.50052 15.1701C1.4873 15.1261 1.48023 15.0795 1.48023 15.0312V9.46742C1.32779 9.53825 1.16166 9.57006 0.996947 9.56226V15.0312C0.996947 15.5662 1.42973 16 1.9635 16H14.0455C14.5792 16 15.012 15.5662 15.012 15.0312V9.56309Z" fill={colour.grayScaleGray02}/></svg>,
            iconActive: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M7.21774 0.318486C7.43012 0.0963974 7.71801 -0.00991672 8.00071 0.000726521C8.28341 -0.00944369 8.57083 0.0968705 8.78226 0.318605L15.7138 7.57422C16.1074 7.98623 16.0933 8.63996 15.6822 9.03447C15.2711 9.42898 14.6189 9.41479 14.2253 9.00278L8.00024 2.48628L1.77472 9.00266C1.38112 9.41467 0.728878 9.42886 0.317809 9.03447C-0.0932613 8.63996 -0.10742 7.98611 0.286188 7.57422L7.21774 0.318486Z" fill={colour.grayScaleBlack}/><path d="M15.012 9.56309C14.8464 9.56746 14.6802 9.5321 14.5287 9.45725V15.0312C14.5287 15.2987 14.3126 15.5156 14.0455 15.5156H13.5622H2.44678H1.9635C1.8356 15.5156 1.7195 15.4659 1.63314 15.3847C1.57178 15.3269 1.52506 15.2532 1.50052 15.1701C1.4873 15.1261 1.48023 15.0795 1.48023 15.0312V9.46742C1.32779 9.53825 1.16166 9.57006 0.996947 9.56226V15.0312C0.996947 15.5662 1.42973 16 1.9635 16H14.0455C14.5792 16 15.012 15.5662 15.012 15.0312V9.56309Z" fill={colour.grayScaleBlack}/></svg>,
            active: pathname === "/" ? true : false
        },
        {
            label: "Explore",
            link: "/my-cities",
            iconInactive: <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none"><path d="M15.8333 1.85735V14.0735C15.8333 14.8231 15.2255 15.4308 14.4759 15.4308C13.7263 15.4308 13.1186 14.8231 13.1186 14.0735L13.1186 5.13427L3.15039 15.1024C2.62031 15.6325 1.76089 15.6325 1.23081 15.1024C0.700732 14.5724 0.700733 13.7129 1.23081 13.1829L11.199 3.2147L2.25978 3.21469C1.51014 3.21469 0.902432 2.60699 0.902432 1.85735C0.902431 1.10771 1.51014 0.5 2.25978 0.5L14.4759 0.500002C15.2255 0.500002 15.8333 1.10771 15.8333 1.85735Z" fill={colour.grayScaleGray02}/></svg>,
            iconActive: <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none"><path d="M15.8333 1.85735V14.0735C15.8333 14.8231 15.2255 15.4308 14.4759 15.4308C13.7263 15.4308 13.1186 14.8231 13.1186 14.0735L13.1186 5.13427L3.15039 15.1024C2.62031 15.6325 1.76089 15.6325 1.23081 15.1024C0.700732 14.5724 0.700733 13.7129 1.23081 13.1829L11.199 3.2147L2.25978 3.21469C1.51014 3.21469 0.902432 2.60699 0.902432 1.85735C0.902431 1.10771 1.51014 0.5 2.25978 0.5L14.4759 0.500002C15.2255 0.500002 15.8333 1.10771 15.8333 1.85735Z" fill={colour.grayScaleBlack}/></svg>,
            active: pathname === "/explore" ? true : false
        },
        {
            label: "Account",
            link: "/profile",
            iconInactive: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M10.7907 8.15764C11.8483 7.32035 12.5268 6.02513 12.5268 4.57143C12.5268 2.0467 10.4801 0 7.95539 0C5.43066 0 3.38396 2.0467 3.38396 4.57143C3.38396 6.05713 4.0927 7.3773 5.19053 8.21228C3.24249 9.3819 2.092 11.7061 2.48741 14.118C2.55317 14.5191 2.61247 14.9046 2.66193 15.2608C2.72706 15.7297 3.15996 16.057 3.62884 15.9918C4.09773 15.9267 4.42504 15.4938 4.35992 15.0249C4.30806 14.6516 4.24652 14.2518 4.17911 13.8407C3.77676 11.3865 5.61612 9.14286 8.03847 9.14286C10.4312 9.14286 12.2372 11.314 11.8015 13.6667L11.557 14.9868C11.4708 15.4523 11.7783 15.8995 12.2437 15.9857C12.7092 16.0719 13.1564 15.7644 13.2426 15.2989L13.4871 13.9788C13.9342 11.5646 12.7511 9.28239 10.7907 8.15764ZM10.8125 4.57143C10.8125 6.14938 9.53335 7.42857 7.95539 7.42857C6.37744 7.42857 5.09825 6.14938 5.09825 4.57143C5.09825 2.99347 6.37744 1.71429 7.95539 1.71429C9.53335 1.71429 10.8125 2.99347 10.8125 4.57143Z" fill={colour.grayScaleGray02}/></svg>,
            iconActive: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M10.7907 8.15764C11.8483 7.32035 12.5268 6.02513 12.5268 4.57143C12.5268 2.0467 10.4801 0 7.95539 0C5.43066 0 3.38396 2.0467 3.38396 4.57143C3.38396 6.05713 4.0927 7.3773 5.19053 8.21228C3.24249 9.3819 2.092 11.7061 2.48741 14.118C2.55317 14.5191 2.61247 14.9046 2.66193 15.2608C2.72706 15.7297 3.15996 16.057 3.62884 15.9918C4.09773 15.9267 4.42504 15.4938 4.35992 15.0249C4.30806 14.6516 4.24652 14.2518 4.17911 13.8407C3.77676 11.3865 5.61612 9.14286 8.03847 9.14286C10.4312 9.14286 12.2372 11.314 11.8015 13.6667L11.557 14.9868C11.4708 15.4523 11.7783 15.8995 12.2437 15.9857C12.7092 16.0719 13.1564 15.7644 13.2426 15.2989L13.4871 13.9788C13.9342 11.5646 12.7511 9.28239 10.7907 8.15764ZM10.8125 4.57143C10.8125 6.14938 9.53335 7.42857 7.95539 7.42857C6.37744 7.42857 5.09825 6.14938 5.09825 4.57143C5.09825 2.99347 6.37744 1.71429 7.95539 1.71429C9.53335 1.71429 10.8125 2.99347 10.8125 4.57143Z" fill={colour.textPrimary}/></svg>,
            active: pathname === "/profile" ? true : false
        }
    ];

    const goToLink = (item) => {
        navigate(item.link, { 
            state: state 
        });
    };

    return (
        <div className="mobile-bottom-nav-outer-container bg-colour-white">
            {
                links.map((item, index) =>
                    <div 
                        className="mobile-bottom-nav-element-container"
                        key={index}
                        onClick={() => goToLink(item)}
                    >
                        <div className="mobile-bottom-nav-inner-container">
                            <div className="mobile-bottom-svg-container margin-x-x-small">
                                {item.active === true ? item.iconActive : item.iconInactive}
                            </div>
                            <span className={"xs-label block-text text-align-center " + (item.active === true ? "colour-primary" : "colour-secondary")}>
                                {item.label}
                            </span>
                        </div>
                    </div>
                )
            }
        </div>
    )
};

export default MobileBottomNav;