import React from 'react';
import '../styles/DownloadSelector.css';

function DownloadSelector(props) {
    // const downloadCSV = props.downloadCSV;
    const buyBox = props.buyBox;
    const editBuyBox = props.editBuyBox;
    const deleteBuyBox = props.deleteBuyBox;
    const item = props.item;
    const setDeleteParam = props.setDeleteParam;

    const clickDelete = () => {
        setDeleteParam(item);
        deleteBuyBox(item);
    };

    return (
        <div className="download-selector-outer-container bg-colour-white">
            {/* <div 
                className="download-selector-element-container mobile-none"
                onClick={() => downloadCSV()}
            >
                <span className="body-regular colour-primary">
                    Download CSV
                </span>
            </div> */}
            {
                buyBox === true ?
                <div 
                    className="download-selector-element-container"
                    onClick={() => editBuyBox(item)}
                >
                    <span className="body-regular colour-primary">
                        Edit
                    </span>
                </div>
                :
                null
            }
            {
                buyBox === true ?
                <div 
                    className="download-selector-element-container"
                    onClick={() => clickDelete(item)}
                >
                    <span className="body-regular colour-error">
                        Delete
                    </span>
                </div>
                :
                null
            }
        </div>
    )
};

export default DownloadSelector;