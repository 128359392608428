import React from 'react';
import { Hand } from '../assets';

function InheritedBadge() {
    return (
        <div className="fixer-upper-badge-outer-container">
			<img
				src={Hand}
				className="fixer-upper-badge-icon"
				alt="Inherited"
			/>
			<span className="top-pick-badge-text">
				Inherited
			</span>
		</div>
    )
}

export default InheritedBadge;