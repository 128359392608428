import React, { useEffect, useState } from 'react';
import '../styles/SellerFinanceCalculator.css';
import { colour, CustomSelect, FinancialsInput, formatterLong } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import { calculateCashOnCash, calculateInterestPaid, calculateMortgagePayment, checkFinancialsInteger, formatPrice, recordEvent } from '../functions';
import { StrategiesPanelFooter, MonthlyCostsTab, RentTab, NewSwitch, RehabTab, LeaseOptionRent, STRRent } from './';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

function SellerFinanceCalculatorStandalone(props) {
    const multipleUniqueUnits = false;
    const highLevelProfits = props.highLevelProfits;
    const setHighLevelProfits = props.setHighLevelProfits;
    const price = props.price;
    const setPrice = props.setPrice;
    const downPayment = props.downPayment;
    const setDownPayment = props.setDownPayment;
    const interestRate = props.interestRate;
    const setInterestRate = props.setInterestRate;
    const balloonPayment = props.balloonPayment;
    const setBalloonPayment = props.setBalloonPayment;
    const duration = props.duration;
    const setDuration = props.setDuration;
    const amortisation = props.amortisation;
    const setAmortisation = props.setAmortisation;
    const monthlyPayment = props.monthlyPayment;
    const setMonthlyPayment = props.setMonthlyPayment;
    const sellerCompensation = props.sellerCompensation;
    const setSellerCompensation = props.setSellerCompensation;
    const viewRentalComps = props.viewRentalComps;
    const strategyPanel = props.strategyPanel;
    const setStrategyPanel = props.setStrategyPanel;
    const subToDisabled = props.subToDisabled;
    const defaultDownPayment = props.defaultDownPayment;
    const costPerSqFoot = props.costPerSqFoot;
    const setCostPerSqFoot = props.setCostPerSqFoot;
    const totalRehabCost = props.totalRehabCost;
    const setTotalRehabCost = props.setTotalRehabCost;
    const blurred = props.blurred;
    const exitStrategy = props.exitStrategy;
    const setExitStrategy = props.setExitStrategy;
    const leaseOptionValue = props.leaseOptionValue;
    const setLeaseOptionValue = props.setLeaseOptionValue;
    const leaseOptionDownPayment = props.leaseOptionDownPayment;
    const setLeaseOptionDownPayment = props.setLeaseOptionDownPayment;
    const leaseOptionInterestRate = props.leaseOptionInterestRate;
    const setLeaseOptionInterestRate = props.setLeaseOptionInterestRate;
    const strRevenue = props.strRevenue;
    const setStrRevenue = props.setStrRevenue;

    const [firstQuery, setFirstQuery] = useState(false);
    const [monthlyCosts, setMonthlyCosts] = useState([]);
    const [rent, setRent] = useState(0);
    const [unitsRent, setUnitsRent] = useState([]);
    const [multiUnitRent, setMultiUnitRent] = useState([]);
    const [multiUnitRentTotal, setMultiUnitRentTotal] = useState(0);
    const [defaultMultiUnitRent] = useState(0);
    const [multiUnitTypeUnitLiving] = useState([0, 0]);
    const [multiUnitRentRatio] = useState([1, 1]);
    const [dollarDownPayment, setDollarDownPayment] = useState((price * (downPayment / 100)).toFixed(2));
    const [downPaymentPercentage, setDownPaymentPercentage] = useState(false);
    const [priceSlider, setPriceSlider] = useState(20);
    const [downPaymentSlider, setDownPaymentSlider] = useState(defaultDownPayment);
    const [leaseOptionDollarDownPayment, setLeaseOptionDollarDownPayment] = useState(leaseOptionValue * (leaseOptionDownPayment / 100));
    const [leaseOptionRent, setLeaseOptionRent] = useState(0);

    const tabs = [
        {
            title: "Purchase",
        },
        {
            title: exitStrategy === 0 ? "Rent" : exitStrategy === 1 ? "Lease option" : "STR revenue",
        },
        {
            title: "Rehab",
        },
        {
            title: "Monthly costs"
        },
    ];
    const [selectedTab, setSelectedTab] = useState(0);

    const strategies = subToDisabled === true ? [
        {
            text: "Seller Finance",
            shortText: "Seller-Fi.",
            title: "",
            description: "",
            link: ""
        }   
    ] 
    : 
    [
		{
            text: "Seller Finance",
            shortText: "Seller-Fi.",
			title: "",
			description: "",
			link: ""
        },
		{
            text: "SubTo",
            shortText: "SubTo",
			title: "",
			description: "",
			link: ""
        }
    ];
    const strategiesText = subToDisabled === true ? [strategies[0].shortText] : [strategies[0].shortText, strategies[1].shortText];

    useEffect(() => {

        const calculateFirstMonthlyPayment = async() => {
            const profitObject = {
                monthlyProfit: 0,
                cashOnCash: 0,
                totalCosts: 0,
                leaseOptionProfit: 0,
                leaseOptionCashOnCash: 0,
                leaseOptionTotalCosts: 0,
                strProfit: 0,
                strCashOnCash: 0
            };
            setHighLevelProfits(profitObject);
        };

        const formatMonthlyCosts = async() => {
			// This is where we format monthly costs to be based off of user settings
            const recurringCosts = [
                {
                    label: "Mortgage payment",
                    value: 0,
                    loss: true
                },
                {
                    label: "HOA fees",
                    value: 0,
                    loss: true
                },
                {
                    label: "Insurance",
                    value: checkFinancialsInteger(0),
                    loss: true
                },
                {
                    label: "Property taxes",
                    value: checkFinancialsInteger(0),
                    loss: true
                },
                {
                    label: "Vacancy",
                    value: checkFinancialsInteger(0),
                    loss: true
                },
                {
                    label: "Management fee",
                    value: checkFinancialsInteger(0),
                    loss: true
                },
                {
                    label: "Maintenance",
                    value: checkFinancialsInteger(0),
                    loss: true
                },
                {
                    label: "Other costs",
                    value: checkFinancialsInteger(0),
                    loss: true
                }
            ];
			const newArray = [
				{
					label: "Property costs",
					inputs: recurringCosts.slice(0, 4)
				},
				{
					label: "Operating expenses",
					inputs: recurringCosts.slice(4, 6)
				},
				{
					label: "Upkeep",
					inputs: recurringCosts.slice(6, 8)
				}
			];
			const relevantRent = 0;

			for (let index = 0; index < newArray.length; index++) {
				const element = newArray[index];
				for (let ii = 0; ii < element.inputs.length; ii++) {
					const input = element.inputs[ii];
                    // Values based on our defaults
                    newArray[index].inputs[ii].percentage = false;
                    if ( index === 0 ) {
                        const value = Number(newArray[index].inputs[ii].value) * 12;
                        const percentageVal = (value / price) * 100;
                        newArray[index].inputs[ii].percentageVal = Number(percentageVal.toFixed(1));
                    }
                    else {
                        const value = Number(newArray[index].inputs[ii].value);
                        const percentageVal = (value / relevantRent) * 100;
                        newArray[index].inputs[ii].percentageVal = Number(percentageVal.toFixed(1));
                    }
                    if ( input.original === undefined ) {
                        newArray[index].inputs[ii].original = input.value;
                    }
				}
			}
			setMonthlyCosts(newArray);
		};

        if ( firstQuery === false ) {
            setFirstQuery(true);
            calculateFirstMonthlyPayment();
            formatMonthlyCosts();
        }
    }, [
        amortisation, 
        downPayment, 
        duration, 
        interestRate, 
        price, 
        firstQuery, 
        setHighLevelProfits,
        setBalloonPayment,
        setMonthlyPayment,
        setSellerCompensation,
        multiUnitRent.length,
        multipleUniqueUnits,
        unitsRent.length,
        strategyPanel,
        rent,
        totalRehabCost,
        leaseOptionDownPayment,
        leaseOptionInterestRate,
        leaseOptionValue,
        strRevenue
    ]);

    const roundToNearestTenth = (num) => {
        return Math.round(num * 10) / 10;
    };

    const onChangePrice = async(value, newStrategy) => {
        setPrice(value);

        const oneSliderUnit = price / 20;
        const newSliderValue = roundToNearestTenth(Number(value) / oneSliderUnit);
        setPriceSlider(newSliderValue);

        const newTotal = await getTotalMonthlyCosts(monthlyCosts);
        recaulculateMonthlyPayment(value, downPayment, interestRate, duration, amortisation, newStrategy, rent, newTotal);
    };

    const onChangePriceSlider = (event, value) => {
        setPriceSlider(value);

        const oneUnit = price / 20;
        const newPrice = (oneUnit * value).toFixed(2).replace(".00", "");
        onChangePrice(newPrice, strategyPanel);
    };

    const onChangeDownPayment = async(value) => {
        setDownPayment(value);
        const newTotal = await getTotalMonthlyCosts(monthlyCosts);
        const newDollar = (Number(value) / 100) * price;
        setDollarDownPayment(newDollar);

        const newSliderValue = roundToNearestTenth(Number(value));
        setDownPaymentSlider(newSliderValue);

        recaulculateMonthlyPayment(price, value, interestRate, duration, amortisation, strategyPanel, rent, newTotal);
    };

    const onChangeDownPaymentDollar = async(value) => {
        const newPercentage = (Number(value) / price) * 100;
        setDownPayment(newPercentage);
        setDollarDownPayment(value);
        setDownPaymentSlider(Math.round(newPercentage));

        const newTotal = await getTotalMonthlyCosts(monthlyCosts);
        recaulculateMonthlyPayment(price, newPercentage, interestRate, duration, amortisation, strategyPanel, rent, newTotal);
    };

    const onChangeDownPaymentSlider = (event, value) => {
        setDownPaymentSlider(value);

        const newDownPayment = ((price * value) / 100).toFixed(2).replace(".00", "");
        onChangeDownPaymentDollar(newDownPayment);
    };

    const toggleDPPercentage = (val) => {
        setDownPaymentPercentage(val);
    };

    const onChangeInterestRate = async(value) => {
        setInterestRate(value);
        const newTotal = await getTotalMonthlyCosts(monthlyCosts);
        recaulculateMonthlyPayment(price, downPayment, value, duration, amortisation, strategyPanel, rent, newTotal);
    };

    const onChangeBalloon = (value) => {
        setBalloonPayment(value);
    };

    const onChangeDuration = async(value) => {
        const newVal = value === "" ? "" : Number(value) * 12;
        setDuration(newVal);
        const newTotal = await getTotalMonthlyCosts(monthlyCosts);
        recaulculateMonthlyPayment(price, downPayment, interestRate, newVal, amortisation, strategyPanel, rent, newTotal);
    };

    const onChangeAmortisation = async(value) => {
        const newVal = value === "" ? "" : Number(value) * 12;
        setAmortisation(newVal);
        const newTotal = await getTotalMonthlyCosts(monthlyCosts);
        recaulculateMonthlyPayment(price, downPayment, interestRate, duration, newVal, strategyPanel, rent, newTotal);
    };
    
    const recaulculateMonthlyPayment = async(newPrice, newDown, newInt, newDuration, newAmortisation, newStrategy, newRent, newMonthlyCostsTotal) => {
        const sellersMortgage = 0;
        const newMortgage = await calculateMortgagePayment(newPrice, newDown, newInt, (newAmortisation / 12)) + sellersMortgage;
        setMonthlyPayment(newMortgage);

        const totalInterest = await calculateInterestPaid(newPrice, newDown, newInt, (newAmortisation / 12), (newDuration / 12));
        const newTotalCompensation = Number(newPrice) + totalInterest;
        setSellerCompensation(newTotalCompensation);
        const downPaymentFee = newPrice * (newDown / 100);
        const totalMonthlyPayments = newMortgage * newDuration;
        const remainingBalance = formatNumber((newTotalCompensation - downPaymentFee) - totalMonthlyPayments, 2);

        setBalloonPayment(remainingBalance);

        const newProfit = newRent - (newMortgage + newMonthlyCostsTotal);
        const newInitialCosts = Number(totalRehabCost) + downPaymentFee;
        const newCashOnCash = await calculateCashOnCash(newProfit, newInitialCosts);

        const newLeaseOptionDown = (leaseOptionDownPayment / 100) * leaseOptionValue;
        const newLeaseMonthlyCostsTotal = (newMortgage + newMonthlyCostsTotal);
        const newLeaseOptionProfit = leaseOptionRent - newLeaseMonthlyCostsTotal;
        const newLeaseOptionInitialCosts = newInitialCosts - newLeaseOptionDown;
        const newLeaseOptionCashOnCash = await calculateCashOnCash(newLeaseOptionProfit, newLeaseOptionInitialCosts);

        const newStrMonthlyRent = (strRevenue / 12);
        const newStrMonthlyProfit = newStrMonthlyRent - newMortgage;
        const newSTRCashOnCash = await calculateCashOnCash(newStrMonthlyProfit, newInitialCosts);

        const profitObject = {
            monthlyProfit: newProfit,
            cashOnCash: newCashOnCash / 100,
            totalCosts: newInitialCosts,
            leaseOptionProfit: newLeaseOptionProfit,
            leaseOptionCashOnCash: newLeaseOptionCashOnCash / 100,
            leaseOptionTotalCosts: newLeaseOptionInitialCosts,
            strProfit: newStrMonthlyProfit,
            strCashOnCash: newSTRCashOnCash / 100
        };
        setHighLevelProfits(profitObject);

        const newMonthlyCosts = [...monthlyCosts];
        newMonthlyCosts[0].inputs[0].value = Number(newMortgage.toFixed(2));
        setMonthlyCosts(newMonthlyCosts);
    };

    const formatNumber = (number, dp) => {
        // Check if the number is an integer
        if (Number.isInteger(number)) {
            return number; // Return the integer as is
        }
        else {
            return number.toFixed(dp); // Round to one decimal place
        }
    };

    const selectTab = (index) => {
        setSelectedTab(index);
    };

    // Function for changing rehab cost input
    const changeRehabCost = (event, val) => {
        setCostPerSqFoot(val);
        const newTotalCost = val;
        setTotalRehabCost(newTotalCost);

        // Register rehab costs
        collectRehabCosts(Number(newTotalCost));
    }

    // Change cost per sq. foot
    const onChangeCostPerSqFoot = (val) => {
        setCostPerSqFoot(val);
        const newTotalCost = Number(val);
        setTotalRehabCost(newTotalCost);

        // Register rehab costs
        collectRehabCosts(Number(newTotalCost));
    };

    // Change total rehab cost
    const onChangeTotalCost = (val) => {
        setTotalRehabCost(val);
        const newCostPerSqFoot = Number(val);
        setCostPerSqFoot(newCostPerSqFoot);

        // Register rehab costs
        collectRehabCosts(Number(val));
    };

    // This is where we send all new rehab costs
	// to then send to a cash flow update function
	const collectRehabCosts = async(rehab) => {
        const downPaymentFee = price * (downPayment / 100);
        const newInitialCosts = Number(rehab) + downPaymentFee;
        const profit = highLevelProfits.monthlyProfit;
        const cashOnCash = await calculateCashOnCash(profit, newInitialCosts);

        const newLeaseOptionDown = (leaseOptionDownPayment / 100) * leaseOptionValue;
        const newLeaseOptionInitialCosts = newInitialCosts - newLeaseOptionDown;
        const newLeaseOptionProfit = highLevelProfits.leaseOptionProfit;
        const newLeaseOptionCashOnCash = await calculateCashOnCash(newLeaseOptionProfit, newLeaseOptionInitialCosts);

        const newSTRCashOnCash = await calculateCashOnCash(highLevelProfits.strProfit, newInitialCosts);

        const profitObject = {
            monthlyProfit: profit,
            cashOnCash: cashOnCash / 100,
            totalCosts: newInitialCosts,
            leaseOptionProfit: highLevelProfits.leaseOptionProfit,
            leaseOptionCashOnCash: newLeaseOptionCashOnCash / 100,
            leaseOptionTotalCosts: newLeaseOptionInitialCosts,
            strProfit: highLevelProfits.strProfit,
            strCashOnCash: newSTRCashOnCash / 100
        };
        setHighLevelProfits(profitObject);
    };

    const onChangeMonthlyCosts = async(val, index, ii) => {
		const newArray = [...monthlyCosts];
        newArray[index].inputs[ii].value = val;
        const numberVal = Number(val);
        if ( index === 0 ) {
            const yearlyValue = numberVal * 12;
            const percentageOfTotal = (yearlyValue / price) * 100;
            newArray[index].inputs[ii].percentageVal = percentageOfTotal.toFixed(1);
        }
        else {
            const percentageOfTotal = (numberVal / rent) * 100;
            newArray[index].inputs[ii].percentageVal = percentageOfTotal.toFixed(1);
        }

		setMonthlyCosts(newArray);

		const newTotal = await getTotalMonthlyCosts(newArray);
		// Register monthly costs
		collectMonthlyCosts(newTotal);
	};

    const onChangeMonthlyCostsPercentage = async(val, index, ii) => {
		const newArray = [...monthlyCosts];
        newArray[index].inputs[ii].percentageVal = val;

        // Choose the appropriate relative value - either property price or monthly rent.
        // If the relative value is property price, divide the price by 12, so as to get the
        // monthly cost, rather than the yearly cost.
        const relativeValue = index === 0 ? price / 12 : await getAppropriateRentEstimate();
        const dollarValue = (relativeValue * (val / 100));
        newArray[index].inputs[ii].value = Number(dollarValue.toFixed(2));
		setMonthlyCosts(newArray);

		const newTotal = await getTotalMonthlyCosts(newArray);
		// Register monthly costs
		collectMonthlyCosts(newTotal);
	};

    const togglePercentage = (val, index, ii) => {
		const newArray = [...monthlyCosts];
		newArray[index].inputs[ii].percentage = val;
		setMonthlyCosts(newArray);
	};

    const onChangeRent = (val) => {
        setRent(val);

        // For 1 unit layout MFH homes, this is where we segment the rent
		// between each unit
        if ( unitsRent.length !== 0 ) {
            const newUnits = [];
            for (let index = 0; index < unitsRent.length; index++) {
				const newUnit = {
					value: Math.round(val / unitsRent.length),
					label: `Unit ${index + 1}`
				};
				newUnits.push(newUnit);
            };
            setUnitsRent(newUnits);
        }

        // Register total rent
        collectTotalRent(Number(val));
    };

    const resetRentEstimates = () => {
		setRent(0);
        const newUnits = [];
		for (let index = 0; index < unitsRent.length; index++) {
			const newUnit = {
				value: Math.round(0 / unitsRent.length),
				label: `Unit ${index + 1}`
			};
			newUnits.push(newUnit);
		};
		setUnitsRent(newUnits);
        collectTotalRent(0);
	};
    
    const collectTotalRent = async(val) => {
		const totalMonthlyCosts = await getTotalMonthlyCosts(monthlyCosts);
		const finalRent = Number(val);
		recaulculateMonthlyPayment(price, downPayment, interestRate, duration, amortisation, strategyPanel, finalRent, totalMonthlyCosts);
	};

    const getAppropriateRentEstimate = async() => {
        return rent;
    };

    const getTotalMonthlyCosts = (vals) => {
		const totalMonthlyCosts = vals.reduce((accumulator, currentValue) => {
			currentValue.inputs.forEach(item => {
                if ( item.label !== "Mortgage payment" ) {
                    accumulator += Number(item.value);
                }
			});
			return accumulator; // Return the accumulator for the next iteration
		}, 0);
		return totalMonthlyCosts;
	};

    const collectMonthlyCosts = async(costs) => {
		const getRent = await getAppropriateRentEstimate();
        recaulculateMonthlyPayment(price, downPayment, interestRate, duration, amortisation, strategyPanel, getRent, costs);
	};

    // Function for changing the rent on INDIVIDUAL units
	// on a Multi-family home with 1 unit type, and on a
	// Multi-family house hack with 1 unit type
    const onChangeUnitRent = (val, unitIndex) => {
		
		// Setting up rent for 1 unit layout MFH homes
        const newUnits = [];
        for (let index = 0; index < unitsRent.length; index++) {
            if ( index === unitIndex ) {
                const newUnit = {
                    value: val,
                    label: `Unit ${index + 1}`
                };
                newUnits.push(newUnit);
            }
            else {
                const newUnit = {
                    value: unitsRent[index].value,
                    label: `Unit ${index + 1}`
                };
                newUnits.push(newUnit);
            }
        };
        setUnitsRent(newUnits);
        const newTotal = newUnits.reduce((a, b) => a + Number(b.value), 0);
        setRent(newTotal);
		collectTotalRent(newTotal);
    };

    // Function for changing the TOTAL rent for MFHs with multiple
	// unique units
	const onChangeMultiUnitTotalRent = (val) => {
		
        // This is if they were NOT in house hack mode
        setMultiUnitRentTotal(val);
        const totalNumber = Number(val);

        // Register total rent
        collectTotalRent(totalNumber);
        
        if ( multiUnitRent.length > 1 ) {
            if ( multiUnitRent[0].units.length < 1 ) {
                return;
            }
            const firstUnitTypeCount = multiUnitRent[0].units.length;
            const ratio = multiUnitRentRatio[1];
            
            // Equation to find new rent rates
            const newFirstUnitRent = (totalNumber / (ratio + firstUnitTypeCount));
            const newSecondUnitRent = newFirstUnitRent * ratio;
            
            const multiUnitRentClone = [...multiUnitRent];
            for (let index = 0; index < multiUnitRentClone.length; index++) {
                const element = multiUnitRentClone[index];
                for (let ii = 0; ii < element.units.length; ii++) {
                    multiUnitRentClone[index].units[ii].value = index === 0 ? Math.round(newFirstUnitRent) : Math.round(newSecondUnitRent);
                }
            }
            setMultiUnitRent(multiUnitRentClone);
        }
	};


	// Function to change indiviudal text inputs for multiple unique
	// units MFH properties
	const onChangeMultiUnitRent = (val, inputIndex, inputSubIndex) => {
		
        // NOT on house hack mode
        const multiUnitRentClone = [...multiUnitRent];
        for (let index = 0; index < multiUnitRentClone.length; index++) {
            const element = multiUnitRentClone[index].units;
            for (let ii = 0; ii < element.length; ii++) {
                if ( index === inputIndex && ii === inputSubIndex ) {
                    multiUnitRentClone[index].units[ii].value = val;
                }
            }
        };
        setMultiUnitRent(multiUnitRentClone);
        const newTotal = multiUnitRentClone.reduce((accumulator, currentValue) => {
            // Use forEach to iterate over the 'items' array of each object
            currentValue.units.forEach(item => {
                // Add the 'value' property to the accumulator
                accumulator += Number(item.value);
            });
            return accumulator; // Return the accumulator for the next iteration
        }, 0);
        setMultiUnitRentTotal(newTotal);

        // Register total rent
        collectTotalRent(newTotal);
	};

    // Function for resetting rent rates for MFHs with multiple
	// unique units
	const resetMFHMultiUnitRentEstimates = () => {
		const newUnits = [];
		const multiUnitRentClone = [...multiUnitRent];
		for (let index = 0; index < multiUnitRentClone.length; index++) {
			const element = multiUnitRentClone[index].units;
			for (let ii = 0; ii < element.length; ii++) {
				multiUnitRentClone[index].units[ii].value = multiUnitRentClone[index].units[ii].original;
			}
			newUnits.push(multiUnitRentClone[index]);
		};
		setMultiUnitRent(newUnits);
		const newUnitsTotal = newUnits.reduce((accumulator, currentValue) => {
			// Use forEach to iterate over the 'items' array of each object
			currentValue.units.forEach(item => {
			  // Add the 'value' property to the accumulator
			  accumulator += Number(item.value);
			});
			return accumulator; // Return the accumulator for the next iteration
		}, 0);
		setMultiUnitRentTotal(newUnitsTotal);

		// Register total rent
        collectTotalRent(newUnitsTotal);
	};

    // Function to reset a specific monthly cost
	const resetMonthlyCost = async(index, ii) => {
		const newMonthlyCosts = [...monthlyCosts];
        newMonthlyCosts[index].inputs[ii].value = newMonthlyCosts[index].inputs[ii].original;

        if ( index === 0 ) {
            const percentageOfTotal = 0;
            newMonthlyCosts[index].inputs[ii].percentageVal = Number(percentageOfTotal.toFixed(1));
        }
        else {
            const percentageOfTotal = 0;
            newMonthlyCosts[index].inputs[ii].percentageVal = Number(percentageOfTotal.toFixed(1));
        }	

		setMonthlyCosts(newMonthlyCosts);
		const newTotal = await getTotalMonthlyCosts(newMonthlyCosts);

		collectMonthlyCosts(newTotal);
	};

    const changeStrategyPanel = async(index) => {
        setStrategyPanel(index);
    };

    const onChangeLeaseValue = (val) => {
        setLeaseOptionValue(val);
        recalculateLeaseOption(Number(val), leaseOptionDownPayment, leaseOptionInterestRate);
    };

    const onChangeLeaseDownPayment = (val) => {
        if ( Number(val) > 100 ) {
            return;
        }
        setLeaseOptionDownPayment(val);
        const newDollar = (val / 100) * leaseOptionValue;
        setLeaseOptionDollarDownPayment(newDollar);
        recalculateLeaseOption(leaseOptionValue, Number(val), leaseOptionInterestRate);
    };

    const onChangeLeaseDownPaymentDollar = (val) => {
        const newPercentage = (Number(val) / leaseOptionValue) * 100;
        setLeaseOptionDownPayment(newPercentage);
        setLeaseOptionDollarDownPayment(val);
        recalculateLeaseOption(leaseOptionValue, newPercentage, leaseOptionInterestRate);
    };

    const onChangeLeaseInterest = (val) => {
        if ( Number(val) > 100 ) {
            return;
        }
        setLeaseOptionInterestRate(val);
        recalculateLeaseOption(leaseOptionValue, leaseOptionDownPayment, Number(val));
    };

    const recalculateLeaseOption = async(newPrice, newDown, newInterest) => {
        const newRent = await calculateMortgagePayment(newPrice, newDown, newInterest, 30);
        setLeaseOptionRent(newRent);

        const newDownPaymentDollar = (newDown / 100) * newPrice;
        const newMonthlyCostsTotal = await getTotalMonthlyCosts(monthlyCosts);
        const newProfit = newRent - (monthlyPayment + newMonthlyCostsTotal);
        const newInitialCosts = Number(totalRehabCost) + Number(dollarDownPayment) - newDownPaymentDollar;
        const newCashOnCash = await calculateCashOnCash(newProfit, newInitialCosts);

        const profitObject = {
            monthlyProfit: highLevelProfits.monthlyProfit,
            cashOnCash: highLevelProfits.cashOnCash,
            totalCosts: highLevelProfits.totalCosts,
            leaseOptionProfit: newProfit,
            leaseOptionCashOnCash: newCashOnCash / 100,
            leaseOptionTotalCosts: newInitialCosts,
            strProfit: highLevelProfits.strProfit,
            strCashOnCash: highLevelProfits.strCashOnCash
        };
        setHighLevelProfits(profitObject);
    }

    const onChangeSTRRevenue = async(val) => {
        setStrRevenue(val);

        const monthlyRent = (Number(val) / 12);
        const monthlyProfit = monthlyRent - monthlyPayment;
        const newCashOnCash = await calculateCashOnCash(monthlyProfit, highLevelProfits.totalCosts);

        const profitObject = {
            monthlyProfit: highLevelProfits.monthlyProfit,
            cashOnCash: highLevelProfits.cashOnCash,
            totalCosts: highLevelProfits.totalCosts,
            leaseOptionProfit: highLevelProfits.leaseOptionProfit,
            leaseOptionCashOnCash: highLevelProfits.leaseOptionCashOnCash,
            leaseOptionTotalCosts: highLevelProfits.leaseOptionTotalCosts,
            strProfit: monthlyProfit,
            strCashOnCash: newCashOnCash / 100
        };
        setHighLevelProfits(profitObject);
    };

    // const openYouTube = () => {
    //     recordEvent("Seller finance calculator - YouTube link clicked", {});
    //     window.open("https://youtu.be/JZiXtmebwz4", "_blank");
    // };

    const changeExitStrategy = (val) => {
        recordEvent("Exit strategy changed", {
            exitStrategy: val
        });
        setExitStrategy(val);

        if ( val !== 0 ) {
            setSelectedTab(1);
        }
    };

    const styles = {
        menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik',
		},
    };

    return (
        <div className="strategies-panel-outer-wrapper">
            <div className="strategies-panel-outer-container margin-x-large">
                <div className="strategies-panel-inner-container">
                    <div className="seller-finance-calculator-outer-container">
                        <div className="seller-finance-calculator-inner-container">
                            <div className="seller-finance-title-container-row">
                                <div className="seller-finance-calculator-title-container">
                                    <h2 className="heading-large-semibold colour-primary margin-x-small">
                                        Seller finance
                                    </h2>
                                    {
                                        subToDisabled === false ?
                                        <div className="seller-finance-calculator-switch-container relative-container">
                                            <NewSwitch
                                                firstOption={strategyPanel === 0 ? true : false}
                                                pricingSwitch={changeStrategyPanel}
                                                textOptions={strategiesText}
                                                large={true}
                                            />
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="seller-finance-title-border margin-tops">
                            </div>
                            <div className="seller-finance-calculator-panel-row">
                                <ul className="seller-finance-calculator-options-container">
                                    <li
                                        className="side-navigation-element-container"
                                    >
                                        <Box>
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId="exit-strategy-selector-label"
                                                    id="exit-strategy-selector"
                                                    value={exitStrategy}
                                                    label=""
                                                    input={<CustomSelect />}
                                                    onChange={(e) => changeExitStrategy(e.target.value)}
                                                > 
                                                    <MenuItem
                                                        value={0}
                                                        style={styles.menuItem}
                                                    >
                                                        <span className="body-regular colour-primary">
                                                            LTR
                                                        </span>
                                                    </MenuItem>	
                                                    <MenuItem 
                                                        value={1}
                                                        style={styles.menuItem}
                                                    >
                                                        <span className="body-regular colour-primary">
                                                            Lease option
                                                        </span>
                                                    </MenuItem>
                                                    <MenuItem 
                                                        value={2}
                                                        style={styles.menuItem}
                                                    >
                                                        <span className="body-regular colour-primary">
                                                            STR
                                                        </span>
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </li>
                                    {
                                        tabs.map((item, index) =>
                                            <li
                                                className="side-navigation-element-container"
                                                key={index}
                                                onClick={() => selectTab(index)}
                                            >
                                                <div className={"button-row side-navigation-element-inner-container cursor-pointer " + (index === selectedTab ? "side-navigation-option-active" : "")}>
                                                    <span className={selectedTab === index ? "body-semibold colour-primary" : "body-regular text-link"}>
                                                        {item.title}
                                                    </span>
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                                {
                                    selectedTab === 0 ?
                                    <div className="seller-finance-calculator-grid-container margin-x-large">
                                        <div className="seller-finance-grid-element">
                                            <div className="seller-finance-input-container margin-x-small">
                                                <FinancialsInput
                                                    value={formatPrice(price)}
                                                    type="text"
                                                    label="Price"
                                                    id={`sf-price-input`}
                                                    onWheel={(e) => e.target.blur()}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                    }}
                                                    onChange={(text) => onChangePrice(text.target.value.replaceAll(",", ""), strategyPanel)}
                                                />
                                            </div>
                                            <Stack 
                                                spacing={2} 
                                                direction="row" 
                                                sx={{ mb: 1, px: 1 }} 
                                                alignItems="center"
                                            >
                                                <Slider
                                                    aria-label="Price"
                                                    value={priceSlider}
                                                    onChange={onChangePriceSlider}
                                                    min={0}
                                                    step={0.1}
                                                    max={25}
                                                    disabled={false}
                                                    sx={{
                                                        color: '#CFBCAD',
                                                        height: '5px',
                                                        '& .MuiSlider-track': {
                                                            border: 'none',
                                                        },
                                                        '& .MuiSlider-thumb': {
                                                            width: 24,
                                                            height: 24,
                                                            backgroundColor: '#fff',
                                                            '&:before': {
                                                                boxShadow: '0px 0px 10px 0px #99959180',
                                                            },
                                                            '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                boxShadow: 'none',
                                                            },
                                                        }
                                                    }}
                                                />
                                            </Stack>

                                        </div>
                                        <div className="seller-finance-grid-element">
                                            <div className={"seller-finance-input-container margin-x-small " + (downPaymentPercentage === true ? "monthly-costs-input-container-right" : "")}>
                                                <FinancialsInput
                                                    value={downPaymentPercentage === false ? formatPrice(dollarDownPayment) : `${downPayment}`.length > 4 ? Number(downPayment).toFixed(2) : downPayment}
                                                    type="text"
                                                    label="Down payment"
                                                    id={`sf-dp-input`}
                                                    onWheel={(e) => e.target.blur()}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment className={downPaymentPercentage === false ? "financials-panel-active-option" : "financials-panel-dormant-option"} onClick={() => toggleDPPercentage(false)} position="start">$</InputAdornment>,
                                                        endAdornment: <InputAdornment className={downPaymentPercentage === true ? "financials-panel-active-option" : "financials-panel-dormant-option"} onClick={() => toggleDPPercentage(true)} position="end">%</InputAdornment>
                                                    }}
                                                    onChange={(text) => downPaymentPercentage === false ? onChangeDownPaymentDollar(text.target.value.replaceAll(",", "")) : onChangeDownPayment(text.target.value)}

                                                />
                                            </div>
                                            <Stack 
                                                spacing={2} 
                                                direction="row" 
                                                sx={{ mb: 1, px: 1 }} 
                                                alignItems="center"
                                            >
                                                <Slider
                                                    aria-label="Down Payment Dollar"
                                                    value={downPaymentSlider}
                                                    onChange={onChangeDownPaymentSlider}
                                                    min={0}
                                                    step={1}
                                                    max={100}
                                                    disabled={false}
                                                    sx={{
                                                        color: '#CFBCAD',
                                                        height: '5px',
                                                        '& .MuiSlider-track': {
                                                            border: 'none',
                                                        },
                                                        '& .MuiSlider-thumb': {
                                                            width: 24,
                                                            height: 24,
                                                            backgroundColor: '#fff',
                                                            '&:before': {
                                                                boxShadow: '0px 0px 10px 0px #99959180',
                                                            },
                                                            '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                boxShadow: 'none',
                                                            },
                                                        }
                                                    }}
                                                />
                                            </Stack>
                                        </div>
                                        <div className="seller-finance-grid-element">
                                            <div className="seller-finance-input-container margin-x-small">
                                                <FinancialsInput
                                                    value={interestRate}
                                                    type="text"
                                                    label="Interest rate"
                                                    id={`sf-int-input`}
                                                    onWheel={(e) => e.target.blur()}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                        style: {
                                                            maxWidth: '100px'
                                                        }
                                                    }}
                                                    onChange={(text) => onChangeInterestRate(text.target.value)}
                                                />
                                            </div>
                                            <span className="body-regular colour-secondary text-italic block-text">
                                                Get as low as possible!
                                            </span>
                                        </div>
                                        <div className="seller-finance-grid-element">
                                            <div className="seller-finance-input-container margin-x-small">
                                                <FinancialsInput
                                                    value={amortisation === "" ? "" : amortisation / 12}
                                                    type="text"
                                                    label="Amortization schedule"
                                                    id={`sf-amortisation-input`}
                                                    onWheel={(e) => e.target.blur()}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">years</InputAdornment>,
                                                        style: {
                                                            maxWidth: '100px'
                                                        }
                                                    }}
                                                    onChange={(text) => onChangeAmortisation(text.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="seller-finance-grid-element">
                                            <div className="seller-finance-input-container margin-x-small">
                                                <FinancialsInput
                                                    value={formatPrice(balloonPayment)}
                                                    type="text"
                                                    label="Balloon payment"
                                                    id={`sf-bp-input`}
                                                    onWheel={(e) => e.target.blur()}
                                                    disabled={true}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                    }}
                                                    onChange={(text) => onChangeBalloon(text.target.value.replaceAll(",", ""))}
                                                />
                                            </div>
                                        </div>
                                        <div className="seller-finance-grid-element">
                                            <div className="seller-finance-input-container margin-x-small">
                                                <FinancialsInput
                                                    value={duration === "" ? "" : duration / 12}
                                                    type="text"
                                                    label="Balloon due in"
                                                    id={`sf-duration-input`}
                                                    onWheel={(e) => e.target.blur()}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">years</InputAdornment>,
                                                        style: {
                                                            maxWidth: '100px'
                                                        }
                                                    }}
                                                    onChange={(text) => onChangeDuration(text.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    selectedTab === 1 ?
                                        exitStrategy === 0 ?
                                        <RentTab
                                            rent={rent}
                                            onChangeRent={onChangeRent}
                                            blurred={blurred}
                                            defaultRent={0}
                                            strategyPanel={0}
                                            property={{propertyTypeDimension: "Single Family", units: 1, financials: {rent: 0}}}
                                            unitsRent={unitsRent}
                                            houseHackUnitsRent={null}
                                            onChangeUnitRent={onChangeUnitRent}
                                            houseHackRent={null}
                                            unitLiving={null}
                                            setUnitLiving={null}
                                            defaultHouseHackRent={null}
                                            onChangeUnitLiving={null}
                                            houseHackBedroomsRent={null}
                                            onChangeBedroomRent={null}
                                            onChangeBedroomLiving={null}
                                            resetRentEstimates={resetRentEstimates}
                                            multipleUniqueUnits={multipleUniqueUnits}
                                            multiUnitRent={multiUnitRent}
                                            multiUnitRentTotal={multiUnitRentTotal}
                                            defaultMultiUnitRent={defaultMultiUnitRent}
                                            houseHackMultiUnitsRent={null}
                                            multiUnitTypeUnitLiving={multiUnitTypeUnitLiving}
                                            multiUnitHouseHackRentTotal={null}
                                            defaultMultiUnitHouseHackRentTotal={null}
                                            multiUnitRentRatio={multiUnitRentRatio}
                                            onChangeMultiUnitTotalRent={onChangeMultiUnitTotalRent}
                                            onChangeMultiUnitLiving={null}
                                            resetMFHMultiUnitRentEstimates={resetMFHMultiUnitRentEstimates}
                                            onChangeMultiUnitRent={onChangeMultiUnitRent}
                                            onChangeSFHHouseHackTotal={null}
                                            onChangeValueAddInput={null}
                                            valueAddOptions={[]}
                                            onChangeValueAddOptions={null}
                                            viewRentalComps={viewRentalComps}
                                        />
                                        :
                                        exitStrategy === 1 ?
                                        <LeaseOptionRent
                                            blurred={blurred}
                                            property={{price: price}}
                                            downPaymentPercentage={downPaymentPercentage}
                                            toggleDPPercentage={toggleDPPercentage}
                                            leaseOptionValue={leaseOptionValue}
                                            leaseOptionDownPayment={leaseOptionDownPayment}
                                            leaseOptionDollarDownPayment={leaseOptionDollarDownPayment}
                                            leaseOptionInterestRate={leaseOptionInterestRate}
                                            onChangeLeaseValue={onChangeLeaseValue}
                                            onChangeLeaseDownPayment={onChangeLeaseDownPayment}
                                            onChangeLeaseDownPaymentDollar={onChangeLeaseDownPaymentDollar}
                                            onChangeLeaseInterest={onChangeLeaseInterest}
                                            leaseOptionRent={leaseOptionRent}
                                        />
                                        :
                                        <STRRent
                                            strRevenue={strRevenue}
                                            blurred={blurred}
                                            onChangeSTRRevenue={onChangeSTRRevenue}
                                        />
                                    :
                                    selectedTab === 2 ?
                                    <RehabTab
                                        property={{livingArea: 1}}
                                        costPerSqFoot={costPerSqFoot}
                                        totalRehabCost={totalRehabCost}
                                        changeRehabCost={changeRehabCost}
                                        onChangeCostPerSqFoot={onChangeCostPerSqFoot}
                                        onChangeTotalCost={onChangeTotalCost}
                                        defaultTotalRehabCost={0}
                                    />
                                    :
                                    <MonthlyCostsTab
                                        strategyPanel={0}
                                        monthlyCosts={monthlyCosts}
                                        onChangeMonthlyCosts={onChangeMonthlyCosts}
                                        togglePercentage={togglePercentage}
                                        onChangeMonthlyCostsPercentage={onChangeMonthlyCostsPercentage}
                                        resetMonthlyCost={resetMonthlyCost}
                                        exitStrategy={exitStrategy}
                                    />
                                }
                            </div>
                            {
                                selectedTab === 0 ?
                                <div className={"seller-finance-calculator-bottom-row margin-large " + (blurred === true ? "blurred" : "")}>
                                    <div className="seller-finance-calculator-results-container bg-colour-light">
                                        <h3 className="heading-small-semibold colour-primary margin-medium">
                                            Good for you
                                        </h3>
                                        <div className="margin-medium">
                                            <span className="label-regular-caps colour-secondary block-text margin-x-x-small">
                                                Mortgage to seller
                                            </span>
                                            <span className="body-semibold colour-primary block-text">
                                                {formatterLong.format(monthlyPayment).replace(".00", "")}
                                                <span className="body-regular">
                                                    /month
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="seller-finance-calculator-seller-compensation bg-colour-light">
                                        <h3 className="heading-small-semibold colour-primary margin-medium">
                                            Good for the seller
                                        </h3>
                                        <div className="seller-finance-seller-compensation-row margin-medium">
                                            <div className="seller-finance-seller-compensation-element subtext-margin-right-x-large">
                                                <span className="label-regular-caps colour-secondary block-text margin-x-x-small">
                                                    Sale price
                                                </span>
                                                <span className="body-semibold colour-primary">
                                                    {formatterLong.format(price).replace(".00", "")}
                                                </span>
                                            </div>
                                            <div className="seller-finance-seller-compensation-element">
                                                <span className="label-regular-caps colour-secondary block-text margin-x-x-small">
                                                    Sale + Interest
                                                </span>
                                                <span className="body-semibold colour-primary">
                                                    {formatterLong.format(sellerCompensation).replace(".00", "")}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="seller-finance-seller-compensation-element margin-medium">
                                            <span className="label-regular-caps colour-secondary block-text margin-x-x-small">
                                                Income from interest
                                            </span>
                                            <span className="body-semibold colour-primary">
                                                {formatterLong.format(Number(sellerCompensation) - Number(price)).replace(".00", "")}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                            {
                                highLevelProfits !== "" ?
                                <StrategiesPanelFooter
                                    highLevelProfits={highLevelProfits}
                                    strategyPanel={0}
                                    blurred={blurred}
                                    cashInDeal={null}
                                    exitStrategy={exitStrategy}
                                />
                                :
                                null
                            }
                            <div className="seller-finance-calculator-standalone-monthly-payment desktop-none">
                                <span className="heading-large-semibold colour-primary">
                                    {formatterLong.format(monthlyPayment)}/month
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SellerFinanceCalculatorStandalone;