import React from 'react';
import { formatterLong, percentage, percentageCompact } from '../styles/GlobalStyles';

function StrategiesPanelFooter(props) {
    const highLevelProfits = props.highLevelProfits;
    const blurred = props.blurred;
    const exitStrategy = props.exitStrategy;

	const percentageChange = () => {
		const newVal = exitStrategy === 1 ? highLevelProfits.leaseOptionProfit : exitStrategy === 2 ? highLevelProfits.strProfit : 0;
		const newChange = ((newVal - highLevelProfits.monthlyProfit) / Math.abs(highLevelProfits.monthlyProfit));
		return percentageCompact.format(Math.abs(newChange));
	};

    return (
        <div className="strategies-panel-footer-container">
            <div className="strategies-panel-footer-body-container">
                <div className="strategies-panel-footer-body-left-container">
                    <div className="sticky-roi-coc-element">
                        <h3 className="label-semibold-caps colour-primary margin-x-x-small">
							{
								exitStrategy === 1 && highLevelProfits.leaseOptionTotalCosts <= 0 ?
								"SURPLUS"
								:
								"UP-FRONT COSTS"
							}
						</h3>
						{
							exitStrategy === 1 ?
							<h2 className={"heading-large-semibold " + (highLevelProfits.leaseOptionTotalCosts <= 0 ? "colour-green" : "colour-error")}>
								{formatterLong.format(Math.round(Math.abs(highLevelProfits.leaseOptionTotalCosts))).replace(".00", "")}
							</h2>
							:
							<h2 className="heading-large-semibold colour-error">
								{formatterLong.format(Math.round(highLevelProfits.totalCosts)).replace(".00", "")}
							</h2>
						}
                    </div>
                </div>
                <div className="strategies-panel-footer-body-right-container">
                    <div className="sticky-roi-coc-element">
                        <h3 className="label-semibold-caps colour-primary margin-x-x-small">
                            CASH-FLOW
                        </h3>
                        {
							exitStrategy === 1 ?
							<h2 className={"heading-large-semibold " + (highLevelProfits.leaseOptionProfit > 0 || blurred === true ? "colour-green" : "colour-error") + (blurred === true ? " blurred" : "")}>
								{
									blurred === true ?
									"$123"
									:
									formatterLong.format(highLevelProfits.leaseOptionProfit).replace(".00", "")
								}
							</h2>
							:
							exitStrategy === 2 ?
							<h2 className={"heading-large-semibold " + (highLevelProfits.strCashOnCash > 0.05 || blurred === true ? "colour-green" : highLevelProfits.strCashOnCash < 0 ? "colour-error" : "colour-medium") + (blurred === true ? " blurred" : "")}>
								{
									blurred === true ?
									"$123"
									:
									formatterLong.format(highLevelProfits.strProfit).replace(".00", "")
								}
							</h2>
							:
							<h2 className={"heading-large-semibold " + (highLevelProfits.cashOnCash > 0.05 || blurred === true ? "colour-green" : highLevelProfits.cashOnCash < 0 ? "colour-error" : "colour-medium") + (blurred === true ? " blurred" : "")}>
								{
									blurred === true ?
									"$123"
									:
									formatterLong.format(highLevelProfits.monthlyProfit).replace(".00", "")
								}
							</h2>
						}
                    </div>
                    <div className="sticky-roi-coc-element">
                        <h3 className="label-semibold-caps colour-primary margin-x-x-small">
                            CASH ON CASH
                        </h3>
                        {
							exitStrategy === 1 ?
							<h2 className={"heading-large-semibold " + (highLevelProfits.leaseOptionProfit > 0.05 || blurred === true ? "colour-green" : "colour-error") + (blurred === true ? " blurred" : "")}>
								{
									blurred === true ?
									`4.2%`
									:
									highLevelProfits.leaseOptionTotalCosts <= 0 ?
									"∞"
									:
									percentage.format(highLevelProfits.leaseOptionCashOnCash).replace(".00", "")
								}
							</h2>
							:
							exitStrategy === 2 ?
							<h2 className={"heading-large-semibold " + (highLevelProfits.strCashOnCash > 0.05 || blurred === true ? "colour-green" : highLevelProfits.strCashOnCash < 0 ? "colour-error" : "colour-medium") + (blurred === true ? " blurred" : "")}>
								{
									blurred === true ?
									`4.2%`
									:
									percentage.format(highLevelProfits.strCashOnCash).replace(".00", "")
								}
							</h2>
							:
							<h2 className={"heading-large-semibold " + (highLevelProfits.cashOnCash > 0.05 || blurred === true ? "colour-green" : highLevelProfits.cashOnCash < 0 ? "colour-error" : "colour-medium") + (blurred === true ? " blurred" : "")}>
								{
									blurred === true ?
									`4.2%`
									:
									percentage.format(highLevelProfits.cashOnCash).replace(".00", "")
								}
							</h2>
						}
                    </div>
                </div>
            </div>
			{
				exitStrategy === 0 ?
				null
				:
				exitStrategy === 1 ?
				<div className="strategies-panel-strategy-comparison-container margin-top-x-small">
					<span className="body-semibold colour-primary">
						{percentageChange()}
					</span>
					<span className={"body-semibold underline block-text margin-sides-small " + (highLevelProfits.leaseOptionProfit > highLevelProfits.monthlyProfit ? "colour-green" : "colour-error")}>
						{
							highLevelProfits.leaseOptionProfit > highLevelProfits.monthlyProfit ?
							"more"
							:
							"less"
						}
					</span>
					<span className="body-semibold colour-primary">
						cash-flow than LTR
					</span>
				</div>
				:
				<div className="strategies-panel-strategy-comparison-container margin-top-x-small">
					<span className="body-semibold colour-primary">
						{percentageChange()}
					</span>
					<span className={"body-semibold underline block-text margin-sides-small " + (highLevelProfits.strProfit > highLevelProfits.monthlyProfit ? "colour-green" : "colour-error")}>
						{
							highLevelProfits.strProfit > highLevelProfits.monthlyProfit ?
							"more"
							:
							"less"
						}
					</span>
					<span className="body-semibold colour-primary">
						cash-flow than LTR
					</span>
				</div>
			}
        </div>
    )
};

export default StrategiesPanelFooter;