import React, { useEffect, useState } from 'react';
import '../styles/NewPropertyMap.css';
import { GoogleMap, OverlayView, useLoadScript, OverlayViewF, InfoWindow } from '@react-google-maps/api';
import { MapLegend, FullScreenButton, ResetButton, SidePanel, SidePanelTrigger, NeighbourhoodGradeMap, DoubleTapMessage, EmergingAreaTooltip, ListingsCard } from './';
import { findNearbyProperties, calcCrow, sortValues, checkCardPosition, recordEvent } from '../functions';
import mapStyles from '../styles/mapStyles.json';
import mapStylesFull from '../styles/mapStylesFull.json';
import { percentage, percentageCompact } from "../styles/GlobalStyles";

function NewPropertyMap(props) {

	const properties = props.properties;
	const userDetails = props.userDetails;
	const centreCoordinates = props.centreCoordinates;
	const allProperties = props.allProperties;
	const setMobileAbtract = props.setMobileAbtract;
	const cityId = props.cityId;
	const hoverCard = props.hoverCard;
	const propertyRefs = props.propertyRefs;
	const setMapProperties = props.setMapProperties;
	const noProperties = props.noProperties;
	const setPropertiesOnPageChange = props.setPropertiesOnPageChange;
	const setPage = props.setPage;
	const setLoading = props.setLoading;
	const setSearchParams = props.setSearchParams;
	const cardIndex = props.cardIndex;
	const setCardIndex = props.setCardIndex;
	const cardClick = props.cardClick;
	const setCardClick = props.setCardClick;
	const sorting = props.sorting;
	const showResetButton = props.showResetButton;
	const setShowResetButton = props.setShowResetButton;
	const setSelectedTracts = props.setSelectedTracts;
	const mapSidePanelOpen = props.mapSidePanelOpen;
	const lastClickedTract = props.lastClickedTract;
	const setMapSidePanelOpen = props.setMapSidePanelOpen;
	const sidePanelToggle = props.sidePanelToggle;
	const setSelectedZIPLegend = props.setSelectedZIPLegend;
	const setSelectedGentrificationLegend = props.setSelectedGentrificationLegend;
	const loading = props.loading;
	const setProperties = props.setProperties;
	const strategy = props.strategy;
	const userId = props.userId;
	const hiddenProperties = props.hiddenProperties;
	const setHiddenProperties = props.setHiddenProperties;
	const leadType = props.leadType;
	const setHideModal = props.setHideModal;
	const setHideProperty = props.setHideProperty;
	const downPayment = props.downPayment;
	const setHoverCard = props.setHoverCard;

	const neighbourhoodData = props.neighbourhoodData;
	const neighbourhoodBounds = props.neighbourhoodBounds;
	const tracts = props.tracts;
	const neighbourhoodOption = props.neighbourhoodOption;
	const clickTract = props.clickTract;
	const clickedTract = props.clickedTract;
	const chosenTract = props.chosenTract;
	const hoverMapLegend = props.hoverMapLegend;
	const setHoverMapLegend = props.setHoverMapLegend;
	const setUserDetails = props.setUserDetails;
	const bubbles = props.bubbles;
	const emergingMarkets = props.emergingMarkets;
	const setEmergingAreaModal = props.setEmergingAreaModal;
	const setEmergingAreaTract = props.setEmergingAreaTract;
	const neighbourhoodGradeMin = props.neighbourhoodGradeMin;
	const neighbourhoodGradeMax = props.neighbourhoodGradeMax;
	const hideHeatmap = props.hideHeatmap;
	const stateView = props.stateView;

	const [renderOverlay, setRenderOverlay] = useState(false);
	const [mapRef, setMapRef] = useState(null);
	const [clickedDots, setClickedDots] = useState([]);
	const [hideMobileMessage, setHideMobileMessage] = useState(false);
	
	// Leave setCentre unused...otherwise map-snap happens for 
	// some reason
	const [centre] = useState({ lat: centreCoordinates.lat, lng: centreCoordinates.lng });
	const api_key = JSON.parse(process.env.REACT_APP_GOOGLE).key

	useEffect(() => {
		// There's a bug with the overlay where 
		// it only renders on a re-render

		if ( renderOverlay === false ) {
			setTimeout(() => {
				setRenderOverlay(true);
			}, 1000)
		}

	}, [renderOverlay]);

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: api_key
	});

	const options = {
		disableDefaultUI: false,
		scrollwheel: true,
		mapTypeControl: false,
		streetViewControl: false,
		fullscreenControl: true,
		disableDoubleClickZoom: true,
		styles: hideHeatmap === true ? mapStylesFull : mapStyles
	};

	const clickIcon = (zpid) => {
		setCardClick(true);
		setCardIndex(cardIndex === zpid ? null : zpid);

		if ( cardIndex === zpid ) {
			recordEvent("Deactivate Map Bubble", {
				zpid: zpid
			});
		}
		else {
			recordEvent("Activate Map Bubble", {
				zpid: zpid
			});
		}

		const allPropertiesIndex = allProperties.findIndex(e => e.zpid === zpid);
		if ( allPropertiesIndex !== -1 ) {
			const item = allProperties[allPropertiesIndex];
			setMobileAbtract(cardIndex === zpid ? "" : item);
		}

		const newArray = clickedDots.slice(0);
		newArray.push(zpid);
		setClickedDots(newArray);

		setTimeout(() => {
			setCardClick(false);
		}, 500);
	};

	const clickOnMap = (e) => {
		if ( cardClick === false ) {
			if ( cardIndex !== null ) {
				recordEvent("Deactivate Map Bubble", {
					zpid: cardIndex
				});
			}
			setCardIndex(null);
		}
	}

	const handleLoad = (map) => {
		setMapRef(map);
	};

	const checkViewedProperties = (zpid) => {
		const clickedDotsIndex = clickedDots.indexOf(zpid);
		if ( clickedDotsIndex === -1 ) {
			return "";
		}
		else {
			return "new-property-map-overlay-container-viewed ";
		}
	};

	const returnLatLng = (item, index) => {
		const object = {
			lat: item.latitude, 
			lng: item.longitude
		};

		return object;
	};

	const mapChange = async() => {
		// Max Zoom is 22
		// Default zoom is 11

		if ( mapRef !== null ) {
			const zoomRate = mapRef.zoom;
			if ( zoomRate > 12 ) {
				const southWest = {
					latitude: mapRef.getBounds().getSouthWest().lat(),
					longitude: mapRef.getBounds().getSouthWest().lng()
				};
				const centrePoint = {
					latitude: mapRef.getCenter().lat(),
					longitude: mapRef.getCenter().lng()
				}
				const kmDistance = await calcCrow(centrePoint, southWest);
				const nearbyProperties = await findNearbyProperties(centrePoint, kmDistance, allProperties);

				const newProps = await sortValues(nearbyProperties, sorting, strategy);
				const lowerBound = 0;
				const upperBound = 20;
				const upperMapBound = 40;
				const propsSegmentedByPage = newProps.slice(lowerBound, upperBound);
				const propsSegmentedByMap = newProps.slice(lowerBound, upperMapBound);

				if ( propsSegmentedByPage.length !== 0 ) {
					setProperties(propsSegmentedByPage);
					setMapProperties(propsSegmentedByMap);
					setPage(1);
					setShowResetButton(true);
				}
			}
		}
	};

	const getEmergingAreaCentre = (item) => {
		const polygon = item.tractPolygon;
		let totalLat = 0;
		let totalLng = 0;
		const amendedPolygonPoints = polygon.flat(100);
		amendedPolygonPoints.forEach(point => {
			totalLat += point.lat;
			totalLng += point.lng;
		});
		
		const centerLat = totalLat / amendedPolygonPoints.length;
		const centerLng = totalLng / amendedPolygonPoints.length;
		
		return { lat: centerLat, lng: centerLng };
	};

	const checkRoI = (item) => {
		if ( strategy === 4 && item.arvComps === true && item.fixerUpper === true && item.propertyTypeDimension === "Single Family" ) {
			const arvPrice = item.arvPrice;
    		const purchasePrice = item.price;
    		const arvSpread = (arvPrice - purchasePrice) / purchasePrice;
			return percentageCompact.format(arvSpread);
		}
		else {
			const cocrVal = strategy === "sellerFinance" ? item.cashOnCashReturnSellerFinDefault : item.cashOnCashReturnSubtoDefault;
			return percentage.format(cocrVal / 100);
		}
	};
	
	return (
		<div className="new-property-map-outer-container">
			<div className="new-property-map-inner-container">
				{
					userDetails === null ?
					null
					:
					userDetails.mobileMessage > 2 || hideMobileMessage === true ?
					null
					:
					<DoubleTapMessage
						userDetails={userDetails}
						setUserDetails={setUserDetails}
						setHideMobileMessage={setHideMobileMessage}
					/>
				}
				{
					isLoaded === true ?
					<GoogleMap
						zoom={stateView === true ? 7 : 11}
						center={centre}
						mapContainerClassName="new-property-map-element"
						options={options}
						onLoad={handleLoad}
						onClick={(e) => clickOnMap(e)}
						onZoomChanged={() => mapRef !== null ? mapChange() : null}
						onDragEnd={() => mapRef !== null ? mapChange() : null}
					>
						{
							renderOverlay === true ?
							<FullScreenButton />
							:
							null
						}
						{
							renderOverlay === true && showResetButton === true ?
							<ResetButton
								allProperties={allProperties}
								setProperties={setProperties}
								setMapProperties={setMapProperties}
								sorting={sorting}
								setPage={setPage}
								setLoading={setLoading}
								setSearchParams={setSearchParams}
								setShowResetButton={setShowResetButton}
								setSelectedTracts={setSelectedTracts}
								setSelectedZIPLegend={setSelectedZIPLegend}
                        		setSelectedGentrificationLegend={setSelectedGentrificationLegend}
								setPropertiesOnPageChange={setPropertiesOnPageChange}
								strategy={strategy}
							/>
							:
							null
						}
						{
							renderOverlay === true && stateView === false ?
							<MapLegend
								hoverMapLegend={hoverMapLegend}
								setHoverMapLegend={setHoverMapLegend}
								neighbourhoodOption={neighbourhoodOption}
							/>
							:
							null
						}
						{
							renderOverlay === true ?
							<SidePanelTrigger
								sidePanelToggle={sidePanelToggle}
								mapSidePanelOpen={mapSidePanelOpen}
								lastClickedTract={lastClickedTract}
								userDetails={userDetails}
							/>
							:
							null
						}
						{
							renderOverlay === true && hideHeatmap === false ?
							<NeighbourhoodGradeMap
								bounds={neighbourhoodBounds}
								tracts={tracts}
								neighbourhoodOption={neighbourhoodOption}
								neighbourhoodData={neighbourhoodData}
								clickTract={clickTract}
								clickedTract={clickedTract}
								hoverMapLegend={hoverMapLegend}
								cardClick={cardClick}
								cardIndex={cardIndex}
								neighbourhoodGradeMin={neighbourhoodGradeMin}
								neighbourhoodGradeMax={neighbourhoodGradeMax}
							/>
							:
							null
						}
						{
							bubbles === false ?
							null
							:
							properties.map((item, index) =>
								<OverlayViewF
									key={index}
									position={returnLatLng(item, 0)}
									mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
								>
									<div 
										className={"new-property-map-overlay-container " + (checkViewedProperties(item.zpid)) + (item.zpid === cardIndex || hoverCard === item.zpid ? "new-property-map-overlay-container-selected" : "")}
										onClick={() => loading === true ? null : clickIcon(item.zpid)}
										ref={propertyRefs[index]}
									>
										<span className="new-property-map-overlay-text">
											{checkRoI(item)}
										</span>
									</div>
								</OverlayViewF>
							)
						}
						{
							renderOverlay === true && emergingMarkets.length > 0 ?
								emergingMarkets.map((item, index) =>
									<InfoWindow 
										options={{ pixelOffset: new window.google.maps.Size(0, 0) }}
										position={getEmergingAreaCentre(item)}
										className="emerging-area-tooltip-container bg-colour-white"
										key={index}
									>
										<EmergingAreaTooltip
											setEmergingAreaModal={setEmergingAreaModal}
											setEmergingAreaTract={setEmergingAreaTract}
											tract={item}
											title="Emerging area detected"
										/>
									</InfoWindow>
								)
							:
							null
						}
						{
							renderOverlay === true && noProperties === false ?
								allProperties.map((item, index) =>
									item.zpid === cardIndex && cardIndex !== null ?
									<OverlayViewF
										key={index}
										position={checkCardPosition(item, mapRef)}
										mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
									>
										<div className="new-map-overlay-property-card-container new-map-overlay-property-card-container-desktop">
											<ListingsCard
												property={item}
												key={index}
												downPayment={downPayment}
												hiddenProperties={hiddenProperties}
												setHiddenProperties={setHiddenProperties}
												userId={userId}
												cityId={cityId}
												type="listing"
												offers={null}
												setOffers={null}
												setHideModal={setHideModal}
												setHideProperty={setHideProperty}
												strategy={leadType}
												abstract={true}
												setHoverCard={setHoverCard}
												propertyRefs={propertyRefs}
												index={index}
											/>
										</div>
									</OverlayViewF>
									:
									null
								)
							:
							null
						}
					</GoogleMap>
					:
					null
				}
				{
					neighbourhoodData.length !== 0 ?
					<SidePanel
						chosenTract={chosenTract}
						neighbourhoodData={neighbourhoodData}
						neighbourhoodOption={neighbourhoodOption}
						clickedTract={clickedTract}
						mapSidePanelOpen={mapSidePanelOpen}
						setMapSidePanelOpen={setMapSidePanelOpen}
					/>
					:
					null
				}
			</div>
		</div>
	)
}

export default NewPropertyMap;