import React from 'react';
import '../styles/IndigoBadge.css';

function IndigoBadge(props) {
    const text = props.text;
    const extraClass = props.extraClass;
    return (
        <div className={"indigo-badge-container bg-colour-sold-status " + (extraClass)}>
            <span className="label-semibold-caps colour-primary block-text text-align-center">
                {text}
            </span>
        </div>
    )
};

export default IndigoBadge;