import React, { useState } from 'react';
import '../styles/PropertyDetails.css';
import { Construction, PriceSqFoot, LotSize, AirConditioning, Heating, Parking } from '../assets';
import { numberFormatter } from '../styles/GlobalStyles';

function PropertyDetails(props) {

	const property = props.property;
	const propertySearch = props.propertySearch;
	const formatter = props.formatter;

	const [descriptionLength, setDescriptionLength] = useState(250);

	const leftGrid = [
		{
			icon: Construction,
			text: property.yearBuilt === null ? "N/A" : `Built in ${property.yearBuilt}`
		},
		{
			icon: PriceSqFoot,
			text: `${property.resoFacts.pricePerSqFoot !== null ? formatter.format(property.resoFacts.pricePerSqFoot).replace(".00", "") : "$0"}/sqft`,
		},
		{
			icon: LotSize,
			text: property.resoFacts.lotSize !== null ? typeof(property.resoFacts.lotSize) === "number" ? `${numberFormatter.format(property.resoFacts.lotSize)} lot sqft` : property.resoFacts.lotSize : ""
		}
	];

	const rightGrid = [
		{
			icon: Parking,
			text: `${property.resoFacts.garageSpaces === null ? "0" : property.resoFacts.garageSpaces} garage spaces`
		},
		{
			icon: AirConditioning,
			text: `${property.resoFacts.cooling === null ? "None" : Object.keys(property.resoFacts.cooling).length === 0 ? "None" : property.resoFacts.cooling === null ? "None" : property.resoFacts.cooling.join(', ')}`
		},
		{
			icon: Heating,
			text: `${property.resoFacts.heating === null ? "None" : Object.keys(property.resoFacts.heating).length === 0 ? "None" : property.resoFacts.heating !== null ? property.resoFacts.heating.join(', ') : "None"}`,
		},
	];

	return (
		<div className="property-details-outer-container">
			<div className="property-details-inner-container">
				<h2 className="heading-small-semibold colour-primary">
					Property
				</h2>
				<div className={"property-details-icons-grid " + (propertySearch === true ? "margin-x-large" : "")}>
					<div className="property-details-left-grid">
						{
							leftGrid.map((item, index) =>
								<div 
									className="property-details-grid-element"
									key={index}
								>
									<img
										src={item.icon}
										className="property-details-icon"
										alt={item.text}
									/>
									<span className="body-regular colour-primary">
										{item.text}
									</span>
								</div>
							)
						}
					</div>
					<div className="property-details-right-grid">
						{
							rightGrid.map((item, index) =>
								<div 
									className="property-details-grid-element"
									key={index}
								>
									<img
										src={item.icon}
										className="property-details-icon"
										alt={item.text}
									/>
									<span className="body-regular colour-primary">
										{item.text}
									</span>
								</div>
							)
						}
					</div>
				</div>
				<div className="property-details-description-container margin-medium">
					{
						property.description === null ?
						null
						:
						<span className="body-regular colour-secondary block-text">
							{property.description.slice(0, descriptionLength)}{
								property.description.length > descriptionLength ?
								"..."
								:
								null
							}
						</span>
					}
					{
						property.description === null ?
						null
						:
						property.description.length < 250 ?
						null
						:
						property.description.length > descriptionLength ?
						<div 
							className="property-details-show-more-container"
							onClick={() => setDescriptionLength(property.description.length + 2)}
						>
							<span className="body-regular-underline colour-link">
								Show more
							</span>
						</div>
						:
						<div 
							className="property-details-show-more-container"
							onClick={() => setDescriptionLength(250)}
						>
							<span className="body-regular-underline colour-link">
								Show less
							</span>
						</div>
					}
				</div>
				<div className="">
					<span className="body-regular colour-secondary">
						Legal description: {property.legalDescription}
					</span>
				</div>
			</div>
		</div>
	)
}

export default PropertyDetails;