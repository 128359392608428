import React, { useEffect, useState } from 'react';
import { Header, Footer, Loading, ListingsCard } from '../components';
import { useParams, useNavigate } from 'react-router-dom';
import { checkOnAuthStateChanged, cloudFunctionV2, getAPIGatewayListings, getDocument } from '../functions';
import { defaultUserData } from '../styles/GlobalStyles';
import moment from 'moment';

function Sent() {
    const params = useParams();
	const cityId = params.id;
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [sent, setSent] = useState([]);
    const [userId, setUserId] = useState(null);

    const options = [
        {
            label: "Properties",
            value: "properties",
            selected: false,
            route: `/properties-list/${cityId}`
        },
        {
            label: "Offers",
            value: "offers",
            selected: false,
            route: `/offers/${cityId}`
        },
        {
            label: "Sent",
            value: "sent",
            selected: true,
            route: `/sent/${cityId}`
        }
    ];

    useEffect(() => {

        const propertyTypes = [
            {
                code: "MultiFamily",
                included: true,
                property: "Multi-Family",
                abbreviation: "MFH"
            },
            {
                code: "SingleFamily",
                included: true,
                property: "Single-Family",
                abbreviation: "SFH"
            }
        ];

        const fetchUserData = async() => {
            const colRef = "Creative Listings";
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const docRef = user.userId;
                setUserId(user.userId);
                const queryData = await getDocument(colRef, docRef);
                if ( queryData.status === 200 ) {
                    const data = queryData.data.data;
                    const findIndex = data.findIndex(e => e.cityId === cityId);
                    if ( findIndex !== -1 ) {
                        const relevantObject = data[findIndex];
                        const savedOffers = relevantObject.offers;
                        const checkedOffers = [];
                        for (let index = 0; index < savedOffers.length; index++) {
                            const element = savedOffers[index];
                            if ( element.sent === true ) {
                                checkedOffers.push(savedOffers[index]);
                            }
                        }
                        queryProperties(checkedOffers);
                    }
                    else {
                        queryProperties([]);
                    }
                }
                else {
                    queryProperties([]);
                }
            }
            else {
                queryProperties([]);
            }
        };

        const queryProperties = async(vals) => {
            if ( vals.length === 0 ) {
                setLoading(false);
                return;
            }
            const newFilters = {
				adu: false,
				bathrooms: 1,
				bedrooms: 1,
				budgetRange: [0, 10000000],
				constructionYears: [1800, 2024],
				squareFootage: [0, 10000000],
				units: 1,
				propTypeMislabelled: false,
				priceReduction: false,
				extraBedroom: false,
				propertyTypes: propertyTypes,
				hideFixerUppers: false,
				onlyFixerUppers: false,
				priceReductionIndex: 0,
				neighbourhoodGradeMin: 1,
				neighbourhoodGradeMax: 8,
				fsbo: false
			};
			const getGatewayQueryString = await getAPIGatewayListings(defaultUserData.settings, false, cityId, newFilters);
			const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
			const gatewayData = {
				type: "gateway",
				resourceId: "listings",
				queryString: getGatewayQueryString
			};
			const getGateway = await cloudFunctionV2(gatewayURL, gatewayData);
			let queryData = [];
			if ( getGateway.status === 200 ) {
				const getGatewayBody = getGateway.body;
                queryData = getGatewayBody.props;
			}
			else if ( getGateway.status === 404 ) {
				const customS3Location = getGateway.body.s3_location;
				const params = {
					type: "s3",
					s3Data: {
						key: customS3Location,
						bucketName: "residentialpropertydata"
					}
				};
				const getS3Query = await cloudFunctionV2(gatewayURL, params);
				if ( getS3Query.status === 200 ) {
					const getS3Body = getS3Query.body;
                    queryData = getS3Body.props;
				}
			}
			else {
                setLoading(false);
			}
            setLoading(false);

            const newListings = [];
            for (let index = 0; index < queryData.length; index++) {
                queryData[index].datePostedTS = new Date(queryData[index].datePosted);
                const a = moment(new Date());
				const b = moment(queryData[index].datePostedTS);
				const dateDifferenceDays = a.diff(b, 'days');
                const dateDifferenceMonths = a.diff(b, 'months');
				queryData[index].dateDifferenceDays = dateDifferenceDays;
                queryData[index].dateDifferenceMonths = dateDifferenceMonths;

                const zpidIndex = vals.findIndex(e => e.zpid === queryData[index].zpid);
                if ( zpidIndex !== -1 ) {
                    queryData[index].offerURL = vals[zpidIndex].offerLink;
                    newListings.push(queryData[index]);
                }
            }
            setSent(newListings);
            setLoading(false);
        };

        fetchUserData();

    }, [cityId]);

    const changeOption = (item) => {
        navigate(item.route);
    };

    return (
        <div className="listings-outer-container">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            <div className="listings-inner-container relative-container">
                {
                    loading === true ?
                    <Loading />
                    :
                    null
                }
                <div className="listings-top-options-container margin-large">
                    {
                        options.map((item, index) =>
                            <div 
                                className="listings-top-option-element cursor-pointer"
                                key={index}
                                onClick={() => changeOption(item)}
                            >
                                <span className={(item.selected === true ? "body-semibold colour-primary" : "body-regular colour-secondary")}>
                                    {item.label}
                                </span>
                            </div>
                        )
                    }
                </div>
                {
                    sent.length === 0 && loading === false ?
                    <div className="listings-no-results-container">
                        <h3 className="heading-small-semibold colour-primary text-align-center margin-x-small">
                            No offers found
                        </h3>
                        <span className="body-regular colour-secondary block-text text-align-center">
                            These are rookie numbers, we gotta pump these up
                        </span>
                    </div>
                    :
                    <div className="listings-grid-container">
                        {
                            sent.map((item, index) =>
                                <ListingsCard
                                    property={item}
                                    key={index}
                                    downPayment={0.2}
                                    hiddenProperties={[]}
                                    setHiddenProperties={null}
                                    userId={userId}
                                    cityId={cityId}
                                    type="sent"
                                    offers={null}
                                    setOffers={null}
                                    strategy="sellerFinance"
                                    abstract={false}
                                    setHoverCard={null}
                                    propertyRefs={[]}
                                    index={index}
                                />
                            )
                        }
                    </div>
                }
            </div>
            <Footer />
        </div>
    )
};

export default Sent;