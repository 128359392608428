import React, { useState, useEffect } from 'react';
import '../styles/FinancialPreferences.css';
import { SideNavigation, Header, FinancialsSettings, Loading } from '../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkOnAuthStateChanged, getUserDocuments } from '../functions';
import { Abacus, ChevronRight, Cog } from '../assets';
import { defaultUserData } from '../styles/GlobalStyles';

function FinancialPreferences() {
    const [monthlyCostDisabled, setMonthlyCostDisabled] = useState(true);
    const [initialCostDisabled, setInitialCostDisabled] = useState(true);
    const [financialSettingsDisabled, setFinancialSettingsDisabled] = useState(true);
    const [monthlyCostRadio, setMonthlyCostRadio] = useState(false);
	const [mortgageRadio, setMortgageRadio] = useState(true);
    const [loading, setLoading] = useState(true);
    const [selectedPanel, setSelectedPanel] = useState(0);
    const [userSettings, setUserSettings] = useState(defaultUserData.settings);
    const [userData, setUserData] = useState(null);
    const location = useLocation();
    const state = location.state;
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Financial Preferences";
        const fetchUserData = async() => {
            const collections = ["Creative Financial Preferences", "Users"];
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const docRef = user.userId;
                const queryData = await getUserDocuments(collections, docRef);
                if ( queryData[0].status === 200 ) {
                    const financialPreferencesData = queryData[0].data.sellerFinance;
                    setUserSettings(financialPreferencesData);
                }

                if ( queryData[1].status === 200 ) {
                    setUserData(queryData[1].data);
                }
               
                setLoading(false);
            }
            else {
                navigate("/sign-up", {
                    state: state
                });
            }
        };
        
        fetchUserData();
    }, [navigate, state]);

    const changeMonthlyCostRadio = () => {
		setMonthlyCostRadio(!monthlyCostRadio);
	};

	const changeMortgageRadio = () => {
		setMortgageRadio(!mortgageRadio);
		setInitialCostDisabled(false);
		setFinancialSettingsDisabled(false);
	};

    const manageSubscriptions = () => {
        navigate("/profile??tab=1", {
            state: state
        });
    };

    return (
        <div className="financial-preferences-outer-container">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            <div className="financial-preferences-row-container">
                <SideNavigation
                    navigate={navigate}
                    state={state}
                    userData={userData}
                />
                <div className="financial-preferences-inner-container">
                    <div className="financial-preferences-inner-width-container">
                        {
                            loading === true ?
                            <div className="financial-preferences-loading-container">
                                <Loading />
                            </div>
                            :
                            null
                        }
                        <div className="financial-preferences-title-container">
                            <div className="financial-preferences-title-row">
                                <img
                                    src={Abacus}
                                    className="financial-preferences-icon"
                                    alt="Financial Preferences"
                                />
                                <div className="financials-preferences-title-text-container">
                                    <h1 className="heading-large-semibold colour-primary margin-x-small">
                                        Settings
                                    </h1>
                                    <span className="body-regular colour-secondary">
                                        Manage how finances are calculated. Settings will automatically apply across all properties.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="financial-preferences-thick-separator margin-tops">
                        </div>
                        <div className="financial-preferences-middle-width">
                            {
                                loading === false ?
                                <FinancialsSettings
                                    monthlyCostRadio={monthlyCostRadio}
                                    setMonthlyCostRadio={setMonthlyCostRadio}
                                    changeMonthlyCostRadio={changeMonthlyCostRadio}
                                    mortgageRadio={mortgageRadio}
                                    setMortgageRadio={setMortgageRadio}
                                    changeMortgageRadio={changeMortgageRadio}
                                    monthlyCostDisabled={monthlyCostDisabled}
                                    setMonthlyCostDisabled={setMonthlyCostDisabled}
                                    initialCostDisabled={initialCostDisabled}
                                    setInitialCostDisabled={setInitialCostDisabled}
                                    disabled={financialSettingsDisabled}
                                    setDisabled={setFinancialSettingsDisabled}
                                    selectedPanel={selectedPanel}
                                    setSelectedPanel={setSelectedPanel}
                                    userSettings={userSettings}
                                />
                                :
                                null
                            }
                        </div>
                    </div>
                    <div className="financial-preferences-mobile-inner-container relative-container">
                        {
                            loading === true ?
                            <div className="financial-preferences-loading-container">
                                <Loading />
                            </div>
                            :
                            null
                        }
                        <div className="financial-preferences-mobile-title-container margin-large">
                            <h1 className="heading-large-semibold colour-primary margin-x-small">
                                Financial preferences
                            </h1>
                            <h2 className="body-regular colour-secondary">
                                Manage how finances are calculated. 
                                Settings will automatically apply across all properties.
                            </h2>
                        </div>
                        <div className="my-cities-light-divider-line">
                        </div>
                        <div className="my-cities-mobile-options-container">
                            <div 
                                className="my-cities-mobile-option-element"
                                onClick={() => manageSubscriptions()}
                            >
                                <div className="button-row my-cities-mobile-option-padding">
                                    <img
                                        src={Cog}
                                        className="my-cities-mobile-option-cog"
                                        alt="Manage subscriptions"
                                    />
                                    <span className="body-regular colour-link block-text subtext-margin-right">
                                        Manage subscriptions
                                    </span>
                                    <img
                                        src={ChevronRight}
                                        className="my-cities-mobile-option-arrow"
                                        alt="Manage subscriptions"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default FinancialPreferences;