import React, { useState } from 'react';
import '../styles/NationwideFSBO.css';
import { USMap } from './';
import { FilterCityInput, allStates } from '../styles/GlobalStyles';
import { Exit, Search } from '../assets';

function NationwideFSBO() {
    const [expandSearch, setExpandSearch] = useState(false);
    const [state, setState] = useState("");
    const [filteredStates, setFilteredStates] = useState([]);
    const [hoverMapText, setHoverMapText] = useState(null);

    const changeState = (text) => {
        setState(text);

        if (text === "") {
            setFilteredStates([]);
        }
        else {
            const allStateClone = [...allStates];
            const newStates = allStateClone.filter((e) => e.abbreviation !== "AK" && e.abbreviation !== "HI" && e.name.toLowerCase().includes(text.toLowerCase()));
            setFilteredStates(newStates);
        }
    };

    const selectState = (item) => {
        setState("");
        setFilteredStates([]);
        window.open(`/state-properties-list/${item.abbreviation}`, "_blank");
    };

    const clickMap = (item) => {
        window.open(`/state-properties-list/${item.abbreviation}`, "_blank");
    };

    const hoverMap = (item, show) => {
        if ( show === true ) {
            setHoverMapText(item.name);
        }
        else {
            setHoverMapText(null);
        }
    };

    return (
        <div className="nationwide-fsbo-outer-container">
            <div className="nationwide-fsbo-inner-container">
                <div className="nationwide-fsbo-title-container">
                    <h2 className="heading-small-semibold colour-primary">
                        Explore nationwide FSBO
                    </h2>
                    <div className="relative-container">
                        <div className={"relative-container " + (expandSearch === true ? "search-active-input-container" : "search-inactive-input-container")}>
                            <FilterCityInput
                                value={state}
                                type="text"
                                id="state-input"
                                placeholder="Arizona"
                                onChange={(text) => changeState(text.target.value)}
                            />
                            <img
                                src={state === "" ? Search : Exit}
                                className={state === "" ? "nationwide-fsbo-search-icon" : "nationwide-fsbo-exit-icon"}
                                alt="Search"
                                onClick={() => state === "" ? setExpandSearch(false) : changeState("")}
                            />
                        </div>
                        {
                            filteredStates.length > 0 &&
                            <div className="nationwide-fsbo-filtered-states-container bg-colour-white">
                                {
                                    filteredStates.map((item, index) =>
                                        <div 
                                            key={index} 
                                            className="nationwide-fsbo-filtered-state"
                                            onClick={() => selectState(item)}
                                        >
                                            <span className="body-regular colour-primary">
                                                {item.name}
                                            </span>
                                        </div>
                                    )
                                }
                            </div>
                        }
                    </div>
                    {
                        expandSearch === false &&
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="16" 
                            height="16" 
                            viewBox="0 0 16 16" 
                            fill="none"
                            className="cursor-pointer"
                            onClick={() => setExpandSearch(true)}
                        >
                            <path 
                                d="M6.60304 13.0826C7.93707 13.0826 9.18721 12.6836 10.2276 12.0021L13.9025 15.6509C14.1458 15.8836 14.4562 16 14.7918 16C15.4882 16 16 15.4597 16 14.7782C16 14.4623 15.8909 14.1548 15.656 13.9221L12.0063 10.2899C12.7614 9.22597 13.2061 7.93766 13.2061 6.5413C13.2061 2.94234 10.236 0 6.60304 0C2.9785 0 0 2.94234 0 6.5413C0 10.1403 2.97011 13.0826 6.60304 13.0826ZM6.60304 11.3371C3.94337 11.3371 1.76193 9.1761 1.76193 6.5413C1.76193 3.90649 3.94337 1.74545 6.60304 1.74545C9.26272 1.74545 11.4442 3.90649 11.4442 6.5413C11.4442 9.1761 9.26272 11.3371 6.60304 11.3371Z" 
                                fill="#2F4858"
                            />
                        </svg>
                    }
                </div>
                <div className="buy-boxes-grid-divider-line margin-tops">
                </div>
                <div className="nationwide-fsbo-map-container bg-colour-light relative-container">
                    <USMap
                        clickMap={clickMap}
                        hoverMap={hoverMap}
                    />
                    {
                        hoverMapText !== null &&
                        <div className="nationwide-fsbo-hover-element">
                            <div className="nationwide-fsbo-hover-inner-element">
                                <span className="body-semibold colour-primary">
                                    {hoverMapText}
                                </span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default NationwideFSBO;