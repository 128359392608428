import React, { Fragment } from 'react';
import '../styles/RehabTab.css';
import { FinancialsInput, numberFormatter } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import { ChangeDot } from '../assets';
import { formatPrice } from '../functions';

function RehabTab(props) {
    const property = props.property;
    const costPerSqFoot = props.costPerSqFoot;
    const totalRehabCost = props.totalRehabCost;
    const changeRehabCost = props.changeRehabCost;
    const onChangeCostPerSqFoot = props.onChangeCostPerSqFoot;
    const onChangeTotalCost = props.onChangeTotalCost;
    const defaultTotalCost = props.defaultTotalRehabCost;

    return (
        <div className="rehab-tab-outer-container">
            <div className="rehab-tab-inner-container">
                <div className="rehab-tab-title-container margin-x-small">
                    <h3 className="body-semibold colour-primary">
                        Estimate rehab cost
                    </h3>
                </div>
                <div className={"rehab-tab-inputs-row " + (totalRehabCost === defaultTotalCost ? "margin-medium" : "")}>
                    <div className="rehab-tab-input-element subtext-margin-right">
                        <FinancialsInput
                            value={formatPrice(costPerSqFoot)}
                            type="text"
                            label={
                                Number(totalRehabCost) !== defaultTotalCost ?
                                <Fragment>
                                    Per squarefoot
                                    <img
                                        src={ChangeDot}
                                        className="financials-panel-change-dot"
                                        alt="Change"
                                    />
                                </Fragment>
                                :
                                `Per squarefoot`
                            }
                            id={`cost-per-sq-foot-input`}
                            placeholder={`30`}
                            onWheel={(e) => e.target.blur()}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled={property.livingArea === 0 || property.livingArea === null ? true : false}
                            onChange={(text) => onChangeCostPerSqFoot(text.target.value.replaceAll(",", ""))}
                        />
                        <span className="body-regular colour-secondary block-text subtext-margin-left">
                            x{numberFormatter.format(property.livingArea)}
                        </span>
                    </div>
                    <div className="rehab-tab-input-element">
                        <span className="body-regular colour-secondary block-text subtext-margin-right">
                            =
                        </span>
                        <FinancialsInput
                            value={formatPrice(totalRehabCost)}
                            type="text"
                            label={
                                Number(totalRehabCost) !== defaultTotalCost ?
                                <Fragment>
                                    Total
                                    <img
                                        src={ChangeDot}
                                        className="financials-panel-change-dot"
                                        alt="Change"
                                    />
                                </Fragment>
                                :
                                `Total`
                            }
                            id={`total-cost-input`}
                            placeholder={`40,000`}
                            onWheel={(e) => e.target.blur()}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled={property.livingArea === 0 || property.livingArea === null ? true : false}
                            onChange={(text) => onChangeTotalCost(text.target.value.replaceAll(",", ""))}
                        />
                    </div>
                </div>
                {
                    totalRehabCost === defaultTotalCost ?
                    null
                    :
                    <div className="rehab-tab-rehab-container margin-medium">
                        <span 
                            className="label-semibold colour-link"
                            onClick={() => onChangeTotalCost(defaultTotalCost)}
                        >
                            Reset
                        </span>
                    </div>
                }
                <div className="rehab-tab-slider-container margin-x-large">
                    <Stack 
                        spacing={2} 
                        direction="row" 
                        sx={{ mb: 1, px: 1 }} 
                        alignItems="center"
                    >
                        <Slider
                            aria-label="Total rehab cost"
                            value={Number(costPerSqFoot)}
                            onChange={changeRehabCost}
                            min={0}
                            max={100}
                            disabled={property.livingArea === 0 || property.livingArea === null ? true : false}
                            sx={{
                                color: '#CFBCAD',
                                height: '5px',
                                '& .MuiSlider-track': {
                                    border: 'none',
                                },
                                '& .MuiSlider-thumb': {
                                    width: 24,
                                    height: 24,
                                    backgroundColor: '#fff',
                                    '&:before': {
                                        boxShadow: '0px 0px 10px 0px #99959180',
                                    },
                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                        boxShadow: 'none',
                                    },
                                }
                            }}
                        />
                    </Stack>
                </div>
            </div>
        </div>
    )
};

export default RehabTab;