import React from 'react';
import { FixerUpper } from '../assets';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CustomRadio } from '../styles/GlobalStyles';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';

function ChooseValueAddOpportunity(props) {
    const onlyFixerUppers = props.onlyFixerUppers;
    const setOnlyFixerUppers = props.setOnlyFixerUppers;
    const hideFixerUppers = props.hideFixerUppers;
    const setHideFixerUppers = props.setHideFixerUppers;
    const setDisabled = props.setDisabled;

    const fixerUpperRadios = [
        {
            label: "Turnkey only",
            value: false
        },
        {
            label: "Fixer-uppers only",
            value: true
        },
        {
            label: "Show both turnkey & fixer-uppers",
            value: "both"
        }
    ];

    const changeFixerUpperRadio = (value) => {
        if ( value === "false" ) {
            value = false;
        }
        else if ( value === "true" ) {
            value = true;
        }

        if ( value === false ) {
            setHideFixerUppers(true);
            setOnlyFixerUppers(false);
        }
        else if ( value === true ) {
            setHideFixerUppers(false);
            setOnlyFixerUppers(true);
        }
        else {
            setHideFixerUppers(false);
            setOnlyFixerUppers(false);
        }

        if ( setDisabled !== null ) {
            setDisabled(false);
        }
    };

    return (
        <div className="choose-value-add-opportunity-outer-container">
            <div className="choose-value-add-opportunity-title-container margin-x-small">
                <img
                    src={FixerUpper}
                    className="choose-value-add-opportunity-icon"
                    alt="Fixer Uppers"
                />
                <span className="body-semibold colour-primary">
                    Fixer Uppers
                </span>
            </div>
            <div className="">
                <FormControl className="new-filters-modal-radio-outer-container">
                    <RadioGroup
                        aria-labelledby="fixer-upper-buttons-group-label"
                        name="radio-buttons-group"
                        value={hideFixerUppers === false && onlyFixerUppers === false ? "both" : onlyFixerUppers === true}
                        className="choose-value-add-opportunity-property-condition-radios"
                        onChange={(val) => changeFixerUpperRadio(val.target.value)}
                    >
                        {
                            fixerUpperRadios.map((item, index) =>
                                <FormControlLabel 
                                    value={item.value} 
                                    control={<CustomRadio />}
                                    className=""
                                    label={<span className="body-regular colour-primary">{item.label}</span>}
                                    key={index}
                                />
                            )
                        }
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    );
};

export default ChooseValueAddOpportunity;