import { useEffect, useState } from 'react';
import { checkOnAuthStateChanged, chooseCity, checkPropertySearchCredits, getUserDocuments, checkUserDocumentExists } from '../functions/index';
import mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';
import { FullStory } from '@fullstory/browser';
import { usePostHog } from 'posthog-js/react';
import { useNavigate } from 'react-router-dom';

function HeaderTotalEffect(props) {

    const setUserData = props.setUserData;
	const setFreeTrial = props.setFreeTrial;
    const setSubscriptions = props.setSubscriptions;
    const setLoading = props.setLoading;
    const state = props.state;
    const filteredResults = props.filteredResults;
    const userData = props.userData;
    const city = props.city;
    const mapSearchAddressAutocomplete = props.mapSearchAddressAutocomplete;
    const selectedFilteredResult = props.selectedFilteredResult;
    const setSelectedFilteredResult = props.setSelectedFilteredResult;
    const nonDisclosureStates = props.nonDisclosureStates;
    const path = props.path;
    const setUpgradeModal = props.setUpgradeModal;
    const freeTrial = props.freeTrial;
    const subscriptions = props.subscriptions;
    const setUpgradeCity = props.setUpgradeCity;
	const setUserId = props.setUserId;
    const posthog = usePostHog();
    const navigate = useNavigate();
    const [firstRender, setFirstRender] = useState(false);
	const today = new Date();
	const todaySeconds = today.getTime() / 1000;

    useEffect(() => {
		async function fetchUserData() {
			const collections = ["Users", "Subscriptions"];
			const getUserId = await checkOnAuthStateChanged();
			if ( getUserId.status === 200 ) {
				const userId = getUserId.userId;
				setUserId(userId);
				mixpanel.identify(userId);

				const emailVerified = getUserId.emailVerified;
				if ( emailVerified === false && path !== "/profile" && path !== "/verify-email" && path !== "/email-verification" && path !== "/describe-you" ) {
					navigate("/verify-email");
				}

				const docRef = userId;
				const query = await getUserDocuments(collections, docRef);
				if ( query[0].status === 200 ) {
					const data = query[0].data;
					setUserData(data);

					if ( data.email !== undefined ) {
						const name = `${data.firstName} ${data.lastName}`;
						const email = data.email.toLowerCase();
						mixpanel.register({
							'Email': email,
							'Name': name
						});

						mixpanel.people.set({
							'$name': name,
							'$email': email,
							'$distinct_id': userId
						});

						FullStory.identify(userId, {
							displayName: name,
							email: email,
							uid: userId
						});

						posthog?.identify(userId, {
							email: email,
							name: name
						});

						const tagManagerArgs = {
							dataLayer: {
								userId: userId,
								email: email,
								name: name
							}
						};
						TagManager.dataLayer(tagManagerArgs);
					}

					// }

					// 3 different user types

					// 1. Paid users who need grandfathering
					// 2. Free Trial users who need grandfathering
					// 3. New users who DON'T need grandfathering (further split into paid vs free)

					if ( query[1].status === 200 ) {
						// User who has subscriptions on new system

						const oldSubs = query[1].data;
						const creativeSubs = [];
						for (let index = 0; index < oldSubs.length; index++) {
							const element = oldSubs[index];
							if ( element.creative === true && element.endDate.seconds > todaySeconds ) {
								creativeSubs.push(element);
							}
						};

						setSubscriptions(creativeSubs);

						const newUserData = query[0].status === 200 ? query[0].data : null;
						if ( creativeSubs.length === 0 && newUserData !== null && newUserData.freeTrial !== undefined ) {
							const freeTrialData = newUserData.freeTrial;
							if ( freeTrialData.length !== 0 ) {
								const freeTrialEndDate = freeTrialData[0].endDate.seconds;
								if ( freeTrialEndDate > todaySeconds ) {
									setFreeTrial(true);
								}
							}
						}
					}
					else {
						const newUserData = query[0].status === 200 ? query[0].data : null;
						if ( newUserData !== null && newUserData.freeTrial !== undefined ) {
							const freeTrialData = newUserData.freeTrial;
							if ( freeTrialData.length !== 0 ) {
								const freeTrialEndDate = freeTrialData[0].endDate.seconds;
								if ( freeTrialEndDate > todaySeconds ) {
									setFreeTrial(true);
								}
							}
						}
					}
				}
				else {
					await checkUserDocumentExists(getUserId, navigate)
				}
			}
			else {
				setUserId("");
			}
		}

		if ( firstRender === false ) {
			setFirstRender(true);
			fetchUserData();
		}

		const keyDownHandler = event => {
			if ( (filteredResults.length !== 0 || mapSearchAddressAutocomplete.length !== 0) && city !== "" ) {
				if (event.key === "ArrowDown") {
					event.preventDefault();
					const newVal = selectedFilteredResult === null || selectedFilteredResult === 6 ? 0 : selectedFilteredResult + 1;
					setSelectedFilteredResult(newVal);
				}
				else if ( event.key === "ArrowUp" ) {
					event.preventDefault();
					const newVal = selectedFilteredResult === null || selectedFilteredResult === 0 ? null : selectedFilteredResult - 1;
					setSelectedFilteredResult(newVal);
				}
				else if ( event.key === "Enter" && selectedFilteredResult !== null ) {
					event.preventDefault();
					if ( mapSearchAddressAutocomplete.length !== 0 ) {
						enterSelectGoogleMapProperty(mapSearchAddressAutocomplete[selectedFilteredResult]);
					}
					else {
						enterCityClick(filteredResults[selectedFilteredResult]);
					}
				}
			}
		};

		const enterSelectGoogleMapProperty = async(item) => {
			setLoading(true);
			if ( userData === "" ) {
				const encodedAddress = encodeURIComponent(item.description);
				navigate(`/quick-report/${encodedAddress}`, {
					state: state
				});
			}
			else if ( item.zpid !== undefined ) {
				window.open(`/properties/${item.cityId}/${item.zpid}`, "_blank");
			}
			else {
				const queryPropertySearch = await checkPropertySearchCredits(item, subscriptions, null, null);
				if ( queryPropertySearch === "no credits" ) {
					const encodedAddress = encodeURIComponent(item.description);
					navigate(`/quick-report/${encodedAddress}`, {
						state: state
					});
				}
			}
			setLoading(false);
		};
	
		const enterCityClick = async(item) => {
			setLoading(true);
			if ( userData !== "" && userData !== null ) {
				const checkCity = await chooseCity(freeTrial, subscriptions, item);
				if ( checkCity.status === 200 && checkCity.cityId !== undefined) {
					setUpgradeCity(null);
					window.open(`/properties-list/${checkCity.cityId}`, "_blank");
				}
				else {
					setUpgradeCity(item);
					setUpgradeModal(true);
				}
			}
			else {
				const newState = {
					chosenCity: {
						city: item.city,
						state: item.state
					},
					onboarding: true
				};
				navigate("/sign-up", {
					state: newState
				});
			}
			setLoading(false);
		};
	  
		document.addEventListener('keydown', keyDownHandler);
	
		// 👇️ clean up event listener
		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, [
		city,
		filteredResults,
		freeTrial,
		mapSearchAddressAutocomplete,
		navigate,
		path,
		posthog,
		selectedFilteredResult,
		state,
		subscriptions,
		userData,
		firstRender,
        nonDisclosureStates,
        setLoading,
        setSelectedFilteredResult,
        setSubscriptions,
        setUpgradeCity,
        setUpgradeModal,
        setUserData,
		setUserId,
		todaySeconds,
		setFreeTrial
	]);
};

export default HeaderTotalEffect;