import React from 'react';
import { ADUBadge, AddBedroomBadge, CashOnlyBadge, DripScoreBadge, FSBOBadge, FixerUpperBadge } from './';
import '../styles/PropertyBadgesRow.css';

function PropertyBadgesRow(props) {
	const property = props.property;
	const strategy = props.strategy;
	const partner = props.partner;
	const score = strategy === 0 ? property.dripScoreOptions.sellerFinancing : property.dripScoreOptions.subto;

	return (
		<div className="property-badges-row-outer-container">
			{
				property.dripScoreOptions !== undefined && property.dripScoreOptions !== null && property.onMarket === true ?
				<div className="property-badges-row-element">
					<DripScoreBadge
						score={score}
					/>
				</div>
				:
				null
			}
			{
				property.fixerUpper === true && partner !== true ?
				<div className="property-badges-row-element">
					<FixerUpperBadge />
				</div>
				:
				null
			}
			{
				property.cashOnly === true && partner !== true ?
				<div className="property-badges-row-element">
					<CashOnlyBadge />
				</div>
				:
				null
			}
			{
				property.addBedOpportunity === true ?
				<div className="property-badges-row-element">
					<AddBedroomBadge />
				</div>
				:
				null
			}
			{
				property.zonedForAdu === true ?
				<div className="property-badges-row-element">
					<ADUBadge />
				</div>
				:
				null
			}
			{
				property.fsbo === true ?
				<div className="property-badges-row-element">
					<FSBOBadge />
				</div>
				:
				null
			}
		</div>
	)
}

export default PropertyBadgesRow;