import React, { useState, useEffect } from 'react';
import '../styles/ListingsCard.css';
import { ActionButton, BlackActionButton, formatterCompact, numberFormatter, percentageCompact } from '../styles/GlobalStyles';
import { BackOnMarketBadge, CustomSwiper, FixerUpperBadge, FSBOBadge, InheritedBadge, Loading, MultipleBadges, PriceCutBadge, PropertyCardBadge } from './';
import { formatPhoneNumber, recordEvent } from '../functions';
import { OffMarketHouse, RedirectArrow, RedirectArrowBlue } from '../assets';

function ListingsCard(props) {
    const property = props.property;
    const cityId = props.cityId;
    const type = props.type;
    const index = props.index;
    const setHideModal = props.setHideModal;
    const setHideProperty = props.setHideProperty;
    const viewOfferModal = props.viewOfferModal;
    const strategy = props.strategy === "all" && property.dripScoreOptions.subto >= property.dripScoreOptions.sellerFinancing ? "subto" : props.strategy === "all" ? "sellerFinance" : props.strategy;
    const skipTraceWebsite = property.ownerAttributes === undefined ? "" : property.ownerAttributes.ownerType === "Company" ? "OpenCorporates" : "FastPeopleSearch";
    const googleLink = `https://www.google.com/maps/place/${property.address.streetAddress.replace(" ", "+")},+${property.address.city},+${property.address.state}+${property.address.zipcode}`;
    const [offerLoading, setOfferLoading] = useState(false);
    const [multipleBadges, setMultipleBadges] = useState(false);
    const score = strategy === "subto" ? property.dripScoreOptions.subto : property.dripScoreOptions.sellerFinancing;
    const relevantCoCR = strategy === "subto" ? property.cashOnCashReturnSubtoDefault : property.cashOnCashReturnSellerFinDefault;
    const abstract = props.abstract;
    const propertyRefs = props.propertyRefs;
    const setHoverCard = props.setHoverCard;

    useEffect(() => {
		const badgeArray = [];
		if ( property.fsbo === true ) {
			badgeArray.push(true);
		}

        if ( property.ownerAttributes !== undefined && property.ownerAttributes !== null && property.ownerAttributes.inherited === true ) {
            badgeArray.push(true);
        }

        if ( property.backOnMarket === true ) {
            badgeArray.push(true);
        }
        
        if ( property.fixerUpper === true ) {
            badgeArray.push(true);
        }

        if ( badgeArray.length > 1 ) {
			setMultipleBadges(true);
		}
		else {
			setMultipleBadges(false);
		}

    }, [property.fsbo, property.ownerAttributes, property.backOnMarket, property.fixerUpper]);

    const round = (x) => {
        return Math.ceil(x / 10) * 10;
    };

	const scoreTimes100 = Math.round(score * 100);
	const roundedScore = round(scoreTimes100) / 20;

    const details = [
        {
            icon:   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M7.26072 0.757035C7.46143 0.547626 7.7335 0.447381 8.00067 0.457417C8.26784 0.447827 8.53947 0.548072 8.73929 0.757147L15.2901 7.59853C15.6621 7.98702 15.6487 8.60343 15.2602 8.97541C14.8717 9.3474 14.2553 9.33402 13.8833 8.94553L8.00023 2.80106L2.11669 8.94542C1.7447 9.33391 1.12829 9.34729 0.739804 8.97541C0.351315 8.60343 0.337934 7.98691 0.70992 7.59853L7.26072 0.757035Z" fill="#989793"/>
                        <path d="M14.6268 9.47385C14.4703 9.47797 14.3133 9.44463 14.1701 9.37405V14.6298C14.1701 14.882 13.9658 15.0865 13.7134 15.0865H13.2567V9.37739C13.2567 9.33034 13.2424 9.28663 13.2183 9.25039C13.1773 9.18928 13.1077 9.14903 13.0283 9.14903H2.9802C2.85397 9.14903 2.75183 9.25128 2.75183 9.37739V15.0865H2.2951C2.17423 15.0865 2.0645 15.0397 1.98288 14.9631C1.9249 14.9086 1.88074 14.8391 1.85755 14.7607C1.84506 14.7192 1.83837 14.6753 1.83837 14.6298V9.38364C1.6943 9.45043 1.5373 9.48043 1.38164 9.47307V14.6298C1.38164 15.1343 1.79064 15.5433 2.2951 15.5433H13.7134C14.2178 15.5433 14.6268 15.1343 14.6268 14.6298V9.47385Z" fill="#989793"/>
                    </svg>,
            text: property.propertyTypeDimension === "Single Family" ? "SFH" : "MFH",
        },
        {
            icon:   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M1.33333 2C1.33333 1.26362 1.93029 0.666666 2.66667 0.666666H13.3333C14.0697 0.666666 14.6667 1.26362 14.6667 2V6.42436C14.4219 6.28279 14.1526 6.179 13.8667 6.12095V4.4C13.8667 3.95817 13.5085 3.6 13.0667 3.6H9.06667C8.62484 3.6 8.26667 3.95817 8.26667 4.4V6.06761H7.73333V4.4C7.73333 3.95817 7.37516 3.6 6.93333 3.6H2.93333C2.49151 3.6 2.13333 3.95817 2.13333 4.4V6.12095C1.84739 6.179 1.57807 6.28279 1.33333 6.42436V2Z" fill="#989793"/>
                        <path d="M2.66667 7.06667C1.19391 7.06667 0 8.26057 0 9.73333V10.8C0 10.8267 0.00039266 10.8533 0.00117188 10.8798C0.000393168 10.8976 0 10.9154 0 10.9333V14.1333C0 14.7961 0.537258 15.3333 1.2 15.3333C1.86274 15.3333 2.4 14.7961 2.4 14.1333V13.4535C2.48771 13.4622 2.57667 13.4667 2.66667 13.4667H13.3333C13.4233 13.4667 13.5123 13.4622 13.6 13.4535V14.1333C13.6 14.7961 14.1373 15.3333 14.8 15.3333C15.4627 15.3333 16 14.7961 16 14.1333V10.9333C16 10.9154 15.9996 10.8976 15.9988 10.8798C15.9996 10.8533 16 10.8267 16 10.8V9.73333C16 8.26057 14.8061 7.06667 13.3333 7.06667H2.66667Z" fill="#989793"/>
                    </svg>,
            text: property.bedrooms
        },
        {
            icon:   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M3.94358 4.27959C3.94358 3.13208 4.5425 2.33807 5.26325 1.99604C5.74552 1.76718 6.29588 1.73075 6.8076 1.94273L6.29253 2.69408C5.98314 3.14539 6.3101 3.75811 6.85724 3.75237L9.15087 3.7283C9.69801 3.72256 10.012 3.1031 9.69325 2.65839L9.0911 1.81838C8.88017 1.52413 8.58116 1.34526 8.26299 1.28102C7.19441 0.341607 5.81265 0.238665 4.68356 0.774478C3.4783 1.34643 2.59145 2.62415 2.59145 4.27959V8.56328H1.69003C1.44111 8.56328 1.23932 8.76507 1.23932 9.01399V11.9436C1.23932 13.2069 1.98177 14.2967 3.05411 14.8006C3.04636 14.8234 3.04216 14.8478 3.04216 14.8732V15.3239C3.04216 15.4484 3.14306 15.5493 3.26752 15.5493H3.94358C4.06804 15.5493 4.16894 15.4484 4.16894 15.3239V15.0907C4.24337 15.0959 4.31852 15.0986 4.39429 15.0986H11.6057C11.6814 15.0986 11.7566 15.0959 11.831 15.0907V15.3239C11.831 15.4484 11.9319 15.5493 12.0564 15.5493H12.7324C12.8569 15.5493 12.9578 15.4484 12.9578 15.3239V14.8732C12.9578 14.8478 12.9536 14.8234 12.9458 14.8006C14.0182 14.2967 14.7606 13.2069 14.7606 11.9436V9.01399C14.7606 8.76507 14.5588 8.56328 14.3099 8.56328H3.94358V4.27959Z" fill="#989793"/>
                        <path d="M8.56548 4.56082C8.55029 4.53963 8.51866 4.53996 8.50392 4.56147L8.12097 5.12008C7.88648 5.46214 8.13429 5.92655 8.54898 5.9222C8.96368 5.91784 9.20169 5.44834 8.96007 5.11128L8.56548 4.56082Z" fill="#989793"/>
                        <path d="M7.15178 6.13895C7.16653 6.11744 7.19816 6.11711 7.21335 6.13831L7.60794 6.68876C7.84956 7.02583 7.61155 7.49533 7.19685 7.49968C6.78215 7.50403 6.53434 7.03963 6.76884 6.69757L7.15178 6.13895Z" fill="#989793"/>
                        <path d="M9.69226 6.81437C9.67707 6.79318 9.64544 6.79351 9.63069 6.81502L9.24774 7.37363C9.01325 7.7157 9.26106 8.1801 9.67576 8.17575C10.0905 8.1714 10.3285 7.70189 10.0868 7.36483L9.69226 6.81437Z" fill="#989793"/>
                    </svg>,
            text: property.bathrooms
        },
        {
            icon:   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.6667 1.81811C11.8398 0.786212 12.7373 -7.43866e-05 13.8184 -7.43866e-05C15.0234 -7.43866e-05 16.0002 0.976759 16.0002 2.18174C16.0002 3.26283 15.2139 4.16028 14.182 4.3334V11.6665C15.2139 11.8396 16.0002 12.737 16.0002 13.8181C16.0002 15.0231 15.0234 15.9999 13.8184 15.9999C12.7373 15.9999 11.8398 15.2136 11.6667 14.1817H4.33365C4.16053 15.2136 3.26309 15.9999 2.182 15.9999C0.977016 15.9999 0.000183105 15.0231 0.000183105 13.8181C0.000183105 12.737 0.786469 11.8396 1.81837 11.6665L1.81836 4.3334C0.786469 4.16028 0.000183105 3.26283 0.000183105 2.18174C0.000183105 0.976759 0.977016 -7.43866e-05 2.182 -7.43866e-05C3.26309 -7.43866e-05 4.16053 0.786212 4.33365 1.81811H11.6667ZM3.81837 2.18174C3.81837 3.08548 3.08574 3.81811 2.182 3.81811C1.27826 3.81811 0.545638 3.08548 0.545638 2.18174C0.545638 1.27801 1.27826 0.54538 2.182 0.54538C3.08574 0.54538 3.81837 1.27801 3.81837 2.18174ZM4.33365 2.54538C4.18037 3.45902 3.45928 4.18012 2.54564 4.3334L2.54564 11.6665C3.45928 11.8197 4.18037 12.5408 4.33365 13.4545H11.6667C11.82 12.5408 12.5411 11.8197 13.4547 11.6665V4.3334C12.5411 4.18012 11.82 3.45902 11.6667 2.54538H4.33365ZM15.4547 2.18174C15.4547 3.08548 14.7221 3.81811 13.8184 3.81811C12.9146 3.81811 12.182 3.08548 12.182 2.18174C12.182 1.27801 12.9146 0.54538 13.8184 0.54538C14.7221 0.54538 15.4547 1.27801 15.4547 2.18174ZM13.8184 15.4545C14.7221 15.4545 15.4547 14.7218 15.4547 13.8181C15.4547 12.9144 14.7221 12.1817 13.8184 12.1817C12.9146 12.1817 12.182 12.9144 12.182 13.8181C12.182 14.7218 12.9146 15.4545 13.8184 15.4545ZM3.81837 13.8181C3.81837 14.7218 3.08574 15.4545 2.182 15.4545C1.27826 15.4545 0.545638 14.7218 0.545638 13.8181C0.545638 12.9144 1.27826 12.1817 2.182 12.1817C3.08574 12.1817 3.81837 12.9144 3.81837 13.8181Z" fill="#989793"/>
                    </svg>,
            text: numberFormatter.format(property.livingArea)
        }
    ];

    const financials = [
        {
            value: formatterCompact.format(property.price).replace("K", "").replace("M", ""),
            post: property.price < 1000000 ? "k" : "m",
            bg: null,
            label: "asking"
        },
        // {
        //     value: formatterCompact.format(property.price * downPayment).replace("K", "").replace("M", ""),
        //     post: (property.price * downPayment) < 1000000 ? "k" : "m",
        //     bg: null,
        //     label: "down"
        // },
        {
            value: property.dateDifferenceMonths > 11 ? property.dateDifferenceYears : property.dateDifferenceMonths > 0 ? property.dateDifferenceMonths : property.dateDifferenceDays,
            post: property.dateDifferenceMonths > 11 ? "y" : property.dateDifferenceMonths > 0 ? "mo" : "dy",
            bg: null,
            label: "on market"
        },
        {
            value: percentageCompact.format(relevantCoCR / 100).replace("%", ""),
            post: "%",
            bg: relevantCoCR > 0 ? "bg-colour-active-status" : "negative",
            label: "cash on cash"
        }
    ];

    const activateHideModal = () => {
        if ( setHideProperty === null ) {
            return;
        }
        setHideModal(true);
        setHideProperty(property);
    };

    const downloadOffer = async() => {
        setOfferLoading(true);
        const url = property.offerURL;

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', 'filename.ext'); // You can set the file name dynamically here if needed
    
        // Append to the document and trigger click
        document.body.appendChild(link);
        link.click();
    
        // Clean up and remove the link
        link.parentNode.removeChild(link);

        setOfferLoading(false);
    };

    const viewProperty = (e) => {
        const target = e.target;
        const targetClass = target.parentNode.classList.value;
        if ( target.classList.value.includes("listings-card-hide-element") || target.classList.value.includes("listings-card-hide-container") || targetClass.includes("listings-card-hide-element") || targetClass.includes("listings-card-hide-container") ) {
            return;
        }
        else {
            const strategyNumber = strategy === "subto" ? 1 : 0;
            window.open(`/properties/${cityId}/${property.zpid}?strategy=${strategyNumber}`, "_blank");
        }
    };

    const addHover = () => {
		setHoverCard(property.zpid);
		if ( propertyRefs[index].current !== null && propertyRefs !== undefined ) {
			propertyRefs[index].current.parentNode.style.zIndex = 3;
		}
	}

	const removeHover = () => {
		if ( propertyRefs[index].current !== null && propertyRefs !== undefined ) {
			propertyRefs[index].current.parentNode.style.zIndex = 1;
		}
		setHoverCard("");
	}

    const arrowClicked = (event) => {
		event.stopPropagation();
	};

    const openGoogle = () => {
		recordEvent("Open Google Link", {});
        window.open(googleLink, '_blank');
    };

    const openFastPeopleSearch = () => {
        if ( property.ownerAttributes.ownerType === "Company" ) {
            // corporate owner https://opencorporates.com
            recordEvent("Viewed Open Corporates", {});
            const companyName = property.ownerAttributes.ownerName.replace(/ /g, "+");
            const corporateLink = `https://opencorporates.com/companies?utf8=✓&utf8=✓&q=${companyName}&jurisdiction_code=&type=companies`;
            window.open(corporateLink, "_blank");
        }
        else {
            recordEvent("Viewed FastPeopleSearch", {});
            const street = property.address.streetAddress.toLowerCase().replace(/ /g, "-");
            const city = property.address.city.toLowerCase().replace(/ /g, "-");
            const state = property.address.state.toLowerCase();
            window.open(`https://www.fastpeoplesearch.com/address/${street}_${city}-${state}`, "_blank");
        }
    };

    return (
        <div 
            className={"listings-card-outer-container " + (type === "listing" ? "cursor-pointer" : "") + (type === "offer" ? "listings-card-offer" : "")}
            onMouseOver={() => abstract === false ? addHover() : null}
			onMouseOut={() => abstract === false ? removeHover() : null}
            onClick={(e) => type === "listing" ? viewProperty(e) : null}
        >
            {
                offerLoading === true ?
                <Loading />
                :
                null
            }
            {
                type === "listing" && setHideProperty !== null ?
                <div 
                    className="listings-card-hide-container"
                    onClick={() => activateHideModal()}
                >
                    <svg className="listings-card-hide-element" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.0796 1.44782C15.4002 1.09182 15.3714 0.543356 15.0154 0.222805C14.6594 -0.0977454 14.111 -0.0690019 13.7904 0.287006L7.99993 6.718L2.20947 0.287037C1.88892 -0.0689705 1.34046 -0.097714 0.984455 0.222837C0.628447 0.543388 0.599703 1.09185 0.920254 1.44785L6.83272 8.01432L0.946038 14.5521C0.625487 14.9081 0.654231 15.4566 1.01024 15.7772C1.36625 16.0977 1.91471 16.069 2.23526 15.713L7.99993 9.31064L13.7646 15.713C14.0852 16.069 14.6336 16.0977 14.9897 15.7772C15.3457 15.4566 15.3744 14.9082 15.0539 14.5522L9.16715 8.01432L15.0796 1.44782Z" fill="#FFFEFC"/>
                    </svg>
                </div>
                :
                null
            }
            <div className="listings-card-inner-container">
                {
					multipleBadges === true ?
					<MultipleBadges
						property={property}
					/>
					:
                    property.fsbo === true ?
                    <FSBOBadge />
                    :
                    property.ownerAttributes !== undefined && property.ownerAttributes !== null && property.ownerAttributes.inherited === true ?
                    <InheritedBadge />
                    :
                    property.backOnMarket === true ?
                    <BackOnMarketBadge />
                    :
                    property.fixerUpper === true ?
                    <FixerUpperBadge />
                    :
					null
				}
                {
                    property.images !== undefined && property.images !== null && property.images.length !== 1 ?
					<div className="listings-card-images-swiper-container cursor-pointer">
						<CustomSwiper
							images={property.images}
							title={property.address.streetAddress}
							defaultImage={property.imgSrc}
							item={property}
							viewProperty={type === "listing" ? null : viewProperty}
							partner={false}
							arrowClicked={arrowClicked}
							imageLimit={10}
							swiperRef={null}
						/>
                        {
							property.auction === true ?
							<PropertyCardBadge
								text="AUCTION"
							/>
							:
							property.priceChange === true ?
							<PriceCutBadge
								priceDrop={property.priceChangeTotalPrctDiff > 0 ? false : true}
								priceChangeHistory={property.priceChangeHistory}
								priceChangeTotalAmount={property.priceChangeTotalAmount}
								priceChangeLatestDate={property.priceChangeLatestDate}
							/>
							:
							null
						}
					</div>
					:
                    property.images === null || (property.images.length > 0 && property.images[0].includes("maps.googleapis.com")) || (property.imgSrc !== undefined && property.imgSrc.includes("maps.googleapis.com")) || (property.images.length === 0) ?
					<div 
						className="off-market-card-image-container"
						onClick={(e) => type === "listing" ? null : viewProperty(e)}
					>
						<img
							src={OffMarketHouse}
							className="off-market-card-icon"
							alt="Off Market"
						/>
						<div 
							className="off-market-card-text-row"
							onClick={() => openGoogle()}
						>
							<span className="label-regular-caps colour-secondary">
								Go to street view
							</span>
							<img
								src={RedirectArrow}
								className="off-market-card-redirect-arrow"
								alt="Redirect"
							/>
						</div>
					</div>
                    :
                    <div 
                        style={{ backgroundImage: `url(${property.imgSrc})` }} 
                        className="listings-card-image-container margin-medium cursor-pointer"
                        onClick={(e) => type === "listing" ? null : viewProperty(e)}
                    >
                        {
							property.auction === true ?
							<PropertyCardBadge
								text="AUCTION"
							/>
							:
							property.priceChange === true ?
							<PriceCutBadge
								priceDrop={property.priceChangeTotalPrctDiff > 0 ? false : true}
								priceChangeHistory={property.priceChangeHistory}
								priceChangeTotalAmount={property.priceChangeTotalAmount}
								priceChangeLatestDate={property.priceChangeLatestDate}
							/>
							:
							null
						}
                    </div>
                }
                <div className="listings-card-body-container">
                    {
                        type === "listing" ?
                        <div className="listings-card-financials-container margin-x-small">
                            {
                                financials.map((item, index) =>
                                    <div 
                                        className={"listings-card-financials-element " + (item.bg !== null ? item.bg : "")}
                                        key={index}
                                    >
                                        <span className="heading-small-semibold colour-primary block-text">
                                            {item.value}<span className="text-regular colour-secondary">{item.post}</span>
                                        </span>
                                        <span className="label-regular colour-secondary block-text">
                                            {item.label}
                                        </span>
                                    </div>
                                )
                            }
                        </div>
                        :
                        null
                    }
                    {
                        type === "listing" ?
                        <div className="listings-card-border">
                        </div>
                        :
                        null
                    }
                    <div className="listings-card-details-row margin-x-small margin-top-small">
                        <div className="listings-card-address-container">
                            <span className="body-semibold colour-primary block-text">
                                {property.address.streetAddress}
                            </span>
                            <span className="body-semibold colour-primary block-text">
                                {property.address.city}, {property.address.state} {property.address.zipcode}
                            </span>
                        </div>
                        <div className="listings-card-score-container">
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="24" 
                                height="22" 
                                viewBox="0 0 24 22" 
                                fill="none"
                                className="block-text subtext-margin-right"
                            >
                                <path 
                                    d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z" 
                                    fill="#292621"
                                />
                            </svg>
                            <span className="heading-small-semibold colour-primary">
                                {roundedScore}
                            </span>
                        </div>
                    </div>
                    <div className="listings-card-details-container">
                        {
                            details.map((item, index) =>
                                <div 
                                    className="listings-card-details-element"
                                    key={index}
                                >
                                    <div className="listings-card-details-icon-container">
                                        {item.icon}
                                    </div>
                                    <span className="body-regular colour-secondary block-text">
                                        {item.text}
                                    </span>
                                </div>
                            )
                        }
                    </div>
                    {
                        type === "offer" ?
                        <div className="listings-card-border">
                        </div>
                        :
                        null
                    }
                    {
                        type === "offer" ?
                        <div className="listings-card-seller-info-container margin-medium">
                            <div className="margin-x-small">
                                <span className="label-regular-caps colour-secondary block-text margin-x-x-small">
                                    SELLER
                                </span>
                                <h3 className="body-semibold colour-primary margin-x-x-small">
                                    {
                                        property.ownerAttributes !== undefined ?
                                        property.ownerAttributes.ownerName
                                        :
                                        "N/A"
                                    }
                                </h3>
                                <div className="listings-card-seller-info-row">
                                    {
                                        property.ownerAttributes !== undefined ?
                                        <div 
                                            className="owner-details-fast-people-search-container"
                                            onClick={() => openFastPeopleSearch()}
                                        >
                                            <span className="body-regular-underline colour-link subtext-margin-right">
                                                View more on {skipTraceWebsite}
                                            </span>
                                            <img
                                                src={RedirectArrowBlue}
                                                className="owner-details-redirect-arrow-blue"
                                                alt={skipTraceWebsite}
                                            />
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="margin-x-small">
                                <span className="label-regular-caps colour-secondary block-text margin-x-x-small">
                                    {
                                        property.fsbo === true ?
                                        "PROPERTY OWNER"
                                        :
                                        "SELLER'S AGENT"
                                    }
                                </span>
                                <h3 className="body-semibold colour-primary margin-x-x-small">
                                    {
                                        property.fsbo === true ?
                                        property.ownerAttributes.ownerName
                                        :
                                        property.listedBy !== undefined && property.listedBy !== null && property.listedBy.displayName !== undefined && property.listedBy.displayName !== null && !property.listedBy.displayName.includes("broker") ?
                                        property.listedBy.displayName
                                        :
                                        property.attributionInfo !== undefined && property.attributionInfo !== null && property.attributionInfo.agentName !== undefined && property.attributionInfo.agentName !== null && property.attributionInfo.agentName !== "" ?
                                        property.attributionInfo.agentName
                                        :
                                        null
                                    }
                                </h3>
                                <div className="listings-card-seller-info-row">
                                    <span className="body-regular colour-primary">
                                        {
                                            property.listedBy !== undefined && property.listedBy !== null && property.listedBy.phone !== undefined && property.listedBy.phone !== null && property.listedBy.phone !== "" ?
                                            `(${property.listedBy.phone.areacode}) ${property.listedBy.phone.prefix}-${property.listedBy.phone.number}`
                                            :
                                            property.attributionInfo !== undefined && property.attributionInfo !== null && property.attributionInfo.agentPhoneNumber !== undefined && property.attributionInfo.agentPhoneNumber !== null && property.attributionInfo.agentPhoneNumber !== "" ?
                                            formatPhoneNumber(property.attributionInfo.agentPhoneNumber)
                                            :
                                            `No phone`
                                        }
                                    </span>
                                    <span className="body-regular colour-primary block-text margin-sides-small">
                                        •
                                    </span>
                                    <span className="body-regular-underline colour-link">
                                        {
                                            property.listedBy !== undefined && property.listedBy !== null && property.listedBy.email !== undefined && property.listedBy.email !== null && property.listedBy.email !== "" ?
                                            property.listedBy.email
                                            :
                                            property.attributionInfo !== undefined && property.attributionInfo !== null && property.attributionInfo.agentEmail !== undefined && property.attributionInfo.agentEmail !== null && property.attributionInfo.agentEmail !== "" ?
                                            property.attributionInfo.agentEmail
                                            :
                                            `No email`
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="listings-card-border">
                            </div>
                        </div>
                        :
                        null
                    }
                    {
                        type === "offer" ?
                        <div className="listings-card-offer-buttons-row">
                            <div className="listings-card-offer-button-element">
                                <ActionButton
                                    disabled={offerLoading}
                                    onClick={() => downloadOffer()}
                                >
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="17"
                                        height="16" 
                                        viewBox="0 0 17 16" 
                                        fill="none"
                                        className="subtext-margin-right-large"
                                    >
                                        <path 
                                            d="M9.03367 11.9488L13.8367 7.14577C14.1314 6.85104 14.1314 6.37317 13.8367 6.07844C13.5419 5.7837 13.0641 5.7837 12.7693 6.07844L9.25472 9.59305L9.25472 1.75472C9.25472 1.3379 8.91682 1 8.5 1C8.08318 1 7.74528 1.3379 7.74528 1.75472L7.74528 9.59305L4.23068 6.07844C3.93594 5.7837 3.45808 5.7837 3.16335 6.07844C2.86861 6.37317 2.86861 6.85104 3.16335 7.14577L7.96634 11.9488C8.26107 12.2435 8.73893 12.2435 9.03367 11.9488Z" 
                                            fill="#FFFEFC"
                                        />
                                        <path 
                                            d="M1.25472 14.283C0.837898 14.283 0.5 14.6209 0.5 15.0377C0.5 15.4546 0.837898 15.7925 1.25472 15.7925H15.7453C16.1621 15.7925 16.5 15.4546 16.5 15.0377C16.5 14.6209 16.1621 14.283 15.7453 14.283H1.25472Z" 
                                            fill="#FFFEFC"
                                        />
                                    </svg>
                                    Download offer
                                </ActionButton>
                            </div>
                            <div className="listings-card-offer-button-element">
                                <BlackActionButton
                                    // onClick={() => markSent()}
                                    onClick={() => viewOfferModal(property)}
                                >
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="17" 
                                        height="16" 
                                        viewBox="0 0 17 16" 
                                        fill="none"
                                        className="subtext-margin-right-large"
                                    >
                                        <path 
                                            fillRule="evenodd" 
                                            clipRule="evenodd" 
                                            d="M15.4995 1.43765C16.1415 2.0449 16.1696 3.05758 15.5623 3.69952L6.22901 13.5662C5.93211 13.8801 5.52089 14.0605 5.08888 14.0665C4.65687 14.0725 4.2408 13.9035 3.93529 13.598L0.468629 10.1314C-0.15621 9.50653 -0.15621 8.49347 0.468629 7.86863C1.09347 7.24379 2.10653 7.24379 2.73137 7.86863L5.0348 10.1721L13.2376 1.50048C13.8449 0.858536 14.8576 0.830406 15.4995 1.43765Z" 
                                            fill="#FFFEFC"
                                        />
                                    </svg>
                                    Send offer
                                </BlackActionButton>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    )
};

export default ListingsCard;