import React from 'react';
import '../styles/FilterDropdown.css';
import { ActionButton, colour, formatterCompact } from '../styles/GlobalStyles';
import { ChooseBudget, ChoosePropertySpecifics, ChoosePropertyType, ChooseValueAddOpportunity, MiscellaneousFilters } from './';
import { deleteSearchParam, resetFilterType, saveSearchParams } from '../functions';

function FilterDropdown(props) {
    const item = props.item;
    const openFilters = props.openFilters;
    const setOpenFilters = props.setOpenFilters;
    const openFilterDropdown = props.openFilterDropdown;
    const searchParams = props.searchParams;
    const setSearchParams = props.setSearchParams;
    const index = props.index;
    const propertyTypes = props.propertyTypes;
	const setPropertyTypes = props.setPropertyTypes;
	const units = props.units;
	const setUnits = props.setUnits;
	const budgetRange = props.budgetRange;
	const setBudgetRange = props.setBudgetRange;
	const bedrooms = props.bedrooms;
	const setBedrooms = props.setBedrooms;
	const bathrooms = props.bathrooms;
	const setBathrooms = props.setBathrooms;
	const squareFootage = props.squareFootage;
	const setSquareFootage = props.setSquareFootage;
	const constructionYears = props.constructionYears;
	const setConstructionYears = props.setConstructionYears;
    const getPropertyTypesParam = searchParams.get("propertyType");
    const getBudgetRangeParam = searchParams.get("budgetRange");
    const getPropertySpecificsParam = searchParams.get("propertySpecifics");
    const getValueAddParam = searchParams.get("valueAdd");
    const getMoreParam = searchParams.get("more");
    const setPage = props.setPage;
    const fsbo = props.fsbo;
    const setFsbo = props.setFsbo;
    const setFiltersChanged = props.setFiltersChanged;
    const onlyFixerUppers = props.onlyFixerUppers;
    const setOnlyFixerUppers = props.setOnlyFixerUppers;
    const hideFixerUppers = props.hideFixerUppers;
    const setHideFixerUppers = props.setHideFixerUppers;
    const inherited = props.inherited;
    const setInherited = props.setInherited;
    const neighbourhoodGradeMin = props.neighbourhoodGradeMin;
    const setNeighbourhoodGradeMin = props.setNeighbourhoodGradeMin;
    const neighbourhoodGradeMax = props.neighbourhoodGradeMax;
	const setNeighbourhoodGradeMax = props.setNeighbourhoodGradeMax;

    const resetFilters = async() => {
        let label;
        if ( index === 0 ) {
            label = "propertyType";
        }
        else if ( index === 1 ) {
            label = "budgetRange";
        }
        else if ( index === 2 ) {
            label = "propertySpecifics";
        }
        else if ( index === 3 ) {
            label = "valueAdd";
        }
        else if ( index === 4 ) {
            label = "more";
        }
        await deleteSearchParam(label, searchParams, setSearchParams);
        await resetFilterType(
            index,
            setPropertyTypes, 
            setUnits,
            setBudgetRange,
            setBedrooms,
            setBathrooms,
            setSquareFootage,
            setConstructionYears,
            setOnlyFixerUppers,
            setHideFixerUppers,
            setInherited,
            setNeighbourhoodGradeMin,
            setNeighbourhoodGradeMax,
            setFsbo,
            setPage
        );
        setOpenFilters(null);
        setFiltersChanged(true);
    };

    const checkActive = () => {
        if ( index === 0 && getPropertyTypesParam !== null ) {
            return true;
        }
        else if ( index === 1 && getBudgetRangeParam !== null ) {
            return true;
        }
        else if ( index === 2 && getPropertySpecificsParam !== null ) {
            return true;
        }
        else if ( index === 3 && getValueAddParam !== null ) {
            return true;
        }
        else if ( index === 4 && getMoreParam !== null ) {
            return true;
        }
        else {
            return false;
        }
    };

    const filterText = () => {
        if ( index === 0 ) {
            const text = JSON.parse(getPropertyTypesParam);
            if ( text.propertyTypes.includes("MFH") && text.propertyTypes.includes("SFH") ) {
                return "SFH, MFH";
            }
            else if ( text.propertyTypes.includes("MFH") ) {
                return "MFH";
            }
            else if ( text.propertyTypes.includes("SFH") ) {
                return "SFH";
            }
        }
        else if ( index === 1 ) {
            const text = JSON.parse(getBudgetRangeParam);
            if ( text[0] === 0 && text[1] === 1000000000 ) {
                return "Any";
            }
            else if ( text[1] === 1000000000 ) {
                return `${formatterCompact.format(text[0])}+`;
            }
            else {
                return `${formatterCompact.format(text[0])} - ${formatterCompact.format(text[1])}`;
            }
        }
        else if ( index === 2 ) {
            const text = JSON.parse(getPropertySpecificsParam);
            return `${text.bedrooms}+ bd, ${text.bathrooms}+ ba...`;
        }
        else if ( index === 3 ) {
            const text = JSON.parse(getValueAddParam);
            return text.onlyFixerUppers === false && text.hideFixerUppers === false ? "Both" : text.onlyFixerUppers === true ? "Fixer Uppers" : "Turnkey";
        }
        else if ( index === 4 ) {
            const text = JSON.parse(getMoreParam);
            if ( (text.inherited === true || text.inherited === "true") && (text.fsbo === true || text.fsbo === "true") ) {
                return "Inherited, FSBO";
            }
            else if ( text.inherited === true || text.inherited === "true" ) {
                return "Inherited";
            }
            else {
                return text.fsbo === true || text.fsbo === "true" ? "FSBO" : "Any";
            }
        }
    };

    const changeFSBOFilter = (val) => {
        setFsbo(val === "true" || val === true ? true : false);
    };

    const saveFilters = async() => {
        setPage(1);
        if ( index === 0 ) {
            const valuesArray = propertyTypes.map(e => e.included ? e.abbreviation : null);
            const filteredValuesArray = valuesArray.filter(value => value !== null);
            const commaSeparatedString = filteredValuesArray.join(',');
            const newObject = {
                propertyTypes: commaSeparatedString
            };
            if ( commaSeparatedString.includes("MFH") ) {
                newObject.units = units;
            }
            else if ( commaSeparatedString === "" ) {
                newObject.propertyTypes = "MFH,SFH";
                newObject.units = 1;
            }
            const label = ["propertyType", "page"];
            await saveSearchParams(label, [JSON.stringify(newObject), 1], searchParams, setSearchParams);
        }
        else if ( index === 1 ) {
            const label = ["budgetRange", "page"];
            await saveSearchParams(label, [JSON.stringify(budgetRange), 1], searchParams, setSearchParams);
        }
        else if ( index === 2 ) {
            const newObject = {
                bedrooms: bedrooms,
                bathrooms: bathrooms,
                squareFootage: squareFootage
            };

            const lowConstructionYearLength = constructionYears[0].length;
            const highConstructionYearLength = constructionYears[1].length;
            if ( 
                constructionYears[0] !== "" && constructionYears[1] !== "" &&
                Number(constructionYears[0]) <= Number(constructionYears[1]) &&
                lowConstructionYearLength === 4 && highConstructionYearLength === 4
            ) {
                const numberArray = constructionYears.map(e => Number(e));
                newObject.constructionYears = numberArray;
            }
            else if ( constructionYears[0].length === 4 && constructionYears[1].length < 4 ) {
                newObject.constructionYears = [Number(constructionYears[0]), 2024];
                setConstructionYears([Number(constructionYears[0]), 2024]);
            }
            const label = ["propertySpecifics", "page"];
            await saveSearchParams(label, [JSON.stringify(newObject), 1], searchParams, setSearchParams);
        }
        else if ( index === 3 ) {
            const newObject = {
                onlyFixerUppers: onlyFixerUppers,
                hideFixerUppers: hideFixerUppers
            };
            const label = ["valueAdd", "page"];
            await saveSearchParams(label, [JSON.stringify(newObject), 1], searchParams, setSearchParams);
        }
        else if ( index === 4 ) {
            const newObject = {
                fsbo: fsbo,
                inherited: inherited,
                neighborhoodGradeMin: neighbourhoodGradeMin,
                neighborhoodGradeMax: neighbourhoodGradeMax
            };
            const label = ["more", "page"];
            await saveSearchParams(label, [JSON.stringify(newObject), 1], searchParams, setSearchParams);
        
        }
        setOpenFilters(null);
        setFiltersChanged(true);
    };

    return (
        <div 
            className="filter-dropdown-outer-container filter-dropdown-chip cursor-pointer relative-container"
            onClick={(e) => openFilterDropdown(index, e)}
        >
            <div className={"filter-dropdown-inner-container filter-dropdown-chip " + (openFilters === index ? "filter-dropdown-reverse" : "")}>
                {
                    checkActive() === true ?
                    <span className="body-regular colour-primary filter-dropdown-chip block-text subtext-margin-right">
                        {filterText()}
                    </span>
                    :
                    <span className="body-regular colour-secondary filter-dropdown-chip block-text subtext-margin-right">
                        {item.label}
                    </span>
                }
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                    <path d="M4 6L7.4641 1.5H0.535898L4 6Z" fill={colour.grayScaleBlack}/>
                </svg>
            </div>
            {
                openFilters === index ?
                <div className="filter-dropdown-element-container bg-colour-light">
                    <div className="filter-dropdown-inner-element-container">
                        <h3 className="heading-small-semibold colour-quaternary margin-x-large">
                            {item.label}
                        </h3>
                        <div className="filter-dropdown-element-body-container margin-medium">
                            {
                                index === 0 ?
                                <ChoosePropertyType
                                    propertyTypes={propertyTypes}
                                    setPropertyTypes={setPropertyTypes}
                                    units={units}
                                    setUnits={setUnits}
                                />
                                :
                                index === 1 ?
                                <ChooseBudget
                                    budgetRange={budgetRange}
                                    setBudgetRange={setBudgetRange}
                                />
                                :
                                index === 2 ?
                                <ChoosePropertySpecifics
                                    bedrooms={bedrooms}
                                    setBedrooms={setBedrooms}
                                    bathrooms={bathrooms}
                                    setBathrooms={setBathrooms}
                                    squareFootage={squareFootage}
                                    setSquareFootage={setSquareFootage}
                                    constructionYears={constructionYears}
                                    setConstructionYears={setConstructionYears}
                                />
                                :
                                index === 3 ?
                                <ChooseValueAddOpportunity
                                    onlyFixerUppers={onlyFixerUppers}
                                    setOnlyFixerUppers={setOnlyFixerUppers}
                                    hideFixerUppers={hideFixerUppers}
						            setHideFixerUppers={setHideFixerUppers}
                                    setDisabled={null}
                                />
                                :
                                index === 4 ?
                                <MiscellaneousFilters
                                    fsbo={fsbo}
                                    changeFSBOFilter={changeFSBOFilter}
                                    inherited={inherited}
                                    setInherited={setInherited}
                                    neighbourhoodGradeMin={neighbourhoodGradeMin}
                                    setNeighbourhoodGradeMin={setNeighbourhoodGradeMin}
                                    neighbourhoodGradeMax={neighbourhoodGradeMax}
						            setNeighbourhoodGradeMax={setNeighbourhoodGradeMax}
                                />
                                :
                                null
                            }
                        </div>
                        <div className="filter-dropdown-element-save-container">
                            <div 
                                className=""
                                onClick={() => resetFilters()}
                            >
                                <span className="body-regular-underline colour-link">
                                    Reset
                                </span>
                            </div>
                            <div className="filter-dropdown-save-container">
                                <ActionButton
                                    onClick={() => saveFilters()}
                                >
                                    Save
                                </ActionButton>
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
        </div>
    )
};

export default FilterDropdown;