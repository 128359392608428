import React, { useState } from 'react';
import '../styles/MyMarkets.css';
import { SecondaryButton } from '../buttons';
import { Loading, UnassignedCounties } from './';

function MyMarkets(props) {
    const anyUnassignedCounty = props.anyUnassignedCounty;
    const goToChooseCounties = props.goToChooseCounties;
    const recentlyViewed = props.recentlyViewed;
    const viewMarket = props.viewMarket;
    const marketSettings = props.marketSettings;
    const setMarketSettings = props.setMarketSettings;
    const removeViewedMarket = props.removeViewedMarket;
    const [maxViewed, setMaxViewed] = useState([0, 4]);
    const [loading, setLoading] = useState(false);

    const openSettings = (item) => {
        setMarketSettings(item.msaCode);
    };

    const deleteMarket = async(item) => {
        setLoading(true);
        await removeViewedMarket(item);
        setLoading(false);
    };

    const previousList = () => {
        const newMin = maxViewed[0] - 4;
        const newMax = maxViewed[1] - 4;
        setMaxViewed([newMin, newMax]);
    };

    const nextList = () => {
        const newMin = maxViewed[0] + 4;
        const newMax = maxViewed[1] + 4;
        setMaxViewed([newMin, newMax]);
    };

    return (
        <div className="my-markets-outer-container">
            <div className="my-markets-inner-container">
                <div className="my-markets-title-container">
                    <div className="my-markets-title-left-container">
                        <h1 className="heading-small-semibold colour-primary">
                            My markets & recently viewed
                        </h1>
                        {
                            anyUnassignedCounty === true ?
                            <UnassignedCounties
                                cta={goToChooseCounties}
                            />
                            :
                            null
                        }
                    </div>
                    {/* <div className="my-markets-title-right-container">
                        <PrimaryButton
                            text="Add a market"
                            cta={newMarket}
                            disabled={false}
                            size="small"
                            leftIcon={PlusWhite}
                            leftIconClass="buy-box-grid-plus-icon"
                            rightIcon={null}
                            alt="Plus icon"
                        />
                        {
                            startTyping === true ?
                            <span className="body-regular colour-green block-text margin-top-x-small">
                                start typing!
                            </span>
                            :
                            null
                        }
                    </div> */}
                </div>
                <div className="buy-boxes-grid-divider-line margin-tops">
                </div>
                <div className="my-markets-body-container">
                    {
                        recentlyViewed.map((item, index) =>
                            index >= maxViewed[0] && index < maxViewed[1] &&
                            <div 
                                className={"my-markets-recently-viewed-element " + (loading === true ? "relative-container" : "")}
                                key={index}
                            >
                                {
                                    loading === true && item.msaCode === marketSettings &&
                                    <Loading />
                                }
                                <span 
                                    className="body-regular colour-link cursor-pointer"
                                    onClick={() => viewMarket(item)}
                                >
                                    {item.msaTitle}
                                </span>
                                <div className="my-markets-icon-container cursor-pointer relative-container">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="24" 
                                        height="24" 
                                        viewBox="0 0 24 24" 
                                        fill="none"
                                        className="my-markets-element-more-icon"
                                        onClick={() => openSettings(item)}
                                    >
                                        <path 
                                            d="M5.5 12C5.5 13.2426 4.49264 14.25 3.25 14.25C2.00736 14.25 1 13.2426 1 12C1 10.7574 2.00736 9.75 3.25 9.75C4.49264 9.75 5.5 10.7574 5.5 12Z" 
                                            fill="#2F4858"
                                        />
                                        <path 
                                            d="M14.25 12C14.25 13.2426 13.2426 14.25 12 14.25C10.7574 14.25 9.75 13.2426 9.75 12C9.75 10.7574 10.7574 9.75 12 9.75C13.2426 9.75 14.25 10.7574 14.25 12Z" 
                                            fill="#2F4858"
                                        />
                                        <path 
                                            d="M20.75 14.25C21.9926 14.25 23 13.2426 23 12C23 10.7574 21.9926 9.75 20.75 9.75C19.5074 9.75 18.5 10.7574 18.5 12C18.5 13.2426 19.5074 14.25 20.75 14.25Z" 
                                            fill="#2F4858"
                                        />
                                    </svg>
                                    {
                                        item.msaCode === marketSettings &&
                                        <div className="download-selector-outer-container bg-colour-white">
                                            <div 
                                                className="download-selector-element-container"
                                                onClick={() => deleteMarket(item)}
                                            >
                                                <span className="body-regular colour-error">
                                                    Delete
                                                </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    }
                    <div className="my-markets-buttons-row">
                        <div className="subtext-margin-right-large">
                            <SecondaryButton
                                text="Previous"
                                cta={previousList}
                                size="small"
                                disabled={maxViewed[0] === 0 ? true : false}
                            />
                        </div>
                        <div className="my-markets-next-button">
                            <SecondaryButton
                                text="Next"
                                cta={nextList}
                                size="small"
                                disabled={maxViewed[1] >= recentlyViewed.length ? true : false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default MyMarkets;