import React from 'react';
import '../styles/PropertyListTopBar.css';
import '../styles/BuyBoxSetup.css';
import { Filters } from '../assets';
import { FilterDropdown } from './';
import { recordEvent } from '../functions';

function PropertyListTopBar(props) {
    const setFiltersModalOpen = props.setFiltersModalOpen;
    const searchParams = props.searchParams;
    const setSearchParams = props.setSearchParams;
    const setPage = props.setPage;
    const propertyTypes = props.propertyTypes;
	const setPropertyTypes = props.setPropertyTypes;
	const units = props.units;
	const setUnits = props.setUnits;
	const budgetRange = props.budgetRange;
	const setBudgetRange = props.setBudgetRange;
	const bedrooms = props.bedrooms;
	const setBedrooms = props.setBedrooms;
	const bathrooms = props.bathrooms;
	const setBathrooms = props.setBathrooms;
	const squareFootage = props.squareFootage;
	const setSquareFootage = props.setSquareFootage;
	const constructionYears = props.constructionYears;
	const setConstructionYears = props.setConstructionYears;
    const setPropertiesOnPageChange = props.setPropertiesOnPageChange;
    const fsbo = props.fsbo;
    const setFsbo = props.setFsbo;
    const setFiltersChanged = props.setFiltersChanged;
    const openFilters = props.openFilters;
    const setOpenFilters = props.setOpenFilters;
    const resetAllFilters = props.resetAllFilters;
    const onlyFixerUppers = props.onlyFixerUppers;
    const setOnlyFixerUppers = props.setOnlyFixerUppers;
    const hideFixerUppers = props.hideFixerUppers;
    const setHideFixerUppers = props.setHideFixerUppers;
    const inherited = props.inherited;
    const setInherited = props.setInherited;
    const neighbourhoodGradeMin = props.neighbourhoodGradeMin;
    const setNeighbourhoodGradeMin = props.setNeighbourhoodGradeMin;
    const neighbourhoodGradeMax = props.neighbourhoodGradeMax;
    const setNeighbourhoodGradeMax = props.setNeighbourhoodGradeMax;

    const filterTypes = [
        {
            label: "Property type"
        },
        {
            label: "Budget"
        },
        {
            label: "Property specifics"
        },
        {
            label: "Value-add"
        },
        {
            label: "More"
        }
    ];

    const openFiltersModal = () => {
		setFiltersModalOpen(true)
        recordEvent("Open Filters", {});
	};

    const clickOutside = (e) => {

    }

    const openFilterDropdown = (index, e) => {
        const target = e.target;

        if ( (target.tagName === "path" || target.tagName === "svg") ) {
            if ( openFilters === index ) {
                setOpenFilters(null);
            }
            else {
                setOpenFilters(index);
            }
            return;
        }
        else if ( target.className.includes("filter-dropdown-chip") && openFilters === index) {
            setOpenFilters(null);
            return;
        }

        if ( index !== openFilters ) {
            setOpenFilters(index);
        }
    };

    return (
        <div className="property-list-top-bar-outer-container">
            <div 
                className="property-list-top-bar-inner-container"
                onClick={(e) => clickOutside(e)}
            >
                <div className="property-list-top-bar-left-container relative-container">
                    <div 
                        className="property-list-top-bar-filters-button"
                        onClick={() => openFiltersModal()}
                    >
                        <img
                            src={Filters}
                            className="top-bar-filters-icon"
                            alt="Filters"
                        />
                        <span className="body-regular text-link">
                            Filters
                        </span>
                    </div>
                    <div className="property-list-top-bar-filter-chips-row">
                        {
                            filterTypes.map((item, index) =>
                                <FilterDropdown
                                    item={item}
                                    key={index}
                                    openFilters={openFilters}
                                    setOpenFilters={setOpenFilters}
                                    openFilterDropdown={openFilterDropdown}
                                    index={index}
                                    searchParams={searchParams}
                                    setSearchParams={setSearchParams}
                                    propertyTypes={propertyTypes}
                                    setPropertyTypes={setPropertyTypes}
                                    units={units}
                                    setUnits={setUnits}
                                    budgetRange={budgetRange}
                                    setBudgetRange={setBudgetRange}
                                    bedrooms={bedrooms}
                                    setBedrooms={setBedrooms}
                                    bathrooms={bathrooms}
                                    setBathrooms={setBathrooms}
                                    squareFootage={squareFootage}
                                    setSquareFootage={setSquareFootage}
                                    constructionYears={constructionYears}
                                    setConstructionYears={setConstructionYears}
                                    setPage={setPage}
                                    setPropertiesOnPageChange={setPropertiesOnPageChange}
                                    fsbo={fsbo}
                                    setFsbo={setFsbo}
                                    setFiltersChanged={setFiltersChanged}
                                    onlyFixerUppers={onlyFixerUppers}
                                    setOnlyFixerUppers={setOnlyFixerUppers}
                                    hideFixerUppers={hideFixerUppers}
                                    setHideFixerUppers={setHideFixerUppers}
                                    inherited={inherited}
                                    setInherited={setInherited}
                                    neighbourhoodGradeMin={neighbourhoodGradeMin}
                                    setNeighbourhoodGradeMin={setNeighbourhoodGradeMin}
                                    neighbourhoodGradeMax={neighbourhoodGradeMax}
                                    setNeighbourhoodGradeMax={setNeighbourhoodGradeMax}
                                />
                            )
                        }
                        {
                            searchParams.get("propertyType") !== null ||
                            searchParams.get("budgetRange") !== null ||
                            searchParams.get("propertySpecifics") !== null ||
                            searchParams.get("valueAdd") !== null ||
                            searchParams.get("more") !== null ?
                            <span 
                                className="body-regular-underline colour-link block-text subtext-margin-left-large"
                                onClick={() => resetAllFilters()}
                            >
                                Clear filters
                            </span>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertyListTopBar;