import React from 'react';
import { BackOnMarket } from '../assets';

function BackOnMarketBadge() {
    return (
        <div className="fixer-upper-badge-outer-container">
			<img
				src={BackOnMarket}
				className="fixer-upper-badge-icon"
				alt="Back on Market"
			/>
			<span className="top-pick-badge-text">
				Back on Market
			</span>
		</div>
    )
}

export default BackOnMarketBadge;