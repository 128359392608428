import React, { useState, useEffect } from 'react';
import '../styles/PropertySearchLandingPage.css';
import { Footer, Header, Loading, PlaceInput, SideNavigation } from '../components';
import { containsNumbers, checkOnAuthStateChanged, recordEvent, getUserDocuments } from '../functions';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { ActionButton } from '../styles/GlobalStyles';
import { LandingImage } from '../assets/images';

function PropertySearchLandingPage() {
    const [searchParams] = useSearchParams({});
    const getAddress = searchParams.get("address");
    const [searchLocation, setSearchLocation] = useState(getAddress === null ? "" : getAddress);
    const [error, setError] = useState("");
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [inputDisabled, setInputDisabled] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    useEffect(() => {
        document.title = "Quick Report";

        const fetchUserData = async() => {
			const getUserId = await checkOnAuthStateChanged();
			if ( getUserId.status === 200 ) {
                const userId = getUserId.userId;
                const collections = [
					"Users",
                    "Subscriptions"
				];

                const today = new Date();
                const todaySeconds = today.getTime() / 1000;
				const queryData = await getUserDocuments(collections, userId);
                if ( queryData[1].status === 200 ) {
                    const subscriptionData = queryData[1].data;
                    const creativeSubs = [];
                    for (let index = 0; index < subscriptionData.length; index++) {
                        const element = subscriptionData[index];
                        if ( element.endDate.seconds > todaySeconds && element.creative === true ) {
                            creativeSubs.push(element);
                        }
                    }

                    const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
                    const isAdmin = adminIds.includes(userId);
                    if ( isAdmin === true ) {
                        setInputDisabled(false);
                    }
                    else {
                        for (let index = 0; index < subscriptionData.length; index++) {
                            const element = subscriptionData[index];
                            if ( element.endDate.seconds > todaySeconds && element.creative === true ) {
                                setInputDisabled(false);
                                break;
                            }
                        }
                    }
                    setSubscriptions(creativeSubs);
                    setUserData(queryData[0].data);
                }
                else {
                    const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
                    const isAdmin = adminIds.includes(userId);
                    if ( isAdmin === true ) {
                        setInputDisabled(false);
                    }
                    setUserData(queryData[0].data);
                }
            }
            setLoading(false);
        };

        fetchUserData();
    }, []);

    const changeLocation = (text) => {
        setSearchLocation(text);
    };

    const selectedLocation = async(place) => {
        if ( place !== null ) {
            const addressElements = place.value.terms;
            const firstElement = addressElements.length !== 0 ? addressElements[0].value : "";
            const address = place.label;
            const firstWord = firstElement.replace(/ .*/,'');
            const checkNumber = await containsNumbers(firstWord);
            if ( checkNumber === true ) {
                setError("");
                const encodedAddress = encodeURIComponent(address);
                recordEvent("Property Searched", {
                    "Address": address
                })

                navigate(`/quick-report/${encodedAddress}`, {
                    state: state
                });
            }
            else {
                setError("It doesn't look like you entered an address.. 🤔");
            }
            setSearchLocation("");
        }
    };

    const upgrade = async() => {
        navigate("/pricing", {
            state: state
        });
    };
    
    return (
        <div className="property-search-outer-container">
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
                mobileNav={false}
            />
            <div className="property-search-inner-container bg-colour-white">
                <div className="property-search-row-container">
                    <SideNavigation
                        navigate={navigate}
                        state={state}
                        userData={userData}
                    />
                    <div className="property-search-landing-page-right-container">
                        <div className="property-search-landing-page-title-container margin-x-small">
                            <div className="button-row">
                                <div className="favourites-title-text-container">
                                    <h1 className="heading-small-semibold colour-primary">
                                        Quick Report
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="property-search-landing-page-divider-container margin-tops">
                        </div>
                        {
                            loading === true ?
                            <Loading />
                            :
                            null
                        }
                        <div className="property-search-landing-component-container">
                            <div className="property-search-landing-component-image-container">
                                <img
                                    src={LandingImage}
                                    className="property-search-landing-image"
                                    alt="Where to invest"
                                />
                            </div>
                            <div className="property-search-landing-component-text-container">
                                <h1 className="body-semibold colour-primary text-align-left margin-medium">
                                    Search to get a quick report
                                </h1>
                                <div className="property-search-input-container">
                                    <PlaceInput
                                        placeholder="Search any residential address"
                                        localLocation={searchLocation}
                                        changeLocation={changeLocation}
                                        selectedLocation={selectedLocation}
                                        types={['address']}
                                        disabled={inputDisabled}
                                    />
                                    {
                                        inputDisabled === true && loading === false ?
                                        <div className="property-search-landing-no-access-container">
                                            <span className="body-regular colour-error block-text text-align-center margin-x-small">
                                                You need a subscription to use this feature
                                            </span>
                                            <div className="property-search-landing-no-access-button-container">
                                                <ActionButton
                                                    onClick={() => upgrade()}
                                                >
                                                    Upgrade now
                                                </ActionButton>
                                            </div>
                                        </div>
                                        :
                                        error === "" ?
                                        null
                                        :
                                        <span className="body-regular colour-error block-text margin-top-small">
                                            {error}
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PropertySearchLandingPage;