import React, { useState } from 'react';
import '../styles/StickyROI.css';
import { ActionButton, formatterLong, percentage } from '../styles/GlobalStyles';
import { PendingTag, ExitScreen } from './';
import { toggleFavourite } from '../functions';
import { Heart, HeartActiveBlue } from '../assets';
import { useNavigate } from 'react-router-dom';

function StickyROI(props) {

	const highLevelProfits = props.highLevelProfits;
	const property = props.property;
	const offerLoading = props.offerLoading;
	const getOffer = props.getOffer;
	const offerLink = props.offerLink;
	const goToOffers = props.goToOffers;
	const propertySearch = props.propertySearch;
	const blurred = props.blurred;
	const favouriteDisable = blurred === true ? true : props.favouriteDisable;
	const setFavouriteDisable = props.setFavouriteDisable;
	const favourites = props.favourites;
	const setFavourites = props.setFavourites;
	const cityId = props.cityId;
	const exitStrategy = props.exitStrategy;
	const zpidIndex = favourites.findIndex(e => e.zpid === property.zpid);
	const inFavourites = zpidIndex === -1 ? false : true;
	const [downloadSelect, setDownloadSelect] = useState(false);
	const cityObject = {
		cityId: cityId,
		city: property.address.city,
		state: property.address.state
	};
	const navigate = useNavigate();

	const closeDownloadSelector = () => {
		setDownloadSelect(false);
	};

	return (
		<div className={"sticky-roi-outer-container " + (property.homeStatus === "PENDING" ? "sticky-roi-pending-status" : "")}>
			{
				downloadSelect === true ?
				<ExitScreen
					clickScreen={() => closeDownloadSelector()}
				/>
				:
				null
			}
			<div className="sticky-roi-inner-container">
				<div className="sticky-roi-coc-container">
					<div className="sticky-roi-coc-element">
						<h3 className="label-semibold-caps colour-primary margin-x-x-small">
							{
								exitStrategy === 1 && highLevelProfits.leaseOptionTotalCosts <= 0 ?
								"SURPLUS"
								:
								"UP-FRONT COSTS"
							}
						</h3>
						{
							exitStrategy === 1 ?
							<h2 className={"heading-large-semibold " + (highLevelProfits.leaseOptionTotalCosts <= 0 ? "colour-green" : "colour-error")}>
								{formatterLong.format(Math.round(Math.abs(highLevelProfits.leaseOptionTotalCosts))).replace(".00", "")}
							</h2>
							:
							<h2 className="heading-large-semibold colour-error">
								{formatterLong.format(Math.round(highLevelProfits.totalCosts)).replace(".00", "")}
							</h2>
						}
					</div>
					<div className="sticky-roi-coc-element">
						<h3 className="label-semibold-caps colour-primary margin-x-x-small">
							CASH-FLOW
						</h3>
						{
							exitStrategy === 1 ?
							<h2 className={"heading-large-semibold " + (highLevelProfits.leaseOptionProfit > 0 || blurred === true ? "colour-green" : "colour-error") + (blurred === true ? " blurred" : "")}>
								{
									blurred === true ?
									"$123"
									:
									formatterLong.format(highLevelProfits.leaseOptionProfit).replace(".00", "")
								}
							</h2>
							:
							exitStrategy === 2 ?
							<h2 className={"heading-large-semibold " + (highLevelProfits.strCashOnCash > 0.05 || blurred === true ? "colour-green" : highLevelProfits.strCashOnCash < 0 ? "colour-error" : "colour-medium") + (blurred === true ? " blurred" : "")}>
								{
									blurred === true ?
									"$123"
									:
									formatterLong.format(highLevelProfits.strProfit).replace(".00", "")
								}
							</h2>
							:
							<h2 className={"heading-large-semibold " + (highLevelProfits.cashOnCash > 0.05 || blurred === true ? "colour-green" : highLevelProfits.cashOnCash < 0 ? "colour-error" : "colour-medium") + (blurred === true ? " blurred" : "")}>
								{
									blurred === true ?
									"$123"
									:
									formatterLong.format(highLevelProfits.monthlyProfit).replace(".00", "")
								}
							</h2>
						}
					</div>
					<div className="sticky-roi-coc-element">
						<h3 className="label-semibold-caps colour-primary margin-x-x-small">
							CASH ON CASH
						</h3>
						{
							exitStrategy === 1 ?
							<h2 className={"heading-large-semibold " + (highLevelProfits.leaseOptionProfit > 0.05 || blurred === true ? "colour-green" : "colour-error") + (blurred === true ? " blurred" : "")}>
								{
									blurred === true ?
									`4.2%`
									:
									highLevelProfits.leaseOptionTotalCosts <= 0 ?
									"∞"
									:
									percentage.format(highLevelProfits.leaseOptionCashOnCash).replace(".00", "")
								}
							</h2>
							:
							exitStrategy === 2 ?
							<h2 className={"heading-large-semibold " + (highLevelProfits.strCashOnCash > 0.05 || blurred === true ? "colour-green" : highLevelProfits.strCashOnCash < 0 ? "colour-error" : "colour-medium") + (blurred === true ? " blurred" : "")}>
								{
									blurred === true ?
									`4.2%`
									:
									percentage.format(highLevelProfits.strCashOnCash).replace(".00", "")
								}
							</h2>
							:
							<h2 className={"heading-large-semibold " + (highLevelProfits.cashOnCash > 0.05 || blurred === true ? "colour-green" : highLevelProfits.cashOnCash < 0 ? "colour-error" : "colour-medium") + (blurred === true ? " blurred" : "")}>
								{
									blurred === true ?
									`4.2%`
									:
									percentage.format(highLevelProfits.cashOnCash).replace(".00", "")
								}
							</h2>
						}
					</div>
					{
						property.homeStatus === "PENDING" ?
						<PendingTag />
						:
						null
					}
				</div>
				<div className="sticky-roi-coc-container">
					{
						(propertySearch === true && (property.zpid === undefined || property.zpid === null) ) ?
						null
						:
						<div className="new-property-interactive-button-element subtext-margin-right-large">
							<div 
								className="new-property-favourite-icon-container icon-button"
								onClick={() => favouriteDisable === true ? null : toggleFavourite(favourites, property.zpid, setFavourites, setFavouriteDisable, navigate, cityObject, null)}
							>
								<img
									src={inFavourites === true ? HeartActiveBlue : Heart}
									className="new-property-favourite"
									alt="Favourite"
								/>
							</div>
						</div>
					}
					{
						offerLink !== "" ?
						<div 
							className="text-button"
							onClick={() => goToOffers()}
						>
							<span className="body-regular text-link">
								Send offer {">"}
							</span>
						</div>
						:
						<div className="sticky-roi-offer-container">
							<ActionButton
								disabled={blurred === true ? true : offerLoading}
								onClick={() => getOffer()}
							>
								Generate Offer
							</ActionButton>
						</div>
					}
				</div>
			</div>
		</div>
	)
}

export default StickyROI;